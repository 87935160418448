import React from 'react';
import { t } from '../utils/labels';
import { AwardDetailQuery } from '../graphql/query/AwardDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Award.json';
import AwardStatusIntrospection from '../static/introspection/AwardStatus.json';
import { AwardCategoryDetail } from './AwardCategoryDetail';
import { AwardCategoryEdit } from './AwardCategoryEdit';
import { AwardCategoryCreate } from './AwardCategoryCreate';
import { AwardInvitedUserEdit } from './AwardInvitedUserEdit';
import { AwardInvitedUserDetail } from './AwardInvitedUserDetail';
import { AwardInvitedUserCreate } from './AwardInvitedUserCreate';
import { StatusSetter } from '../components/StatusSetter';
import { setAwardStatusMutation } from '../graphql/mutations/setAwardStatusMutation';
import { Link } from 'react-router-dom';
import { ACTIONS, generateUrl } from '../utils/urls';
import { Button } from 'reactstrap';
import { AwardResultCreate } from './AwardResultCreate';
import { AwardResultDetail } from './AwardResultDetail';
import { AwardResultEdit } from './AwardResultEdit';
import { AwardInvitedCompanyEdit } from './AwardInvitedCompanyEdit';
import { AwardInvitedCompanyCreate } from './AwardInvitedCompanyCreate';
import { AwardInvitedCompanyDetail } from './AwardInvitedCompanyDetail';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { eLabel, generateDefaultEntityListField } from '../utils/misc';
import { AwardResultsQuery } from '../graphql/query/AwardResultsQuery';
import { AwardProducerDetail } from './AwardProducerDetail';
import { UserData, UserDataInterface } from '@food/auth';
import { curryN } from 'ramda';
import { Award, UserRole } from '../server-types';
import { EntityDetailPage } from '../types/entities';

const statuses = AwardStatusIntrospection.enumValues.map((o) => o.name);
const awardLabel = curryN(2, eLabel)(ENTITY.AWARD);

export const AwardDetail: React.FC<EntityDetailPage<Award>> = ({ id }) => (
	<UserData>
		{(user: UserDataInterface) => {
			const isProducer = user.userData.role === UserRole.Producer;

			// in tal caso ho una visualizzazione diversa, mando ad un componente completamente diverso
			if (isProducer) {
				return <AwardProducerDetail id={id} />;
			}

			return (
				<EntityDetailFetcher
					query={AwardDetailQuery}
					entityName={ENTITY.AWARD}
					id={id}
					typeIntro={typeIntro}
					clonable={true}
					customActionComponents={(entity) => [
						<StatusSetter
							key={1}
							statuses={statuses}
							entityName={ENTITY.AWARD}
							entity={entity}
							statusFieldName="status"
							mutation={setAwardStatusMutation}
							entityIntrospection={typeIntro}
						/>,
						<Link
							key={2}
							to={
								generateUrl(ENTITY.CANDIDACY, ACTIONS.LIST) +
								'?Award=' +
								encodeURIComponent(entity.id)
							}
						>
							<Button color="info" className="margin-right">
								<i className="fa fa-eye" /> {t`See candidacies`}
							</Button>
						</Link>,
					]}
					fields={[
						'name',
						'edition',
						'year',
						'managerUser',
						'langCode',
						'logoMedia',
						'status',
						'visibility',
						'publicDescription',
						'eventDescription',
						'descriptionForProducer',
						'descriptionForVoter',
						'descriptionProducerHighlights',
						'maxPerCompanyCandidacies',
						'maxGoodsPerCandidacy',
						'candidaciesCloseAt',
						'votesCloseAt',
						'autovoterRoles',
						'ratingOverallLabel',
						'rating1Label',
						'rating2Label',
						'rating3Label',
						'rating4Label',
						'rating5Label',
						'comment1Label',
						'comment1Max',
						'comment2Label',
						'comment2Max',
						'comment3Label',
						'comment3Max',
						'website',
						'extraMediaType',
						'extraMediaLabel',
						'options',
						'description1Label',
						'description1Max',
						'description2Label',
						'description2Max',
						'description3Label',
						'description3Max',
						'description4Label',
						'description4Max',
						'description5Label',
						'description5Max',
						'description6Label',
						'description6Max',
						'description7Label',
						'description7Max',
						'description8Label',
						'description8Max',
						'description9Label',
						'description9Max',
						'description10Label',
						'description10Max',
						'categories',
						'invitedUsers',
						'invitedCompanies',
						'vademecumCompany',
						'vademecumGoods',
						'vademecumCandidacies',
						'vademecumInvited',
						'supportEmail',
						'regulationsMedia',
						'contractMedia',
					]}
					transformer={(data, entity) => {
						data.fields.forEach((f) => {
							switch (f.name) {
								case 'publicDescription':
								case 'eventDescription':
								case 'descriptionForProducer':
								case 'descriptionForVoter':
								case 'descriptionProducerHighlights':
								case 'vademecumCompany':
								case 'vademecumGoods':
								case 'vademecumCandidacies':
								case 'vademecumInvited':
									f.tab = 1;
									break;

								case 'description1Label':
								case 'description1Max':
								case 'description2Label':
								case 'description2Max':
								case 'description3Label':
								case 'description3Max':
								case 'description4Label':
								case 'description4Max':
								case 'description5Label':
								case 'description5Max':
								case 'description6Label':
								case 'description6Max':
								case 'description7Label':
								case 'description7Max':
								case 'description8Label':
								case 'description8Max':
								case 'description9Label':
								case 'description9Max':
								case 'description10Label':
								case 'description10Max':
									f.tab = 2;
									break;

								case 'ratingOverallLabel':
								case 'rating1Label':
								case 'rating2Label':
								case 'rating3Label':
								case 'rating4Label':
								case 'rating5Label':
								case 'comment1Label':
								case 'comment1Max':
								case 'comment2Label':
								case 'comment2Max':
								case 'comment3Label':
								case 'comment3Max':
									f.tab = 3;
									break;

								case 'categories':
									if (f.type === 'EntityList') {
										f.tab = 4;
										f.addComponent = AwardCategoryCreate;
										f.editComponent = AwardCategoryEdit;
										f.detailComponent = AwardCategoryDetail;
										f.props.headers = ['name', 'special'];
										f.props.fragment = gql`
											fragment Fragment on AwardCategory {
												id
												name
												special
											}
										`;
									}
									break;

								case 'invitedUsers':
									if (f.type === 'EntityList') {
										f.tab = 5;
										f.editComponent = AwardInvitedUserEdit;
										f.detailComponent = AwardInvitedUserDetail;
										f.addComponent = AwardInvitedUserCreate;
										f.props.headers = ['User', 'roles'];
										f.props.fragment = gql`
											fragment Fragment on AwardInvitedUser {
												User {
													id
													name
													email
												}
												roles
											}
										`;
									}
									break;

								case 'invitedCompanies':
									if (f.type === 'EntityList') {
										f.tab = 6;
										f.editComponent = AwardInvitedCompanyEdit;
										f.detailComponent = AwardInvitedCompanyDetail;
										f.addComponent = AwardInvitedCompanyCreate;
										f.props.headers = [
											'Company',
											'maxGoodsPerCandidacy',
											'maxPerCompanyCandidacies',
										];
										f.props.fragment = gql`
											fragment Fragment on AwardInvitedCompany {
												signedContract
												Company {
													id
													name
												}
												maxGoodsPerCandidacy
												maxPerCompanyCandidacies
											}
										`;
										f.props.paginated = true;
									}
									break;

								default:
									break;
							}
						});

						const awardResultsField = generateDefaultEntityListField(
							'awardResults',
							user,
							ENTITY.AWARD_RESULT,
							entity,
						);
						awardResultsField.tab = 7;
						awardResultsField.props.headers = [
							'AwardCategory',
							'Candidacy',
							'finalPosition',
						];
						awardResultsField.props.query = AwardResultsQuery;
						awardResultsField.props.extractor = (data) => {
							if (!data || !data.node) {
								return [];
							} // TODO BUG DI APOLLO!!!
							return data.node.categories.reduce((list, cat) => {
								return list.concat(cat.awardResults);
							}, []);
						};
						awardResultsField.addComponent = AwardResultCreate;
						awardResultsField.editComponent = AwardResultEdit;
						awardResultsField.detailComponent = AwardResultDetail;
						awardResultsField.originalEntity = entity;

						data.fields = data.fields.concat([awardResultsField]);

						return data;
					}}
					tabs={[
						t('generic data'),
						awardLabel('descriptions'),
						awardLabel('candidacies fields'),
						awardLabel('rating options'),
						awardLabel('categories'),
						awardLabel('invitedUsers'),
						awardLabel('invitedCompany'),
						awardLabel('awardResult'),
					]}
					cols={[
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
					]}
				/>
			);
		}}
	</UserData>
);
