import gql from 'graphql-tag';

export const AwardListQuery = gql`
	query AwardListQuery(
		$first: Int,
		$cursor: String,
		$orderBy: AwardOrderBy,
		$orderDesc: Boolean,
		$filter: AwardFilterInput
	) {
		entities: awards(first: $first, after: $cursor, orderBy: $orderBy, orderDesc: $orderDesc, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					edition
					year
					status
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


