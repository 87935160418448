import { t } from '../utils/labels';
import { ENTITY } from '../utils/entities';
import { ACTIONS, generateUrl } from '../utils/urls';
import { pluralEntityLabel } from '../utils/misc';
import { UserDataInterface } from '@food/auth';
import { UserRole, InternalCapability } from '../server-types';

export interface MenuItem {
	readonly label: string;
	readonly icon?: string;
	readonly path?: string;
	readonly entity?: ENTITY;
	readonly children?: MenuItem[];
	readonly forcedView?: boolean;
}

const entityMenuVoice: (e: ENTITY) => MenuItem = (e) => ({
	label: t(pluralEntityLabel(e)),
	path: generateUrl(e, ACTIONS.LIST),
	entity: e,
});

export const createMenu = ({
	userData,
	userHasCapability,
	managedCompanyIsItalian,
}: UserDataInterface) => {
	const isProducer = userData.role === UserRole.Producer;
	const isInternal = userData.role === UserRole.Internal;

	const menu: MenuItem[] = [
		{
			label: t`home`,
			path: '/',
			icon: 'mdi-home',
			forcedView: true,
		},
	];

	menu.push({
		label: t`Companies and Goods`,
		icon: 'mdi-food-fork-drink',
		children: [
			{
				label: t(
					isProducer
						? 'Company profile'
						: pluralEntityLabel(ENTITY.COMPANY),
				),
				path: generateUrl(ENTITY.COMPANY, ACTIONS.LIST),
				entity: ENTITY.COMPANY,
			},
			entityMenuVoice(ENTITY.GOOD),
			entityMenuVoice(ENTITY.COMPANY_GROUP),
			entityMenuVoice(ENTITY.FOOD_SERVICE),
			entityMenuVoice(ENTITY.SERVICE),
			entityMenuVoice(ENTITY.RETAILER),
			entityMenuVoice(ENTITY.TEAM),
			userHasCapability(InternalCapability.AdminExtra) &&
				entityMenuVoice(ENTITY.COMPANY_CERTIFICATION),
			userHasCapability(InternalCapability.AdminExtra) &&
				entityMenuVoice(ENTITY.GOOD_FEATURE_CERTIFICATION),
		],
	});

	if (isProducer) {
		if (managedCompanyIsItalian) {
			menu.push({
				label: t`Italianfood.net Platform`,
				icon: 'mdi-lock-pattern',
				path: '/platforms/ifn',
				forcedView: true,
			});
		}
		menu.push({
			entity: ENTITY.AWARD,
			label: t`AwardProducerPublic/labelPlural`,
			path: '/awards-producer-public',
			icon: 'mdi-trophy-variant',
		});
		menu.push({
			entity: ENTITY.AWARD,
			label: t`AwardProducerPrivate/labelPlural`,
			path: '/awards-producer-private',
			icon: 'mdi-earth',
		});
		menu.push({
			entity: ENTITY.AWARD,
			label: t`AwardProducerSourcing/labelPlural`,
			path: '/awards-producer-sourcing',
			icon: 'mdi-earth',
		});
		menu.push({
			label: t`FAQ`,
			path: '/faq',
			icon: 'mdi-comment-question-outline',
			forcedView: true,
		});
	} else {
		menu.push({
			label: t`Award/labelPlural/internal`,
			icon: 'mdi-trophy-variant',
			children: [
				entityMenuVoice(ENTITY.AWARD),
				entityMenuVoice(ENTITY.CANDIDACY),
			],
		});
	}

	menu.push(
		{
			label: t`Fair/labelPlural`,
			icon: 'mdi-calendar',
			children: [
				entityMenuVoice(ENTITY.FAIR),
				// entityMenuVoice(ENTITY.FAIR_VISIT),
			],
		},
		{
			label: t`Editorial`,
			icon: 'mdi-book-open',
			children: [
				entityMenuVoice(ENTITY.NEWSLETTER),
				entityMenuVoice(ENTITY.NEWSLETTER_ISSUE),
				entityMenuVoice(ENTITY.MAGAZINE_COLLECTION),
				entityMenuVoice(ENTITY.MAGAZINE),
				//entityMenuVoice(ENTITY.NEWS),
				entityMenuVoice(ENTITY.EDITORIAL_SECTION),
				entityMenuVoice(ENTITY.EDITORIAL_TAG),
				// entityMenuVoice(ENTITY.BANNER),
				// entityMenuVoice(ENTITY.WIDGET),
				entityMenuVoice(ENTITY.COMPANY_BADGE),
				userHasCapability(InternalCapability.AdminExtra) &&
					entityMenuVoice(ENTITY.GOOD_CLASS),
			],
		},
		{
			label: t`GeoIndication/labelPlural`,
			icon: 'mdi-map-marker-radius',
			children: [
				entityMenuVoice(ENTITY.GEO_INDICATION),
				entityMenuVoice(ENTITY.EU_CATEGORY),
			],
		},
	);

	if (isInternal) {
		menu.push({
			label: t`Ecommerce`,
			icon: 'mdi-cart',
			children: [entityMenuVoice(ENTITY.COUPON)],
		});
		menu.push({
			label: t`Reports`,
			icon: 'mdi-chart-areaspline',
			path: '/reports',
			forcedView: true,
		});
		menu.push({
			label: t`Meta`,
			icon: 'mdi-account',
			children: [
				// entityMenuVoice(ENTITY.MEDIA),
				//entityMenuVoice(ENTITY.TYPEFORM),
				entityMenuVoice(ENTITY.EMAIL_DOMAIN),
				entityMenuVoice(ENTITY.USER),
				entityMenuVoice(ENTITY.OBJECT_HISTORY),
				// entityMenuVoice(ENTITY.HTML_TEMPLATE),
				entityMenuVoice(ENTITY.TERMS_OF_SERVICE),
				entityMenuVoice(ENTITY.QUESTION_SET),
				entityMenuVoice(ENTITY.ANSWER_SET),
				entityMenuVoice(ENTITY.FREQUENT_SEARCH),
			],
		});
	} else {
		menu.push({
			label: t`Comunications`,
			icon: 'mdi-forum',
			children: [entityMenuVoice(ENTITY.ANSWER_SET)],
		});
	}

	if (userHasCapability(InternalCapability.AdminExtra)) {
		menu.push({
			label: t`Maintenance tools`,
			icon: 'mdi-wrench',
			children: [
				{
					label: t`Merge objects`,
					path: '/objects-merge',
					forcedView: true,
				},
				{
					label: t`Directory reload`,
					path: '/directory-reload',
					forcedView: true,
				},
				{
					label: t`Update IFN platform stats`,
					path: '/update-ifn-stats',
					forcedView: true,
				},
			],
		});
	}

	if (userHasCapability(InternalCapability.AdminExtra)) {
		menu.push({
			label: t`Developer tools`,
			icon: 'mdi-code-braces',
			children: [
				{
					label: t`GraphiQL`,
					path: '/graphiql',
					forcedView: true,
				},
				/*{
					label: t`explorer`,
					path: '/explorer',
					icon: 'mdi-code-braces',
					forcedView: true,
				},
				{
					label: t`schema-voyager`,
					path: '/schema-voyager',
					icon: 'mdi-code-braces',
					forcedView: true,
				},*/
			],
		});
	}

	return menu;
};
