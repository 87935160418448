import { toRefInput } from './dataTrasform';
import { ENTITY } from './entities';
import { STRUCTURE } from '../static/structures';
import { calculateUserPermissions } from './permissions';
import { DT_PRECISION, IAsyncChoiceListField, IEntityListField } from './list';
import moment from 'moment';
import { canView } from './viewRoles';
import { ACTIONS, generateUrl } from './urls';
import { entity2String } from './toString';
import { Link } from 'react-router-dom';
import React from 'react';
import { client } from './client';
import { deleteNodeMutation } from '../graphql/mutations/DeleteNodeMutation';
import { UserDataInterface } from '@food/auth';
import {
	EntityListFieldType,
	FieldsTransformer,
	IField,
	SyncChoiceFieldType,
} from '../types/form';
import { t } from './labels';
import { curryN, prop } from 'ramda';
import { required } from './validation';
import { AsyncQuery, generateDefaultListQuery } from './graphql';
import { ITransformerFunction } from '../components/EntityEditFetcher';
import { UserDropDown } from '../components/additionalDataDropdowns/UserDropDown';
import { EntityAdditionalData } from '../components/EntityAdditionalData';
import { UserRole } from '../server-types';
import { CompanyDropDown } from '../components/additionalDataDropdowns/CompanyDropDown';

export interface Dict {
	[key: string]: any;
}

export interface IChoice {
	label: string;
	value: string | boolean | null;
}

const fieldToggler = (context, fieldName: string) => () =>
	context.setState({
		[fieldName]: !context.state[fieldName],
	});

const generateFakeAsyncQuery = (choices: any[]): AsyncQuery => async (
	value,
) => ({
	entities: choices.filter(
		(c) => c.name.indexOf(value) !== -1 || value === '',
	),
	remaining: 0,
});

/**
 * Utility per ottenere un label plurale di default
 *
 * @param e:ENTITY
 * @return string
 */
const pluralEntityLabel = (e: ENTITY) => e + '/labelPlural';

/**
 * Utility per velocizzare l'uso di liste provenienti da graphql
 *
 * @param queryResult: any
 * @return any[];
 */
const nodes = (queryResult: any): any[] => queryResult.edges.map(prop('node'));

const eLabel = (e: ENTITY, s: string) => t([e, s, 'label'].join('/'));

// Rendo sincrono un campo sincrono, passando le varie scelte che e' possibile fare
function AsyncToSyncField(field, choices: any[]) {
	field.asyncQuery = generateFakeAsyncQuery(choices);
	field.beforeSaveTransformer = (e) => {
		if (Array.isArray(e)) {
			return e.map((c) => toRefInput(c.value));
		} else {
			return toRefInput(e.value);
		}
	};
	return field;
}

const generateDefaultEntityListField = (
	name: string,
	user: UserDataInterface,
	entityName: ENTITY,
	//entityStructure: any,
	originalEntity?: any,
): EntityListFieldType => ({
	type: 'EntityList',
	tab: 0,
	col: 0,
	originalEntity,
	//entityStructure,
	description: {},
	validators: [],
	label: (originalEntity ? originalEntity.__typename + '/' : '') + name,
	name,
	permissions: calculateUserPermissions(
		user.capabilities,
		user.userData.role,
		STRUCTURE[entityName].description,
	),
	props: {
		paginated: false,
		headers: ['name'],
		typeIntro: STRUCTURE[entityName],
		entityName,
		originalEntity,
		query: generateDefaultListQuery(
			entityName,
			'name',
			false,
		),
		additionalVariables: originalEntity
			? {
				id: originalEntity.id,
			}
			: {},
	},
});

function formatDate(date: Date, precision: DT_PRECISION) {
	if (!date || !moment(date).isValid()) return '';

	if (
		[
			DT_PRECISION.FULL,
			DT_PRECISION.MINUTE_BEGIN,
			DT_PRECISION.MINUTE_END,
		].includes(precision)
	)
		return moment(date).format('DD/MM/YYYY HH:mm');
	else if (
		[DT_PRECISION.HOUR_BEGIN, DT_PRECISION.HOUR_END].includes(precision)
	)
		return moment(date).format('DD/MM/YYYY HH');
	else if ([DT_PRECISION.DAY_BEGIN, DT_PRECISION.DAY_END].includes(precision))
		return moment(date).format('DD/MM/YYYY');
	else if (
		[DT_PRECISION.MONTH_BEGIN, DT_PRECISION.MONTH_END].includes(precision)
	)
		return moment(date).format('MM/YYYY');
	else if (
		[DT_PRECISION.YEAR_BEGIN, DT_PRECISION.YEAR_END].includes(precision)
	)
		return moment(date).format('YYYY');
	else return date.toString();
}

const renderEntityRO = (
	field: SyncChoiceFieldType | IEntityListField | IAsyncChoiceListField,
	user: UserDataInterface,
	entity: any,
) => {
	// TODO sdoppiare le funzioni per gestire casi cosi' eterogenei di `field`
	const e = entity || field.value;
	const type = 'mapTo' in field ? field.mapTo : e.__typename;
	const url =
		'url' in field && field.url
			? field.url
			: generateUrl(type, ACTIONS.DETAIL, e.id);
	//const myToString = field.myToString && field.myToString(e);
	//const name = t(myToString || entity2String(type, e));
	const name = entity2String(type, e);

	if (canView(user, type))
		if (type === ENTITY.USER && user.userData.role === UserRole.Internal) {
			return (
				<EntityAdditionalData card={() => <UserDropDown id={entity.id} />}>
					{(loading) => (
						<Link to={url} target={'_blank'}>
							{name}{' '}
							<i
								className={
									'fa fa-asterisk' + (loading ? ' rotation' : '')
								}
							/>
						</Link>
					)}
				</EntityAdditionalData>
			);
		} else if (
			type === ENTITY.COMPANY &&
			user.userData.role === UserRole.Internal
		) {
			return (
				<EntityAdditionalData
					card={() => <CompanyDropDown id={entity.id} />}
				>
					{(loading) => (
						<Link to={url} target={'_blank'}>
							{name}{' '}
							<i
								className={
									'fa fa-asterisk' + (loading ? ' rotation' : '')
								}
							/>
						</Link>
					)}
				</EntityAdditionalData>
			);
		} else {
			return (
				<Link to={url} target={'_blank'}>
					{name} <i className="fa fa-external-link-square" />
				</Link>
			);
		}
	else return name;
};

async function deleteMutation(id: string) {
	return await client.mutate({
		mutation: deleteNodeMutation,
		variables: { id },
	});
}

function html2text(html: string): string {
	const n = document.createElement('div');
	n.innerHTML = html;
	return n.innerText;
}

function goodFormatsWithEAN(good: any): any[] {
	if (!good || !('formats' in good)) return [];
	return good.formats.filter((f) => !!f.ean);
}

const notNull = (f) => f !== null;

/**
 * Dato un Field lo rende obbligatorio
 *
 * @param f: IField
 * @param impure?: boolean
 * @return IField
 */
const toRequired = (f: IField, impure?: boolean): IField => {
	const tmp = impure ? f : { ...f };
	tmp.required = true;
	tmp.label = tmp.label.replace('*', '') + '*';
	tmp.validators = [...tmp.validators, required]; // non e' veramente puro... ma per ora va bene
	return tmp;
};

/**
 * Data una lista di any restituice una lista di IChoice utilizzabile nelle select
 *
 * @param e: any[]
 * @return IChoice[]
 */
const toChoices = (e: ReadonlyArray<string>): IChoice[] =>
	e.map((c) => ({ label: t(c), value: c }));

/**
 * Filtra i Field apparenenti ad una determinata tab
 *
 * @param fields: IField[]
 * @param tab: number
 * @return IField[]
 */
/*const inTab = (fields: IField[], tab: number) =>
	fields.filter((f) => f.tab === tab || (!f.tab && tab === 0));*/

/**
 * Implementazione basilare del debounce.
 * Le funzioni non devono dipendere dal contesto e devono restituire void
 *
 * @param func: (...args: any) => void
 * @param wait: number
 * @param immediate?: boolean
 * @return () => void
 */
const debounce = (
	func: (...args: any) => void,
	wait: number,
	immediate?: boolean,
) => {
	let timeout;
	return (...args) => {
		const later = () => {
			timeout = null;
			if (!immediate) {
				func(...args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func(...args);
		}
	};
};

const fieldLabel = (
	entityName: ENTITY,
	fieldName: string,
	helpText?: boolean,
): string => [entityName, fieldName, helpText ? 'helpText' : 'label'].join('/');

/**
 * Una funzione simile ad Object.assign, solo nello stile di ramda
 */
const assign = curryN(2, (newTraits, obj) => Object.assign({}, obj, newTraits));

/**
 * Funzione per generare il nome di una entita' tradotto
 *
 * @param entityName: string
 * @param plural?: boolean
 * @return string
 */
const getEntityLabel = (entityName: string, plural?): string =>
	t([entityName, plural ? 'labelPlural' : 'label'].join('/'));

/**
 * Utility da utilizzare nei transformer piu' classici, ovvero quelli che applicano solo un transformer senza dipendere da dati esterni alla lista stessa dei campi
 *
 * @param fn: FieldsTransformer
 * @return ITransformerFunction
 */
const transformerHandler = (fn: FieldsTransformer): ITransformerFunction => (
	data,
) => {
	data.fields = fn(data.fields);
	return data;
};

/**
 * Genero un ID alfanumerico random. Utilizzato per rendere unici alcuni elementi senza troppa paura delle collisioni
 *
 * @return string
 */
const generateRandomID = () =>
	Math.random()
		.toString(36)
		.substr(2, 9);

/**
 * Trasforma un testo in sentenceCase. Utilizza un secondo parametro booleano per rendere eventualmente la stringa di input lowercase (a volte necessario)
 *
 * @param input: string
 * @param lowercaseBefore?: boolean
 */
/*const toSentenceCase = (input: string, lowercaseBefore?: boolean): string => {
	input = input === undefined || input === null ? '' : input;
	if (lowercaseBefore) {
		input = input.toLowerCase();
	}
	return input
		.toString()
		.replace(/(^|\. *)([a-z])/g, function(match, separator, char) {
			return separator + char.toUpperCase();
		});
};*/

const identifyColors = (input: any): string[] => {
	let colors = [];
	let color = null;

	if (typeof (input) === 'string') {
		if (input.indexOf('<p>') > -1) {
			// è un campo RichText, cerco per i codici rgb(xx,xx,xx)
			const matches = input.match(/rgb\([\d]{1,3},[\s]*[\d]{1,3},[\s]*[\d]{1,3}[\s]*\)/ig);
			if (matches && matches.length) {
				matches.map((rgb) => {
					const [r, g, b] = rgb.match(/rgb\(([\d]{1,3}),[\s]*([\d]{1,3}),[\s]*([\d]{1,3})[\s]*\)/i).slice(1);
					color = "#" + ((1 << 24) + (Number(r) << 16) + (Number(g) << 8) + Number(b)).toString(16).slice(1);

					if (color && !colors.includes(color)) {
						colors.push(color);
					}
				});
			}
		} else {
			// identifico un colore esadecimale
			const matches = input.match(new RegExp(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'ig'));
			if (matches !== null && matches.length) {
				color = matches[0];

				if (color.length === 4) {
					color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
				}
			}
			if (color && !colors.includes(color)) {
				colors.push(color);
			}
		}
	} else if (typeof (input) === 'object') {
		// reiterate
		for (let key in input) {
			const subColors = identifyColors(input[key]);
			if (subColors.length) colors = [...colors, ...subColors];
		}
	}
	return colors;
}

const googleFontInputClean = (input: string): string => {
	if (typeof (input) != 'string' || !input.length) return input;

	// cerco formati di stringhe possibili
	const regExps = [
		new RegExp(/^https:\/\/fonts.google.com\/specimen\/([^\/]+)[\/]*$/, 'i'),
		new RegExp(/\?family=([^\&]+)/, 'i')
	];
	let matched: string = '';
	let matches: boolean | RegExpMatchArray;
	regExps.map((regExp) => {
		if (!matched) {
			matches = input.match(regExp);
			if (matches !== null && typeof (matches) === 'object' && matches.length > 1) {
				matched = matches[1];
			}
		}
	});
	if (matched != '') input = matched;

	// pulisco l'input (o la sottostringa ricavata dall'espressione regolare) da eventuali +
	input = input.replace(/\+/g, " ");

	// ritorno il nome del font
	return input;
}

export {
	googleFontInputClean,
	fieldToggler,
	AsyncToSyncField,
	pluralEntityLabel,
	generateDefaultEntityListField,
	formatDate,
	renderEntityRO,
	deleteMutation,
	html2text,
	goodFormatsWithEAN,
	// getLabelFromValue,
	nodes,
	notNull,
	generateFakeAsyncQuery,
	eLabel,
	toRequired,
	toChoices,
	// inTab,
	debounce,
	fieldLabel,
	assign,
	getEntityLabel,
	transformerHandler,
	generateRandomID,
	// toSentenceCase,
	identifyColors
};
