import React from 'react';
import { NewsletterPatchMutation } from '../graphql/mutations/NewsletterPatchMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Newsletter.json';
import inputTypeIntro from '../static/introspection/NewsletterPatchInput.json';
import { ENTITY } from '../utils/entities';
import {
	newsletterBeforeSaveTransformer,
	newsletterFetcher,
	newsletterTransformer,
} from '../utils/entities/newsletter';

export const NewsletterEdit: React.FC<{ id: string }> = ({ id }) => (
	<EntityEditFetcher
		fetcher={newsletterFetcher(id)}
		mutation={NewsletterPatchMutation}
		entityName={ENTITY.NEWSLETTER}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'target', 'delivery', 'inputTemplate']}
		transformer={(data, dataFetcherResult) => {
			data.fields = newsletterTransformer(data.fields, dataFetcherResult);
			return data;
		}}
		beforeSaveTransformer={newsletterBeforeSaveTransformer}
	/>
);
