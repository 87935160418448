import React from 'react';
import { GoodFormatPatchMutation } from '../graphql/mutations/GoodFormatPatchMutation';
import { GoodFormatEditQuery } from '../graphql/query/GoodFormatEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodFormat.json';
import inputTypeIntro from '../static/introspection/GoodFormatPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Good, GoodFormat } from '../server-types';

export const GoodFormatEdit: React.FC<EntityEditModal<GoodFormat, Good>> = ({
	id,
	onSubmit,
}) => (
	<EntityEditFetcher
		id={id}
		onSubmit={onSubmit}
		fetcher={graphqlQuery(GoodFormatEditQuery, { id })}
		mutation={GoodFormatPatchMutation}
		entityName={ENTITY.GOOD_FORMAT}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		modal={true}
		fields={['cuMesureUnit', 'cuMesureValue', 'cuPerSellingPack', 'ean']}
	/>
);
