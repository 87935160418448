import { ENTITY } from '../utils/entities';
import AwardCategory from './introspection/AwardCategory.json';
import AwardInvitedUser from './introspection/AwardInvitedUser.json';
import AwardInvitedCompany from './introspection/AwardInvitedCompany.json';
import CompanyCertification from './introspection/CompanyCertification.json';
import Brand from './introspection/Brand.json';
import CompanyYear from './introspection/CompanyYear.json';
import HighlightedGood from './introspection/HighlightedGood.json';
import Branch from './introspection/Branch.json';
import FairParticipation from './introspection/FairParticipation.json';
import CompanyLevel from './introspection/CompanyLevel.json';
import AwardResult from './introspection/AwardResult.json';
import GoodClass from './introspection/GoodClass.json';
import ManagedByUser from './introspection/ManagedByUser.json';
import Good from './introspection/Good.json';
import GoodFeatureCertification from './introspection/GoodFeatureCertification.json';
import GoodFormat from './introspection/GoodFormat.json';
import Candidacy from './introspection/Candidacy.json';
import NewsCitation from './introspection/NewsCitation.json';
import User from './introspection/User.json';
import CandidacyRating from './introspection/CandidacyRating.json';
import GeoIndication from './introspection/GeoIndication.json';
import Award from './introspection/Award.json';
import AnswerSet from './introspection/AnswerSet.json';
import Company from './introspection/Company.json';
import StateProvice from './introspection/StateProvince.json';
import Fair from './introspection/Fair.json';
import FairVisit from './introspection/FairVisit.json';
import CompanyBadge from './introspection/CompanyBadge.json';
import EditorialSection from './introspection/EditorialSection.json';
import DistributionChannel from './introspection/DistributionChannel.json';
import Country from './introspection/Country.json';
import Media from './introspection/Media.json';
import Team from './introspection/Team.json';
import Tos from './introspection/ToS.json';
import News from './introspection/News.json';
import Newsletter from './introspection/Newsletter.json';
import NewsletterIssue from './introspection/NewsletterIssue.json';
import Retailer from './introspection/Retailer.json';
import CompanyGroup from './introspection/CompanyGroup.json';
import Service from './introspection/Service.json';
import QuestionSet from './introspection/QuestionSet.json';
import MagazineCollection from './introspection/MagazineCollection.json';
import MagazineContent from './introspection/MagazineContent.json';
import Magazine from './introspection/Magazine.json';
import Banner from './introspection/Banner.json';
import Typeform from './introspection/Typeform.json';
import FrequentSearch from './introspection/FrequentSearch.json';
import HtmlTemplate from './introspection/HtmlTemplate.json';
import EuCategory from './introspection/EuCategory.json';
import FoodService from './introspection/FoodService.json';

// Associo al nome delle entita' le rispettive introspezioni graphql

export const STRUCTURE = {
	[ENTITY.AWARD_CATEGORY]: AwardCategory,
	[ENTITY.AWARD_INVITED_USER]: AwardInvitedUser,
	[ENTITY.AWARD_INVITED_COMPANY]: AwardInvitedCompany,
	[ENTITY.COMPANY_CERTIFICATION]: CompanyCertification,
	[ENTITY.BRAND]: Brand,
	[ENTITY.COMPANY_YEAR]: CompanyYear,
	[ENTITY.HIGHLIGHTED_GOOD]: HighlightedGood,
	[ENTITY.BRANCH]: Branch,
	[ENTITY.FAIR_PARTICIPATION]: FairParticipation,
	[ENTITY.COMPANY_LEVEL]: CompanyLevel,
	[ENTITY.AWARD_RESULT]: AwardResult,
	[ENTITY.GOOD_CLASS]: GoodClass,
	[ENTITY.MANAGED_BY_USER]: ManagedByUser,
	[ENTITY.GOOD]: Good,
	[ENTITY.GOOD_FEATURE_CERTIFICATION]: GoodFeatureCertification,
	[ENTITY.GOOD_FORMAT]: GoodFormat,
	[ENTITY.CANDIDACY]: Candidacy,
	[ENTITY.NEWS_CITATION]: NewsCitation,
	[ENTITY.USER]: User,
	[ENTITY.CANDIDACY_RATING]: CandidacyRating,
	[ENTITY.GEO_INDICATION]: GeoIndication,
	[ENTITY.AWARD]: Award,
	[ENTITY.ANSWER_SET]: AnswerSet,
	[ENTITY.COMPANY]: Company,
	[ENTITY.STATE_PROVINCE]: StateProvice,
	[ENTITY.FAIR]: Fair,
	[ENTITY.FAIR_VISIT]: FairVisit,
	[ENTITY.COMPANY_BADGE]: CompanyBadge,
	[ENTITY.EDITORIAL_SECTION]: EditorialSection,
	[ENTITY.DISTRIBUTION_CHANNEL]: DistributionChannel,
	[ENTITY.COUNTRY]: Country,
	[ENTITY.MEDIA]: Media,
	[ENTITY.TEAM]: Team,
	[ENTITY.TERMS_OF_SERVICE]: Tos,
	[ENTITY.NEWS]: News,
	[ENTITY.RETAILER]: Retailer,
	[ENTITY.COMPANY_GROUP]: CompanyGroup,
	[ENTITY.SERVICE]: Service,
	[ENTITY.QUESTION_SET]: QuestionSet,
	[ENTITY.NEWSLETTER]: Newsletter,
	[ENTITY.NEWSLETTER_ISSUE]: NewsletterIssue,
	[ENTITY.MAGAZINE_COLLECTION]: MagazineCollection,
	[ENTITY.MAGAZINE]: Magazine,
	[ENTITY.MAGAZINE_CONTENT]: MagazineContent,
	[ENTITY.BANNER]: Banner,
	[ENTITY.TYPEFORM]: Typeform,
	[ENTITY.FREQUENT_SEARCH]: FrequentSearch,
	[ENTITY.HTML_TEMPLATE]: HtmlTemplate,
	[ENTITY.EU_CATEGORY]: EuCategory,
	[ENTITY.FOOD_SERVICE]: FoodService,
};
