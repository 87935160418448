import { FieldsTransformer, FieldTransformer, IField } from '../types/form';
import { assign } from './misc';
import {
	AsyncQuery,
	generateAsyncQuery,
	generatePaginatedSelectQuery,
} from './graphql';
import { ENTITY } from './entities';
import { assoc, always, cond, T, identity } from 'ramda';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { UserSelectQuery } from '../graphql/query/UserSelectQuery';
import { BrandSelectQuery } from '../graphql/query/BrandSelectQuery';
import { newsletterIssueSelectQuery } from '../graphql/query/newsletterIssueSelectQuery';

const setValue = (value: any): FieldTransformer<IField, IField> =>
	assign({
		changed: true,
		value,
	});

const assignAsyncQuery = (query: AsyncQuery) => assign({ asyncQuery: query });

// buona parte delle personalizzazioni sulle asyncQuery sono solo dovute all'assenza dell'ordinazione, presente nelle
// query di default ma non in quella sul backend. Raggruppo tutti questi casi in un unico punto.
const noOrderSelect = [
	ENTITY.FAIR,
	ENTITY.HTML_TEMPLATE,
	ENTITY.NEWS_FORMAT,
	ENTITY.EDITORIAL_TAG,
	ENTITY.EU_CATEGORY,
	ENTITY.MAGAZINE,
	ENTITY.FREQUENT_SEARCH,
	ENTITY.MAGAZINE_COLLECTION,
].reduce((result, entityType) => {
	const query = generateAsyncQuery(
		generatePaginatedSelectQuery(
			entityType,
			false,
			// always('SearchByNameInput'),
		),
		true,
	);
	result[entityType] = assignAsyncQuery(query);
	return result;
}, {});

// a volte ci sono effettivamente casi particolari, quindi li unisco all'oggetto precedente per prendere tutti i casi
const selectTransformers = Object.assign(noOrderSelect, {
	[ENTITY.AWARD]: assignAsyncQuery(generateAsyncQuery(AwardSelectQuery, true)),
	[ENTITY.USER]: assignAsyncQuery(
		generateAsyncQuery(UserSelectQuery, true, (value) => ({
			filter: { nameOrEmail: value },
		})),
	),
	[ENTITY.BRAND]: assignAsyncQuery(generateAsyncQuery(BrandSelectQuery, true)),
	[ENTITY.NEWSLETTER_ISSUE]: assignAsyncQuery(
		generateAsyncQuery(newsletterIssueSelectQuery, true, (subject) => ({
			filter: { subject },
		})),
	),
	[ENTITY.NEWSLETTER]: assignAsyncQuery(
		generateAsyncQuery(
			generatePaginatedSelectQuery(
				ENTITY.NEWSLETTER,
				false,
				always('NewsletterFilterInput'),
			),
			true,
		),
	),
	[ENTITY.TEAM]: assignAsyncQuery(
		generateAsyncQuery(
			generatePaginatedSelectQuery(
				ENTITY.TEAM,
				false,
				always('SearchByNameInput'),
			),
			true,
		),
	),
	query: assoc('type', 'Query'),
});

/**
 * Transformer per sostituire le query di select su entita' a livello globale
 *
 * @param fields: IField[]
 * @return IField[]
 */

const globalSelectTransformQuery: FieldsTransformer = (fields) => {
	return fields.map(
		cond([
			[
				(field) =>
					field.type === 'AsyncChoices' &&
					field.entityType in selectTransformers,
				(field) => selectTransformers[field.entityType](field),
			],
			[
				(field) => field.name === 'goodFeatureCertifications',
				assignAsyncQuery(
					generateAsyncQuery(
						generatePaginatedSelectQuery(
							ENTITY.GOOD_FEATURE_CERTIFICATION,
							false,
							always('SearchByNameInput'),
						),
						true,
					),
				),
			],
			[
				(field) => field.name === 'companyCertifications',
				assignAsyncQuery(
					generateAsyncQuery(
						generatePaginatedSelectQuery(
							ENTITY.COMPANY_CERTIFICATION,
							false,
							always('SearchByNameInput'),
						),
						true,
					),
				),
			],
			[
				(field) => field.name === 'fairs',
				assignAsyncQuery(
					generateAsyncQuery(
						generatePaginatedSelectQuery(ENTITY.FAIR, false),
						true,
					),
				),
			],
			[T, identity],
		]),
	);
};

export { setValue, globalSelectTransformQuery, assignAsyncQuery };
