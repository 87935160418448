import { IField } from '../types/form';
import { flatten } from 'ramda';

/**
 *
 * @param field
 * @param touched
 * @param previousPath
 */
const validateFieldValue = async (
	field: IField,
	touched: ReadonlyArray<string>,
	previousPath?: string,
): Promise<{ [key: string]: Error[] }> => {
	const { name, validators = [], value } = field;
	const localFieldPath = (previousPath ? previousPath + '/' : '') + name;

	if (!touched.includes(localFieldPath)) {
		// non valido un campo che non risulta touched, forse si tratta di un sotto-campo appena caricato
		return {};
	}

	const localValidationResults = await Promise.all(
		validators.map((validator) => validator(value)),
	);
	const onlyErrors = localValidationResults.filter(
		(v): v is Error => v instanceof Error,
	);
	const errors: { [key: string]: Error[] } =
		onlyErrors.length > 0 ? { [localFieldPath]: onlyErrors } : {};

	// controllo gli errori dei sotto-figli
	let subValidations: ReadonlyArray<{ [key: string]: Error[] }> = [];
	if (field.type === 'SubForm' && field.value) {

		subValidations = await Promise.all(
			field.value.fields.map((subField) =>
				validateFieldValue(subField, touched, localFieldPath),
			),
		);
	} else if (field.type === 'SubFormList' && field.value) {
		subValidations = await Promise.all(
			field.value.map((subField) =>
				validateFieldValue(subField, touched, localFieldPath),
			),
		);
	}

	Object.assign(errors, ...subValidations);

	return errors;
};

const getLocalError = (
	path: string,
	errors: { [key: string]: ReadonlyArray<Error> },
): Error | undefined => {
	const validErrors = errors[path];
	if (validErrors && validErrors.length > 0) {
		return validErrors[0];
	}
	return undefined;
};

const hasErrors = (
	path: string,
	errors: { [key: string]: ReadonlyArray<Error> },
): Boolean => Object.keys(errors).some((key) => key.includes(path));

const getFieldPaths = (field: IField, previousPath?): ReadonlyArray<string> => {
	const { name, value, type } = field;
	const localFieldPath = (previousPath ? previousPath + '/' : '') + name;

	let subFields = [];
	if (type === 'SubForm' && value) {
		subFields = value.fields;
	} else if (type === 'SubFormList' && value) {
		subFields = value;
	}

	const subPaths = flatten(
		subFields.map((subField) => getFieldPaths(subField, localFieldPath)),
	) as ReadonlyArray<string>;

	return [localFieldPath, ...subPaths];
};

const getAllPaths = (fields: ReadonlyArray<IField>): ReadonlyArray<string> =>
	flatten(fields.map((field) => getFieldPaths(field)));

/*const isSubPath = (p1: ReadonlyArray<string>, p2: ReadonlyArray<string>) =>
	p1.every((value, index) => p2[index] === value);*/

export { validateFieldValue, getLocalError, hasErrors, getAllPaths };
