import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { GoodListQuery } from '../graphql/query/GoodListQuery';
import typeIntro from '../static/introspection/Good.json';
import GoodFilters from '../static/introspection/GoodFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const GoodList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={GoodListQuery}
				entityName={ENTITY.GOOD}
				headers={['name', 'nameIta']}
				// sortables={['name', 'nameIta']}
				typeIntro={typeIntro}
				filtersIntro={GoodFilters}
				filterFields={
					isInternal
						? ['name', 'producerChangedAfter', 'Brand', 'Company']
						: null
				}
				additionalVariables={{
					orderBy: 'name', // forzo l'ordinamento per nome in quanto, di default, sono ordinati per id
				}}
				customFields={[
					{
						header: 'Brand',
						field: (entity) => ({
							name: 'Brand',
							label: 'Brand',
							type: 'Text',
							value: entity.Brand.name,
							// mapTo: ENTITY.COMPANY,
							// url: generateUrl(
							// 	ENTITY.COMPANY,
							// 	ACTIONS.DETAIL,
							// 	entity.Brand.Company.id,
							// ),
						}),
					},
					{
						header: 'Has EAN',
						field: (entity) => ({
							name: 'EAN',
							label: 'Has EAN',
							type: 'Boolean',
							value: entity.formats.some((format) => format.ean),
						}),
					},
				]}
			/>
		)}
	</UserData>
);
