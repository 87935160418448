import React, { useContext, useEffect, useRef, useState, memo } from 'react';
import {
	FormGroup,
	Input,
	Label,
	Col,
	FormFeedback,
	FormText,
	Badge,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { cns } from '@food/css-manager';
import { FieldComponent, TextFieldType } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';

export const TextField: React.FC<FieldComponent<TextFieldType>> = memo(
	({ field, changeHandler, path }) => {
		const { errors, touched } = useContext(FormContext);
		const error = getLocalError(path, errors);
		const errorText = error ? error.message : '';
		const [localText, setLocalText] = useState(field.value);
		const submittedText = useRef<string>();

		useEffect(() => {
			//console.log(localText, field.value, touched.includes(path));
			if (localText !== field.value || touched.includes(path)) {
				const t = setTimeout(() => {
					changeHandler(localText === '' ? null : localText, path);
				}, 500);
				submittedText.current = localText;
				return () => clearTimeout(t);
			}
			return () => {};
		}, [localText]);

		useEffect(() => {
			if (
				field.value !== submittedText.current &&
				field.value !== localText
			) {
				setLocalText(field.value);
			}
		}, [field.value]);

		return (
			<FormGroup
				row
				color={error ? 'danger' : ''}
				className={field.hidden ? 'hidden' : ''}
			>
				<Label sm={3}>{field.label}: </Label>
				<Col sm={9}>
					<Input
						type={field.multiline ? 'textarea' : 'text'}
						name={field.name}
						value={localText || ''}
						onChange={(event) => setLocalText(event.target.value)}
						invalid={Boolean(error)}
						disabled={field.disabled}
					/>
					{error && (
						<FormFeedback color={'danger'}>
							{t(errorText + '/text', errorText)}
						</FormFeedback>
					)}
					<FormText color="muted">
						{field.helpText}
						<div
							className={cns(
								'usability-note',
								field.description.max &&
									field.description.max < (localText || '').length &&
									'error',
							)}
						>
							{t`Text length`}: {(localText || '').length}
							{field.description.max && '/' + field.description.max}{' '}
							{t`chars`}
							{field.description.min &&
								` (minimo ${field.description.min} caratteri)`}
						</div>
						{field.description.readonly && (
							<div>
								<Badge color="warning">{t`warning`}</Badge>{' '}
								{t`readonly field description`}
							</div>
						)}
					</FormText>
				</Col>
			</FormGroup>
		);
	},
);
