import React from 'react';
import { GoodPatchMutation } from '../graphql/mutations/GoodPatchMutation';
import { GoodEditQuery } from '../graphql/query/GoodEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Good.json';
import inputTypeIntro from '../static/introspection/GoodPatchInput.json';
import { goodTransformer } from '../utils/entityFieldsTrasformers';
import { Col, Row, Alert } from 'reactstrap';
import { t } from '../utils/labels';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { graphqlQuery } from '../utils/graphql';
import { nodes } from '../utils/misc';
import { Good } from '../server-types';
import { EntityEditPage } from '../types/entities';

export const GoodEdit: React.FC<EntityEditPage<Good>> = ({ id }) => (
	<UserData>
		{(user) => (
			<EntityEditFetcher
				fetcher={graphqlQuery(GoodEditQuery, { id })}
				id={id}
				mutation={GoodPatchMutation}
				entityName={ENTITY.GOOD}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entitiesToFields={(props) => ({
					EditorialSections: props.editorialSectionTree,
					certifications: props.goodFeatureCertifications,
					brands: nodes(props.brands),
				})}
				pre={() => (
					<Row>
						<Col sm="10">
							<Alert color="info">
								<span
									dangerouslySetInnerHTML={{
										__html: t`change good name readonly explication`,
									}}
								/>
							</Alert>
						</Col>
					</Row>
				)}
				fields={[
					'nameIta',
					'name',
					'Brand',
					'range',
					'featuredImageItaMedia',
					'featuredImageMedia',
					'ingredientsImageItaMedia',
					'ingredientsImageMedia',
					'GoodClass',
					'goodFeatureCertifications',
					'shelfLifeLength',
					'formats',
					'descriptionIta',
					'description',
					'descriptionLongIta',
					'descriptionLong',
					'GeoIndication',
				]}
				transformer={(...args) => goodTransformer(user, ...args)}
			/>
		)}
	</UserData>
);
