import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const RetailerFullFragment = gql`
	fragment RetailerFullFragment on Retailer {
		id
		localId
		sfId
		name
		logoMedia {
			...MediaBaseFragment
		}
		type
		billingName
		description
		descriptionIta
		descriptionLong
		descriptionLongIta
		pecEmail
		vatNumber
		website
		Country {
			id
			name
		}
		StateProvince {
			id
			name
		}
		postalCode
		city
		address1
		address2
		lat
		lng
		managedByUsers {
			id
			User {
				id
				name
			}
			capabilities
		}
	}
	${MediaBaseFragment}
`;
