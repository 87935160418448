import React from 'react';
import { PageTitle } from './PageTitle';
import { Tabs } from './Tabs';
import { EntityEditActions } from './EntityEditActions';
import { Form } from 'reactstrap';
import { BreadcrumbItem } from '../types/ui';
import { ValidationErrorsModal } from './ValidationErrorsModal';
import { renderField } from '../utils/form';
import { t } from '../utils/labels';
import { pluralEntityLabel } from '../utils/misc';
import { ACTIONS, generateUrl } from '../utils/urls';
import { entity2String } from '../utils/toString';
import { EntityEditViewProps } from './EntityEditViewSwitch';

export const EntityEditView: React.FC<EntityEditViewProps> = ({
	fields,
	id,
	clone,
	tabs = ['generic data'],
	title,
	pre,
	onSubmit,
	entity,
	entityName,
	create,
	validationModalOpen,
	toggleValidationModal,
	mutatorFactory,
	entityLabel,
	myToString,
}) => {
	// definisco il breadcrumb
	const breadcrumbs: BreadcrumbItem[] = [
		{ label: t`home`, path: '/' },
		{
			label: t(entityLabel || pluralEntityLabel(entityName)),
			path: generateUrl(entityName, ACTIONS.LIST),
		},
	];

	if (clone) {
		breadcrumbs.push({ label: t`clone` });
	} else if (create) {
		breadcrumbs.push({ label: t`create` });
	} else if (entity) {
		breadcrumbs.push(
			{
				
				label:
					myToString
						? myToString(entity)
						: entity2String(entityName, entity),
				path: generateUrl(entityName, ACTIONS.DETAIL, id),
			},
			{ label: t`update` },
		);
	}
	
	return (
		<div className="edit-entity-view">
			<PageTitle title={title} breadcrumbs={breadcrumbs} />
			{pre && pre(entity)}
			<Tabs tabs={tabs}>
				{(activeTab, tabIndex) => (
					<Form className="Form">
						<EntityEditActions
							entityName={entityName}
							create={create}
							id={id}
							save={onSubmit}
						/>
						{fields
							.filter(
								(f) =>
									f.tab === tabIndex ||
									(f.tab === undefined && tabIndex === 0),
							)
							.map((f) =>
								renderField(f, f.name, mutatorFactory(f), false),
							)}

						<EntityEditActions
							entityName={entityName}
							create={create}
							id={id}
							save={onSubmit}
						/>
					</Form>
				)}
			</Tabs>
			<ValidationErrorsModal
				open={validationModalOpen}
				onToggle={toggleValidationModal}
			/>
		</div>
	);
};
