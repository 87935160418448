import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const AwardFullFragment = gql`
	fragment AwardFullFragment on Award {
		id
		localId
		name
		edition
		year
		visibility
		publicDescription
		eventDescription
		targetSites
		langCode
		logoMedia {
			...MediaBaseFragment
		}
		managerUser {
			id
			name
			email
		}
		status
		descriptionForProducer
		descriptionForVoter
		descriptionProducerHighlights
		maxPerCompanyCandidacies
		maxGoodsPerCandidacy
		candidaciesCloseAt
		votesCloseAt
		autovoterRoles
		ratingOverallLabel
		rating1Label
		rating2Label
		rating3Label
		rating4Label
		rating5Label
		comment1Label
		comment1Max
		comment2Label
		comment2Max
		comment3Label
		comment3Max
		website
		description1Label
		description1Max
		description2Label
		description2Max
		description3Label
		description3Max
		description4Label
		description4Max
		description5Label
		description5Max
		description6Label
		description6Max
		description7Label
		description7Max
		description8Label
		description8Max
		description9Label
		description9Max
		description10Label
		description10Max
		visibility
		options
		extraMediaType
		extraMediaLabel
		categories {
			id
			name
			special
		}
		candidacies {
			id
			name
		}
		invitedUsers {
			id
			roles
			User {
				id
				name
			}
		}
		vademecumCompany
		vademecumGoods
		vademecumCandidacies
		vademecumInvited
		supportEmail
		regulationsMedia {
			...MediaBaseFragment
		}
		contractMedia {
			...MediaBaseFragment
		}
	}
	${MediaBaseFragment}
`;
