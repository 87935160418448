export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The Email scalar type represents E-Mail addresses compliant to RFC 822. */
  Email: any;
  /** The DateTime scalar type represents date time strings complying to ISO-8601. */
  DateTime: any;
  /** The URL scalar type represents URL addresses. */
  URL: any;
  /**
   * The `Int53` scalar type represents non-fractional signed whole numeric values.
   * Int53 can represent values between -(2^53 - 1) and 2^53 - 1. 
   */
  Int53: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","CONSUMER":true,"PRODUCER":"EDIT","BUYER":"EDIT","BUYER_PL_ITALIA":"EDIT","FOODSERVICE":"EDIT","SHOPWORKER":"EDIT","SERVICE":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type Abbonamento = Node & {
  __typename?: 'Abbonamento';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"required":false,"max":80} */
  Name?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>;
  /** {"required":true} */
  Attivo_Form: Scalars['Boolean'];
  /** {"allowFormInlining":true} */
  Indirizzo?: Maybe<Indirizzo>;
  /** {} */
  N_copie?: Maybe<Scalars['Float']>;
  /** {} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  /** {} */
  Giorni_alla_scadenza?: Maybe<Scalars['Float']>;
  /** {} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>;
  /** {} */
  Package_price?: Maybe<Scalars['Float']>;
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi: Array<AbbonamentoPeriodo>;
  /** {} */
  accountCompany?: Maybe<Company>;
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  accountService?: Maybe<Service>;
  /** {} */
  accountRetailer?: Maybe<Retailer>;
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>;
  /** {} */
  accountFoodService?: Maybe<FoodService>;
  /** {} */
  referenteUser?: Maybe<User>;
};

/** SF picklist */
export enum Abbonamento__C_Mezzo_Di_Pagamento__C_Enum {
  /** Assegno Bancario */
  AssegnoBancario = 'ASSEGNO_BANCARIO',
  /** Bonifico Bancario */
  BonificoBancario = 'BONIFICO_BANCARIO',
  /** Carta di credito */
  CartaDiCredito = 'CARTA_DI_CREDITO',
  /** CC postale */
  CcPostale = 'CC_POSTALE',
  /** Contanti */
  Contanti = 'CONTANTI',
  /** Omaggio Agente */
  OmaggioAgente = 'OMAGGIO_AGENTE',
  /** Omaggio Annabella */
  OmaggioAnnabella = 'OMAGGIO_ANNABELLA',
  /** Omaggio Aziendale */
  OmaggioAziendale = 'OMAGGIO_AZIENDALE',
  /** Omaggio con scadenza */
  OmaggioConScadenza = 'OMAGGIO_CON_SCADENZA',
  /** Omaggio Davide */
  OmaggioDavide = 'OMAGGIO_DAVIDE',
  /** Omaggio Fochi */
  OmaggioFochi = 'OMAGGIO_FOCHI',
  /** Omaggio Paolo */
  OmaggioPaolo = 'OMAGGIO_PAOLO',
  /** Omaggio Valentina */
  OmaggioValentina = 'OMAGGIO_VALENTINA',
  /** Paypal */
  Paypal = 'PAYPAL',
  /** Ricevuta Bancaria */
  RicevutaBancaria = 'RICEVUTA_BANCARIA'
}

/** SF picklist */
export enum Abbonamento__C_Package__C_Enum {
  /** Cartacei e Sfogliabili Digitali */
  CartaceiESfogliabiliDigitali = 'CARTACEI_E_SFOGLIABILI_DIGITALI',
  /** Sfogliabili Digitali */
  SfogliabiliDigitali = 'SFOGLIABILI_DIGITALI'
}

/** SF picklist */
export enum Abbonamento__C_Rivista__C_Enum {
  /** FOOD */
  Food = 'FOOD',
  /** Dolcesalato */
  Dolcesalato = 'DOLCESALATO',
  /** ITALIANFOOD.NET */
  ItalianfoodNet = 'ITALIANFOOD_NET',
  /** Food Service */
  FoodService = 'FOOD_SERVICE'
}

/** A connection to a list of items. */
export type AbbonamentoConnection = {
  __typename?: 'AbbonamentoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AbbonamentoEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AbbonamentoCreateInput = {
  /** {"required":false,"max":80} */
  Name?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>;
  /** {"allowFormInlining":true} */
  Indirizzo?: Maybe<RefInputObject>;
  /** {"allowFormInlining":true} */
  Indirizzo__inline?: Maybe<Indirizzo_From_Abbonamento_Type>;
  /** {} */
  N_copie?: Maybe<Scalars['Float']>;
  /** {} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  /** {} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>;
  /** {} */
  Package_price?: Maybe<Scalars['Float']>;
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi?: Maybe<Array<RefInputObject>>;
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi__inline?: Maybe<Array<AbbonamentoPeriodo_From_Abbonamento_Type>>;
  /** {} */
  accountCompany?: Maybe<RefInputObject>;
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  accountService?: Maybe<RefInputObject>;
  /** {} */
  accountRetailer?: Maybe<RefInputObject>;
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>;
  /** {} */
  accountFoodService?: Maybe<RefInputObject>;
  /** {} */
  referenteUser?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type AbbonamentoEdge = {
  __typename?: 'AbbonamentoEdge';
  /** The item at the end of the edge */
  node?: Maybe<Abbonamento>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type AbbonamentoFilterInput = {
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  isActive?: Maybe<Scalars['Boolean']>;
  referenteIsCurrentUser?: Maybe<Scalars['Boolean']>;
};

export type AbbonamentoPatchInput = {
  /** {"required":false,"max":80} */
  Name?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  Inizio?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Fine?: Maybe<Scalars['DateTime']>;
  /** {"allowFormInlining":true} */
  Indirizzo?: Maybe<RefInputObject>;
  /** {"allowFormInlining":true} */
  Indirizzo__inline?: Maybe<Indirizzo_From_Abbonamento_Type>;
  /** {} */
  N_copie?: Maybe<Scalars['Float']>;
  /** {} */
  Rivista?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  /** {} */
  Package?: Maybe<Abbonamento__C_Package__C_Enum>;
  /** {} */
  Package_price?: Maybe<Scalars['Float']>;
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi?: Maybe<Array<RefInputObject>>;
  /** {"allowFormInlining":true} */
  Abbonamento_Periodi__inline?: Maybe<Array<AbbonamentoPeriodo_From_Abbonamento_Type>>;
  /** {} */
  accountCompany?: Maybe<RefInputObject>;
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  accountService?: Maybe<RefInputObject>;
  /** {} */
  accountRetailer?: Maybe<RefInputObject>;
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>;
  /** {} */
  accountFoodService?: Maybe<RefInputObject>;
  /** {} */
  referenteUser?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type AbbonamentoPeriodo = Node & {
  __typename?: 'AbbonamentoPeriodo';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"required":true,"max":80} */
  Name: Scalars['String'];
  /** {"required":true,"readonly":true} */
  Abbonamento: Abbonamento;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Inizio?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Fine?: Maybe<Scalars['DateTime']>;
  /** {} */
  Quote_Line_Item?: Maybe<QuoteLineItem>;
  /** {} */
  Provenienza?: Maybe<Abbonamento__C_Mezzo_Di_Pagamento__C_Enum>;
  /** {"required":false} */
  Approvato?: Maybe<Scalars['Boolean']>;
  /** {"max":32} */
  Stripe_Transaction_ID?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Account_Name?: Maybe<Scalars['String']>;
  /** {"required":false} */
  Privato?: Maybe<Scalars['Boolean']>;
  /** {"max":64} */
  First_Name?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Last_Name?: Maybe<Scalars['String']>;
  /** {"max":13} */
  Partita_IVA?: Maybe<Scalars['String']>;
  /** {"max":32} */
  Billing_Country?: Maybe<Scalars['String']>;
  /** {"max":128} */
  Billing_Street?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Billing_City?: Maybe<Scalars['String']>;
  /** {"max":9} */
  Billing_Postal_Code?: Maybe<Scalars['String']>;
  /** {"max":3} */
  Billing_Province_State_Cod?: Maybe<Scalars['String']>;
  /** {"max":16} */
  Codice_Fiscale?: Maybe<Scalars['String']>;
  /** {"max":16} */
  Codice_Fiscale_Estero?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Posta_Elettronica_Certificata?: Maybe<Scalars['Email']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Billing_Email?: Maybe<Scalars['Email']>;
};

export type AbbonamentoPeriodo_From_Abbonamento_Type = {
  /** {"required":true,"readonly":true} */
  Abbonamento?: Maybe<RefInputObject>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Inizio?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Fine?: Maybe<Scalars['DateTime']>;
  /** {} */
  Quote_Line_Item?: Maybe<RefInputObject>;
  /** {} */
  Provenienza?: Maybe<Abbonamento__C_Mezzo_Di_Pagamento__C_Enum>;
  /** {"required":false} */
  Approvato?: Maybe<Scalars['Boolean']>;
  /** {"max":32} */
  Stripe_Transaction_ID?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Account_Name?: Maybe<Scalars['String']>;
  /** {"required":false} */
  Privato?: Maybe<Scalars['Boolean']>;
  /** {"max":64} */
  First_Name?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Last_Name?: Maybe<Scalars['String']>;
  /** {"max":13} */
  Partita_IVA?: Maybe<Scalars['String']>;
  /** {"max":32} */
  Billing_Country?: Maybe<Scalars['String']>;
  /** {"max":128} */
  Billing_Street?: Maybe<Scalars['String']>;
  /** {"max":64} */
  Billing_City?: Maybe<Scalars['String']>;
  /** {"max":9} */
  Billing_Postal_Code?: Maybe<Scalars['String']>;
  /** {"max":3} */
  Billing_Province_State_Cod?: Maybe<Scalars['String']>;
  /** {"max":16} */
  Codice_Fiscale?: Maybe<Scalars['String']>;
  /** {"max":16} */
  Codice_Fiscale_Estero?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Posta_Elettronica_Certificata?: Maybe<Scalars['Email']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Billing_Email?: Maybe<Scalars['Email']>;
};

/** Per accesso in base al role. Flags */
export enum AccessRole {
  Anonymus = 'ANONYMUS',
  Internal = 'INTERNAL',
  Producer = 'PRODUCER',
  Buyer = 'BUYER',
  BuyerPlItalia = 'BUYER_PL_ITALIA',
  Foodservice = 'FOODSERVICE',
  Shopworker = 'SHOPWORKER',
  Service = 'SERVICE',
  Consumer = 'CONSUMER'
}

/** SF picklist */
export enum Account_Settore2__C_Enum {
  /** Industria */
  Industria = 'INDUSTRIA',
  /** Retail */
  Retail = 'RETAIL',
  /** Editoria */
  Editoria = 'EDITORIA',
  /** Servizi */
  Servizi = 'SERVIZI',
  /** Freelancer */
  Freelancer = 'FREELANCER',
  /** Food Service */
  FoodService = 'FOOD_SERVICE',
  /** Punto Vendita */
  PuntoVendita = 'PUNTO_VENDITA',
  /** Istituzioni */
  Istituzioni = 'ISTITUZIONI'
}

export enum AccountType {
  Industry = 'INDUSTRY',
  RetailPl = 'RETAIL_PL',
  RetailNoPl = 'RETAIL_NO_PL',
  Institution = 'INSTITUTION',
  Service = 'SERVICE',
  Foodservice = 'FOODSERVICE'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","PRODUCER":"EDIT","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}} */
export type AnswerSet = Node & {
  __typename?: 'AnswerSet';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  QuestionSet: QuestionSet;
  /** {"readonly":true} */
  Company: Company;
  /** {} */
  goods: Array<Good>;
  /** {"dtPrecision":"FULL"} */
  sentDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  isSeen: Scalars['Boolean'];
  /** {} */
  exclusionReasons: Array<ExclusionReason>;
  /** {} */
  SampleRequest?: Maybe<SampleRequest>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<Media>;
  /** {} */
  answer1?: Maybe<Scalars['String']>;
  /** {} */
  answer2?: Maybe<Scalars['String']>;
  /** {} */
  answer3?: Maybe<Scalars['String']>;
  /** {} */
  answer4?: Maybe<Scalars['String']>;
  /** {} */
  answer5?: Maybe<Scalars['String']>;
  /** {} */
  answer6?: Maybe<Scalars['String']>;
  /** {} */
  answer7?: Maybe<Scalars['String']>;
  /** {} */
  answer8?: Maybe<Scalars['String']>;
  /** {} */
  answer9?: Maybe<Scalars['String']>;
  /** {} */
  answer10?: Maybe<Scalars['String']>;
  /** {} */
  answer11?: Maybe<Scalars['String']>;
  /** {} */
  answer12?: Maybe<Scalars['String']>;
  /** {} */
  answer13?: Maybe<Scalars['String']>;
  /** {} */
  answer14?: Maybe<Scalars['String']>;
  /** {} */
  answer15?: Maybe<Scalars['String']>;
  /** {} */
  answer16?: Maybe<Scalars['String']>;
  /** {} */
  answer17?: Maybe<Scalars['String']>;
  /** {} */
  answer18?: Maybe<Scalars['String']>;
  /** {} */
  answer19?: Maybe<Scalars['String']>;
  /** {} */
  answer20?: Maybe<Scalars['String']>;
};

export type AnswerSet_From_QuestionSet_Type = {
  /** {"readonly":true} */
  QuestionSet?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {} */
  isSeen: Scalars['Boolean'];
  /** {} */
  exclusionReasons?: Maybe<Array<ExclusionReason>>;
  /** {} */
  SampleRequest?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>;
  /** {} */
  answer1?: Maybe<Scalars['String']>;
  /** {} */
  answer2?: Maybe<Scalars['String']>;
  /** {} */
  answer3?: Maybe<Scalars['String']>;
  /** {} */
  answer4?: Maybe<Scalars['String']>;
  /** {} */
  answer5?: Maybe<Scalars['String']>;
  /** {} */
  answer6?: Maybe<Scalars['String']>;
  /** {} */
  answer7?: Maybe<Scalars['String']>;
  /** {} */
  answer8?: Maybe<Scalars['String']>;
  /** {} */
  answer9?: Maybe<Scalars['String']>;
  /** {} */
  answer10?: Maybe<Scalars['String']>;
  /** {} */
  answer11?: Maybe<Scalars['String']>;
  /** {} */
  answer12?: Maybe<Scalars['String']>;
  /** {} */
  answer13?: Maybe<Scalars['String']>;
  /** {} */
  answer14?: Maybe<Scalars['String']>;
  /** {} */
  answer15?: Maybe<Scalars['String']>;
  /** {} */
  answer16?: Maybe<Scalars['String']>;
  /** {} */
  answer17?: Maybe<Scalars['String']>;
  /** {} */
  answer18?: Maybe<Scalars['String']>;
  /** {} */
  answer19?: Maybe<Scalars['String']>;
  /** {} */
  answer20?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type AnswerSetConnection = {
  __typename?: 'AnswerSetConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AnswerSetEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type AnswerSetEdge = {
  __typename?: 'AnswerSetEdge';
  /** The item at the end of the edge */
  node?: Maybe<AnswerSet>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type AnswerSetFilterInput = {
  isClosed?: Maybe<Scalars['Boolean']>;
  isSent?: Maybe<Scalars['Boolean']>;
};

export type AnswerSetPatchInput = {
  /** {} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {} */
  isSeen?: Maybe<Scalars['Boolean']>;
  /** {} */
  exclusionReasons?: Maybe<Array<ExclusionReason>>;
  /** {} */
  SampleRequest?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>;
  /** {} */
  answer1?: Maybe<Scalars['String']>;
  /** {} */
  answer2?: Maybe<Scalars['String']>;
  /** {} */
  answer3?: Maybe<Scalars['String']>;
  /** {} */
  answer4?: Maybe<Scalars['String']>;
  /** {} */
  answer5?: Maybe<Scalars['String']>;
  /** {} */
  answer6?: Maybe<Scalars['String']>;
  /** {} */
  answer7?: Maybe<Scalars['String']>;
  /** {} */
  answer8?: Maybe<Scalars['String']>;
  /** {} */
  answer9?: Maybe<Scalars['String']>;
  /** {} */
  answer10?: Maybe<Scalars['String']>;
  /** {} */
  answer11?: Maybe<Scalars['String']>;
  /** {} */
  answer12?: Maybe<Scalars['String']>;
  /** {} */
  answer13?: Maybe<Scalars['String']>;
  /** {} */
  answer14?: Maybe<Scalars['String']>;
  /** {} */
  answer15?: Maybe<Scalars['String']>;
  /** {} */
  answer16?: Maybe<Scalars['String']>;
  /** {} */
  answer17?: Maybe<Scalars['String']>;
  /** {} */
  answer18?: Maybe<Scalars['String']>;
  /** {} */
  answer19?: Maybe<Scalars['String']>;
  /** {} */
  answer20?: Maybe<Scalars['String']>;
};

/** {} */
export type AtecoCode = Node & {
  __typename?: 'AtecoCode';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  code: Scalars['String'];
  /** {} */
  description: Scalars['String'];
};

/** A connection to a list of items. */
export type AtecoCodeConnection = {
  __typename?: 'AtecoCodeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AtecoCodeEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type AtecoCodeEdge = {
  __typename?: 'AtecoCodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AtecoCode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type AtecoCodeFilterInput = {
  /** Contained in code or description */
  name?: Maybe<Scalars['String']>;
  /** Contained in code */
  code?: Maybe<Scalars['String']>;
  /** Contained in description */
  description?: Maybe<Scalars['String']>;
};

export enum AtecoCodeOrderBy {
  Code = 'code',
  Description = 'description'
}

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_OPEN":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_CLOSED":{"INTERNAL":"MARKETING_BASE"},"VOTING_OPEN":{"INTERNAL":"MARKETING_BASE"},"VOTING_CLOSED":{"INTERNAL":"MARKETING_BASE"},"CONCLUDED":{"INTERNAL":"MARKETING_BASE"}}}} */
export type Award = Node & {
  __typename?: 'Award';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  slug: Scalars['String'];
  /** {} */
  name: Scalars['String'];
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year: Scalars['Int'];
  /** {} */
  targetSites: Array<TargetSite>;
  /** {} */
  langCode?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {} */
  status: AwardStatus;
  /** {"required":true,"readonly":true} */
  managerUser?: Maybe<User>;
  /** {} */
  visibility: AwardVisibility;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>;
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies: Scalars['Int'];
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy: Scalars['Int'];
  /** {"dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {} */
  autovoterRoles: Array<AccessRole>;
  /** {} */
  options: Array<AwardOptionFlag>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>;
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<Media>;
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<Media>;
  /** {} */
  extraMediaType?: Maybe<MediaType>;
  /** {} */
  extraMediaLabel?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  categories: Array<AwardCategory>;
  /** {} */
  candidacies: Array<Candidacy>;
  /** {} */
  invitedUsers: Array<AwardInvitedUser>;
  /** {} */
  invitedCompanies?: Maybe<AwardInvitedCompanyConnection>;
  /** {} */
  companyForm?: Maybe<Scalars['String']>;
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>;
  /** {} */
  ratingOverallLabel?: Maybe<Scalars['String']>;
  /** {} */
  rating1Label?: Maybe<Scalars['String']>;
  /** {} */
  rating2Label?: Maybe<Scalars['String']>;
  /** {} */
  rating3Label?: Maybe<Scalars['String']>;
  /** {} */
  rating4Label?: Maybe<Scalars['String']>;
  /** {} */
  rating5Label?: Maybe<Scalars['String']>;
  /** {} */
  comment1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>;
  /** {} */
  description2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>;
  /** {} */
  description3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description4Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>;
  /** {} */
  description5Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>;
  /** {} */
  description6Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>;
  /** {} */
  description7Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>;
  /** {} */
  description8Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>;
  /** {} */
  description9Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>;
  /** {} */
  description10Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>;
};


/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_OPEN":{"INTERNAL":"MARKETING_BASE"},"CANDIDANCY_CLOSED":{"INTERNAL":"MARKETING_BASE"},"VOTING_OPEN":{"INTERNAL":"MARKETING_BASE"},"VOTING_CLOSED":{"INTERNAL":"MARKETING_BASE"},"CONCLUDED":{"INTERNAL":"MARKETING_BASE"}}}} */
export type AwardInvitedCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<AwardInvitedCompaniesFilterInput>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardCategory = Node & {
  __typename?: 'AwardCategory';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  Award: Award;
  /** {} */
  name: Scalars['String'];
  /** {"multiline":true} */
  description: Scalars['String'];
  /** {"mediaType":"ICON"} */
  Media?: Maybe<Media>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>;
  /** {} */
  special: Scalars['Boolean'];
  /** {"forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>;
  /** {} */
  candidacies: Array<Candidacy>;
  /** {} */
  candidacyRatings: Array<CandidacyRating>;
  /** {} */
  awardResults: Array<AwardResult>;
};

export type AwardCategoryCreateInput = {
  /** {} */
  Award: RefInputObject;
  /** {} */
  name: Scalars['String'];
  /** {"multiline":true} */
  description: Scalars['String'];
  /** {"mediaType":"ICON"} */
  Media?: Maybe<RefInputObject>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>;
  /** {} */
  special: Scalars['Boolean'];
  /** {"forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>;
  /** {} */
  candidacyRatings?: Maybe<Array<RefInputObject>>;
};

export type AwardCategoryPatchInput = {
  /** {} */
  Award?: Maybe<RefInputObject>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  description?: Maybe<Scalars['String']>;
  /** {"mediaType":"ICON"} */
  Media?: Maybe<RefInputObject>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  giftUrl?: Maybe<Scalars['URL']>;
  /** {} */
  special?: Maybe<Scalars['Boolean']>;
  /** {"forceCase":"LOWER"} */
  slug?: Maybe<Scalars['String']>;
  /** {} */
  candidacyRatings?: Maybe<Array<RefInputObject>>;
};

/** A connection to a list of items. */
export type AwardConnection = {
  __typename?: 'AwardConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AwardEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AwardCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year: Scalars['Int'];
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {} */
  langCode?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"required":true,"readonly":true} */
  managerUser?: Maybe<RefInputObject>;
  /** {} */
  visibility: AwardVisibility;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>;
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies: Scalars['Int'];
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy: Scalars['Int'];
  /** {"dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {} */
  autovoterRoles?: Maybe<Array<AccessRole>>;
  /** {} */
  options?: Maybe<Array<AwardOptionFlag>>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>;
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<RefInputObject>;
  /** {} */
  extraMediaType?: Maybe<MediaType>;
  /** {} */
  extraMediaLabel?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  companyForm?: Maybe<Scalars['String']>;
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>;
  /** {} */
  ratingOverallLabel?: Maybe<Scalars['String']>;
  /** {} */
  rating1Label?: Maybe<Scalars['String']>;
  /** {} */
  rating2Label?: Maybe<Scalars['String']>;
  /** {} */
  rating3Label?: Maybe<Scalars['String']>;
  /** {} */
  rating4Label?: Maybe<Scalars['String']>;
  /** {} */
  rating5Label?: Maybe<Scalars['String']>;
  /** {} */
  comment1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>;
  /** {} */
  description2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>;
  /** {} */
  description3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description4Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>;
  /** {} */
  description5Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>;
  /** {} */
  description6Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>;
  /** {} */
  description7Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>;
  /** {} */
  description8Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>;
  /** {} */
  description9Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>;
  /** {} */
  description10Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type AwardEdge = {
  __typename?: 'AwardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Award>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type AwardFilterInput = {
  /** Singolo Award */
  Object?: Maybe<RefInputObject>;
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Contained in edition */
  edition?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<AwardStatus>>;
  visibility?: Maybe<AwardVisibility>;
};

export type AwardInvitedCompaniesFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardInvitedCompany = Node & {
  __typename?: 'AwardInvitedCompany';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Award: Award;
  /** {"readonly":true} */
  Company: Company;
  /** {} */
  signedContract: Scalars['Boolean'];
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>;
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type AwardInvitedCompanyConnection = {
  __typename?: 'AwardInvitedCompanyConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AwardInvitedCompanyEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AwardInvitedCompanyCreateInput = {
  /** {"readonly":true} */
  Award: RefInputObject;
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {} */
  signedContract: Scalars['Boolean'];
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>;
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type AwardInvitedCompanyEdge = {
  __typename?: 'AwardInvitedCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<AwardInvitedCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AwardInvitedCompanyPatchInput = {
  /** {} */
  signedContract?: Maybe<Scalars['Boolean']>;
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>;
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardInvitedUser = Node & {
  __typename?: 'AwardInvitedUser';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  Award: Award;
  /** {} */
  User: User;
  /** {} */
  roles: Array<AwardRoles>;
};

export type AwardInvitedUserCreateInput = {
  /** {} */
  Award: RefInputObject;
  /** {} */
  User: RefInputObject;
  /** {} */
  roles?: Maybe<Array<AwardRoles>>;
};

export type AwardInvitedUserPatchInput = {
  /** {} */
  Award?: Maybe<RefInputObject>;
  /** {} */
  User?: Maybe<RefInputObject>;
  /** {} */
  roles?: Maybe<Array<AwardRoles>>;
};

/** // TODO */
export enum AwardOptionFlag {
  EanRequired = 'EAN_REQUIRED',
  LaunchdateRequired = 'LAUNCHDATE_REQUIRED',
  ExtramediaRequired = 'EXTRAMEDIA_REQUIRED',
  CompanyLogoprintRequired = 'COMPANY_LOGOPRINT_REQUIRED',
  SignedContractRequired = 'SIGNED_CONTRACT_REQUIRED',
  GalleryEnabled = 'GALLERY_ENABLED',
  IngredientsImageRequired = 'INGREDIENTS_IMAGE_REQUIRED'
}

export enum AwardOrderBy {
  Name = 'name',
  Edition = 'edition',
  Year = 'year',
  Status = 'status',
  CandidaciesCloseAt = 'candidaciesCloseAt',
  VotesCloseAt = 'votesCloseAt'
}

export type AwardPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year?: Maybe<Scalars['Int']>;
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {} */
  langCode?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {} */
  visibility?: Maybe<AwardVisibility>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCompany?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumGoods?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumCandidacies?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  vademecumInvited?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForProducer?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionForVoter?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionProducerHighlights?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  publicDescription?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  eventDescription?: Maybe<Scalars['String']>;
  /** {"max":100,"min":1} */
  maxPerCompanyCandidacies?: Maybe<Scalars['Int']>;
  /** {"max":100,"min":1} */
  maxGoodsPerCandidacy?: Maybe<Scalars['Int']>;
  /** {"dtPrecision":"DAY_END"} */
  candidaciesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  votesCloseAt?: Maybe<Scalars['DateTime']>;
  /** {} */
  autovoterRoles?: Maybe<Array<AccessRole>>;
  /** {} */
  options?: Maybe<Array<AwardOptionFlag>>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  supportEmail?: Maybe<Scalars['Email']>;
  /** {"mediaType":"PDF_WEB"} */
  regulationsMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_WEB"} */
  contractMedia?: Maybe<RefInputObject>;
  /** {} */
  extraMediaType?: Maybe<MediaType>;
  /** {} */
  extraMediaLabel?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  companyForm?: Maybe<Scalars['String']>;
  /** {} */
  candidacyForm?: Maybe<Scalars['String']>;
  /** {} */
  ratingOverallLabel?: Maybe<Scalars['String']>;
  /** {} */
  rating1Label?: Maybe<Scalars['String']>;
  /** {} */
  rating2Label?: Maybe<Scalars['String']>;
  /** {} */
  rating3Label?: Maybe<Scalars['String']>;
  /** {} */
  rating4Label?: Maybe<Scalars['String']>;
  /** {} */
  rating5Label?: Maybe<Scalars['String']>;
  /** {} */
  comment1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment1Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment2Max?: Maybe<Scalars['Int']>;
  /** {} */
  comment3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  comment3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description1Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description1Max?: Maybe<Scalars['Int']>;
  /** {} */
  description2Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description2Max?: Maybe<Scalars['Int']>;
  /** {} */
  description3Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description3Max?: Maybe<Scalars['Int']>;
  /** {} */
  description4Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description4Max?: Maybe<Scalars['Int']>;
  /** {} */
  description5Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description5Max?: Maybe<Scalars['Int']>;
  /** {} */
  description6Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description6Max?: Maybe<Scalars['Int']>;
  /** {} */
  description7Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description7Max?: Maybe<Scalars['Int']>;
  /** {} */
  description8Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description8Max?: Maybe<Scalars['Int']>;
  /** {} */
  description9Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description9Max?: Maybe<Scalars['Int']>;
  /** {} */
  description10Label?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  description10Max?: Maybe<Scalars['Int']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type AwardResult = Node & {
  __typename?: 'AwardResult';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  AwardCategory: AwardCategory;
  /** {} */
  Candidacy?: Maybe<Candidacy>;
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>;
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>;
  /** {} */
  finalPosition?: Maybe<FinalPosition>;
};

export type AwardResultCreateInput = {
  /** {} */
  AwardCategory: RefInputObject;
  /** {} */
  Candidacy?: Maybe<RefInputObject>;
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>;
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>;
  /** {} */
  finalPosition?: Maybe<FinalPosition>;
};

export type AwardResultPatchInput = {
  /** {} */
  AwardCategory?: Maybe<RefInputObject>;
  /** {} */
  Candidacy?: Maybe<RefInputObject>;
  /** {} */
  legacyCandidacyName?: Maybe<Scalars['String']>;
  /** {} */
  legacyCompanyName?: Maybe<Scalars['String']>;
  /** {} */
  finalPosition?: Maybe<FinalPosition>;
};

/** Ruoli che gli utenti (invitati) possono ricoprire per un premio. Flags */
export enum AwardRoles {
  SpecialJuror = 'SPECIAL_JUROR',
  Speaker = 'SPEAKER',
  SpecialGuest = 'SPECIAL_GUEST'
}

/** Award's possible statues */
export enum AwardStatus {
  Draft = 'DRAFT',
  CandidancyOpen = 'CANDIDANCY_OPEN',
  CandidancyClosed = 'CANDIDANCY_CLOSED',
  VotingOpen = 'VOTING_OPEN',
  VotingClosed = 'VOTING_CLOSED',
  Concluded = 'CONCLUDED'
}

/** Award's possible visibility statues */
export enum AwardVisibility {
  Vote = 'VOTE',
  Showcase = 'SHOWCASE',
  Sourcing = 'SOURCING'
}

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"},"canSetStatusRoles":{"isValidated":{"FALSE":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"TRUE":{"INTERNAL":"MARKETING_BASE"}}},"canPatchNeedCurrentStatusAccess":"isValidated"} */
export type Banner = Node & {
  __typename?: 'Banner';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  targetSite: TargetSite;
  /** {} */
  targetMedia: TargetMedia;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  locations: Array<WidgetLocation>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'];
  /** {} */
  layout: BannerLayout;
  /** {"max":50} */
  title?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"max":50} */
  body?: Maybe<Scalars['String']>;
  /** {} */
  callToAction?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>;
  /** {} */
  Media?: Maybe<Media>;
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>;
  /** {} */
  FrequentSearch?: Maybe<FrequentSearch>;
  /** {} */
  MagazineCollection?: Maybe<MagazineCollection>;
  /** {} */
  NewsletterIssue?: Maybe<NewsletterIssue>;
  /** {} */
  Magazine?: Maybe<Magazine>;
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>;
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>;
  /** {} */
  sponsorCompany?: Maybe<Company>;
  /** {} */
  sponsorCompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  sponsorService?: Maybe<Service>;
  /** {} */
  sponsorFoodService?: Maybe<FoodService>;
  /** {} */
  sponsorRetailer?: Maybe<Retailer>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  GeoIndication?: Maybe<GeoIndication>;
  /** {} */
  Fair?: Maybe<Fair>;
  /** {} */
  Award?: Maybe<Award>;
  /** {} */
  isValidated: Scalars['Boolean'];
  /** {"dtPrecision":"DAY_END"} */
  deadlineDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  isSfAbandoned: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type BannerConnection = {
  __typename?: 'BannerConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BannerCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  targetSite: TargetSite;
  /** {} */
  targetMedia: TargetMedia;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'];
  /** {} */
  layout: BannerLayout;
  /** {"max":50} */
  title?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"max":50} */
  body?: Maybe<Scalars['String']>;
  /** {} */
  callToAction?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>;
  /** {} */
  Media?: Maybe<RefInputObject>;
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>;
  /** {} */
  FrequentSearch?: Maybe<RefInputObject>;
  /** {} */
  MagazineCollection?: Maybe<RefInputObject>;
  /** {} */
  NewsletterIssue?: Maybe<RefInputObject>;
  /** {} */
  Magazine?: Maybe<RefInputObject>;
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>;
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>;
  /** {} */
  sponsorCompany?: Maybe<RefInputObject>;
  /** {} */
  sponsorCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  sponsorService?: Maybe<RefInputObject>;
  /** {} */
  sponsorFoodService?: Maybe<RefInputObject>;
  /** {} */
  sponsorRetailer?: Maybe<RefInputObject>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Award?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** The item at the end of the edge */
  node?: Maybe<Banner>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type BannerFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Started and not expired at */
  targetDate?: Maybe<Scalars['DateTime']>;
  targetSite?: Maybe<TargetSite>;
  targetMedia?: Maybe<TargetMedia>;
  location?: Maybe<WidgetLocation>;
  isValidated?: Maybe<Scalars['Boolean']>;
  isSfAbandoned?: Maybe<Scalars['Boolean']>;
  sponsorCompany?: Maybe<RefInputObject>;
  sponsorCompanyGroup?: Maybe<RefInputObject>;
  sponsorService?: Maybe<RefInputObject>;
  sponsorRetailer?: Maybe<RefInputObject>;
  Company?: Maybe<RefInputObject>;
  GeoIndication?: Maybe<RefInputObject>;
  Fair?: Maybe<RefInputObject>;
  Award?: Maybe<RefInputObject>;
};

/** Per determinare il mediaType faccio : mediaType = BannerLayout2MediaType[bannerEntity.layout] */
export enum BannerLayout {
  Full = 'Full',
  SidedBoxWithFrequentSearch = 'SidedBoxWithFrequentSearch',
  SmallImageOnly = 'SmallImageOnly',
  LeaderboardImageOnly = 'LeaderboardImageOnly',
  PopupImageOnly = 'PopupImageOnly',
  DirectoryCard = 'DirectoryCard',
  Popup = 'Popup',
  Toaster = 'Toaster',
  ImpiantoMezza = 'Impianto_Mezza',
  ImpiantoMezzaVerticale = 'Impianto_MezzaVerticale',
  ImpiantoIntera = 'Impianto_Intera',
  ImpiantoBattente = 'Impianto_Battente',
  PubbliRedazionaleSingola = 'PubbliRedazionale_Singola',
  PubbliRedazionaleDoppia = 'PubbliRedazionale_Doppia'
}

export enum BannerOrderBy {
  Name = 'name',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  IsValidated = 'isValidated',
  IsSfAbandoned = 'isSfAbandoned',
  DeadlineDate = 'deadlineDate',
  Size = 'size',
  Media = 'Media'
}

export type BannerPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  targetSite?: Maybe<TargetSite>;
  /** {} */
  targetMedia?: Maybe<TargetMedia>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority?: Maybe<Scalars['Int']>;
  /** {} */
  layout?: Maybe<BannerLayout>;
  /** {"max":50} */
  title?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"max":50} */
  body?: Maybe<Scalars['String']>;
  /** {} */
  callToAction?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"} */
  url?: Maybe<Scalars['URL']>;
  /** {} */
  Media?: Maybe<RefInputObject>;
  /** {} */
  themeColor?: Maybe<WidgetStyleColor>;
  /** {} */
  FrequentSearch?: Maybe<RefInputObject>;
  /** {} */
  MagazineCollection?: Maybe<RefInputObject>;
  /** {} */
  NewsletterIssue?: Maybe<RefInputObject>;
  /** {} */
  Magazine?: Maybe<RefInputObject>;
  /** {"max":2147483647,"min":-2147483647} */
  magazinePage?: Maybe<Scalars['Int']>;
  /** {"max":30} */
  sponsorName?: Maybe<Scalars['String']>;
  /** {} */
  sponsorCompany?: Maybe<RefInputObject>;
  /** {} */
  sponsorCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  sponsorService?: Maybe<RefInputObject>;
  /** {} */
  sponsorFoodService?: Maybe<RefInputObject>;
  /** {} */
  sponsorRetailer?: Maybe<RefInputObject>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Award?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type BannerView = Node & {
  __typename?: 'BannerView';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Banner: Banner;
  /** {} */
  User?: Maybe<User>;
  /** {"readonly":true} */
  type: BannerViewType;
  /** {} */
  targetSite: TargetSite;
  /** {} */
  referrer?: Maybe<Scalars['String']>;
  /** {} */
  useragent?: Maybe<Scalars['String']>;
  /** {} */
  timestamp: Scalars['DateTime'];
  /** {} */
  ip?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type BannerViewConnection = {
  __typename?: 'BannerViewConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerViewEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BannerViewCreateInput = {
  /** {"readonly":true} */
  Banner: RefInputObject;
  /** {"readonly":true} */
  type: BannerViewType;
};

/** An edge in a connection. */
export type BannerViewEdge = {
  __typename?: 'BannerViewEdge';
  /** The item at the end of the edge */
  node?: Maybe<BannerView>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type BannerViewFilterInput = {
  Banner?: Maybe<Array<RefInputObject>>;
  User?: Maybe<Array<RefInputObject>>;
  types?: Maybe<Array<BannerViewType>>;
  targetSite?: Maybe<TargetSite>;
  /** Contained in referrer */
  referrer?: Maybe<Scalars['String']>;
  /** Contained in useragent */
  useragent?: Maybe<Scalars['String']>;
  /** Contained in ip */
  ip?: Maybe<Scalars['String']>;
  timespanMin?: Maybe<Scalars['DateTime']>;
  timespanMax?: Maybe<Scalars['DateTime']>;
};

/** @TODO DESCRIBE ME */
export type BannerViewInput = {
  locations?: Maybe<Array<WidgetLocation>>;
  targetMedia: TargetMedia;
};

export enum BannerViewType {
  Impression = 'IMPRESSION',
  Click = 'CLICK'
}

/** Map boolean to enum for xxSetStatus usage */
export enum BooleanEnum {
  False = 'FALSE',
  True = 'TRUE'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Branch = Node & {
  __typename?: 'Branch';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  Company: Company;
  /** {} */
  name: Scalars['String'];
  /** {} */
  type: Array<BranchType>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

export type Branch_From_Company_Type = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  type?: Maybe<Array<BranchType>>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

export type BranchCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  Company: RefInputObject;
  /** {} */
  name: Scalars['String'];
  /** {} */
  type?: Maybe<Array<BranchType>>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

export type BranchPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  type?: Maybe<Array<BranchType>>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

export enum BranchType {
  ManufacturingPlant = 'MANUFACTURING_PLANT',
  PoBox = 'PO_BOX',
  LogisticDepartment = 'LOGISTIC_DEPARTMENT',
  SalesDepartment = 'SALES_DEPARTMENT'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Brand = Node & {
  __typename?: 'Brand';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"readonly":true} */
  Company: Company;
  /** {} */
  slug: Scalars['String'];
  /** {"required":true,"max":50,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {} */
  pricePositioning: PricePositioning;
  /** {"multiline":true} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>;
  /** {"max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>;
  /** {} */
  retailChainDistribution: Scalars['Boolean'];
  /** {} */
  foodServiceChainDistribution: Scalars['Boolean'];
  /** {} */
  gourmetShopChainDistribution: Scalars['Boolean'];
  /**
   * {}
   * @deprecated Non si usa
   */
  order: Scalars['Int'];
  /** {} */
  goods: Array<Good>;
  /** {} */
  goodClasses: Array<GoodClass>;
  /** {} */
  goodClassesFull: Array<GoodClass>;
  /** {} */
  editorialSections: Array<EditorialSection>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
};

/** A connection to a list of items. */
export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BrandCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"readonly":true} */
  Company: RefInputObject;
  /** {"required":true,"max":50,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {} */
  pricePositioning: PricePositioning;
  /** {"multiline":true} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>;
  /** {"max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>;
  /** {} */
  retailChainDistribution: Scalars['Boolean'];
  /** {} */
  foodServiceChainDistribution: Scalars['Boolean'];
  /** {} */
  gourmetShopChainDistribution: Scalars['Boolean'];
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The item at the end of the edge */
  node?: Maybe<Brand>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type BrandFilterInput = {
  /** Contained in name or nameIta */
  name?: Maybe<Scalars['String']>;
  Company?: Maybe<RefInputObject>;
};

export type BrandPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":50,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {} */
  pricePositioning?: Maybe<PricePositioning>;
  /** {"multiline":true} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":255,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":255} */
  socialInstagramPage?: Maybe<Scalars['String']>;
  /** {"max":255} */
  socialFacebookPage?: Maybe<Scalars['String']>;
  /** {} */
  retailChainDistribution?: Maybe<Scalars['Boolean']>;
  /** {} */
  foodServiceChainDistribution?: Maybe<Scalars['Boolean']>;
  /** {} */
  gourmetShopChainDistribution?: Maybe<Scalars['Boolean']>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
};

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type CampaignMember = Node & {
  __typename?: 'CampaignMember';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  Esito_Campaign?: Maybe<CampaignMember_Esito_Campaign__C_Enum>;
  /** {} */
  CampaignId: Scalars['String'];
};

/** SF picklist */
export enum CampaignMember_Esito_Campaign__C_Enum {
  /** Assente */
  Assente = 'ASSENTE',
  /** Presente */
  Presente = 'PRESENTE',
  /** Presente solo convegno */
  PresenteSoloConvegno = 'PRESENTE_SOLO_CONVEGNO',
  /** Presente solo cena */
  PresenteSoloCena = 'PRESENTE_SOLO_CENA',
  /** Interessato */
  Interessato = 'INTERESSATO',
  /** Non Interessato */
  NonInteressato = 'NON_INTERESSATO'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Candidacy = Node & {
  __typename?: 'Candidacy';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Award: Award;
  /** {"readonly":true} */
  Company: Company;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<Media>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia: Array<Media>;
  /** {} */
  AwardCategory?: Maybe<AwardCategory>;
  /** {} */
  slug: Scalars['String'];
  /** {} */
  name: Scalars['String'];
  /** {"required":true} */
  Brand: Brand;
  /** {} */
  range?: Maybe<Scalars['String']>;
  /** {} */
  extraMedia?: Maybe<Media>;
  /** {"required":true,"min":1} */
  goods: Array<Good>;
  /** {} */
  candidacyRatings: Array<CandidacyRating>;
  /** {} */
  awardResults: Array<AwardResult>;
  /** {"dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CandidacyConnection = {
  __typename?: 'CandidacyConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CandidacyEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CandidacyCreateInput = {
  /** {"readonly":true} */
  Award: RefInputObject;
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  AwardCategory?: Maybe<RefInputObject>;
  /** {} */
  name: Scalars['String'];
  /** {"required":true} */
  Brand: RefInputObject;
  /** {} */
  range?: Maybe<Scalars['String']>;
  /** {} */
  extraMedia?: Maybe<RefInputObject>;
  /** {"required":true,"min":1} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {"dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CandidacyEdge = {
  __typename?: 'CandidacyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Candidacy>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CandidacyFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  Award?: Maybe<RefInputObject>;
  Brand?: Maybe<RefInputObject>;
  Company?: Maybe<RefInputObject>;
};

export enum CandidacyOrderBy {
  Name = 'name',
  AwardId = 'awardId',
  AwardCategoryId = 'awardCategoryId'
}

export type CandidacyPatchInput = {
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  AwardCategory?: Maybe<RefInputObject>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true} */
  Brand?: Maybe<RefInputObject>;
  /** {} */
  range?: Maybe<Scalars['String']>;
  /** {} */
  extraMedia?: Maybe<RefInputObject>;
  /** {"required":true,"min":1} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {"dtPrecision":"MONTH_BEGIN"} */
  launchDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  formValues?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description1?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description2?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description3?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description4?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description5?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description6?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description7?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description8?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description9?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FORMATTING"} */
  description10?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}} */
export type CandidacyRating = Node & {
  __typename?: 'CandidacyRating';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  Candidacy: Candidacy;
  /** {} */
  askSample: Scalars['Boolean'];
  /** {"max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CandidacyRatingConnection = {
  __typename?: 'CandidacyRatingConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CandidacyRatingEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CandidacyRatingCreateInput = {
  /** {} */
  User: RefInputObject;
  /** {} */
  Candidacy: RefInputObject;
  /** {} */
  askSample: Scalars['Boolean'];
  /** {"max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CandidacyRatingEdge = {
  __typename?: 'CandidacyRatingEdge';
  /** The item at the end of the edge */
  node?: Maybe<CandidacyRating>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CandidacyRatingFilterInput = {
  Award?: Maybe<RefInputObject>;
  AwardCategory?: Maybe<RefInputObject>;
  askSample?: Maybe<Scalars['Boolean']>;
  comment1?: Maybe<Scalars['Boolean']>;
  comment2?: Maybe<Scalars['Boolean']>;
  comment3?: Maybe<Scalars['Boolean']>;
  User?: Maybe<RefInputObject>;
};

export enum CandidacyRatingOrderBy {
  User = 'User',
  Candidacy = 'Candidacy',
  LocalId = 'localId'
}

export type CandidacyRatingPatchInput = {
  /** {} */
  User?: Maybe<RefInputObject>;
  /** {} */
  Candidacy?: Maybe<RefInputObject>;
  /** {} */
  askSample?: Maybe<Scalars['Boolean']>;
  /** {"max":10,"min":1} */
  overall?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating1?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating2?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating3?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating4?: Maybe<Scalars['Int']>;
  /** {"max":10,"min":1} */
  rating5?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":200} */
  comment1?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment2?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  comment3?: Maybe<Scalars['String']>;
};

/** User possible managing Capabilities */
export enum Capability {
  Edit = 'EDIT',
  Account = 'ACCOUNT',
  Purchase = 'PURCHASE',
  Manage = 'MANAGE'
}

export type CategoryWithCount = {
  __typename?: 'CategoryWithCount';
  name: Scalars['String'];
  count: Scalars['Int'];
};

export type Change = {
  __typename?: 'change';
  type: Scalars['String'];
  id: Scalars['ID'];
  fields?: Maybe<Array<Scalars['String']>>;
};

export enum Channel {
  Retail = 'RETAIL',
  Foodservice = 'FOODSERVICE',
  GourmetShops = 'GOURMET_SHOPS',
  Wholesaler = 'WHOLESALER'
}

export type CompaniesDir = Company | Brand;

/** A connection to a list of items. */
export type CompaniesDirConnection = {
  __typename?: 'companiesDirConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompaniesDirEdge>>>;
  total?: Maybe<Scalars['Int']>;
  facet?: Maybe<CompaniesDirFacets>;
  relatedFrequentSearches?: Maybe<Array<Maybe<FrequentSearch>>>;
};

/** An edge in a connection. */
export type CompaniesDirEdge = {
  __typename?: 'companiesDirEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompaniesDir>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  score: Scalars['Float'];
};

export type CompaniesDirFacets = {
  __typename?: 'companiesDirFacets';
  plProducer?: Maybe<Array<FacetValueCount>>;
  type?: Maybe<Array<FacetValueCount>>;
  goodClasses?: Maybe<Array<FacetValueCount>>;
  editorialSections?: Maybe<Array<FacetValueCount>>;
  companyCertifications?: Maybe<Array<FacetValueCount>>;
  stateProvince?: Maybe<Array<FacetValueCount>>;
  revenue?: Maybe<Array<FacetValueCount>>;
};

/** @TODO DESCRIBE ME */
export type CompaniesFilterType = {
  targetSite?: Maybe<TargetSite>;
  items?: Maybe<Array<RefInputObject>>;
  FrequentSearch?: Maybe<RefInputObject>;
  textSearch?: Maybe<Scalars['String']>;
  types?: Maybe<Array<CompaniesTypeEnum>>;
  levels?: Maybe<Array<CompanyLevelEnum>>;
  plProducer?: Maybe<Scalars['Boolean']>;
  goodClasses?: Maybe<Array<RefInputObject>>;
  editorialSections?: Maybe<Array<RefInputObject>>;
  companyCertifications?: Maybe<Array<RefInputObject>>;
  geoIndications?: Maybe<Array<RefInputObject>>;
  fairs?: Maybe<Array<RefInputObject>>;
  stateProvinces?: Maybe<Array<RefInputObject>>;
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>;
};

/** Companies possible types */
export enum CompaniesTypeEnum {
  Company = 'Company',
  Brand = 'Brand'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Company = Node & {
  __typename?: 'Company';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<Media>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia: Array<Media>;
  /** {} */
  industry: CompanyIndustry;
  /** {} */
  ownership?: Maybe<CompanyOwnership>;
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<Branch>;
  /** {} */
  headquarterBranch?: Maybe<Branch>;
  /** {"max":2021,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {} */
  AtecoCode?: Maybe<AtecoCode>;
  /**
   * {"dtPrecision":"DAY_BEGIN"}
   * @deprecated Resterà solo su SF
   */
  vatOpeningDate?: Maybe<Scalars['DateTime']>;
  /**
   * {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
   * @deprecated Resterà solo su SF
   */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$"} */
  emailDomain?: Maybe<Scalars['String']>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>;
  /** {} */
  brands: Array<Brand>;
  /** {} */
  branches: Array<Branch>;
  /** {} */
  distributionChannels: Array<DistributionChannel>;
  /** {} */
  companyYears?: Maybe<Array<CompanyYear>>;
  /** {} */
  currentCompanyLevels: Array<CompanyLevel>;
  /** {} */
  companyLevels: Array<CompanyLevel>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  goods: Array<Good>;
  /** {} */
  highlightedGoods: Array<HighlightedGood>;
  /** {} */
  goodClassesFull: Array<GoodClass>;
  /** {} */
  editorialSections: Array<EditorialSection>;
  /** {} */
  companyCertifications: Array<CompanyCertification>;
  /** {} */
  goodFeatureCertifications: Array<GoodFeatureCertification>;
  /** {} */
  awardInvitedCompanies: Array<AwardInvitedCompany>;
  /** {} */
  geoIndications: Array<GeoIndication>;
  /** {} */
  fairParticipations?: Maybe<Array<FairParticipation>>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};


/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyCompanyYearsArgs = {
  filter?: Maybe<CompanyYearsFilter>;
};


/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyFairParticipationsArgs = {
  Fair?: Maybe<RefInputObject>;
  isConcluded?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyBadge = Node & {
  __typename?: 'CompanyBadge';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":255} */
  name: Scalars['String'];
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<Media>;
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"readonly":true} */
  targetSite: TargetSite;
};

/** A connection to a list of items. */
export type CompanyBadgeConnection = {
  __typename?: 'CompanyBadgeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyBadgeEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyBadgeCreateInput = {
  /** {"max":255} */
  name: Scalars['String'];
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>;
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"readonly":true} */
  targetSite: TargetSite;
};

/** An edge in a connection. */
export type CompanyBadgeEdge = {
  __typename?: 'CompanyBadgeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyBadge>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CompanyBadgeFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  targetSite?: Maybe<TargetSite>;
};

export type CompanyBadgePatchInput = {
  /** {"max":255} */
  name?: Maybe<Scalars['String']>;
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>;
  /** {"max":255} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyCertification = Node & {
  __typename?: 'CompanyCertification';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CompanyCertificationConnection = {
  __typename?: 'CompanyCertificationConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyCertificationEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyCertificationCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CompanyCertificationEdge = {
  __typename?: 'CompanyCertificationEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyCertification>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyCertificationPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  industry: CompanyIndustry;
  /** {} */
  ownership?: Maybe<CompanyOwnership>;
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<RefInputObject>;
  /** {"required":true,"allowFormInlining":true} */
  billingBranch__inline?: Maybe<Branch_From_Company_Type>;
  /** {} */
  headquarterBranch?: Maybe<RefInputObject>;
  /** {"max":2021,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {} */
  AtecoCode?: Maybe<RefInputObject>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  vatOpeningDate?: Maybe<Scalars['DateTime']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>;
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CompanyFilterInput = {
  /** Contained in name, billingName and brands.name(s) */
  name?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  Award?: Maybe<Array<RefInputObject>>;
  hasLogo?: Maybe<Scalars['Boolean']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  hasGoodsWithEan?: Maybe<Scalars['Boolean']>;
  hasCandidacies?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type CompanyGroup = Node & {
  __typename?: 'CompanyGroup';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"readonly":true} */
  type: CompanyGroupType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /**
   * {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
   * @deprecated Resterà solo su SF
   */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};

/** A connection to a list of items. */
export type CompanyGroupConnection = {
  __typename?: 'CompanyGroupConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyGroupEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyGroupCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  type: CompanyGroupType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** An edge in a connection. */
export type CompanyGroupEdge = {
  __typename?: 'CompanyGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGroup>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CompanyGroupFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<CompanyGroupType>>;
  Country?: Maybe<Array<RefInputObject>>;
};

export enum CompanyGroupOrderBy {
  Name = 'name',
  Type = 'type'
}

export type CompanyGroupPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** CompanyGroup possible types */
export enum CompanyGroupType {
  AssociazioneDiCategoria = 'ASSOCIAZIONE_DI_CATEGORIA',
  CameraDiCommercioEntePromozione = 'CAMERA_DI_COMMERCIO_ENTE_PROMOZIONE',
  ConsorziDiTutela = 'CONSORZI_DI_TUTELA',
  ConsorziPrivati = 'CONSORZI_PRIVATI',
  IstituzioneCulturale = 'ISTITUZIONE_CULTURALE',
  OrganiStatali = 'ORGANI_STATALI',
  ScuoleEUniversit = 'SCUOLE_E_UNIVERSIT',
  FormazioneEnogastronomicaProfessionale = 'FORMAZIONE_ENOGASTRONOMICA_PROFESSIONALE'
}

/** Company possible industries */
export enum CompanyIndustry {
  IndustriaAlimentare = 'INDUSTRIA_ALIMENTARE',
  IndustriaBeniDiConsumoNonFood = 'INDUSTRIA_BENI_DI_CONSUMO_NON_FOOD',
  IndustriaImpiantiIndAlimArredamenti = 'INDUSTRIA_IMPIANTI_IND_ALIM_ARREDAMENTI',
  IndustriaPackAlimTrasformazione = 'INDUSTRIA_PACK_ALIM_TRASFORMAZIONE'
}

/** Company possible UI layouts */
export enum CompanyLayout {
  BrandGrouped = 'BRAND_GROUPED',
  RangeGrouped = 'RANGE_GROUPED'
}

/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type CompanyLevel = Node & {
  __typename?: 'CompanyLevel';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Company: Company;
  /** {"readonly":true} */
  targetSite: TargetSite;
  /** {"readonly":true,"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"readonly":true} */
  level: CompanyLevelEnum;
  /** {"max":200,"formatRegexStr":"^/[^/ ][^ ]*"} */
  newsPath?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>;
  /** {} */
  companyBadges: Array<CompanyBadge>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  videoUrl?: Maybe<Scalars['URL']>;
  /** {} */
  layout: CompanyLayout;
};

export type CompanyLevelCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {"readonly":true} */
  targetSite: TargetSite;
  /** {"readonly":true,"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"readonly":true} */
  level: CompanyLevelEnum;
  /** {"max":200,"formatRegexStr":"^/[^/ ][^ ]*"} */
  newsPath?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>;
  /** {} */
  companyBadges?: Maybe<Array<RefInputObject>>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  videoUrl?: Maybe<Scalars['URL']>;
  /** {} */
  layout: CompanyLayout;
};

/** Company possible level types */
export enum CompanyLevelEnum {
  Hidden = 'HIDDEN',
  Free = 'FREE',
  PaidL1 = 'PAID_L1',
  PaidL2 = 'PAID_L2',
  PaidL3 = 'PAID_L3',
  PaidL4 = 'PAID_L4'
}

export type CompanyLevelPatchInput = {
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":200,"formatRegexStr":"^/[^/ ][^ ]*"} */
  newsPath?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":100,"min":1} */
  maxHightlightedGoods?: Maybe<Scalars['Int']>;
  /** {} */
  companyBadges?: Maybe<Array<RefInputObject>>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  videoUrl?: Maybe<Scalars['URL']>;
  /** {} */
  layout?: Maybe<CompanyLayout>;
};

export enum CompanyOrderBy {
  Name = 'name',
  Industry = 'industry'
}

/** Company possible ownership types */
export enum CompanyOwnership {
  FamilyBusiness = 'FAMILY_BUSINESS',
  MultinationalCorporation = 'MULTINATIONAL_CORPORATION',
  PrivateEquity = 'PRIVATE_EQUITY',
  Cooperative = 'COOPERATIVE'
}

export type CompanyPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"LOGO_PRINT"} */
  logoPrintMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GALLERY_GENERIC"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  industry?: Maybe<CompanyIndustry>;
  /** {} */
  ownership?: Maybe<CompanyOwnership>;
  /** {"required":true,"allowFormInlining":true} */
  billingBranch?: Maybe<RefInputObject>;
  /** {"required":true,"allowFormInlining":true} */
  billingBranch__inline?: Maybe<Branch_From_Company_Type>;
  /** {} */
  headquarterBranch?: Maybe<RefInputObject>;
  /** {"max":2021,"min":1000} */
  yearEstablished?: Maybe<Scalars['Int']>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {} */
  AtecoCode?: Maybe<RefInputObject>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  vatOpeningDate?: Maybe<Scalars['DateTime']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"required":true,"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"required":true,"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  companyEmail?: Maybe<Scalars['Email']>;
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>;
};

/** Company revenue ranges */
export enum CompanyRevenueRange {
  /** Under 30000000 */
  Before = 'BEFORE',
  /** Over 100000000 */
  After = 'AFTER',
  /** Range 30000000-100000000 */
  Range_0 = 'RANGE_0'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type CompanyYear = Node & {
  __typename?: 'CompanyYear';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Company: Company;
  /** {"max":2100,"min":1900} */
  year: Scalars['Int'];
  /** {"max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>;
  /** {"max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>;
  /** {"max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>;
};

export type CompanyYearCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {"max":2100,"min":1900} */
  year: Scalars['Int'];
  /** {"max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>;
  /** {"max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>;
  /** {"max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>;
};

export type CompanyYearPatchInput = {
  /** {"max":2100,"min":1900} */
  year?: Maybe<Scalars['Int']>;
  /** {"max":1000000000000,"min":0} */
  revenue?: Maybe<Scalars['Int53']>;
  /** {"max":100,"min":-100} */
  ebitda?: Maybe<Scalars['Float']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  gain?: Maybe<Scalars['Int53']>;
  /** {"max":1000000000000,"min":-1000000000000} */
  mol?: Maybe<Scalars['Int53']>;
  /** {"max":1000000,"min":0} */
  numEmployees?: Maybe<Scalars['Int']>;
};

export type CompanyYearsFilter = {
  year?: Maybe<Scalars['Int']>;
  hasRevenue?: Maybe<Scalars['Boolean']>;
  hasEbitda?: Maybe<Scalars['Boolean']>;
  hasGain?: Maybe<Scalars['Boolean']>;
  hasNumEmployees?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type Contact = Node & {
  __typename?: 'Contact';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  Email?: Maybe<Scalars['Email']>;
  /** {} */
  Items_Active_Access?: Maybe<Array<Contact_Items_Active_Access__C_Enum>>;
};

/** SF picklist */
export enum Contact_Items_Active_Access__C_Enum {
  /** DS | Rivista | Periodico e Speciali */
  DsRivistaPeriodicoESpeciali = 'DS_RIVISTA_PERIODICO_E_SPECIALI',
  /** DS | Digitale | Periodico Completo */
  DsDigitalePeriodicoCompleto = 'DS_DIGITALE_PERIODICO_COMPLETO',
  /** FOOD | Rivista | Periodico e Speciali */
  FoodRivistaPeriodicoESpeciali = 'FOOD_RIVISTA_PERIODICO_E_SPECIALI',
  /** FOOD | Digitale | Periodico Completo */
  FoodDigitalePeriodicoCompleto = 'FOOD_DIGITALE_PERIODICO_COMPLETO',
  /** FS | Rivista | Periodico e Speciali */
  FsRivistaPeriodicoESpeciali = 'FS_RIVISTA_PERIODICO_E_SPECIALI',
  /** FS | Digitale | Periodico Completo */
  FsDigitalePeriodicoCompleto = 'FS_DIGITALE_PERIODICO_COMPLETO',
  /** IFNET | Rivista | Periodico e Speciali */
  IfnetRivistaPeriodicoESpeciali = 'IFNET_RIVISTA_PERIODICO_E_SPECIALI',
  /** IFNET | Digitale | Periodico Completo */
  IfnetDigitalePeriodicoCompleto = 'IFNET_DIGITALE_PERIODICO_COMPLETO'
}

/** {} */
export type Country = Node & {
  __typename?: 'Country';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {"max":2} */
  code?: Maybe<Scalars['String']>;
  /** {} */
  area: Scalars['String'];
  /** {} */
  continentMc?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** The item at the end of the edge */
  node?: Maybe<Country>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CountryFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Contained in code */
  code?: Maybe<Scalars['String']>;
  /** Contained in area */
  area?: Maybe<Scalars['String']>;
  /** Contained in continentMc */
  continentMc?: Maybe<Scalars['String']>;
};

export enum CountryOrderByEnum {
  Name = 'name',
  Code = 'code',
  Area = 'area',
  ContinentMc = 'continentMc'
}

/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type Coupon = Node & {
  __typename?: 'Coupon';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {"max":50} */
  code: Scalars['String'];
  /** {} */
  type: CouponType;
  /** {"max":100,"min":0} */
  amount: Scalars['Float'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":2147483647,"min":-2147483647} */
  maxUsages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  usagesCount: Scalars['Int'];
  /** {"max":2147483647,"min":-2147483647} */
  maxUsagesPerQuote?: Maybe<Scalars['Int']>;
  /** {"max":250} */
  brandSf?: Maybe<Scalars['String']>;
  /** {"max":250} */
  brandAreaSf?: Maybe<Scalars['String']>;
  /** {"max":18,"min":18} */
  product2Sf?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CouponConnection = {
  __typename?: 'CouponConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CouponEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CouponCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {"max":100,"min":0} */
  amount: Scalars['Float'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":2147483647,"min":-2147483647} */
  maxUsages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  usagesCount: Scalars['Int'];
  /** {"max":2147483647,"min":-2147483647} */
  maxUsagesPerQuote?: Maybe<Scalars['Int']>;
  /** {"max":250} */
  brandSf?: Maybe<Scalars['String']>;
  /** {"max":250} */
  brandAreaSf?: Maybe<Scalars['String']>;
  /** {"max":18,"min":18} */
  product2Sf?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CouponEdge = {
  __typename?: 'CouponEdge';
  /** The item at the end of the edge */
  node?: Maybe<Coupon>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type CouponFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Contained in code */
  code?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum CouponOrderBy {
  Name = 'name',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate'
}

export type CouponPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"max":100,"min":0} */
  amount?: Maybe<Scalars['Float']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":2147483647,"min":-2147483647} */
  maxUsages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  usagesCount?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  maxUsagesPerQuote?: Maybe<Scalars['Int']>;
  /** {"max":250} */
  brandSf?: Maybe<Scalars['String']>;
  /** {"max":250} */
  brandAreaSf?: Maybe<Scalars['String']>;
  /** {"max":18,"min":18} */
  product2Sf?: Maybe<Scalars['String']>;
};

export enum CouponType {
  Fix = 'FIX',
  Percentage = 'PERCENTAGE'
}


export enum DeliveryService {
  Pardot = 'PARDOT',
  Mailchimp = 'MAILCHIMP',
  Mailup = 'MAILUP'
}

export enum DirectoryTypeEnum {
  Company = 'COMPANY',
  Good = 'GOOD'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type DistributionChannel = Node & {
  __typename?: 'DistributionChannel';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"required":true,"readonly":true} */
  Company: Company;
  /** {} */
  mode: DistributionMode;
  /** {"required":true,"min":1} */
  channels: Array<Channel>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<Media>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>;
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>;
  /** {"required":true,"min":1} */
  countries: Array<Country>;
};

export type DistributionChannelCreateInput = {
  /** {"required":true,"readonly":true} */
  Company: RefInputObject;
  /** {} */
  mode: DistributionMode;
  /** {"required":true,"min":1} */
  channels?: Maybe<Array<Channel>>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>;
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>;
  /** {"required":true,"min":1} */
  countries?: Maybe<Array<RefInputObject>>;
};

export type DistributionChannelPatchInput = {
  /** {} */
  mode?: Maybe<DistributionMode>;
  /** {"required":true,"min":1} */
  channels?: Maybe<Array<Channel>>;
  /** {"mediaType":"PDF_GENERIC"} */
  catalogMedia?: Maybe<RefInputObject>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  contactEmail?: Maybe<Scalars['Email']>;
  /** {"multiline":true,"max":150} */
  note?: Maybe<Scalars['String']>;
  /** {"required":true,"min":1} */
  countries?: Maybe<Array<RefInputObject>>;
};

export enum DistributionMode {
  Direct = 'DIRECT',
  Distributor = 'DISTRIBUTOR',
  LocalBranch = 'LOCAL_BRANCH',
  Broker = 'BROKER'
}

/** TODO */
export enum EditorialBrand {
  Barbusiness = 'BARBUSINESS',
  Foodweb = 'FOODWEB',
  Ifn = 'IFN',
  CmAttuali = 'CM_ATTUALI',
  Goods4Hub = 'GOODS4HUB',
  Food = 'FOOD',
  Foodservice = 'FOODSERVICE',
  Dolcesalato = 'DOLCESALATO'
}

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type EditorialSection = Node & {
  __typename?: 'EditorialSection';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  editorialBrand: EditorialBrand;
  /** {"required":true} */
  name: Scalars['String'];
  /** {} */
  slug?: Maybe<Scalars['String']>;
  /** {} */
  Parent?: Maybe<EditorialSection>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  level: Scalars['Int'];
  /** {} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<Media>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  featuredMedia?: Maybe<Media>;
  /** {} */
  goodClasses: Array<GoodClass>;
};

/** A connection to a list of items. */
export type EditorialSectionConnection = {
  __typename?: 'EditorialSectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EditorialSectionEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type EditorialSectionCreateInput = {
  /** {"readonly":true} */
  editorialBrand: EditorialBrand;
  /** {"required":true} */
  name: Scalars['String'];
  /** {} */
  Parent?: Maybe<RefInputObject>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  level: Scalars['Int'];
  /** {} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  featuredMedia?: Maybe<RefInputObject>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type EditorialSectionEdge = {
  __typename?: 'EditorialSectionEdge';
  /** The item at the end of the edge */
  node?: Maybe<EditorialSection>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type EditorialSectionFilterInput = {
  /** Contained in name or akas */
  name?: Maybe<Scalars['String']>;
  EditorialBrand?: Maybe<EditorialBrand>;
};

export enum EditorialSectionOrderBy {
  Name = 'name',
  EditorialBrand = 'editorialBrand'
}

export type EditorialSectionPatchInput = {
  /** {"required":true} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  Parent?: Maybe<RefInputObject>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  level?: Maybe<Scalars['Int']>;
  /** {} */
  styleClass?: Maybe<Scalars['String']>;
  /** {"max":45} */
  color?: Maybe<Scalars['String']>;
  /** {"mediaType":"ICON"} */
  iconMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  featuredMedia?: Maybe<RefInputObject>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type EditorialTag = Node & {
  __typename?: 'EditorialTag';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  slug: Scalars['String'];
};

/** A connection to a list of items. */
export type EditorialTagConnection = {
  __typename?: 'EditorialTagConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EditorialTagEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type EditorialTagCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  slug: Scalars['String'];
};

/** An edge in a connection. */
export type EditorialTagEdge = {
  __typename?: 'EditorialTagEdge';
  /** The item at the end of the edge */
  node?: Maybe<EditorialTag>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type EditorialTagFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
};

export type EditorialTagPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  slug?: Maybe<Scalars['String']>;
};


/** {"canCreateRoles":{"INTERNAL":"USERS_BASE"},"canPatchRoles":{"INTERNAL":"USERS_BASE"},"canDeleteRoles":{"INTERNAL":"USERS_BASE"}} */
export type EmailDomain = Node & {
  __typename?: 'EmailDomain';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain: Scalars['String'];
  /** {} */
  accountType?: Maybe<AccountType>;
  /** {} */
  action?: Maybe<EmailDomainAction>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  Service?: Maybe<Service>;
  /** {} */
  FoodService?: Maybe<FoodService>;
  /** {} */
  Team?: Maybe<Team>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":15,"min":15} */
  accountSfid?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
};

/** Automatic internal action performed */
export enum EmailDomainAction {
  WaitAndNotify = 'WAIT_AND_NOTIFY',
  Accept = 'ACCEPT',
  ConsumerOnly = 'CONSUMER_ONLY',
  ShopworkerOnly = 'SHOPWORKER_ONLY'
}

/** A connection to a list of items. */
export type EmailDomainConnection = {
  __typename?: 'EmailDomainConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailDomainEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type EmailDomainCreateInput = {
  /** {"required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain: Scalars['String'];
  /** {} */
  accountType?: Maybe<AccountType>;
  /** {} */
  action?: Maybe<EmailDomainAction>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
  /** {} */
  Team?: Maybe<RefInputObject>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":15,"min":15} */
  accountSfid?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type EmailDomainEdge = {
  __typename?: 'EmailDomainEdge';
  /** The item at the end of the edge */
  node?: Maybe<EmailDomain>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type EmailDomainFilterInput = {
  /** Contained in emailDomain */
  emailDomain?: Maybe<Scalars['String']>;
  accountTypes?: Maybe<Array<AccountType>>;
  actions?: Maybe<Array<EmailDomainAction>>;
};

export enum EmailDomainOrderBy {
  EmailDomain = 'emailDomain',
  AccountType = 'accountType',
  Action = 'action'
}

export type EmailDomainPatchInput = {
  /** {"required":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  emailDomain?: Maybe<Scalars['String']>;
  /** {} */
  accountType?: Maybe<AccountType>;
  /** {} */
  action?: Maybe<EmailDomainAction>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
  /** {} */
  Team?: Maybe<RefInputObject>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {"max":15,"min":15} */
  accountSfid?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type EuCategory = Node & {
  __typename?: 'EuCategory';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  HtmlTemplate: HtmlTemplate;
};

/** A connection to a list of items. */
export type EuCategoryConnection = {
  __typename?: 'EuCategoryConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EuCategoryEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type EuCategoryCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  HtmlTemplate: RefInputObject;
};

/** An edge in a connection. */
export type EuCategoryEdge = {
  __typename?: 'EuCategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<EuCategory>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type EuCategoryPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>;
};

/** EU Indications */
export enum EuIndication {
  Pdo = 'PDO',
  Pgi = 'PGI',
  Tsg = 'TSG'
}

/** Possible exclusion reasons */
export enum ExclusionReason {
  Other = 'OTHER',
  Price = 'PRICE',
  Packaging = 'PACKAGING',
  Logistic = 'LOGISTIC',
  Certifications = 'CERTIFICATIONS',
  ProductiveCapability = 'PRODUCTIVE_CAPABILITY'
}

export type FacetValueCount = {
  __typename?: 'FacetValueCount';
  value?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Fair = Node & {
  __typename?: 'Fair';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  slug: Scalars['String'];
  /** {} */
  name: Scalars['String'];
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year: Scalars['Int'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  begin: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  end: Scalars['DateTime'];
  /** {} */
  targetSites: Array<TargetSite>;
  /** {"max":200} */
  newsPath?: Maybe<Scalars['String']>;
  /** {} */
  goodClasses: Array<GoodClass>;
  /** {} */
  editorialSections: Array<EditorialSection>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>;
  /** {} */
  ourNote?: Maybe<Scalars['String']>;
  /** {} */
  participations?: Maybe<FairParticipationConnection>;
  /** {} */
  visits?: Maybe<Array<FairVisit>>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
};


/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type FairParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<FairParticipationsFilterInput>;
};

/** A connection to a list of items. */
export type FairConnection = {
  __typename?: 'FairConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FairCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year: Scalars['Int'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  begin: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  end: Scalars['DateTime'];
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {"max":200} */
  newsPath?: Maybe<Scalars['String']>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>;
  /** {} */
  ourNote?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type FairEdge = {
  __typename?: 'FairEdge';
  /** The item at the end of the edge */
  node?: Maybe<Fair>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type FairFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  hasMyVisit?: Maybe<Scalars['Boolean']>;
  isConcluded?: Maybe<Scalars['Boolean']>;
  timespanMin?: Maybe<Scalars['DateTime']>;
  timespanMax?: Maybe<Scalars['DateTime']>;
  Country?: Maybe<Array<RefInputObject>>;
  minParticipants?: Maybe<Scalars['Int']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE","PRODUCER":"EDIT"}} */
export type FairParticipation = Node & {
  __typename?: 'FairParticipation';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  Fair: Fair;
  /** {} */
  Company: Company;
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
  /** {} */
  visits: Array<FairVisit>;
};

/** A connection to a list of items. */
export type FairParticipationConnection = {
  __typename?: 'FairParticipationConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairParticipationEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FairParticipationCreateInput = {
  /** {} */
  Fair: RefInputObject;
  /** {} */
  Company: RefInputObject;
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type FairParticipationEdge = {
  __typename?: 'FairParticipationEdge';
  /** The item at the end of the edge */
  node?: Maybe<FairParticipation>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type FairParticipationPatchInput = {
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {"max":10,"forceCase":"LOWER"} */
  pavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  stand?: Maybe<Scalars['String']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
};

export type FairParticipationsFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
};

export type FairPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  edition?: Maybe<Scalars['String']>;
  /** {"max":2100,"min":2000} */
  year?: Maybe<Scalars['Int']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  begin?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  end?: Maybe<Scalars['DateTime']>;
  /** {} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {"max":200} */
  newsPath?: Maybe<Scalars['String']>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourPavilion?: Maybe<Scalars['String']>;
  /** {"max":10,"forceCase":"LOWER"} */
  ourStand?: Maybe<Scalars['String']>;
  /** {} */
  ourNote?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true}} */
export type FairVisit = Node & {
  __typename?: 'FairVisit';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  Fair: Fair;
  /** {} */
  fairParticipations: Array<FairParticipation>;
};

/** A connection to a list of items. */
export type FairVisitConnection = {
  __typename?: 'FairVisitConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FairVisitEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FairVisitCreateInput = {
  /** {} */
  Fair: RefInputObject;
  /** {} */
  fairParticipations?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type FairVisitEdge = {
  __typename?: 'FairVisitEdge';
  /** The item at the end of the edge */
  node?: Maybe<FairVisit>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type FairVisitFilterInput = {
  User?: Maybe<RefInputObject>;
  Fair?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"ADMIN_BASE","BUYER":true,"BUYER_PL_ITALIA":true}} */
export type FairVisitParticipation = Node & {
  __typename?: 'FairVisitParticipation';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  FairVisit: FairVisit;
  /** {} */
  FairParticipation?: Maybe<FairParticipation>;
  /** {} */
  done: Scalars['Boolean'];
};

export type FairVisitParticipationCreateInput = {
  /** {} */
  FairParticipation?: Maybe<RefInputObject>;
  /** {} */
  done: Scalars['Boolean'];
};

export type FairVisitParticipationPatchInput = {
  /** {} */
  FairParticipation?: Maybe<RefInputObject>;
  /** {} */
  done?: Maybe<Scalars['Boolean']>;
};

export type FairVisitPatchInput = {
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  fairParticipations?: Maybe<Array<RefInputObject>>;
};

/** Possible final positions for award candidacies */
export enum FinalPosition {
  Winner = 'WINNER',
  Second = 'SECOND',
  Third = 'THIRD',
  Finalist = 'FINALIST'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","FOODSERVICE":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type FoodService = Node & {
  __typename?: 'FoodService';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"readonly":true} */
  dimension: FoodServiceDimensionType;
  /** {"readonly":true} */
  type: FoodServiceType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /**
   * {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
   * @deprecated Resterà solo su SF
   */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};

/** A connection to a list of items. */
export type FoodServiceConnection = {
  __typename?: 'FoodServiceConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FoodServiceEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FoodServiceCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  dimension: FoodServiceDimensionType;
  /** {"readonly":true} */
  type: FoodServiceType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** FoodService possible types */
export enum FoodServiceDimensionType {
  FoodService = 'FOOD_SERVICE',
  PuntoVendita = 'PUNTO_VENDITA'
}

/** An edge in a connection. */
export type FoodServiceEdge = {
  __typename?: 'FoodServiceEdge';
  /** The item at the end of the edge */
  node?: Maybe<FoodService>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type FoodServiceFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<Array<FoodServiceDimensionType>>;
  types?: Maybe<Array<FoodServiceType>>;
  Country?: Maybe<Array<RefInputObject>>;
};

export enum FoodServiceOrderBy {
  Name = 'name',
  Type = 'type'
}

export type FoodServicePatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** FoodService possible types */
export enum FoodServiceType {
  Agriturismo = 'AGRITURISMO',
  Asporto = 'ASPORTO',
  BB = 'B_B',
  BarTradizionale = 'BAR_TRADIZIONALE',
  Camping = 'CAMPING',
  Catering = 'CATERING',
  CinemaMultisala = 'CINEMA_MULTISALA',
  Discoteca = 'DISCOTECA',
  Enoteche = 'ENOTECHE',
  Farmacia = 'FARMACIA',
  FastFood = 'FAST_FOOD',
  FoodTruck = 'FOOD_TRUCK',
  Franchiser = 'FRANCHISER',
  Gelateria = 'GELATERIA',
  Hotel = 'HOTEL',
  ImpiantiSportivi = 'IMPIANTI_SPORTIVI',
  Latteria = 'LATTERIA',
  Libreria = 'LIBRERIA',
  LocalePolifunz = 'LOCALE_POLIFUNZ',
  Macelleria = 'MACELLERIA',
  Mense = 'MENSE',
  OstelliHotel_2Stelle = 'OSTELLI_HOTEL_2_STELLE',
  Panetteria = 'PANETTERIA',
  ParchiDiDivertimento = 'PARCHI_DI_DIVERTIMENTO',
  Pasticceria = 'PASTICCERIA',
  Pescheria = 'PESCHERIA',
  Polleria = 'POLLERIA',
  PubBirreria = 'PUB_BIRRERIA',
  ResortVillaggioTuristico = 'RESORT_VILLAGGIO_TURISTICO',
  RifugiDiMontagna = 'RIFUGI_DI_MONTAGNA',
  RistPizzeria = 'RIST_PIZZERIA',
  RistEtnico = 'RIST_ETNICO',
  RistPremium = 'RIST_PREMIUM',
  RistorazioneCollettiva = 'RISTORAZIONE_COLLETTIVA',
  RistorazioneCommerciale = 'RISTORAZIONE_COMMERCIALE',
  RistorazioneSuMezzi = 'RISTORAZIONE_SU_MEZZI',
  SaleScommesse = 'SALE_SCOMMESSE',
  Salumeria = 'SALUMERIA',
  StabilimentoBalneare = 'STABILIMENTO_BALNEARE',
  StazioneDiServizio = 'STAZIONE_DI_SERVIZIO',
  Tabaccheria = 'TABACCHERIA',
  TeatroSalaDaConcerto = 'TEATRO_SALA_DA_CONCERTO',
  Torrefazione = 'TORREFAZIONE',
  TrattoriaOsteria = 'TRATTORIA_OSTERIA',
  RetailBio = 'RETAIL_BIO',
  RetailCC = 'RETAIL_C_C',
  RetailDiscount = 'RETAIL_DISCOUNT',
  RetailIper = 'RETAIL_IPER',
  RetailLs = 'RETAIL_LS',
  RetailProssimit = 'RETAIL_PROSSIMIT',
  RetailSuper = 'RETAIL_SUPER'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"canSetStatusRoles":{"status":{"DRAFT":{"INTERNAL":"DIRECTORY_BASE"},"PUBLISHED":{"INTERNAL":"DIRECTORY_BASE","BUYER":true,"BUYER_PL_ITALIA":true},"HIGHLIGHTED":{"INTERNAL":"EDITORIAL_EXTRA"},"DELETED":{"INTERNAL":"DIRECTORY_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type FrequentSearch = Node & {
  __typename?: 'FrequentSearch';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  collection: FrequentSearchCollection;
  /** {"required":true,"min":1} */
  targetSites: Array<TargetSite>;
  /** {"required":true,"max":150,"min":3} */
  name: Scalars['String'];
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>;
  /** {} */
  linkRoles: Array<AccessRole>;
  /** {} */
  status: FrequentSearchStatus;
  /** {} */
  relatedFair?: Maybe<Fair>;
  /** {} */
  isDynamic: Scalars['Boolean'];
  /** {} */
  systemType?: Maybe<FrequentSearchSystemType>;
  /** {} */
  User?: Maybe<User>;
  /** {} */
  Team?: Maybe<Team>;
  /** {} */
  goodClasses: Array<GoodClass>;
  /** {} */
  goodFeatureCertifications: Array<GoodFeatureCertification>;
  /** {} */
  geoIndications: Array<GeoIndication>;
  /** {} */
  companyCertifications: Array<CompanyCertification>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {} */
  fairs: Array<Fair>;
  /** {} */
  awards: Array<Award>;
  /** {} */
  awardFinalPositions: Array<FinalPosition>;
  /** {} */
  preservations: Array<Preservation>;
  /** {} */
  trasformationLevels: Array<TransformationLevel>;
  /** {} */
  stateProvinces: Array<StateProvince>;
  /** {} */
  textSearch?: Maybe<Scalars['String']>;
  /** {} */
  reveneueRanges: Array<CompanyRevenueRange>;
  /** {} */
  pricePositionings: Array<PricePositioning>;
  /** {} */
  highlightReasons: Array<HighlightReasons>;
  /** {} */
  companies: Array<Company>;
  /** {} */
  goods: Array<Good>;
  /** {} */
  retailers: Array<Retailer>;
  /** {} */
  companyGroups: Array<CompanyGroup>;
  /** {} */
  services: Array<Service>;
  /** {} */
  foodServices: Array<FoodService>;
};

/** FrequentSearch's possible collections */
export enum FrequentSearchCollection {
  Good = 'GOOD',
  Company = 'COMPANY',
  GeoIndication = 'GEO_INDICATION',
  News = 'NEWS'
}

/** A connection to a list of items. */
export type FrequentSearchConnection = {
  __typename?: 'FrequentSearchConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FrequentSearchEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FrequentSearchCreateInput = {
  /** {"readonly":true} */
  collection: FrequentSearchCollection;
  /** {"required":true,"min":1} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {"required":true,"max":150,"min":3} */
  name: Scalars['String'];
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>;
  /** {} */
  linkRoles?: Maybe<Array<AccessRole>>;
  /** {} */
  relatedFair?: Maybe<RefInputObject>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>;
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {} */
  fairs?: Maybe<Array<RefInputObject>>;
  /** {} */
  awards?: Maybe<Array<RefInputObject>>;
  /** {} */
  awardFinalPositions?: Maybe<Array<FinalPosition>>;
  /** {} */
  preservations?: Maybe<Array<Preservation>>;
  /** {} */
  trasformationLevels?: Maybe<Array<TransformationLevel>>;
  /** {} */
  stateProvinces?: Maybe<Array<RefInputObject>>;
  /** {} */
  textSearch?: Maybe<Scalars['String']>;
  /** {} */
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>;
  /** {} */
  pricePositionings?: Maybe<Array<PricePositioning>>;
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>;
  /** {} */
  companies?: Maybe<Array<RefInputObject>>;
  /** {} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {} */
  retailers?: Maybe<Array<RefInputObject>>;
  /** {} */
  companyGroups?: Maybe<Array<RefInputObject>>;
  /** {} */
  services?: Maybe<Array<RefInputObject>>;
  /** {} */
  foodServices?: Maybe<Array<RefInputObject>>;
  FrequentSearch?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type FrequentSearchEdge = {
  __typename?: 'FrequentSearchEdge';
  /** The item at the end of the edge */
  node?: Maybe<FrequentSearch>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type FrequentSearchFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  collection?: Maybe<FrequentSearchCollection>;
  targetSite?: Maybe<TargetSite>;
  /** TRUE - solo currentUser, FALSE - solo !currentUser */
  mine?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<FrequentSearchStatus>>;
  /** TRUE - solo isDynamic, FALSE - solo !isDynamic */
  isDynamic?: Maybe<Scalars['Boolean']>;
  systemTypes?: Maybe<Array<FrequentSearchSystemType>>;
  noUser?: Maybe<Scalars['Boolean']>;
  User?: Maybe<RefInputObject>;
  Team?: Maybe<RefInputObject>;
  relatedFair?: Maybe<RefInputObject>;
  /** !isDynamic and contains passed item */
  containsItem?: Maybe<RefInputObject>;
};

export type FrequentSearchPatchInput = {
  /** {"required":true,"min":1} */
  targetSites?: Maybe<Array<TargetSite>>;
  /** {"required":true,"max":150,"min":3} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {} */
  linkUrl?: Maybe<Scalars['URL']>;
  /** {} */
  linkRoles?: Maybe<Array<AccessRole>>;
  /** {} */
  relatedFair?: Maybe<RefInputObject>;
  /** {} */
  goodClasses?: Maybe<Array<RefInputObject>>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  geoIndications?: Maybe<Array<RefInputObject>>;
  /** {} */
  companyCertifications?: Maybe<Array<RefInputObject>>;
  /** {} */
  plProducer?: Maybe<Scalars['Boolean']>;
  /** {} */
  fairs?: Maybe<Array<RefInputObject>>;
  /** {} */
  awards?: Maybe<Array<RefInputObject>>;
  /** {} */
  awardFinalPositions?: Maybe<Array<FinalPosition>>;
  /** {} */
  preservations?: Maybe<Array<Preservation>>;
  /** {} */
  trasformationLevels?: Maybe<Array<TransformationLevel>>;
  /** {} */
  stateProvinces?: Maybe<Array<RefInputObject>>;
  /** {} */
  textSearch?: Maybe<Scalars['String']>;
  /** {} */
  reveneueRanges?: Maybe<Array<CompanyRevenueRange>>;
  /** {} */
  pricePositionings?: Maybe<Array<PricePositioning>>;
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>;
  /** {} */
  companies?: Maybe<Array<RefInputObject>>;
  /** {} */
  goods?: Maybe<Array<RefInputObject>>;
  /** {} */
  retailers?: Maybe<Array<RefInputObject>>;
  /** {} */
  companyGroups?: Maybe<Array<RefInputObject>>;
  /** {} */
  services?: Maybe<Array<RefInputObject>>;
  /** {} */
  foodServices?: Maybe<Array<RefInputObject>>;
};

/** FrequentSearch's possible statues */
export enum FrequentSearchStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Highlighted = 'HIGHLIGHTED',
  Deleted = 'DELETED'
}

/** If FrequentSearch is system-defined this is the type */
export enum FrequentSearchSystemType {
  Favorites = 'FAVORITES'
}

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type GeoIndication = Node & {
  __typename?: 'GeoIndication';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {} */
  name: Scalars['String'];
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {} */
  status: NewsStatus;
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {"readonly":true} */
  euIndication?: Maybe<EuIndication>;
  /** {"readonly":true} */
  italianIndication?: Maybe<ItalianIndication>;
  /** {} */
  descriptionValues?: Maybe<Scalars['String']>;
  /** {} */
  description?: Maybe<Scalars['String']>;
  /** {} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  socialInstagramPage?: Maybe<Scalars['URL']>;
  /** {} */
  socialFacebookPage?: Maybe<Scalars['URL']>;
  /** {"readonly":true} */
  EuCategory: EuCategory;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  goods: Array<Good>;
  /** {} */
  companies: Array<Company>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
};

/** A connection to a list of items. */
export type GeoIndicationConnection = {
  __typename?: 'GeoIndicationConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GeoIndicationEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GeoIndicationCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  name: Scalars['String'];
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {"readonly":true} */
  euIndication?: Maybe<EuIndication>;
  /** {"readonly":true} */
  italianIndication?: Maybe<ItalianIndication>;
  /** {} */
  descriptionValues?: Maybe<Scalars['String']>;
  /** {} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  socialInstagramPage?: Maybe<Scalars['URL']>;
  /** {} */
  socialFacebookPage?: Maybe<Scalars['URL']>;
  /** {"readonly":true} */
  EuCategory: RefInputObject;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  companies?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type GeoIndicationEdge = {
  __typename?: 'GeoIndicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<GeoIndication>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type GeoIndicationFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  euIndications?: Maybe<Array<EuIndication>>;
  italianIndications?: Maybe<Array<ItalianIndication>>;
  Company?: Maybe<RefInputObject>;
  Country?: Maybe<RefInputObject>;
  StateProvince?: Maybe<RefInputObject>;
  statuses?: Maybe<Array<NewsStatus>>;
};

export enum GeoIndicationOrderBy {
  Name = 'name',
  StateProvince = 'StateProvince',
  EuIndication = 'euIndication',
  ItalianIndication = 'italianIndication',
  Status = 'status'
}

export type GeoIndicationPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {} */
  descriptionValues?: Maybe<Scalars['String']>;
  /** {} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['URL']>;
  /** {} */
  socialInstagramPage?: Maybe<Scalars['URL']>;
  /** {} */
  socialFacebookPage?: Maybe<Scalars['URL']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  companies?: Maybe<Array<RefInputObject>>;
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type Good = Node & {
  __typename?: 'Good';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"required":true} */
  Brand: Brand;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<Media>;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<Media>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageMedia?: Maybe<Media>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageItaMedia?: Maybe<Media>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesMedia: Array<Media>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesItaMedia: Array<Media>;
  /** {} */
  GeoIndication?: Maybe<GeoIndication>;
  /** {"required":true} */
  GoodClass?: Maybe<GoodClass>;
  /** {} */
  goodFeatureCertifications: Array<GoodFeatureCertification>;
  /** {"max":50,"min":3} */
  range?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {} */
  slugIta: Scalars['String'];
  /** {"required":true,"max":150,"min":3} */
  name: Scalars['String'];
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":36500,"min":0} */
  shelfLifeLength: Scalars['Int'];
  /** {} */
  extraMedia?: Maybe<Media>;
  /** {} */
  extraItaMedia?: Maybe<Media>;
  /** {} */
  formats: Array<GoodFormat>;
  /** {} */
  productionBranches: Array<Branch>;
  /** {} */
  highlightedGoods: Array<HighlightedGood>;
  /** {} */
  candidacies: Array<Candidacy>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type GoodClass = Node & {
  __typename?: 'GoodClass';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  nameIta: Scalars['String'];
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {} */
  level1Code: Scalars['String'];
  /** {} */
  level1Desc: Scalars['String'];
  /** {} */
  level1DescIta: Scalars['String'];
  /** {} */
  level2Code?: Maybe<Scalars['String']>;
  /** {} */
  level2Desc?: Maybe<Scalars['String']>;
  /** {} */
  level2DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level3Code?: Maybe<Scalars['String']>;
  /** {} */
  level3Desc?: Maybe<Scalars['String']>;
  /** {} */
  level3DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level4Code?: Maybe<Scalars['String']>;
  /** {} */
  level4Desc?: Maybe<Scalars['String']>;
  /** {} */
  level4DescIta?: Maybe<Scalars['String']>;
  /** {} */
  shortname: Scalars['String'];
  /** {} */
  shortnameIta: Scalars['String'];
  /** {} */
  preservation?: Maybe<Preservation>;
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>;
  /** {} */
  editorialSections: Array<EditorialSection>;
};

/** A connection to a list of items. */
export type GoodClassConnection = {
  __typename?: 'GoodClassConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodClassEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GoodClassCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  nameIta: Scalars['String'];
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {} */
  level1Code: Scalars['String'];
  /** {} */
  level1Desc: Scalars['String'];
  /** {} */
  level1DescIta: Scalars['String'];
  /** {} */
  level2Code?: Maybe<Scalars['String']>;
  /** {} */
  level2Desc?: Maybe<Scalars['String']>;
  /** {} */
  level2DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level3Code?: Maybe<Scalars['String']>;
  /** {} */
  level3Desc?: Maybe<Scalars['String']>;
  /** {} */
  level3DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level4Code?: Maybe<Scalars['String']>;
  /** {} */
  level4Desc?: Maybe<Scalars['String']>;
  /** {} */
  level4DescIta?: Maybe<Scalars['String']>;
  /** {} */
  shortname: Scalars['String'];
  /** {} */
  shortnameIta: Scalars['String'];
  /** {} */
  preservation?: Maybe<Preservation>;
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>;
};

/** An edge in a connection. */
export type GoodClassEdge = {
  __typename?: 'GoodClassEdge';
  /** The item at the end of the edge */
  node?: Maybe<GoodClass>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GoodClassPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  nameIta?: Maybe<Scalars['String']>;
  /** {} */
  akas?: Maybe<Scalars['String']>;
  /** {} */
  level1Code?: Maybe<Scalars['String']>;
  /** {} */
  level1Desc?: Maybe<Scalars['String']>;
  /** {} */
  level1DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level2Code?: Maybe<Scalars['String']>;
  /** {} */
  level2Desc?: Maybe<Scalars['String']>;
  /** {} */
  level2DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level3Code?: Maybe<Scalars['String']>;
  /** {} */
  level3Desc?: Maybe<Scalars['String']>;
  /** {} */
  level3DescIta?: Maybe<Scalars['String']>;
  /** {} */
  level4Code?: Maybe<Scalars['String']>;
  /** {} */
  level4Desc?: Maybe<Scalars['String']>;
  /** {} */
  level4DescIta?: Maybe<Scalars['String']>;
  /** {} */
  shortname?: Maybe<Scalars['String']>;
  /** {} */
  shortnameIta?: Maybe<Scalars['String']>;
  /** {} */
  preservation?: Maybe<Preservation>;
  /** {} */
  trasformationLevel?: Maybe<TransformationLevel>;
};

/** A connection to a list of items. */
export type GoodConnection = {
  __typename?: 'GoodConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GoodCreateInput = {
  /** {"required":true} */
  Brand: RefInputObject;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageItaMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesItaMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {"required":true} */
  GoodClass?: Maybe<RefInputObject>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {"max":50,"min":3} */
  range?: Maybe<Scalars['String']>;
  /** {"required":true,"max":150,"min":3} */
  name: Scalars['String'];
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":36500,"min":0} */
  shelfLifeLength: Scalars['Int'];
  /** {} */
  extraMedia?: Maybe<RefInputObject>;
  /** {} */
  extraItaMedia?: Maybe<RefInputObject>;
  /** {} */
  productionBranches?: Maybe<Array<RefInputObject>>;
  /** {} */
  candidacies?: Maybe<Array<RefInputObject>>;
};

/** An edge in a connection. */
export type GoodEdge = {
  __typename?: 'GoodEdge';
  /** The item at the end of the edge */
  node?: Maybe<Good>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type GoodFeatureCertification = Node & {
  __typename?: 'GoodFeatureCertification';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  nameIta: Scalars['String'];
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type GoodFeatureCertificationConnection = {
  __typename?: 'GoodFeatureCertificationConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodFeatureCertificationEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GoodFeatureCertificationCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  nameIta: Scalars['String'];
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type GoodFeatureCertificationEdge = {
  __typename?: 'GoodFeatureCertificationEdge';
  /** The item at the end of the edge */
  node?: Maybe<GoodFeatureCertification>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GoodFeatureCertificationPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  nameIta?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  akas?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
};

/** @TODO DESCRIBE ME */
export type GoodFilterInput = {
  /** Contained in name or nameIta */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  Brand?: Maybe<RefInputObject>;
  Company?: Maybe<RefInputObject>;
  eanPresent?: Maybe<Scalars['Boolean']>;
  ingredientsMediaPresent?: Maybe<Scalars['Boolean']>;
  ingredientsItaMediaPresent?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"}} */
export type GoodFormat = Node & {
  __typename?: 'GoodFormat';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  Good: Good;
  /** {} */
  cuMesureUnit: GoodUnit;
  /** {"max":1000000,"min":1} */
  cuMesureValue: Scalars['Int'];
  /** {"max":48,"min":1} */
  cuPerSellingPack: Scalars['Int'];
  /** {"max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>;
  /**
   * {"max":45,"forceCase":"LOWER"}
   * @deprecated Migliorata logica identificazione Good/GoodFormat
   */
  flavor?: Maybe<Scalars['String']>;
  /**
   * {"max":45,"forceCase":"LOWER"}
   * @deprecated Migliorata logica identificazione Good/GoodFormat
   */
  flavorIta?: Maybe<Scalars['String']>;
};

export type GoodFormatCreateInput = {
  /** {} */
  Good: RefInputObject;
  /** {} */
  cuMesureUnit: GoodUnit;
  /** {"max":1000000,"min":1} */
  cuMesureValue: Scalars['Int'];
  /** {"max":48,"min":1} */
  cuPerSellingPack: Scalars['Int'];
  /** {"max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>;
};

export type GoodFormatPatchInput = {
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  cuMesureUnit?: Maybe<GoodUnit>;
  /** {"max":1000000,"min":1} */
  cuMesureValue?: Maybe<Scalars['Int']>;
  /** {"max":48,"min":1} */
  cuPerSellingPack?: Maybe<Scalars['Int']>;
  /** {"max":15,"min":13,"formatRegexStr":"^[0-9]*$"} */
  ean?: Maybe<Scalars['String']>;
};

export enum GoodOrderBy {
  Name = 'name',
  NameIta = 'nameIta'
}

export type GoodPatchInput = {
  /** {"required":true} */
  Brand?: Maybe<RefInputObject>;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"required":true,"mediaType":"GOOD_IMAGE"} */
  featuredImageItaMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  ingredientsImageItaMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesMedia?: Maybe<Array<RefInputObject>>;
  /** {"mediaType":"GOOD_IMAGE"} */
  additionalImagesItaMedia?: Maybe<Array<RefInputObject>>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {"required":true} */
  GoodClass?: Maybe<RefInputObject>;
  /** {} */
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  /** {"max":50,"min":3} */
  range?: Maybe<Scalars['String']>;
  /** {"required":true,"max":150,"min":3} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"max":150,"min":3} */
  nameIta?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":200} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":500,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"max":36500,"min":0} */
  shelfLifeLength?: Maybe<Scalars['Int']>;
  /** {} */
  extraMedia?: Maybe<RefInputObject>;
  /** {} */
  extraItaMedia?: Maybe<RefInputObject>;
  /** {} */
  productionBranches?: Maybe<Array<RefInputObject>>;
  /** {} */
  candidacies?: Maybe<Array<RefInputObject>>;
};

export type GoodsDir = Good;

/** A connection to a list of items. */
export type GoodsDirConnection = {
  __typename?: 'goodsDirConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoodsDirEdge>>>;
  total?: Maybe<Scalars['Int']>;
  facet?: Maybe<GoodsDirFacets>;
  relatedFrequentSearches?: Maybe<Array<Maybe<FrequentSearch>>>;
};

/** An edge in a connection. */
export type GoodsDirEdge = {
  __typename?: 'goodsDirEdge';
  /** The item at the end of the edge */
  node?: Maybe<GoodsDir>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  score: Scalars['Float'];
};

export type GoodsDirFacets = {
  __typename?: 'goodsDirFacets';
  goodClass?: Maybe<Array<FacetValueCount>>;
  editorialSections?: Maybe<Array<FacetValueCount>>;
  preservation?: Maybe<Array<FacetValueCount>>;
  trasformationLevel?: Maybe<Array<FacetValueCount>>;
  goodFeatureCertifications?: Maybe<Array<FacetValueCount>>;
  pricePositioning?: Maybe<Array<FacetValueCount>>;
  awardFinalPositions?: Maybe<Array<FacetValueCount>>;
  highlightReasons?: Maybe<Array<FacetValueCount>>;
  stateProvinces?: Maybe<Array<FacetValueCount>>;
  companyCertifications?: Maybe<Array<FacetValueCount>>;
};

/** @TODO DESCRIBE ME */
export type GoodsFilterType = {
  targetSite?: Maybe<TargetSite>;
  items?: Maybe<Array<RefInputObject>>;
  FrequentSearch?: Maybe<RefInputObject>;
  textSearch?: Maybe<Scalars['String']>;
  companies?: Maybe<Array<RefInputObject>>;
  brands?: Maybe<Array<RefInputObject>>;
  goodClasses?: Maybe<Array<RefInputObject>>;
  editorialSections?: Maybe<Array<RefInputObject>>;
  preservations?: Maybe<Array<Preservation>>;
  trasformationLevels?: Maybe<Array<TransformationLevel>>;
  goodFeatureCertifications?: Maybe<Array<RefInputObject>>;
  pricePositionings?: Maybe<Array<PricePositioning>>;
  highlightReasons?: Maybe<Array<HighlightReasons>>;
  geoIndications?: Maybe<Array<RefInputObject>>;
  awards?: Maybe<Array<RefInputObject>>;
  awardFinalPositions?: Maybe<Array<FinalPosition>>;
  fairs?: Maybe<Array<RefInputObject>>;
  stateProvinces?: Maybe<Array<RefInputObject>>;
  plProducer?: Maybe<Scalars['Boolean']>;
  companyCertifications?: Maybe<Array<RefInputObject>>;
};

export enum GoodUnit {
  Grams = 'GRAMS',
  Milliliter = 'MILLILITER',
  Milligrams = 'MILLIGRAMS'
}

/** // TODO */
export enum GridSize {
  Col_1 = 'COL_1',
  Col_2 = 'COL_2',
  Col_3 = 'COL_3'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"ACCOUNT"}} */
export type HighlightedGood = Node & {
  __typename?: 'HighlightedGood';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  Good: Good;
  /** {"readonly":true} */
  targetSite: TargetSite;
  /** {} */
  highlightReasons: Array<HighlightReasons>;
  /** {"max":50} */
  claim1?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim2?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim3?: Maybe<Scalars['String']>;
};

export type HighlightedGoodCreateInput = {
  /** {"readonly":true} */
  Good: RefInputObject;
  /** {"readonly":true} */
  targetSite: TargetSite;
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>;
  /** {"max":50} */
  claim1?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim2?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim3?: Maybe<Scalars['String']>;
};

export type HighlightedGoodPatchInput = {
  /** {} */
  highlightReasons?: Maybe<Array<HighlightReasons>>;
  /** {"max":50} */
  claim1?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim2?: Maybe<Scalars['String']>;
  /** {"max":50} */
  claim3?: Maybe<Scalars['String']>;
};

/** Possible Good hightlight reasons */
export enum HighlightReasons {
  NewProduct = 'NEW_PRODUCT',
  RenovatedRecipe = 'RENOVATED_RECIPE',
  RenovatedPackaging = 'RENOVATED_PACKAGING',
  SpecialEdition = 'SPECIAL_EDITION',
  NewSizes = 'NEW_SIZES'
}

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type HtmlTemplate = Node & {
  __typename?: 'HtmlTemplate';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  form: Scalars['String'];
  /** {"multiline":true} */
  defaultValues?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  validator?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplate: Scalars['String'];
  /** {"multiline":true} */
  renderTemplateIta: Scalars['String'];
  /** {"multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type HtmlTemplateConnection = {
  __typename?: 'HtmlTemplateConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HtmlTemplateEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HtmlTemplateCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  form: Scalars['String'];
  /** {"multiline":true} */
  defaultValues?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  validator?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplate: Scalars['String'];
  /** {"multiline":true} */
  renderTemplateIta: Scalars['String'];
  /** {"multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type HtmlTemplateEdge = {
  __typename?: 'HtmlTemplateEdge';
  /** The item at the end of the edge */
  node?: Maybe<HtmlTemplate>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type HtmlTemplatePatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  form?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  defaultValues?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  validator?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplate?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplateIta?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplateDem?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  renderTemplateDemIta?: Maybe<Scalars['String']>;
};

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type Indirizzo = Node & {
  __typename?: 'Indirizzo';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"required":true} */
  IsDeleted: Scalars['Boolean'];
  /** {"required":false,"max":80} */
  Name?: Maybe<Scalars['String']>;
  /** {"max":255} */
  Via?: Maybe<Scalars['String']>;
  /** {"max":40} */
  Citt?: Maybe<Scalars['String']>;
  /** {"max":3} */
  Provincia?: Maybe<Scalars['String']>;
  /** {"max":12} */
  CAP?: Maybe<Scalars['String']>;
  /** {"max":80} */
  Paese?: Maybe<Scalars['String']>;
  /** {"max":100} */
  Note?: Maybe<Scalars['String']>;
  /** {} */
  Tipo?: Maybe<Indirizzo__C_Tipo__C_Enum>;
  /** {"max":20} */
  Regione?: Maybe<Scalars['String']>;
  /** {} */
  Abbonamenti: Array<Abbonamento>;
  /** {} */
  accountCompany?: Maybe<Company>;
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  accountService?: Maybe<Service>;
  /** {} */
  accountRetailer?: Maybe<Retailer>;
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>;
  /** {} */
  accountFoodService?: Maybe<FoodService>;
  /** {} */
  referenteUser?: Maybe<User>;
};

/** SF picklist */
export enum Indirizzo__C_Tipo__C_Enum {
  /** Sede legale */
  SedeLegale = 'SEDE_LEGALE',
  /** Sede operativa */
  SedeOperativa = 'SEDE_OPERATIVA',
  /** Spedizioni */
  Spedizioni = 'SPEDIZIONI',
  /** Filiale */
  Filiale = 'FILIALE',
  /** Privato */
  Privato = 'PRIVATO',
  /** Stabilimento di Produzione */
  StabilimentoDiProduzione = 'STABILIMENTO_DI_PRODUZIONE',
  /** Dipartimento Logistico */
  DipartimentoLogistico = 'DIPARTIMENTO_LOGISTICO'
}

export type Indirizzo_From_Abbonamento_Type = {
  /** {"required":false,"max":80} */
  Name?: Maybe<Scalars['String']>;
  /** {"max":255} */
  Via?: Maybe<Scalars['String']>;
  /** {"max":40} */
  Citt?: Maybe<Scalars['String']>;
  /** {"max":3} */
  Provincia?: Maybe<Scalars['String']>;
  /** {"max":12} */
  CAP?: Maybe<Scalars['String']>;
  /** {"max":80} */
  Paese?: Maybe<Scalars['String']>;
  /** {"max":100} */
  Note?: Maybe<Scalars['String']>;
  /** {} */
  Tipo?: Maybe<Indirizzo__C_Tipo__C_Enum>;
  /** {"max":20} */
  Regione?: Maybe<Scalars['String']>;
  /** {} */
  accountCompany?: Maybe<RefInputObject>;
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  accountService?: Maybe<RefInputObject>;
  /** {} */
  accountRetailer?: Maybe<RefInputObject>;
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>;
  /** {} */
  accountFoodService?: Maybe<RefInputObject>;
  /** {} */
  referenteUser?: Maybe<RefInputObject>;
};

/** Client-side code load proper form based on this value */
export enum InputTemplate {
  NewsletterGeneric = 'NEWSLETTER_GENERIC',
  DemGeneric = 'DEM_GENERIC'
}


/** User possible internal Capabilities */
export enum InternalCapability {
  UsersBase = 'USERS_BASE',
  UsersExtra = 'USERS_EXTRA',
  MarketingBase = 'MARKETING_BASE',
  MarketingExtra = 'MARKETING_EXTRA',
  EditorialBase = 'EDITORIAL_BASE',
  EditorialExtra = 'EDITORIAL_EXTRA',
  DirectoryBase = 'DIRECTORY_BASE',
  DirectoryExtra = 'DIRECTORY_EXTRA',
  AdminBase = 'ADMIN_BASE',
  AdminExtra = 'ADMIN_EXTRA'
}

/** Italian Indications */
export enum ItalianIndication {
  Docg = 'DOCG',
  Doc = 'DOC',
  Igt = 'IGT',
  BevandaSpiritosaIg = 'BEVANDA_SPIRITOSA_IG',
  Pat = 'PAT',
  PresidioSlowFood = 'PRESIDIO_SLOW_FOOD',
  ArcaSlowFood = 'ARCA_SLOW_FOOD'
}

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"}} */
export type Magazine = Node & {
  __typename?: 'Magazine';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  MagazineCollection: MagazineCollection;
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  slug: Scalars['String'];
  /** {"max":30} */
  name: Scalars['String'];
  /** {} */
  title: Scalars['String'];
  /** {"max":80} */
  category?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>;
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>;
  /** {} */
  customVisibilityArgs?: Maybe<Scalars['String']>;
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>;
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>;
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>;
  /** {"mediaType":"PDF_WEB"} */
  Media?: Maybe<Media>;
  /** {"mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<Media>;
  /** {} */
  source: NewsSource;
  /** {} */
  externalSourceId?: Maybe<Scalars['Int']>;
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<Media>;
  /** {} */
  featuredImageUrl?: Maybe<Scalars['String']>;
  /** {} */
  featuredImageSizes?: Maybe<Scalars['String']>;
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<MagazineContent>>;
  /** {} */
  banners?: Maybe<Array<Banner>>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canPatchRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"}} */
export type MagazineCollection = Node & {
  __typename?: 'MagazineCollection';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  BrandSf: Abbonamento__C_Rivista__C_Enum;
  /** {} */
  name: Scalars['String'];
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<Media>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":2022,"min":1950} */
  startYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  highlightDays?: Maybe<Scalars['Int']>;
  /** {} */
  visibilityType: VisibilityType;
  /** {} */
  visibilityArgs?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  priority: Scalars['Int'];
  /** {} */
  notInLatestIssues: Scalars['Boolean'];
  /** {} */
  hasCategory: Scalars['Boolean'];
  /** {} */
  categoriesWithCounts: Array<CategoryWithCount>;
};

/** A connection to a list of items. */
export type MagazineCollectionConnection = {
  __typename?: 'MagazineCollectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MagazineCollectionEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MagazineCollectionCreateInput = {
  /** {} */
  BrandSf: Abbonamento__C_Rivista__C_Enum;
  /** {} */
  name: Scalars['String'];
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":2022,"min":1950} */
  startYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  highlightDays?: Maybe<Scalars['Int']>;
  /** {} */
  visibilityType: VisibilityType;
  /** {} */
  visibilityArgs?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  priority: Scalars['Int'];
  /** {} */
  notInLatestIssues: Scalars['Boolean'];
  /** {} */
  hasCategory: Scalars['Boolean'];
};

/** An edge in a connection. */
export type MagazineCollectionEdge = {
  __typename?: 'MagazineCollectionEdge';
  /** The item at the end of the edge */
  node?: Maybe<MagazineCollection>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type MagazineCollectionFilterInput = {
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  /** Contained in BrandSf or name */
  name?: Maybe<Scalars['String']>;
  notInLatestIssues?: Maybe<Scalars['Boolean']>;
  hasCategory?: Maybe<Scalars['Boolean']>;
};

export type MagazineCollectionPatchInput = {
  /** {} */
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  description?: Maybe<Scalars['String']>;
  /** {"max":2022,"min":1950} */
  startYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  highlightDays?: Maybe<Scalars['Int']>;
  /** {} */
  visibilityType?: Maybe<VisibilityType>;
  /** {} */
  visibilityArgs?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  priority?: Maybe<Scalars['Int']>;
  /** {} */
  notInLatestIssues?: Maybe<Scalars['Boolean']>;
  /** {} */
  hasCategory?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type MagazineConnection = {
  __typename?: 'MagazineConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MagazineEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type MagazineContent = Node & {
  __typename?: 'MagazineContent';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  Magazine?: Maybe<Magazine>;
  /** {} */
  name: Scalars['String'];
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>;
};

export type MagazineContent_From_Magazine_Type = {
  /** {} */
  Magazine?: Maybe<RefInputObject>;
  /** {} */
  name: Scalars['String'];
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>;
};

export type MagazineContentCreateInput = {
  /** {} */
  Magazine?: Maybe<RefInputObject>;
  /** {} */
  name: Scalars['String'];
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>;
};

export type MagazineContentPatchInput = {
  /** {} */
  Magazine?: Maybe<RefInputObject>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  pageNumber?: Maybe<Scalars['Int']>;
};

export type MagazineCreateInput = {
  /** {} */
  MagazineCollection: RefInputObject;
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":30} */
  name: Scalars['String'];
  /** {} */
  title: Scalars['String'];
  /** {"max":80} */
  category?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>;
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>;
  /** {} */
  customVisibilityArgs?: Maybe<Scalars['String']>;
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>;
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>;
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>;
  /** {"mediaType":"PDF_WEB"} */
  Media?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<RefInputObject>>;
  /** {"allowFormInlining":true} */
  magazineContents__inline?: Maybe<Array<MagazineContent_From_Magazine_Type>>;
};

/** An edge in a connection. */
export type MagazineEdge = {
  __typename?: 'MagazineEdge';
  /** The item at the end of the edge */
  node?: Maybe<Magazine>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type MagazineFilterInput = {
  BrandSf?: Maybe<Abbonamento__C_Rivista__C_Enum>;
  MagazineCollection?: Maybe<Array<RefInputObject>>;
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Contained in title */
  title?: Maybe<Scalars['String']>;
  notInLatestIssues?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAfter?: Maybe<Scalars['DateTime']>;
  publishedBefore?: Maybe<Scalars['DateTime']>;
};

export enum MagazineOrderBy {
  PublicationDate = 'publicationDate',
  IssueNumber = 'issueNumber'
}

export type MagazinePatchInput = {
  /** {} */
  MagazineCollection?: Maybe<RefInputObject>;
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {"max":30} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  title?: Maybe<Scalars['String']>;
  /** {"max":80} */
  category?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  customHighlightDays?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  issueNumber?: Maybe<Scalars['Int']>;
  /** {"min":1,"max":2147483647} */
  numOfTheYear?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  numPages?: Maybe<Scalars['Int']>;
  /** {"max":2147483647,"min":-2147483647} */
  prefixPages?: Maybe<Scalars['Int']>;
  /** {} */
  customVisibilityType?: Maybe<VisibilityType>;
  /** {} */
  customVisibilityArgs?: Maybe<Scalars['String']>;
  /** {} */
  fullUrl?: Maybe<Scalars['URL']>;
  /** {} */
  previewUrl?: Maybe<Scalars['URL']>;
  /** {} */
  dropboxPath?: Maybe<Scalars['String']>;
  /** {"mediaType":"PDF_WEB"} */
  Media?: Maybe<RefInputObject>;
  /** {"mediaType":"PDF_WEB"} */
  previewMedia?: Maybe<RefInputObject>;
  /** {"mediaType":"MAGAZINE_COVER"} */
  featuredImageMedia?: Maybe<RefInputObject>;
  /** {"allowFormInlining":true} */
  magazineContents?: Maybe<Array<RefInputObject>>;
  /** {"allowFormInlining":true} */
  magazineContents__inline?: Maybe<Array<MagazineContent_From_Magazine_Type>>;
};

export type MaintenanceResult = {
  __typename?: 'MaintenanceResult';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** {"canCreateRoles":{"INTERNAL":"USERS_EXTRA"},"canPatchRoles":{"INTERNAL":"USERS_EXTRA","PRODUCER":"MANAGE"},"canDeleteRoles":{"INTERNAL":"USERS_EXTRA","PRODUCER":"MANAGE"}} */
export type ManagedByUser = Node & {
  __typename?: 'ManagedByUser';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  capabilities: Array<Capability>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  Service?: Maybe<Service>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  GeoIndication?: Maybe<GeoIndication>;
  /** {} */
  FoodService?: Maybe<FoodService>;
};

export type ManagedByUserCreateInput = {
  /** {} */
  User: RefInputObject;
  /** {} */
  capabilities?: Maybe<Array<Capability>>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
};

export type ManagedByUserPatchInput = {
  /** {} */
  User?: Maybe<RefInputObject>;
  /** {} */
  capabilities?: Maybe<Array<Capability>>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":true,"PRODUCER":"EDIT"},"canPatchRoles":{"INTERNAL":true,"PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":true,"PRODUCER":"EDIT"}} */
export type Media = Node & {
  __typename?: 'Media';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  type: MediaType;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  caption?: Maybe<Scalars['String']>;
  /** {} */
  origFileName: Scalars['String'];
  /** {} */
  timestamp: Scalars['DateTime'];
  /** {} */
  origUrl: Scalars['URL'];
  /** {} */
  largeThumbUrl?: Maybe<Scalars['URL']>;
  /** {} */
  mediumThumbUrl?: Maybe<Scalars['URL']>;
  /** {} */
  smallThumbUrl?: Maybe<Scalars['URL']>;
  /** {} */
  vecThumbUrl?: Maybe<Scalars['URL']>;
};

/** A connection to a list of items. */
export type MediaConnection = {
  __typename?: 'MediaConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MediaEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MediaCreateInput = {
  type: MediaType;
  /** @TODO DESCRIBE ME */
  Company?: Maybe<RefInputObject>;
  /** @TODO DESCRIBE ME */
  caption?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
};

/** An edge in a connection. */
export type MediaEdge = {
  __typename?: 'MediaEdge';
  /** The item at the end of the edge */
  node?: Maybe<Media>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type MediaFilterInput = {
  /** Contained in name o origFileName */
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  Company?: Maybe<RefInputObject>;
};

export enum MediaOrderBy {
  Caption = 'caption',
  Timestamp = 'timestamp'
}

export type MediaPatchInput = {
  /** {} */
  caption?: Maybe<Scalars['String']>;
};

/** Supported Media types */
export enum MediaType {
  Logo = 'LOGO',
  GoodImage = 'GOOD_IMAGE',
  Icon = 'ICON',
  PdfWeb = 'PDF_WEB',
  PdfGeneric = 'PDF_GENERIC',
  BannerFull = 'BANNER_FULL',
  BannerHalf = 'BANNER_HALF',
  BannerLeaderboard = 'BANNER_LEADERBOARD',
  BannerPopup = 'BANNER_POPUP',
  News = 'NEWS',
  MagazineCover = 'MAGAZINE_COVER',
  LogoPrint = 'LOGO_PRINT',
  GoodPrintImage = 'GOOD_PRINT_IMAGE',
  GalleryGeneric = 'GALLERY_GENERIC',
  MailImage = 'MAIL_IMAGE'
}

export type Mutation = {
  __typename?: 'Mutation';
  nodeDelete?: Maybe<Scalars['Int']>;
  userCreate?: Maybe<User>;
  userPatch?: Maybe<User>;
  userSetStatus?: Maybe<User>;
  userChangePassword?: Maybe<Scalars['Int']>;
  managedByUserCreate?: Maybe<ManagedByUser>;
  managedByUserPatch?: Maybe<ManagedByUser>;
  goodClassCreate?: Maybe<GoodClass>;
  goodClassPatch?: Maybe<GoodClass>;
  goodFeatureCertificationCreate?: Maybe<GoodFeatureCertification>;
  goodFeatureCertificationPatch?: Maybe<GoodFeatureCertification>;
  companyCertificationCreate?: Maybe<CompanyCertification>;
  companyCertificationPatch?: Maybe<CompanyCertification>;
  companyCreate?: Maybe<Company>;
  companyPatch?: Maybe<Company>;
  companyYearCreate?: Maybe<CompanyYear>;
  companyYearPatch?: Maybe<CompanyYear>;
  companyLevelCreate?: Maybe<CompanyLevel>;
  companyLevelPatch?: Maybe<CompanyLevel>;
  mediaCreate?: Maybe<Media>;
  mediaPatch?: Maybe<Media>;
  brandCreate?: Maybe<Brand>;
  brandPatch?: Maybe<Brand>;
  branchCreate?: Maybe<Branch>;
  branchPatch?: Maybe<Branch>;
  goodCreate?: Maybe<Good>;
  goodPatch?: Maybe<Good>;
  goodFormatCreate?: Maybe<GoodFormat>;
  goodFormatPatch?: Maybe<GoodFormat>;
  highlightedGoodCreate?: Maybe<HighlightedGood>;
  highlightedGoodPatch?: Maybe<HighlightedGood>;
  awardCreate?: Maybe<Award>;
  awardPatch?: Maybe<Award>;
  awardSetStatus?: Maybe<Award>;
  awardCategoryCreate?: Maybe<AwardCategory>;
  awardCategoryPatch?: Maybe<AwardCategory>;
  awardResultCreate?: Maybe<AwardResult>;
  awardResultPatch?: Maybe<AwardResult>;
  awardInvitedUserCreate?: Maybe<AwardInvitedUser>;
  awardInvitedUserPatch?: Maybe<AwardInvitedUser>;
  awardInvitedCompanyCreate?: Maybe<AwardInvitedCompany>;
  awardInvitedCompanyPatch?: Maybe<AwardInvitedCompany>;
  candidacyCreate?: Maybe<Candidacy>;
  candidacyPatch?: Maybe<Candidacy>;
  candidacyRatingCreate?: Maybe<CandidacyRating>;
  candidacyRatingPatch?: Maybe<CandidacyRating>;
  companyGroupCreate?: Maybe<CompanyGroup>;
  companyGroupPatch?: Maybe<CompanyGroup>;
  serviceCreate?: Maybe<Service>;
  servicePatch?: Maybe<Service>;
  retailerCreate?: Maybe<Retailer>;
  retailerPatch?: Maybe<Retailer>;
  teamCreate?: Maybe<Team>;
  teamPatch?: Maybe<Team>;
  euCategoryCreate?: Maybe<EuCategory>;
  euCategoryPatch?: Maybe<EuCategory>;
  geoIndicationCreate?: Maybe<GeoIndication>;
  geoIndicationPatch?: Maybe<GeoIndication>;
  geoIndicationSetStatus?: Maybe<GeoIndication>;
  fairCreate?: Maybe<Fair>;
  fairPatch?: Maybe<Fair>;
  fairParticipationCreate?: Maybe<FairParticipation>;
  fairParticipationPatch?: Maybe<FairParticipation>;
  editorialSectionCreate?: Maybe<EditorialSection>;
  editorialSectionPatch?: Maybe<EditorialSection>;
  editorialTagCreate?: Maybe<EditorialTag>;
  editorialTagPatch?: Maybe<EditorialTag>;
  htmlTemplateCreate?: Maybe<HtmlTemplate>;
  htmlTemplatePatch?: Maybe<HtmlTemplate>;
  newsFormatCreate?: Maybe<NewsFormat>;
  newsFormatPatch?: Maybe<NewsFormat>;
  newsSetStatus?: Maybe<News>;
  newsCitationCreate?: Maybe<NewsCitation>;
  newsCitationPatch?: Maybe<NewsCitation>;
  bannerCreate?: Maybe<Banner>;
  bannerPatch?: Maybe<Banner>;
  bannerSetStatus?: Maybe<Banner>;
  widgetCreate?: Maybe<Widget>;
  widgetPatch?: Maybe<Widget>;
  abbonamentoCreate?: Maybe<Abbonamento>;
  abbonamentoPatch?: Maybe<Abbonamento>;
  magazineCreate?: Maybe<Magazine>;
  magazinePatch?: Maybe<Magazine>;
  magazineCollectionCreate?: Maybe<MagazineCollection>;
  magazineCollectionPatch?: Maybe<MagazineCollection>;
  magazineContentCreate?: Maybe<MagazineContent>;
  magazineContentPatch?: Maybe<MagazineContent>;
  newsletterCreate?: Maybe<Newsletter>;
  newsletterPatch?: Maybe<Newsletter>;
  newsletterIssueCreate?: Maybe<NewsletterIssue>;
  newsletterIssuePatch?: Maybe<NewsletterIssue>;
  newsletterIssueSetStatus?: Maybe<NewsletterIssue>;
  newsletterIssueHasNewsCreate?: Maybe<NewsletterIssueHasNews>;
  newsletterIssueHasNewsPatch?: Maybe<NewsletterIssueHasNews>;
  emailDomainCreate?: Maybe<EmailDomain>;
  emailDomainPatch?: Maybe<EmailDomain>;
  toSCreate?: Maybe<ToS>;
  toSPatch?: Maybe<ToS>;
  userNoteCreate?: Maybe<UserNote>;
  userNotePatch?: Maybe<UserNote>;
  userObjectSubscriptionCreate?: Maybe<UserObjectSubscription>;
  userObjectSubscriptionPatch?: Maybe<UserObjectSubscription>;
  questionSetCreate?: Maybe<QuestionSet>;
  answerSetPatch?: Maybe<AnswerSet>;
  frequentSearchCreate?: Maybe<FrequentSearch>;
  frequentSearchPatch?: Maybe<FrequentSearch>;
  frequentSearchSetStatus?: Maybe<FrequentSearch>;
  frequentSearchAddRemoveItems?: Maybe<Scalars['Int']>;
  fairVisitCreate?: Maybe<FairVisit>;
  fairVisitPatch?: Maybe<FairVisit>;
  fairVisitParticipationCreate?: Maybe<FairVisitParticipation>;
  fairVisitParticipationPatch?: Maybe<FairVisitParticipation>;
  bannerViewCreate?: Maybe<BannerView>;
  userAchievementCreate?: Maybe<UserAchievement>;
  sampleRequestCreate?: Maybe<SampleRequest>;
  sampleRequestPatch?: Maybe<SampleRequest>;
  sampleRequestSetStatus?: Maybe<SampleRequest>;
  companyBadgeCreate?: Maybe<CompanyBadge>;
  companyBadgePatch?: Maybe<CompanyBadge>;
  distributionChannelCreate?: Maybe<DistributionChannel>;
  distributionChannelPatch?: Maybe<DistributionChannel>;
  typeformCreate?: Maybe<Typeform>;
  typeformPatch?: Maybe<Typeform>;
  quoteCreate?: Maybe<Quote>;
  quotePay?: Maybe<Quote>;
  prospectPatch?: Maybe<Prospect>;
  foodServiceCreate?: Maybe<FoodService>;
  foodServicePatch?: Maybe<FoodService>;
  couponCreate?: Maybe<Coupon>;
  couponPatch?: Maybe<Coupon>;
  objectsMerge?: Maybe<MaintenanceResult>;
  invalidateDirectory?: Maybe<MaintenanceResult>;
  updateIfnPlatformStats?: Maybe<MaintenanceResult>;
};


export type MutationNodeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationUserCreateArgs = {
  values: UserCreateInput;
};


export type MutationUserPatchArgs = {
  id: Scalars['ID'];
  changes: UserPatchInput;
};


export type MutationUserSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: UserStatus;
};


export type MutationUserChangePasswordArgs = {
  id: Scalars['ID'];
  newPassword: Scalars['String'];
};


export type MutationManagedByUserCreateArgs = {
  values: ManagedByUserCreateInput;
};


export type MutationManagedByUserPatchArgs = {
  id: Scalars['ID'];
  changes: ManagedByUserPatchInput;
};


export type MutationGoodClassCreateArgs = {
  values: GoodClassCreateInput;
};


export type MutationGoodClassPatchArgs = {
  id: Scalars['ID'];
  changes: GoodClassPatchInput;
};


export type MutationGoodFeatureCertificationCreateArgs = {
  values: GoodFeatureCertificationCreateInput;
};


export type MutationGoodFeatureCertificationPatchArgs = {
  id: Scalars['ID'];
  changes: GoodFeatureCertificationPatchInput;
};


export type MutationCompanyCertificationCreateArgs = {
  values: CompanyCertificationCreateInput;
};


export type MutationCompanyCertificationPatchArgs = {
  id: Scalars['ID'];
  changes: CompanyCertificationPatchInput;
};


export type MutationCompanyCreateArgs = {
  values: CompanyCreateInput;
};


export type MutationCompanyPatchArgs = {
  id: Scalars['ID'];
  changes: CompanyPatchInput;
};


export type MutationCompanyYearCreateArgs = {
  values: CompanyYearCreateInput;
};


export type MutationCompanyYearPatchArgs = {
  id: Scalars['ID'];
  changes: CompanyYearPatchInput;
};


export type MutationCompanyLevelCreateArgs = {
  values: CompanyLevelCreateInput;
};


export type MutationCompanyLevelPatchArgs = {
  id: Scalars['ID'];
  changes: CompanyLevelPatchInput;
};


export type MutationMediaCreateArgs = {
  values: MediaCreateInput;
};


export type MutationMediaPatchArgs = {
  id: Scalars['ID'];
  changes: MediaPatchInput;
};


export type MutationBrandCreateArgs = {
  values: BrandCreateInput;
};


export type MutationBrandPatchArgs = {
  id: Scalars['ID'];
  changes: BrandPatchInput;
};


export type MutationBranchCreateArgs = {
  values: BranchCreateInput;
};


export type MutationBranchPatchArgs = {
  id: Scalars['ID'];
  changes: BranchPatchInput;
};


export type MutationGoodCreateArgs = {
  values: GoodCreateInput;
};


export type MutationGoodPatchArgs = {
  id: Scalars['ID'];
  changes: GoodPatchInput;
};


export type MutationGoodFormatCreateArgs = {
  values: GoodFormatCreateInput;
};


export type MutationGoodFormatPatchArgs = {
  id: Scalars['ID'];
  changes: GoodFormatPatchInput;
};


export type MutationHighlightedGoodCreateArgs = {
  values: HighlightedGoodCreateInput;
};


export type MutationHighlightedGoodPatchArgs = {
  id: Scalars['ID'];
  changes: HighlightedGoodPatchInput;
};


export type MutationAwardCreateArgs = {
  values: AwardCreateInput;
};


export type MutationAwardPatchArgs = {
  id: Scalars['ID'];
  changes: AwardPatchInput;
};


export type MutationAwardSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: AwardStatus;
};


export type MutationAwardCategoryCreateArgs = {
  values: AwardCategoryCreateInput;
};


export type MutationAwardCategoryPatchArgs = {
  id: Scalars['ID'];
  changes: AwardCategoryPatchInput;
};


export type MutationAwardResultCreateArgs = {
  values: AwardResultCreateInput;
};


export type MutationAwardResultPatchArgs = {
  id: Scalars['ID'];
  changes: AwardResultPatchInput;
};


export type MutationAwardInvitedUserCreateArgs = {
  values: AwardInvitedUserCreateInput;
};


export type MutationAwardInvitedUserPatchArgs = {
  id: Scalars['ID'];
  changes: AwardInvitedUserPatchInput;
};


export type MutationAwardInvitedCompanyCreateArgs = {
  values: AwardInvitedCompanyCreateInput;
};


export type MutationAwardInvitedCompanyPatchArgs = {
  id: Scalars['ID'];
  changes: AwardInvitedCompanyPatchInput;
};


export type MutationCandidacyCreateArgs = {
  values: CandidacyCreateInput;
};


export type MutationCandidacyPatchArgs = {
  id: Scalars['ID'];
  changes: CandidacyPatchInput;
};


export type MutationCandidacyRatingCreateArgs = {
  values: CandidacyRatingCreateInput;
};


export type MutationCandidacyRatingPatchArgs = {
  id: Scalars['ID'];
  changes: CandidacyRatingPatchInput;
};


export type MutationCompanyGroupCreateArgs = {
  values: CompanyGroupCreateInput;
};


export type MutationCompanyGroupPatchArgs = {
  id: Scalars['ID'];
  changes: CompanyGroupPatchInput;
};


export type MutationServiceCreateArgs = {
  values: ServiceCreateInput;
};


export type MutationServicePatchArgs = {
  id: Scalars['ID'];
  changes: ServicePatchInput;
};


export type MutationRetailerCreateArgs = {
  values: RetailerCreateInput;
};


export type MutationRetailerPatchArgs = {
  id: Scalars['ID'];
  changes: RetailerPatchInput;
};


export type MutationTeamCreateArgs = {
  values: TeamCreateInput;
};


export type MutationTeamPatchArgs = {
  id: Scalars['ID'];
  changes: TeamPatchInput;
};


export type MutationEuCategoryCreateArgs = {
  values: EuCategoryCreateInput;
};


export type MutationEuCategoryPatchArgs = {
  id: Scalars['ID'];
  changes: EuCategoryPatchInput;
};


export type MutationGeoIndicationCreateArgs = {
  values: GeoIndicationCreateInput;
};


export type MutationGeoIndicationPatchArgs = {
  id: Scalars['ID'];
  changes: GeoIndicationPatchInput;
};


export type MutationGeoIndicationSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: NewsStatus;
};


export type MutationFairCreateArgs = {
  values: FairCreateInput;
};


export type MutationFairPatchArgs = {
  id: Scalars['ID'];
  changes: FairPatchInput;
};


export type MutationFairParticipationCreateArgs = {
  values: FairParticipationCreateInput;
};


export type MutationFairParticipationPatchArgs = {
  id: Scalars['ID'];
  changes: FairParticipationPatchInput;
};


export type MutationEditorialSectionCreateArgs = {
  values: EditorialSectionCreateInput;
};


export type MutationEditorialSectionPatchArgs = {
  id: Scalars['ID'];
  changes: EditorialSectionPatchInput;
};


export type MutationEditorialTagCreateArgs = {
  values: EditorialTagCreateInput;
};


export type MutationEditorialTagPatchArgs = {
  id: Scalars['ID'];
  changes: EditorialTagPatchInput;
};


export type MutationHtmlTemplateCreateArgs = {
  values: HtmlTemplateCreateInput;
};


export type MutationHtmlTemplatePatchArgs = {
  id: Scalars['ID'];
  changes: HtmlTemplatePatchInput;
};


export type MutationNewsFormatCreateArgs = {
  values: NewsFormatCreateInput;
};


export type MutationNewsFormatPatchArgs = {
  id: Scalars['ID'];
  changes: NewsFormatPatchInput;
};


export type MutationNewsSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: NewsStatus;
};


export type MutationNewsCitationCreateArgs = {
  values: NewsCitationCreateInput;
};


export type MutationNewsCitationPatchArgs = {
  id: Scalars['ID'];
  changes: NewsCitationPatchInput;
};


export type MutationBannerCreateArgs = {
  values: BannerCreateInput;
};


export type MutationBannerPatchArgs = {
  id: Scalars['ID'];
  changes: BannerPatchInput;
};


export type MutationBannerSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: BooleanEnum;
};


export type MutationWidgetCreateArgs = {
  values: WidgetCreateInput;
};


export type MutationWidgetPatchArgs = {
  id: Scalars['ID'];
  changes: WidgetPatchInput;
};


export type MutationAbbonamentoCreateArgs = {
  values: AbbonamentoCreateInput;
};


export type MutationAbbonamentoPatchArgs = {
  id: Scalars['ID'];
  changes: AbbonamentoPatchInput;
};


export type MutationMagazineCreateArgs = {
  values: MagazineCreateInput;
};


export type MutationMagazinePatchArgs = {
  id: Scalars['ID'];
  changes: MagazinePatchInput;
};


export type MutationMagazineCollectionCreateArgs = {
  values: MagazineCollectionCreateInput;
};


export type MutationMagazineCollectionPatchArgs = {
  id: Scalars['ID'];
  changes: MagazineCollectionPatchInput;
};


export type MutationMagazineContentCreateArgs = {
  values: MagazineContentCreateInput;
};


export type MutationMagazineContentPatchArgs = {
  id: Scalars['ID'];
  changes: MagazineContentPatchInput;
};


export type MutationNewsletterCreateArgs = {
  values: NewsletterCreateInput;
};


export type MutationNewsletterPatchArgs = {
  id: Scalars['ID'];
  changes: NewsletterPatchInput;
};


export type MutationNewsletterIssueCreateArgs = {
  values: NewsletterIssueCreateInput;
};


export type MutationNewsletterIssuePatchArgs = {
  id: Scalars['ID'];
  changes: NewsletterIssuePatchInput;
};


export type MutationNewsletterIssueSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: NewsStatus;
};


export type MutationNewsletterIssueHasNewsCreateArgs = {
  values: NewsletterIssueHasNewsCreateInput;
};


export type MutationNewsletterIssueHasNewsPatchArgs = {
  id: Scalars['ID'];
  changes: NewsletterIssueHasNewsPatchInput;
};


export type MutationEmailDomainCreateArgs = {
  values: EmailDomainCreateInput;
};


export type MutationEmailDomainPatchArgs = {
  id: Scalars['ID'];
  changes: EmailDomainPatchInput;
};


export type MutationToSCreateArgs = {
  values: ToSCreateInput;
};


export type MutationToSPatchArgs = {
  id: Scalars['ID'];
  changes: ToSPatchInput;
};


export type MutationUserNoteCreateArgs = {
  values: UserNoteCreateInput;
};


export type MutationUserNotePatchArgs = {
  id: Scalars['ID'];
  changes: UserNotePatchInput;
};


export type MutationUserObjectSubscriptionCreateArgs = {
  values: UserObjectSubscriptionCreateInput;
};


export type MutationUserObjectSubscriptionPatchArgs = {
  id: Scalars['ID'];
  changes: UserObjectSubscriptionPatchInput;
};


export type MutationQuestionSetCreateArgs = {
  values: QuestionSetCreateInput;
};


export type MutationAnswerSetPatchArgs = {
  id: Scalars['ID'];
  changes: AnswerSetPatchInput;
};


export type MutationFrequentSearchCreateArgs = {
  values: FrequentSearchCreateInput;
};


export type MutationFrequentSearchPatchArgs = {
  id: Scalars['ID'];
  changes: FrequentSearchPatchInput;
};


export type MutationFrequentSearchSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: FrequentSearchStatus;
};


export type MutationFrequentSearchAddRemoveItemsArgs = {
  items: Array<RefInputObject>;
  addToFrequentSearches?: Maybe<Array<RefInputObject>>;
  removeFromFrequentSearches?: Maybe<Array<RefInputObject>>;
};


export type MutationFairVisitCreateArgs = {
  values: FairVisitCreateInput;
};


export type MutationFairVisitPatchArgs = {
  id: Scalars['ID'];
  changes: FairVisitPatchInput;
};


export type MutationFairVisitParticipationCreateArgs = {
  values: FairVisitParticipationCreateInput;
};


export type MutationFairVisitParticipationPatchArgs = {
  id: Scalars['ID'];
  changes: FairVisitParticipationPatchInput;
};


export type MutationBannerViewCreateArgs = {
  values: BannerViewCreateInput;
};


export type MutationUserAchievementCreateArgs = {
  values: UserAchievementCreateInput;
};


export type MutationSampleRequestCreateArgs = {
  values: SampleRequestCreateInput;
};


export type MutationSampleRequestPatchArgs = {
  id: Scalars['ID'];
  changes: SampleRequestPatchInput;
};


export type MutationSampleRequestSetStatusArgs = {
  id: Scalars['ID'];
  newStatus: SampleRequestStatus;
};


export type MutationCompanyBadgeCreateArgs = {
  values: CompanyBadgeCreateInput;
};


export type MutationCompanyBadgePatchArgs = {
  id: Scalars['ID'];
  changes: CompanyBadgePatchInput;
};


export type MutationDistributionChannelCreateArgs = {
  values: DistributionChannelCreateInput;
};


export type MutationDistributionChannelPatchArgs = {
  id: Scalars['ID'];
  changes: DistributionChannelPatchInput;
};


export type MutationTypeformCreateArgs = {
  values: TypeformCreateInput;
};


export type MutationTypeformPatchArgs = {
  id: Scalars['ID'];
  changes: TypeformPatchInput;
};


export type MutationQuoteCreateArgs = {
  values: QuoteCreateInput;
};


export type MutationQuotePayArgs = {
  id: Scalars['ID'];
  stripeCardToken?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['Email']>;
  billingVat?: Maybe<Scalars['String']>;
  expectedChargeAmountCents: Scalars['Int'];
  recurring?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};


export type MutationProspectPatchArgs = {
  email?: Maybe<Scalars['Email']>;
  piListSubscriptionChanges: Array<PiListSubscriptionChange>;
};


export type MutationFoodServiceCreateArgs = {
  values: FoodServiceCreateInput;
};


export type MutationFoodServicePatchArgs = {
  id: Scalars['ID'];
  changes: FoodServicePatchInput;
};


export type MutationCouponCreateArgs = {
  values: CouponCreateInput;
};


export type MutationCouponPatchArgs = {
  id: Scalars['ID'];
  changes: CouponPatchInput;
};


export type MutationObjectsMergeArgs = {
  source: RefInputObject;
  target: RefInputObject;
};


export type MutationInvalidateDirectoryArgs = {
  directory: DirectoryTypeEnum;
  preClear: Scalars['Boolean'];
};


export type MutationUpdateIfnPlatformStatsArgs = {
  date: Scalars['DateTime'];
};

/** {"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type News = Node & {
  __typename?: 'News';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  NewsFormat: NewsFormat;
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  status: NewsStatus;
  /** {"html":true,"htmlLevel":"BASE"} */
  title: Scalars['String'];
  /** {"html":true,"htmlLevel":"BASE_MULTILINE"} */
  excerpt?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  body?: Maybe<Scalars['String']>;
  /** {} */
  source: NewsSource;
  /** {} */
  externalSourceId?: Maybe<Scalars['Int']>;
  /** {"required":true,"mediaType":"NEWS"} */
  featuredImageMedia?: Maybe<Media>;
  /** {} */
  featuredImageUrl?: Maybe<Scalars['String']>;
  /** {} */
  featuredImageSizes?: Maybe<Scalars['String']>;
  /** {} */
  CreatorUser: User;
  /** {} */
  PublisherUser?: Maybe<User>;
  /** {} */
  primaryEditorialSection?: Maybe<EditorialSection>;
  /** {"readonly":true} */
  langCode: Scalars['String'];
  /** {"readonly":true} */
  target: TargetSite;
  /** {} */
  Permalink?: Maybe<Permalink>;
  /** {} */
  seoMeta?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  deadlineDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  isSfAbandoned: Scalars['Boolean'];
  /** {} */
  htmlTemplateValues?: Maybe<Scalars['String']>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'];
  /** {} */
  editorialSections: Array<EditorialSection>;
  /** {} */
  editorialTags: Array<EditorialTag>;
  /** {} */
  embeddedMedias: Array<Media>;
  /** {} */
  citations: Array<NewsCitation>;
};

/** A connection to a list of items. */
export type News_2Connection = {
  __typename?: 'News_2Connection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<News_2Edge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type News_2Edge = {
  __typename?: 'News_2Edge';
  /** The item at the end of the edge */
  node?: Maybe<News>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type NewsCitation = Node & {
  __typename?: 'NewsCitation';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  News: News;
  /** {"min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  Brand?: Maybe<Brand>;
  /** {} */
  Good?: Maybe<Good>;
  /** {} */
  GeoIndication?: Maybe<GeoIndication>;
  /** {} */
  Fair?: Maybe<Fair>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  Service?: Maybe<Service>;
  /** {} */
  FoodService?: Maybe<FoodService>;
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>;
};

export type NewsCitationCreateInput = {
  /** {} */
  News: RefInputObject;
  /** {"min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Brand?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
};

export type NewsCitationPatchInput = {
  /** {} */
  News?: Maybe<RefInputObject>;
  /** {"min":1,"max":2147483647} */
  count?: Maybe<Scalars['Int']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Brand?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
};

/** A connection to a list of items. */
export type NewsConnection = {
  __typename?: 'NewsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type NewsEdge = {
  __typename?: 'NewsEdge';
  /** The item at the end of the edge */
  node?: Maybe<News>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type NewsFilterInput = {
  NewsFormat?: Maybe<Array<RefInputObject>>;
  /** Contained in title or excerpt */
  text?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<NewsStatus>>;
  CreatorUser?: Maybe<RefInputObject>;
  targetSite?: Maybe<TargetSite>;
  EditorialSection?: Maybe<Array<RefInputObject>>;
  EditorialTag?: Maybe<Array<RefInputObject>>;
  isSfAbandoned?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type NewsFormat = Node & {
  __typename?: 'NewsFormat';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  HtmlTemplate?: Maybe<HtmlTemplate>;
  /** {} */
  saleable: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type NewsFormatConnection = {
  __typename?: 'NewsFormatConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsFormatEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type NewsFormatCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>;
  /** {} */
  saleable: Scalars['Boolean'];
};

/** An edge in a connection. */
export type NewsFormatEdge = {
  __typename?: 'NewsFormatEdge';
  /** The item at the end of the edge */
  node?: Maybe<NewsFormat>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type NewsFormatPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  HtmlTemplate?: Maybe<RefInputObject>;
  /** {} */
  saleable?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA"},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA"},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA"}} */
export type Newsletter = Node & {
  __typename?: 'Newsletter';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  target: NewsletterBrand;
  /** {} */
  delivery: DeliveryService;
  /** {} */
  inputTemplate: InputTemplate;
  /** {} */
  values?: Maybe<Scalars['String']>;
};

/** // TODO */
export enum NewsletterAcceptedFlag {
  General = 'GENERAL'
}

export enum NewsletterBrand {
  Ifn = 'IFN',
  Food = 'FOOD',
  Foodservice = 'FOODSERVICE',
  Dolcesalato = 'DOLCESALATO',
  ClientIta = 'CLIENT_ITA',
  ClientEng = 'CLIENT_ENG'
}

/** A connection to a list of items. */
export type NewsletterConnection = {
  __typename?: 'NewsletterConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type NewsletterCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  target: NewsletterBrand;
  /** {} */
  delivery: DeliveryService;
  /** {} */
  inputTemplate: InputTemplate;
  /** {} */
  values?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type NewsletterEdge = {
  __typename?: 'NewsletterEdge';
  /** The item at the end of the edge */
  node?: Maybe<Newsletter>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type NewsletterFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  targets?: Maybe<Array<NewsletterBrand>>;
  deliveries?: Maybe<Array<DeliveryService>>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_EXTRA"},"canSetStatusRoles":{"status":{"STUB":{"INTERNAL":"EDITORIAL_BASE"},"DRAFT":{"INTERNAL":"EDITORIAL_BASE"},"WAITING_PUBLICATION":{"INTERNAL":"EDITORIAL_BASE"},"PUBLISHED":{"INTERNAL":"EDITORIAL_EXTRA"},"HIDDEN":{"INTERNAL":"EDITORIAL_BASE"},"DELETED":{"INTERNAL":"EDITORIAL_BASE"}}},"canPatchNeedCurrentStatusAccess":"status"} */
export type NewsletterIssue = Node & {
  __typename?: 'NewsletterIssue';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  status: NewsStatus;
  /** {} */
  Newsletter: Newsletter;
  /** {"max":100} */
  name: Scalars['String'];
  /** {"max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro: Scalars['String'];
  /** {} */
  values?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type NewsletterIssueConnection = {
  __typename?: 'NewsletterIssueConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterIssueEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type NewsletterIssueCreateInput = {
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  Newsletter: RefInputObject;
  /** {"max":100} */
  name: Scalars['String'];
  /** {"max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro: Scalars['String'];
  /** {} */
  values?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type NewsletterIssueEdge = {
  __typename?: 'NewsletterIssueEdge';
  /** The item at the end of the edge */
  node?: Maybe<NewsletterIssue>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type NewsletterIssueFilterInput = {
  Newsletter?: Maybe<Array<RefInputObject>>;
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<NewsStatus>>;
};

/** {"canCreateRoles":{"INTERNAL":"EDITORIAL_BASE"},"canPatchRoles":{"INTERNAL":"EDITORIAL_BASE"},"canDeleteRoles":{"INTERNAL":"EDITORIAL_BASE"}} */
export type NewsletterIssueHasNews = Node & {
  __typename?: 'NewsletterIssueHasNews';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  NewsletterIssue: NewsletterIssue;
  /** {} */
  News?: Maybe<News>;
  /** {"max":2147483647,"min":-2147483647} */
  order: Scalars['Int'];
  /** {"html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>;
};

export type NewsletterIssueHasNewsCreateInput = {
  /** {} */
  NewsletterIssue: RefInputObject;
  /** {} */
  News?: Maybe<RefInputObject>;
  /** {"max":2147483647,"min":-2147483647} */
  order: Scalars['Int'];
  /** {"html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>;
};

export type NewsletterIssueHasNewsPatchInput = {
  /** {} */
  NewsletterIssue?: Maybe<RefInputObject>;
  /** {} */
  News?: Maybe<RefInputObject>;
  /** {"max":2147483647,"min":-2147483647} */
  order?: Maybe<Scalars['Int']>;
  /** {"html":true,"htmlLevel":"BASE"} */
  customTitle?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"BASE_MULTILINE"} */
  customExcerpt?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"LINK_EMBED"} */
  customBody?: Maybe<Scalars['String']>;
};

export type NewsletterIssuePatchInput = {
  /** {"dtPrecision":"FULL"} */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  Newsletter?: Maybe<RefInputObject>;
  /** {"max":100} */
  name?: Maybe<Scalars['String']>;
  /** {"max":100,"html":true,"htmlLevel":"BASE"} */
  hiddenIntro?: Maybe<Scalars['String']>;
  /** {} */
  values?: Maybe<Scalars['String']>;
};

export type NewsletterPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  target?: Maybe<NewsletterBrand>;
  /** {} */
  delivery?: Maybe<DeliveryService>;
  /** {} */
  inputTemplate?: Maybe<InputTemplate>;
  /** {} */
  values?: Maybe<Scalars['String']>;
};

/** News's possible source */
export enum NewsSource {
  Hub = 'HUB',
  WpIfn = 'WP_IFN',
  WpFoodweb = 'WP_FOODWEB',
  WpFoodserviceweb = 'WP_FOODSERVICEWEB',
  PaperIfn = 'PAPER_IFN'
}

/** News's possible statues */
export enum NewsStatus {
  Stub = 'STUB',
  Draft = 'DRAFT',
  WaitingPublication = 'WAITING_PUBLICATION',
  Published = 'PUBLISHED',
  Hidden = 'HIDDEN',
  Deleted = 'DELETED'
}

/** @TODO DESCRIBE ME */
export type NewsViewInput = {
  /** Contained in title or excerpt */
  text?: Maybe<Scalars['String']>;
  EditorialSections?: Maybe<Array<RefInputObject>>;
  EditorialTags?: Maybe<Array<RefInputObject>>;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

/** {} */
export type ObjectHistory = Node & {
  __typename?: 'ObjectHistory';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {"dtPrecision":"FULL"} */
  timestamp: Scalars['DateTime'];
  /** {} */
  action: ObjectHistoryAction;
  /** {} */
  collection: Scalars['String'];
  /** {} */
  objectGid: Scalars['ID'];
  /** {} */
  Object?: Maybe<Node>;
  /** {} */
  params?: Maybe<Scalars['String']>;
};

export enum ObjectHistoryAction {
  Create = 'CREATE',
  Patch = 'PATCH',
  SetStatus = 'SET_STATUS',
  Delete = 'DELETE',
  Subcreate = 'SUBCREATE',
  Merge = 'MERGE'
}

export enum ObjectHistoryCollection {
  Abbonamento = 'Abbonamento',
  AbbonamentoPeriodo = 'AbbonamentoPeriodo',
  AnswerSet = 'AnswerSet',
  AtecoCode = 'AtecoCode',
  Award = 'Award',
  AwardCategory = 'AwardCategory',
  AwardInvitedCompany = 'AwardInvitedCompany',
  AwardInvitedUser = 'AwardInvitedUser',
  AwardResult = 'AwardResult',
  Banner = 'Banner',
  BannerView = 'BannerView',
  Branch = 'Branch',
  Brand = 'Brand',
  CampaignMember = 'CampaignMember',
  Candidacy = 'Candidacy',
  CandidacyRating = 'CandidacyRating',
  Company = 'Company',
  CompanyBadge = 'CompanyBadge',
  CompanyCertification = 'CompanyCertification',
  CompanyGroup = 'CompanyGroup',
  CompanyLevel = 'CompanyLevel',
  CompanyYear = 'CompanyYear',
  Contact = 'Contact',
  Country = 'Country',
  Coupon = 'Coupon',
  DistributionChannel = 'DistributionChannel',
  EditorialSection = 'EditorialSection',
  EditorialTag = 'EditorialTag',
  EmailDomain = 'EmailDomain',
  EuCategory = 'EuCategory',
  Fair = 'Fair',
  FairParticipation = 'FairParticipation',
  FairVisit = 'FairVisit',
  FairVisitParticipation = 'FairVisitParticipation',
  FoodService = 'FoodService',
  FrequentSearch = 'FrequentSearch',
  GeoIndication = 'GeoIndication',
  Good = 'Good',
  GoodClass = 'GoodClass',
  GoodFeatureCertification = 'GoodFeatureCertification',
  GoodFormat = 'GoodFormat',
  HighlightedGood = 'HighlightedGood',
  HtmlTemplate = 'HtmlTemplate',
  Indirizzo = 'Indirizzo',
  Magazine = 'Magazine',
  MagazineCollection = 'MagazineCollection',
  MagazineContent = 'MagazineContent',
  ManagedByUser = 'ManagedByUser',
  Media = 'Media',
  News = 'News',
  NewsCitation = 'NewsCitation',
  NewsFormat = 'NewsFormat',
  Newsletter = 'Newsletter',
  NewsletterIssue = 'NewsletterIssue',
  NewsletterIssueHasNews = 'NewsletterIssueHasNews',
  ObjectHistory = 'ObjectHistory',
  Permalink = 'Permalink',
  PiList = 'PiList',
  PricebookEntry = 'PricebookEntry',
  Prospect = 'Prospect',
  QuestionSet = 'QuestionSet',
  Quote = 'Quote',
  QuoteLineItem = 'QuoteLineItem',
  Retailer = 'Retailer',
  SampleRequest = 'SampleRequest',
  Service = 'Service',
  StateProvince = 'StateProvince',
  Team = 'Team',
  ToS = 'ToS',
  Typeform = 'Typeform',
  User = 'User',
  UserAchievement = 'UserAchievement',
  UserNote = 'UserNote',
  UserObjectSubscription = 'UserObjectSubscription',
  Widget = 'Widget'
}

/** A connection to a list of items. */
export type ObjectHistoryConnection = {
  __typename?: 'ObjectHistoryConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ObjectHistoryEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ObjectHistoryEdge = {
  __typename?: 'ObjectHistoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<ObjectHistory>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type ObjectHistoryFilterInput = {
  actions?: Maybe<Array<ObjectHistoryAction>>;
  collections?: Maybe<Array<ObjectHistoryCollection>>;
  userRoles?: Maybe<Array<UserRole>>;
  User?: Maybe<RefInputObject>;
  Object?: Maybe<RefInputObject>;
  /** Cerco in quelle fatte da utenti che gestiscono Company invitate a quel Award */
  Award?: Maybe<RefInputObject>;
  timestampMin?: Maybe<Scalars['DateTime']>;
  timestampMax?: Maybe<Scalars['DateTime']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** {} */
export type Permalink = Node & {
  __typename?: 'Permalink';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  url: Scalars['String'];
  /** {} */
  targetSite: TargetSite;
  /** {} */
  collection: Scalars['String'];
  /** {"max":2147483647,"min":-2147483647} */
  objectId: Scalars['Int'];
};

/** {} */
export type PiList = Node & {
  __typename?: 'PiList';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  piId?: Maybe<Scalars['String']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  title?: Maybe<Scalars['String']>;
  /** {} */
  description?: Maybe<Scalars['String']>;
  /** {} */
  isPublic?: Maybe<Scalars['Boolean']>;
  /** {} */
  isDynamic?: Maybe<Scalars['Boolean']>;
  /** {} */
  isCrmVisible?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type PiListConnection = {
  __typename?: 'PiListConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PiListEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type PiListEdge = {
  __typename?: 'PiListEdge';
  /** The item at the end of the edge */
  node?: Maybe<PiList>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type PiListFilterInput = {
  isPublic?: Maybe<Scalars['Boolean']>;
  isCrmVisible?: Maybe<Scalars['Boolean']>;
};

/** {} */
export type PiListSubscription = {
  __typename?: 'PiListSubscription';
  /** {} */
  PiList: PiList;
  /** {} */
  didOptIn?: Maybe<Scalars['Boolean']>;
  /** {} */
  didOptOut?: Maybe<Scalars['Boolean']>;
};

export type PiListSubscriptionChange = {
  PiList: RefInputObject;
  optIn: Scalars['Boolean'];
};

/** Preservation types */
export enum Preservation {
  Perishable = 'PERISHABLE',
  Frozen = 'FROZEN',
  ShelfStable = 'SHELF_STABLE'
}

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type PricebookEntry = Node & {
  __typename?: 'PricebookEntry';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"max":255} */
  Name?: Maybe<Scalars['String']>;
  /** {"required":true} */
  UnitPrice: Scalars['Float'];
  /** {"required":false} */
  IsActive?: Maybe<Scalars['Boolean']>;
  /** {"required":true,"max":18} */
  Product2Id: Scalars['String'];
  /** {"multiline":true,"max":4000} */
  Product2Description?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  Product2DisplayUrl?: Maybe<Scalars['URL']>;
  /** {} */
  Product2Brand?: Maybe<Product2_Brand__C_EnumFiltered>;
  /** {"dtPrecision":"DAY_END"} */
  Product2Data_di_Scadenza?: Maybe<Scalars['DateTime']>;
  /** {} */
  Product2Tipologia?: Maybe<Product2_Tipologia__C_Enum>;
  /** {} */
  Product2Candidature?: Maybe<Scalars['Float']>;
  /** {} */
  Product2Brand_Area?: Maybe<Product2_Brand_Area__C_Enum>;
  /** {"dtPrecision":"DAY_END"} */
  Product2Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Product2Data_Fine_Competenza?: Maybe<Scalars['DateTime']>;
  /** {} */
  Product2Famiglia?: Maybe<Product2_Famiglia__C_Enum>;
  /** {"required":true,"max":60} */
  Product2Tipo: Scalars['String'];
  /** {} */
  Product2Aliquota_IVA?: Maybe<Scalars['Float']>;
};

/** A connection to a list of items. */
export type PricebookEntryConnection = {
  __typename?: 'PricebookEntryConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PricebookEntryEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type PricebookEntryEdge = {
  __typename?: 'PricebookEntryEdge';
  /** The item at the end of the edge */
  node?: Maybe<PricebookEntry>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type PricebookEntryFilterInput = {
  Product2Brand?: Maybe<Product2_Brand__C_EnumFiltered>;
  Product2Brand_Area?: Maybe<Product2_Brand_Area__C_Enum>;
  Product2Tipologia?: Maybe<Product2_Tipologia__C_Enum>;
  Product2Tipo?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
};

export enum PricebookEntryOrderBy {
  Name = 'Name',
  UnitPrice = 'UnitPrice',
  Product2Brand = 'Product2Brand',
  Product2BrandArea = 'Product2Brand_Area',
  Product2DataDiScadenza = 'Product2Data_di_Scadenza',
  Product2DataInizioCompetenza = 'Product2Data_Inizio_Competenza',
  Product2DataFineCompetenza = 'Product2Data_Fine_Competenza'
}

export enum PricePositioning {
  Discount = 'DISCOUNT',
  Mainstream = 'MAINSTREAM',
  Premium = 'PREMIUM',
  Luxury = 'LUXURY'
}

/** SF picklist */
export enum Product2_Brand__C_EnumFiltered {
  /** FOOD */
  Food = 'FOOD',
  /** FOODSERVICE */
  Foodservice = 'FOODSERVICE',
  /** DOLCESALATO */
  Dolcesalato = 'DOLCESALATO',
  /** ITALIANFOOD.NET */
  ItalianfoodNet = 'ITALIANFOOD_NET',
  /** FIOR FIORE IN CUCINA */
  FiorFioreInCucina = 'FIOR_FIORE_IN_CUCINA',
  /** PROGETTI */
  Progetti = 'PROGETTI',
  /** FOOD LABS */
  FoodLabs = 'FOOD_LABS',
  /** P&B */
  PB = 'P_B',
  /** BAR BUSINESS */
  BarBusiness = 'BAR_BUSINESS',
  /** FOOD EDITORE */
  FoodEditore = 'FOOD_EDITORE'
}

/** SF picklist */
export enum Product2_Brand_Area__C_Enum {
  /** Rivista */
  Rivista = 'RIVISTA',
  /** Digitale */
  Digitale = 'DIGITALE',
  /** Digitale ITA */
  DigitaleIta = 'DIGITALE_ITA',
  /** Digitale INT */
  DigitaleInt = 'DIGITALE_INT',
  /** Abbonamenti */
  Abbonamenti = 'ABBONAMENTI',
  /** Eventi */
  Eventi = 'EVENTI',
  /** Content */
  Content = 'CONTENT',
  /** Promo */
  Promo = 'PROMO',
  /** Strategia e Progetti Integrati */
  StrategiaEProgettiIntegrati = 'STRATEGIA_E_PROGETTI_INTEGRATI',
  /** Distribuzione Con Reso */
  DistribuzioneConReso = 'DISTRIBUZIONE_CON_RESO',
  /** Distribuzione Senza Reso */
  DistribuzioneSenzaReso = 'DISTRIBUZIONE_SENZA_RESO',
  /** Estero */
  Estero = 'ESTERO',
  /** Servizi Editoriali */
  ServiziEditoriali = 'SERVIZI_EDITORIALI',
  /** Produzione Editoriale Multicanale */
  ProduzioneEditorialeMulticanale = 'PRODUZIONE_EDITORIALE_MULTICANALE'
}

/** SF picklist */
export enum Product2_Famiglia__C_Enum {
  /** Food */
  Food = 'FOOD',
  /** Out Of Home */
  OutOfHome = 'OUT_OF_HOME',
  /** Content Marketing and Promo */
  ContentMarketingAndPromo = 'CONTENT_MARKETING_AND_PROMO',
  /** Editoria */
  Editoria = 'EDITORIA',
  /** Italianfood.net */
  ItalianfoodNet = 'ITALIANFOOD_NET'
}

/** SF picklist */
export enum Product2_Tipologia__C_Enum {
  /** Periodico */
  Periodico = 'PERIODICO',
  /** Digitale */
  Digitale = 'DIGITALE',
  /** Speciale */
  Speciale = 'SPECIALE',
  /** Food Summit */
  FoodSummit = 'FOOD_SUMMIT',
  /** Premiato Prodotto Food */
  PremiatoProdottoFood = 'PREMIATO_PRODOTTO_FOOD',
  /** Altri Eventi */
  AltriEventi = 'ALTRI_EVENTI',
  /** Foto - Video */
  FotoVideo = 'FOTO_VIDEO',
  /** Pubblicazioni */
  Pubblicazioni = 'PUBBLICAZIONI',
  /** Post */
  Post = 'POST',
  /** Concorso a premi */
  ConcorsoAPremi = 'CONCORSO_A_PREMI',
  /** Materiale POP */
  MaterialePop = 'MATERIALE_POP',
  /** In Store Promotion */
  InStorePromotion = 'IN_STORE_PROMOTION',
  /** Gadget */
  Gadget = 'GADGET',
  /** Editoriale */
  Editoriale = 'EDITORIALE',
  /** Raccolta Pubblicitaria */
  RaccoltaPubblicitaria = 'RACCOLTA_PUBBLICITARIA',
  /** Progettuale */
  Progettuale = 'PROGETTUALE',
  /** Riviste */
  Riviste = 'RIVISTE',
  /** Showcase */
  Showcase = 'SHOWCASE',
  /** Canone */
  Canone = 'CANONE',
  /** Servizio Spot */
  ServizioSpot = 'SERVIZIO_SPOT',
  /** Fee */
  Fee = 'FEE'
}

/** {"canPatchRoles":true} */
export type Prospect = Node & {
  __typename?: 'Prospect';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  piId?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'];
  /** {} */
  piListSubscriptions?: Maybe<Array<PiListSubscription>>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches objects given their IDs */
  nodes: Array<Maybe<Node>>;
  users?: Maybe<UserConnection>;
  currentUser?: Maybe<User>;
  goodClasses?: Maybe<GoodClassConnection>;
  goodFeatureCertifications?: Maybe<GoodFeatureCertificationConnection>;
  companyCertifications?: Maybe<CompanyCertificationConnection>;
  companies?: Maybe<CompanyConnection>;
  medias?: Maybe<MediaConnection>;
  brands?: Maybe<BrandConnection>;
  goods?: Maybe<GoodConnection>;
  awards?: Maybe<AwardConnection>;
  candidacies?: Maybe<CandidacyConnection>;
  awardCategoryCandidaciesToRate: Array<Maybe<Candidacy>>;
  atecoCodes?: Maybe<AtecoCodeConnection>;
  candidacyRatings?: Maybe<CandidacyRatingConnection>;
  companyGroups?: Maybe<CompanyGroupConnection>;
  services?: Maybe<ServiceConnection>;
  retailers?: Maybe<RetailerConnection>;
  teams?: Maybe<TeamConnection>;
  euCategories?: Maybe<EuCategoryConnection>;
  geoIndications?: Maybe<GeoIndicationConnection>;
  fairs?: Maybe<FairConnection>;
  countries?: Maybe<CountryConnection>;
  stateProvinces?: Maybe<StateProvinceConnection>;
  editorialSections?: Maybe<EditorialSectionConnection>;
  editorialSectionTree: Array<EditorialSection>;
  editorialTags?: Maybe<EditorialTagConnection>;
  htmlTemplates?: Maybe<HtmlTemplateConnection>;
  newsFormats?: Maybe<NewsFormatConnection>;
  /** Fetches an object given its Permalink URL */
  url2node?: Maybe<Node>;
  news?: Maybe<NewsConnection>;
  newsView?: Maybe<News_2Connection>;
  banners?: Maybe<BannerConnection>;
  /** Get banner to show at current user in current context */
  bannersView: Array<Banner>;
  widgets?: Maybe<WidgetConnection>;
  /** Get widgets to show at current user in current context */
  widgetsView: Array<Widget>;
  abbonamenti?: Maybe<AbbonamentoConnection>;
  magazines?: Maybe<MagazineConnection>;
  magazineCollections?: Maybe<MagazineCollectionConnection>;
  newsletters?: Maybe<NewsletterConnection>;
  newsletterIssues?: Maybe<NewsletterIssueConnection>;
  emailDomains?: Maybe<EmailDomainConnection>;
  objectHistories?: Maybe<ObjectHistoryConnection>;
  toSs?: Maybe<ToSConnection>;
  userNotes?: Maybe<UserNoteConnection>;
  userObjectSubscriptions?: Maybe<UserObjectSubscriptionConnection>;
  questionSets?: Maybe<QuestionSetConnection>;
  answerSets?: Maybe<AnswerSetConnection>;
  frequentSearchs?: Maybe<FrequentSearchConnection>;
  fairVisits?: Maybe<FairVisitConnection>;
  bannerViews?: Maybe<BannerViewConnection>;
  userAchievements?: Maybe<UserAchievementConnection>;
  sampleRequests?: Maybe<SampleRequestConnection>;
  companyBadges?: Maybe<CompanyBadgeConnection>;
  typeforms?: Maybe<TypeformConnection>;
  pricebookEntries?: Maybe<PricebookEntryConnection>;
  quotes?: Maybe<QuoteConnection>;
  piLists?: Maybe<PiListConnection>;
  foodServices?: Maybe<FoodServiceConnection>;
  coupons?: Maybe<CouponConnection>;
  /** Get active coupon from valid code */
  couponsVerify?: Maybe<Coupon>;
  companiesDir?: Maybe<CompaniesDirConnection>;
  goodsDir?: Maybe<GoodsDirConnection>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilterInput>;
  orderBy?: Maybe<UserOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryGoodClassesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchGoodClassInput>;
};


export type QueryGoodFeatureCertificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryCompanyCertificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompanyFilterInput>;
  orderBy?: Maybe<CompanyOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryMediasArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<MediaFilterInput>;
  orderBy?: Maybe<MediaOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryBrandsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<BrandFilterInput>;
};


export type QueryGoodsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<GoodFilterInput>;
  orderBy?: Maybe<GoodOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryAwardsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<AwardFilterInput>;
  orderBy?: Maybe<AwardOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryCandidaciesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CandidacyFilterInput>;
  orderBy?: Maybe<CandidacyOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryAwardCategoryCandidaciesToRateArgs = {
  AwardCategory: RefInputObject;
};


export type QueryAtecoCodesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<AtecoCodeFilterInput>;
  orderBy?: Maybe<AtecoCodeOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryCandidacyRatingsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CandidacyRatingFilterInput>;
  orderBy?: Maybe<CandidacyRatingOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryCompanyGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompanyGroupFilterInput>;
  orderBy?: Maybe<CompanyGroupOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryServicesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<ServiceFilterInput>;
  orderBy?: Maybe<ServiceOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryRetailersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<RetailerFilterInput>;
  orderBy?: Maybe<RetailerOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryTeamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryEuCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryGeoIndicationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<GeoIndicationFilterInput>;
  orderBy?: Maybe<GeoIndicationOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryFairsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<FairFilterInput>;
};


export type QueryCountriesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CountryFilterInput>;
  orderBy?: Maybe<CountryOrderByEnum>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryStateProvincesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<StateProvinceFilterInput>;
  orderBy?: Maybe<StateProvinceOrderByEnum>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryEditorialSectionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<EditorialSectionFilterInput>;
  orderBy?: Maybe<EditorialSectionOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryEditorialSectionTreeArgs = {
  EditorialBrand: EditorialBrand;
};


export type QueryEditorialTagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<EditorialTagFilterInput>;
};


export type QueryHtmlTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryNewsFormatsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryUrl2nodeArgs = {
  url: Scalars['String'];
};


export type QueryNewsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewsFilterInput>;
};


export type QueryNewsViewArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewsViewInput>;
};


export type QueryBannersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<BannerFilterInput>;
  orderBy?: Maybe<BannerOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryBannersViewArgs = {
  filter: BannerViewInput;
};


export type QueryWidgetsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<WidgetFilterInput>;
  orderBy?: Maybe<WidgetOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryWidgetsViewArgs = {
  filter: WidgetViewInput;
};


export type QueryAbbonamentiArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<AbbonamentoFilterInput>;
};


export type QueryMagazinesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<MagazineFilterInput>;
  orderBy?: Maybe<MagazineOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryMagazineCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<MagazineCollectionFilterInput>;
};


export type QueryNewslettersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewsletterFilterInput>;
};


export type QueryNewsletterIssuesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewsletterIssueFilterInput>;
};


export type QueryEmailDomainsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<EmailDomainFilterInput>;
  orderBy?: Maybe<EmailDomainOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryObjectHistoriesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<ObjectHistoryFilterInput>;
};


export type QueryToSsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchByNameInput>;
};


export type QueryUserNotesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserNoteFilterInput>;
};


export type QueryUserObjectSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserObjectSubscriptionFilterInput>;
};


export type QueryQuestionSetsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<QuestionSetFilterInput>;
};


export type QueryAnswerSetsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<AnswerSetFilterInput>;
};


export type QueryFrequentSearchsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<FrequentSearchFilterInput>;
};


export type QueryFairVisitsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<FairVisitFilterInput>;
};


export type QueryBannerViewsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<BannerViewFilterInput>;
};


export type QueryUserAchievementsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserAchievementFilterInput>;
};


export type QuerySampleRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<SampleRequestFilterInput>;
};


export type QueryCompanyBadgesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompanyBadgeFilterInput>;
};


export type QueryTypeformsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<TypeformFilterInput>;
};


export type QueryPricebookEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<PricebookEntryFilterInput>;
  orderBy?: Maybe<PricebookEntryOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPiListsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<PiListFilterInput>;
};


export type QueryFoodServicesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<FoodServiceFilterInput>;
  orderBy?: Maybe<FoodServiceOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryCouponsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CouponFilterInput>;
  orderBy?: Maybe<CouponOrderBy>;
  orderDesc?: Maybe<Scalars['Boolean']>;
};


export type QueryCouponsVerifyArgs = {
  code: Scalars['String'];
};


export type QueryCompaniesDirArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompaniesFilterType>;
};


export type QueryGoodsDirArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<GoodsFilterType>;
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true,"ANONYMUS":true,"CONSUMER":true,"FOODSERVICE":true,"SHOPWORKER":true,"PRODUCER":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"}} */
export type QuestionSet = Node & {
  __typename?: 'QuestionSet';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"readonly":true} */
  parentQuestionSet?: Maybe<QuestionSet>;
  /** {} */
  targetSite: TargetSite;
  /** {} */
  User: User;
  /** {} */
  Team?: Maybe<Team>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {"dtPrecision":"FULL"} */
  sentDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  deadlineDate: Scalars['DateTime'];
  /** {"readonly":true} */
  requests: Array<QuestionSetRequest>;
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets: Array<AnswerSet>;
  /** {} */
  question1?: Maybe<Scalars['String']>;
  /** {} */
  question2?: Maybe<Scalars['String']>;
  /** {} */
  question3?: Maybe<Scalars['String']>;
  /** {} */
  question4?: Maybe<Scalars['String']>;
  /** {} */
  question5?: Maybe<Scalars['String']>;
  /** {} */
  question6?: Maybe<Scalars['String']>;
  /** {} */
  question7?: Maybe<Scalars['String']>;
  /** {} */
  question8?: Maybe<Scalars['String']>;
  /** {} */
  question9?: Maybe<Scalars['String']>;
  /** {} */
  question10?: Maybe<Scalars['String']>;
  /** {} */
  question11?: Maybe<Scalars['String']>;
  /** {} */
  question12?: Maybe<Scalars['String']>;
  /** {} */
  question13?: Maybe<Scalars['String']>;
  /** {} */
  question14?: Maybe<Scalars['String']>;
  /** {} */
  question15?: Maybe<Scalars['String']>;
  /** {} */
  question16?: Maybe<Scalars['String']>;
  /** {} */
  question17?: Maybe<Scalars['String']>;
  /** {} */
  question18?: Maybe<Scalars['String']>;
  /** {} */
  question19?: Maybe<Scalars['String']>;
  /** {} */
  question20?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type QuestionSetConnection = {
  __typename?: 'QuestionSetConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionSetEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QuestionSetCreateInput = {
  /** {"readonly":true} */
  parentQuestionSet?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  requests?: Maybe<Array<QuestionSetRequest>>;
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets?: Maybe<Array<RefInputObject>>;
  /** {"readonly":true,"allowFormInlining":true} */
  answerSets__inline?: Maybe<Array<AnswerSet_From_QuestionSet_Type>>;
  /** {} */
  question1?: Maybe<Scalars['String']>;
  /** {} */
  question2?: Maybe<Scalars['String']>;
  /** {} */
  question3?: Maybe<Scalars['String']>;
  /** {} */
  question4?: Maybe<Scalars['String']>;
  /** {} */
  question5?: Maybe<Scalars['String']>;
  /** {} */
  question6?: Maybe<Scalars['String']>;
  /** {} */
  question7?: Maybe<Scalars['String']>;
  /** {} */
  question8?: Maybe<Scalars['String']>;
  /** {} */
  question9?: Maybe<Scalars['String']>;
  /** {} */
  question10?: Maybe<Scalars['String']>;
  /** {} */
  question11?: Maybe<Scalars['String']>;
  /** {} */
  question12?: Maybe<Scalars['String']>;
  /** {} */
  question13?: Maybe<Scalars['String']>;
  /** {} */
  question14?: Maybe<Scalars['String']>;
  /** {} */
  question15?: Maybe<Scalars['String']>;
  /** {} */
  question16?: Maybe<Scalars['String']>;
  /** {} */
  question17?: Maybe<Scalars['String']>;
  /** {} */
  question18?: Maybe<Scalars['String']>;
  /** {} */
  question19?: Maybe<Scalars['String']>;
  /** {} */
  question20?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['Email']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
  userCompanyName?: Maybe<Scalars['String']>;
  userCountry?: Maybe<Scalars['String']>;
  userNewsletterAccepted?: Maybe<Scalars['Boolean']>;
};

/** An edge in a connection. */
export type QuestionSetEdge = {
  __typename?: 'QuestionSetEdge';
  /** The item at the end of the edge */
  node?: Maybe<QuestionSet>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type QuestionSetFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<RefInputObject>;
  Company?: Maybe<RefInputObject>;
};

/** Possible requests */
export enum QuestionSetRequest {
  Catalog = 'CATALOG',
  Price = 'PRICE',
  Resellers = 'RESELLERS',
  Contact = 'CONTACT'
}

/** {"canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"CONSUMER":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"ANONYMUS":true},"canPatchRoles":false,"canDeleteRoles":false} */
export type Quote = Node & {
  __typename?: 'Quote';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"required":true,"max":255} */
  Name: Scalars['String'];
  /** {"required":true,"max":30} */
  QuoteNumber: Scalars['String'];
  /** {"required":false} */
  Status?: Maybe<Quote_Status_Enum>;
  /** {"multiline":true,"max":32000} */
  Description?: Maybe<Scalars['String']>;
  /** {} */
  TotalPrice?: Maybe<Scalars['Float']>;
  /** {"multiline":true,"max":255} */
  BillingStreet?: Maybe<Scalars['String']>;
  /** {"max":40} */
  BillingCity?: Maybe<Scalars['String']>;
  /** {"max":20} */
  BillingPostalCode?: Maybe<Scalars['String']>;
  /** {} */
  BillingStateCode?: Maybe<Quote_BillingStateCode_Enum>;
  /** {"required":false} */
  BillingCountryCode?: Maybe<Quote_BillingCountryCode_Enum>;
  /** {"max":255} */
  BillingName?: Maybe<Scalars['String']>;
  /** {} */
  Discount?: Maybe<Scalars['Float']>;
  /** {} */
  Condizioni_di_pagamento?: Maybe<Quote_Condizioni_Di_Pagamento__C_Enum>;
  /** {"allowFormInlining":true} */
  QuoteLineItems: Array<QuoteLineItem>;
  /** {} */
  Pricebook2Id?: Maybe<Scalars['String']>;
  /** {} */
  RecordType?: Maybe<Scalars['String']>;
  /** {} */
  Opportunity?: Maybe<Scalars['String']>;
  /** {} */
  referenteUser?: Maybe<User>;
  /** {} */
  accountCompany?: Maybe<Company>;
  /** {} */
  accountCompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  accountService?: Maybe<Service>;
  /** {} */
  accountRetailer?: Maybe<Retailer>;
  /** {} */
  accountGeoIndication?: Maybe<GeoIndication>;
  /** {} */
  accountFoodService?: Maybe<FoodService>;
};

/** SF picklist */
export enum Quote_BillingCountryCode_Enum {
  /** Afghanistan */
  Af = 'AF',
  /** Aland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia Erzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cape Verde */
  Cv = 'CV',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Cote d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Democratic Republic of the Congo */
  Cd = 'CD',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italia */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Korea, Democratic People's Republic of */
  Kp = 'KP',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Macedonia */
  Mk = 'MK',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palestine */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Qatar */
  Qa = 'QA',
  /** Reunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Swaziland */
  Sz = 'SZ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** SF picklist */
export enum Quote_BillingStateCode_Enum {
  /** Acre */
  Ac = 'AC',
  /** Aguascalientes */
  Ag = 'AG',
  /** Alessandria */
  Al = 'AL',
  /** Alaska */
  Ak = 'AK',
  /** Alberta */
  Ab = 'AB',
  /** Ascoli Piceno */
  Ap = 'AP',
  /** Amazonas */
  Am = 'AM',
  /** Andaman and Nicobar Islands */
  An = 'AN',
  /** Anhui */
  V_34 = 'V_34',
  /** Aosta */
  Ao = 'AO',
  /** Arunachal Pradesh */
  Ar = 'AR',
  /** Arizona */
  Az = 'AZ',
  /** Assam */
  As = 'AS',
  /** Asti */
  At = 'AT',
  /** Australian Capital Territory */
  Act = 'ACT',
  /** Avellino */
  Av = 'AV',
  /** Bari */
  Ba = 'BA',
  /** British Columbia */
  Bc = 'BC',
  /** Brescia */
  Bs = 'BS',
  /** Barletta-Andria-Trani */
  Bt = 'BT',
  /** Beijing */
  V_11 = 'V_11',
  /** Belluno */
  Bl = 'BL',
  /** Benevento */
  Bn = 'BN',
  /** Bergamo */
  Bg = 'BG',
  /** Biella */
  Bi = 'BI',
  /** Brindisi */
  Br = 'BR',
  /** Bologna */
  Bo = 'BO',
  /** Bolzano */
  Bz = 'BZ',
  /** California */
  Ca = 'CA',
  /** Colima */
  Cl = 'CL',
  /** Campeche */
  Cm = 'CM',
  /** Campobasso */
  Cb = 'CB',
  /** Carbonia-Iglesias */
  Ci = 'CI',
  /** Carlow */
  Cw = 'CW',
  /** Clare */
  Ce = 'CE',
  /** Connecticut */
  Ct = 'CT',
  /** Catanzaro */
  Cz = 'CZ',
  /** Cuneo */
  Cn = 'CN',
  /** Chihuahua */
  Ch = 'CH',
  /** Cosenza */
  Cs = 'CS',
  /** Chongqing */
  V_50 = 'V_50',
  /** Cork */
  Co = 'CO',
  /** Cremona */
  Cr = 'CR',
  /** Crotone */
  Kr = 'KR',
  /** Dadra and Nagar Haveli */
  Dn = 'DN',
  /** Daman and Diu */
  Dd = 'DD',
  /** Delaware */
  De = 'DE',
  /** Donegal */
  Dl = 'DL',
  /** District of Columbia */
  Dc = 'DC',
  /** Federal District */
  Df = 'DF',
  /** Dublin */
  D = 'D',
  /** Durango */
  Dg = 'DG',
  /** Enna */
  En = 'EN',
  /** Espírito Santo */
  Es = 'ES',
  /** Fermo */
  Fm = 'FM',
  /** Ferrara */
  Fe = 'FE',
  /** Firenze */
  Fi = 'FI',
  /** Florida */
  Fl = 'FL',
  /** Foggia */
  Fg = 'FG',
  /** Forlì-Cesena */
  Fc = 'FC',
  /** Frosinone */
  Fr = 'FR',
  /** Fujian */
  V_35 = 'V_35',
  /** Galway */
  G = 'G',
  /** Gansu */
  V_62 = 'V_62',
  /** Genova */
  Ge = 'GE',
  /** Goa */
  Ga = 'GA',
  /** Gorizia */
  Go = 'GO',
  /** Guerrero */
  Gr = 'GR',
  /** Guanajuato */
  Gt = 'GT',
  /** Guangdong */
  V_44 = 'V_44',
  /** Guangxi */
  V_45 = 'V_45',
  /** Guizhou */
  V_52 = 'V_52',
  /** Gujarat */
  Gj = 'GJ',
  /** Hainan */
  V_46 = 'V_46',
  /** Haryana */
  Hr = 'HR',
  /** Hawaii */
  Hi = 'HI',
  /** Hebei */
  V_13 = 'V_13',
  /** Heilongjiang */
  V_23 = 'V_23',
  /** Henan */
  V_41 = 'V_41',
  /** Hidalgo */
  Hg = 'HG',
  /** Himachal Pradesh */
  Hp = 'HP',
  /** Hong Kong */
  V_91 = 'V_91',
  /** Hubei */
  V_42 = 'V_42',
  /** Hunan */
  V_43 = 'V_43',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Imperia */
  Im = 'IM',
  /** Indiana */
  In = 'IN',
  /** Iowa */
  Ia = 'IA',
  /** Isernia */
  Is = 'IS',
  /** Jalisco */
  Ja = 'JA',
  /** Jammu and Kashmir */
  Jk = 'JK',
  /** Jharkhand */
  Jh = 'JH',
  /** Jiangsu */
  V_32 = 'V_32',
  /** Jiangxi */
  V_36 = 'V_36',
  /** Jilin */
  V_22 = 'V_22',
  /** Kansas */
  Ks = 'KS',
  /** Karnataka */
  Ka = 'KA',
  /** Kerry */
  Ky = 'KY',
  /** Kerala */
  Kl = 'KL',
  /** Kildare */
  Ke = 'KE',
  /** Kilkenny */
  Kk = 'KK',
  /** L'Aquila */
  Aq = 'AQ',
  /** Longford */
  Ld = 'LD',
  /** Laois */
  Ls = 'LS',
  /** São Paulo */
  Sp = 'SP',
  /** Latina */
  Lt = 'LT',
  /** Lecce */
  Le = 'LE',
  /** Lecco */
  Lc = 'LC',
  /** Leitrim */
  Lm = 'LM',
  /** Liaoning */
  V_21 = 'V_21',
  /** Limerick */
  Lk = 'LK',
  /** Livorno */
  Li = 'LI',
  /** Lodi */
  Lo = 'LO',
  /** Louisiana */
  La = 'LA',
  /** Louth */
  Lh = 'LH',
  /** Lucca */
  Lu = 'LU',
  /** Macao */
  V_92 = 'V_92',
  /** Macerata */
  Mc = 'MC',
  /** Madhya Pradesh */
  Mp = 'MP',
  /** Meath */
  Mh = 'MH',
  /** Mexico State */
  Me = 'ME',
  /** Monaghan */
  Mn = 'MN',
  /** Monza - Brianza */
  Mb = 'MB',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** Morelos */
  Mo = 'MO',
  /** Medio Campidano */
  Vs = 'VS',
  /** Meghalaya */
  Ml = 'ML',
  /** Milano */
  Mi = 'MI',
  /** Minas Gerais */
  Mg = 'MG',
  /** Mizoram */
  Mz = 'MZ',
  /** Nuevo León */
  Nl = 'NL',
  /** Nayarit */
  Na = 'NA',
  /** Nebraska */
  Ne = 'NE',
  /** Nei Mongol */
  V_15 = 'V_15',
  /** Nevada */
  Nv = 'NV',
  /** New Brunswick */
  Nb = 'NB',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** New South Wales */
  Nsw = 'NSW',
  /** New York */
  Ny = 'NY',
  /** Ningxia */
  V_64 = 'V_64',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Northwest Territories */
  Nt = 'NT',
  /** Novara */
  No = 'NO',
  /** Nova Scotia */
  Ns = 'NS',
  /** Nuoro */
  Nu = 'NU',
  /** Oaxaca */
  Oa = 'OA',
  /** Oristano */
  Or = 'OR',
  /** Offaly */
  Oy = 'OY',
  /** Ogliastra */
  Og = 'OG',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Olbia -Tempio */
  Ot = 'OT',
  /** Ontario */
  On = 'ON',
  /** Padova */
  Pd = 'PD',
  /** Pennsylvania */
  Pa = 'PA',
  /** Punjab */
  Pb = 'PB',
  /** Parma */
  Pr = 'PR',
  /** Pavia */
  Pv = 'PV',
  /** Prince Edward Island */
  Pe = 'PE',
  /** Perugia */
  Pg = 'PG',
  /** Pesaro - Urbino */
  Pu = 'PU',
  /** Piacenza */
  Pc = 'PC',
  /** Pisa */
  Pi = 'PI',
  /** Pistoia */
  Pt = 'PT',
  /** Pordenone */
  Pn = 'PN',
  /** Potenza */
  Pz = 'PZ',
  /** Prato */
  Po = 'PO',
  /** Puducherry */
  Py = 'PY',
  /** Qinghai */
  V_63 = 'V_63',
  /** Quebec */
  Qc = 'QC',
  /** Queensland */
  Qld = 'QLD',
  /** Querétaro */
  Qe = 'QE',
  /** Quintana Roo */
  Qr = 'QR',
  /** Ragusa */
  Rg = 'RG',
  /** Rio de Janeiro */
  Rj = 'RJ',
  /** Ravenna */
  Ra = 'RA',
  /** Reggio Calabria */
  Rc = 'RC',
  /** Reggio Emilia */
  Re = 'RE',
  /** Rieti */
  Ri = 'RI',
  /** Roscommon */
  Rn = 'RN',
  /** Rio Grande do Sul */
  Rs = 'RS',
  /** Roma */
  Rm = 'RM',
  /** Rovigo */
  Ro = 'RO',
  /** Roraima */
  Rr = 'RR',
  /** South Australia */
  Sa = 'SA',
  /** San Luis Potosí */
  Sl = 'SL',
  /** South Carolina */
  Sc = 'SC',
  /** Sikkim */
  Sk = 'SK',
  /** Sassari */
  Ss = 'SS',
  /** Savona */
  Sv = 'SV',
  /** Sergipe */
  Se = 'SE',
  /** Shaanxi */
  V_61 = 'V_61',
  /** Shandong */
  V_37 = 'V_37',
  /** Shanghai */
  V_31 = 'V_31',
  /** Shanxi */
  V_14 = 'V_14',
  /** Sichuan */
  V_51 = 'V_51',
  /** Sinaloa */
  Si = 'SI',
  /** Siracusa */
  Sr = 'SR',
  /** Sonora */
  So = 'SO',
  /** South Dakota */
  Sd = 'SD',
  /** Tabasco */
  Tb = 'TB',
  /** Taiwan */
  V_71 = 'V_71',
  /** Tamaulipas */
  Tm = 'TM',
  /** Trento */
  Tn = 'TN',
  /** Tipperary */
  Ta = 'TA',
  /** Tasmania */
  Tas = 'TAS',
  /** Teramo */
  Te = 'TE',
  /** Tripura */
  Tr = 'TR',
  /** Texas */
  Tx = 'TX',
  /** Tianjin */
  V_12 = 'V_12',
  /** Tlaxcala */
  Tl = 'TL',
  /** Torino */
  To = 'TO',
  /** Trapani */
  Tp = 'TP',
  /** Treviso */
  Tv = 'TV',
  /** Trieste */
  Ts = 'TS',
  /** Udine */
  Ud = 'UD',
  /** Uttarakhand */
  Ut = 'UT',
  /** Uttar Pradesh */
  Up = 'UP',
  /** Virginia */
  Va = 'VA',
  /** Veracruz */
  Ve = 'VE',
  /** Verbano-Cusio-Ossola */
  Vb = 'VB',
  /** Vercelli */
  Vc = 'VC',
  /** Viterbo */
  Vt = 'VT',
  /** Verona */
  Vr = 'VR',
  /** Vibo Valentia */
  Vv = 'VV',
  /** Vicenza */
  Vi = 'VI',
  /** Victoria */
  Vic = 'VIC',
  /** Western Australia */
  Wa = 'WA',
  /** Waterford */
  Wd = 'WD',
  /** West Bengal */
  Wb = 'WB',
  /** Westmeath */
  Wh = 'WH',
  /** West Virginia */
  Wv = 'WV',
  /** Wexford */
  Wx = 'WX',
  /** Wicklow */
  Ww = 'WW',
  /** Wisconsin */
  Wi = 'WI',
  /** Wyoming */
  Wy = 'WY',
  /** Xinjiang */
  V_65 = 'V_65',
  /** Xizang */
  V_54 = 'V_54',
  /** Yucatán */
  Yu = 'YU',
  /** Yukon Territories */
  Yt = 'YT',
  /** Yunnan */
  V_53 = 'V_53',
  /** Zacatecas */
  Za = 'ZA',
  /** Zhejiang */
  V_33 = 'V_33'
}

/** SF picklist */
export enum Quote_Condizioni_Di_Pagamento__C_Enum {
  /** BONIFICO BANCARIO 30 GG DF. FM. */
  BonificoBancario_30GgDfFm = 'BONIFICO_BANCARIO_30_GG_DF_FM',
  /** BONIFICO BANCARIO 60 GG DF. FM. */
  BonificoBancario_60GgDfFm = 'BONIFICO_BANCARIO_60_GG_DF_FM',
  /** BONIFICO BANCARIO 90 GG DF. FM. */
  BonificoBancario_90GgDfFm = 'BONIFICO_BANCARIO_90_GG_DF_FM',
  /** RICEVUTA BANCARIA 30 GG DF.FM. */
  RicevutaBancaria_30GgDfFm = 'RICEVUTA_BANCARIA_30_GG_DF_FM',
  /** RICEVUTA BANCARIA 60 GG DF.FM. */
  RicevutaBancaria_60GgDfFm = 'RICEVUTA_BANCARIA_60_GG_DF_FM',
  /** RICEVUTA BANCARIA 90 GG DF.FM. */
  RicevutaBancaria_90GgDfFm = 'RICEVUTA_BANCARIA_90_GG_DF_FM',
  /** COMPENSAZIONE PARTITE */
  CompensazionePartite = 'COMPENSAZIONE_PARTITE',
  /** ASSEGNO BANCARIO */
  AssegnoBancario = 'ASSEGNO_BANCARIO',
  /** ADDEBITO CARTA DI CREDITO */
  AddebitoCartaDiCredito = 'ADDEBITO_CARTA_DI_CREDITO',
  /** ADDEBITO C/POSTALE */
  AddebitoCPostale = 'ADDEBITO_C_POSTALE',
  /** BONIFICO BANCARIO */
  BonificoBancario = 'BONIFICO_BANCARIO',
  /** DENARO CONTANTE */
  DenaroContante = 'DENARO_CONTANTE',
  /** B.B. 50% ALLA CONSEGNA + B.B. 50% 60 GG DATA FATTURA FM */
  BB_50AllaConsegnaBB_50_60GgDataFatturaFm = 'B_B_50_ALLA_CONSEGNA_B_B_50_60_GG_DATA_FATTURA_FM',
  /** B.B. 50% ALLA CONSEGNA + B.B. 50% A 30 GG DATA FATTURA */
  BB_50AllaConsegnaBB_50A_30GgDataFattura = 'B_B_50_ALLA_CONSEGNA_B_B_50_A_30_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 120 GG DF.FM. */
  BonificoBancario_120GgDfFm = 'BONIFICO_BANCARIO_120_GG_DF_FM',
  /** BONIFICO BANCARIO 150 GG DF.FM. */
  BonificoBancario_150GgDfFm = 'BONIFICO_BANCARIO_150_GG_DF_FM',
  /** BONIFICO BANCARIO 180 GG.DF.FM */
  BonificoBancario_180GgDfFm = 'BONIFICO_BANCARIO_180_GG_DF_FM',
  /** BONIFICO BANCARIO 120 GG DATA FATTURA */
  BonificoBancario_120GgDataFattura = 'BONIFICO_BANCARIO_120_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 15 GG DATA FATTURA FINE MESE */
  BonificoBancario_15GgDataFatturaFineMese = 'BONIFICO_BANCARIO_15_GG_DATA_FATTURA_FINE_MESE',
  /** BONIFICO BANCARIO 30 GG DATA FATTURA */
  BonificoBancario_30GgDataFattura = 'BONIFICO_BANCARIO_30_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 30 GG DF.FM. + 15 MESE SUCCESSIVO */
  BonificoBancario_30GgDfFm_15MeseSuccessivo = 'BONIFICO_BANCARIO_30_GG_DF_FM_15_MESE_SUCCESSIVO',
  /** BONIFICO BANCARIO 30/60 GG DF FM */
  BonificoBancario_30_60GgDfFm = 'BONIFICO_BANCARIO_30_60_GG_DF_FM',
  /** BONIFICO BANCARIO 60 GG DATA FATTURA */
  BonificoBancario_60GgDataFattura = 'BONIFICO_BANCARIO_60_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 90 GG DATA FATTURA */
  BonificoBancario_90GgDataFattura = 'BONIFICO_BANCARIO_90_GG_DATA_FATTURA',
  /** BONIFICO BANCARIO 90/120 GG DF.FM. */
  BonificoBancario_90_120GgDfFm = 'BONIFICO_BANCARIO_90_120_GG_DF_FM',
  /** RICEVUTA BANCARIA 120 GG DF.FM. */
  RicevutaBancaria_120GgDfFm = 'RICEVUTA_BANCARIA_120_GG_DF_FM',
  /** RICEVUTA BANCARIA 150 GG.DF FM */
  RicevutaBancaria_150GgDfFm = 'RICEVUTA_BANCARIA_150_GG_DF_FM',
  /** RICEVUTA BANCARIA 30/60/90 GG DF.FM. */
  RicevutaBancaria_30_60_90GgDfFm = 'RICEVUTA_BANCARIA_30_60_90_GG_DF_FM',
  /** RICEVUTA BANCARIA 60/90 GG DF.FM. */
  RicevutaBancaria_60_90GgDfFm = 'RICEVUTA_BANCARIA_60_90_GG_DF_FM',
  /** RIMESSA DIRETTA RICEVIMENTO FATTURA */
  RimessaDirettaRicevimentoFattura = 'RIMESSA_DIRETTA_RICEVIMENTO_FATTURA',
  /** FATTURE INFRAGRUPPO */
  FattureInfragruppo = 'FATTURE_INFRAGRUPPO',
  /** CONFIRMING A 120 GG */
  ConfirmingA_120Gg = 'CONFIRMING_A_120_GG',
  /** BB 1/3 A VISTA, 1/3 A 90 A GG DF FM, 1/3 90 A 150 GG DF FM */
  Bb_1_3AVista_1_3A_90AGgDfFm_1_3_90A_150GgDfFm = 'BB_1_3_A_VISTA_1_3_A_90_A_GG_DF_FM_1_3_90_A_150_GG_DF_FM'
}

/** SF picklist */
export enum Quote_Status_Enum {
  /** Da approvare */
  DaApprovare = 'DA_APPROVARE',
  /** Rifiutato */
  Rifiutato = 'RIFIUTATO',
  /** Approvato */
  Approvato = 'APPROVATO'
}

/** Se chi compra lo fa da privato, libero professionista o azienda */
export enum QuoteAccountType {
  Private = 'PRIVATE',
  Freelance = 'FREELANCE',
  Company = 'COMPANY'
}

/** A connection to a list of items. */
export type QuoteConnection = {
  __typename?: 'QuoteConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuoteEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QuoteCreateInput = {
  /** {"multiline":true,"max":32000} */
  Description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":255} */
  BillingStreet: Scalars['String'];
  /** {"max":40} */
  BillingCity: Scalars['String'];
  /** {"max":20} */
  BillingPostalCode?: Maybe<Scalars['String']>;
  /** {} */
  BillingStateCode?: Maybe<Quote_BillingStateCode_Enum>;
  /** {"required":false} */
  BillingCountryCode: Quote_BillingCountryCode_Enum;
  /** {"max":255} */
  BillingName: Scalars['String'];
  /** {} */
  Condizioni_di_pagamento?: Maybe<Quote_Condizioni_Di_Pagamento__C_Enum>;
  /** {"allowFormInlining":true} */
  QuoteLineItems?: Maybe<Array<RefInputObject>>;
  /** {"allowFormInlining":true} */
  QuoteLineItems__inline?: Maybe<Array<QuoteLineItem_From_Quote_Type>>;
  /** {} */
  accountCompany?: Maybe<RefInputObject>;
  /** {} */
  accountCompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  accountService?: Maybe<RefInputObject>;
  /** {} */
  accountRetailer?: Maybe<RefInputObject>;
  /** {} */
  accountGeoIndication?: Maybe<RefInputObject>;
  /** {} */
  accountFoodService?: Maybe<RefInputObject>;
  tosApproved: Scalars['Boolean'];
  quoteAccountType: QuoteAccountType;
  /** Last Name */
  Account_LastName?: Maybe<Scalars['String']>;
  /** First Name */
  Account_FirstName?: Maybe<Scalars['String']>;
  /** Settore */
  Account_Settore2?: Maybe<Account_Settore2__C_Enum>;
  /** Codice Fiscale */
  Account_Codice_Fiscale?: Maybe<Scalars['String']>;
  /** Posta Certificata */
  Account_Posta_Certificata?: Maybe<Scalars['Email']>;
  /** Codice Fiscale Estero */
  Account_Codice_Fiscale_Estero?: Maybe<Scalars['String']>;
  /** Partita IVA */
  Account_Partita_IVA?: Maybe<Scalars['String']>;
  /** Email */
  Account_Email?: Maybe<Scalars['Email']>;
  /** Codice Destinatario SDI */
  Account_Codice_SDI?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type QuoteEdge = {
  __typename?: 'QuoteEdge';
  /** The item at the end of the edge */
  node?: Maybe<Quote>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** {"canCreateRoles":false,"canPatchRoles":false,"canDeleteRoles":false} */
export type QuoteLineItem = Node & {
  __typename?: 'QuoteLineItem';
  /** {} */
  id: Scalars['ID'];
  /** {"required":true,"max":18} */
  Id: Scalars['String'];
  /** {"required":true} */
  IsDeleted: Scalars['Boolean'];
  /** {"required":true,"max":255} */
  LineNumber: Scalars['String'];
  /** {"required":true,"readonly":true} */
  Quote: Quote;
  /** {"required":true,"readonly":true} */
  PricebookEntry: PricebookEntry;
  /** {"required":true} */
  Quantity: Scalars['Float'];
  /** {"required":true} */
  UnitPrice: Scalars['Float'];
  /** {} */
  Discount?: Maybe<Scalars['Float']>;
  /** {"max":255} */
  Description?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  ServiceDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  SortOrder?: Maybe<Scalars['Int']>;
  /** {} */
  ListPrice?: Maybe<Scalars['Float']>;
  /** {} */
  Subtotal?: Maybe<Scalars['Float']>;
  /** {} */
  TotalPrice?: Maybe<Scalars['Float']>;
  /** {"max":1300} */
  Mese_di_uscita?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Anno?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Tipo_prodotto_del?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Stato?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Famiglia?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Brand?: Maybe<Scalars['String']>;
  /** {} */
  IVA_22?: Maybe<Scalars['Float']>;
  /** {} */
  Prezzo_finale?: Maybe<Scalars['Float']>;
  /** {"max":255} */
  Cambia_Stato_Prodotto_a_Listino?: Maybe<Scalars['String']>;
  /** {} */
  Sconto?: Maybe<Scalars['Float']>;
  /** {} */
  Provvigione_agente?: Maybe<Scalars['Float']>;
  /** {} */
  Prezzo_di_listino?: Maybe<Scalars['Float']>;
  /** {"max":1300} */
  Brand_Area?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Tipologia?: Maybe<Scalars['String']>;
  /** {"max":4} */
  Anno_QLI?: Maybe<Scalars['String']>;
  /** {"max":100} */
  Brand_TEXT_QLI?: Maybe<Scalars['String']>;
  /** {} */
  Total_Price_NET_NET?: Maybe<Scalars['Float']>;
  /** {"max":1300} */
  Listino_del_QLI?: Maybe<Scalars['String']>;
  /** {"max":11} */
  Listino_del_QLI_TEXT?: Maybe<Scalars['String']>;
  /** {"max":1300} */
  Codice_Articolo_E?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Fatturazione?: Maybe<Scalars['DateTime']>;
  /** {"max":1300} */
  Codice_Conto_Contabilit?: Maybe<Scalars['String']>;
  /** {} */
  Provvigione_Capo_Area?: Maybe<Scalars['Float']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Scadenza?: Maybe<Scalars['DateTime']>;
  /** {"required":true} */
  IsUnique: Scalars['Boolean'];
  /** {} */
  Candidature?: Maybe<Scalars['Float']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_Fine_Competenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Pagamento?: Maybe<Scalars['DateTime']>;
  /** {} */
  Aliquota_IVA?: Maybe<Scalars['Float']>;
  /** {"max":5} */
  Tipologia_di_aliquota?: Maybe<Scalars['String']>;
  /** {} */
  Abbonamento_Periodi: Array<AbbonamentoPeriodo>;
  /** {} */
  Product2Id?: Maybe<Scalars['String']>;
};

export type QuoteLineItem_From_Quote_Type = {
  /** {"required":true,"readonly":true} */
  Quote?: Maybe<RefInputObject>;
  /** {"required":true,"readonly":true} */
  PricebookEntry: RefInputObject;
  /** {"required":true} */
  Quantity: Scalars['Float'];
  /** {"required":true} */
  UnitPrice: Scalars['Float'];
  /** {} */
  Discount?: Maybe<Scalars['Float']>;
  /** {"max":255} */
  Description?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  ServiceDate?: Maybe<Scalars['DateTime']>;
  /** {"max":255} */
  Cambia_Stato_Prodotto_a_Listino?: Maybe<Scalars['String']>;
  /** {} */
  Prezzo_di_listino?: Maybe<Scalars['Float']>;
  /** {"max":4} */
  Anno_QLI?: Maybe<Scalars['String']>;
  /** {"max":100} */
  Brand_TEXT_QLI?: Maybe<Scalars['String']>;
  /** {"max":11} */
  Listino_del_QLI_TEXT?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Fatturazione?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Scadenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_Inizio_Competenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_Fine_Competenza?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  Data_di_Pagamento?: Maybe<Scalars['DateTime']>;
  /** {} */
  Aliquota_IVA?: Maybe<Scalars['Float']>;
  /** {"max":5} */
  Tipologia_di_aliquota?: Maybe<Scalars['String']>;
  /** {} */
  Product2Id?: Maybe<Scalars['String']>;
  Referenti?: Maybe<Array<QuoteLineItems__Inline_ReferenteIndirizzo>>;
};

export type QuoteLineItems__Inline_Indirizzo = {
  address: Scalars['String'];
  city: Scalars['String'];
  /** Ref all'oggetto Provincia */
  StateProvince?: Maybe<RefInputObject>;
  postalCode?: Maybe<Scalars['String']>;
  /** Ref all'oggetto Country */
  Country: RefInputObject;
};

export type QuoteLineItems__Inline_Referente = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['Email'];
  phone?: Maybe<Scalars['String']>;
};

export type QuoteLineItems__Inline_ReferenteIndirizzo = {
  Referente: QuoteLineItems__Inline_Referente;
  Indirizzo?: Maybe<QuoteLineItems__Inline_Indirizzo>;
};

/** Ref to an existing object */
export type RefInputObject = {
  id: Scalars['ID'];
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","PRODUCER":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Retailer = Node & {
  __typename?: 'Retailer';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"readonly":true} */
  type: RetailerType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /**
   * {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
   * @deprecated Resterà solo su SF
   */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};

/** A connection to a list of items. */
export type RetailerConnection = {
  __typename?: 'RetailerConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailerEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type RetailerCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  type: RetailerType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** An edge in a connection. */
export type RetailerEdge = {
  __typename?: 'RetailerEdge';
  /** The item at the end of the edge */
  node?: Maybe<Retailer>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type RetailerFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<RetailerType>>;
  Country?: Maybe<Array<RefInputObject>>;
};

export enum RetailerOrderBy {
  Name = 'name',
  Type = 'type'
}

export type RetailerPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** Retailer possible types */
export enum RetailerType {
  FoodServiceAltriGrossisti = 'FOOD_SERVICE_ALTRI_GROSSISTI',
  FoodServiceGrossistaBeverage = 'FOOD_SERVICE_GROSSISTA_BEVERAGE',
  FoodServiceGrossistaCaffET = 'FOOD_SERVICE_GROSSISTA_CAFF_E_T',
  FoodServiceGrossistaCarniESalumi = 'FOOD_SERVICE_GROSSISTA_CARNI_E_SALUMI',
  FoodServiceGrossistaDolciario = 'FOOD_SERVICE_GROSSISTA_DOLCIARIO',
  FoodServiceGrossistaGourmet = 'FOOD_SERVICE_GROSSISTA_GOURMET',
  FoodServiceGrossistaLatteEDerivati = 'FOOD_SERVICE_GROSSISTA_LATTE_E_DERIVATI',
  FoodServiceGrossistaOli = 'FOOD_SERVICE_GROSSISTA_OLI',
  FoodServiceGrossistaOrtofrutta = 'FOOD_SERVICE_GROSSISTA_ORTOFRUTTA',
  FoodServiceGrossistaPesce = 'FOOD_SERVICE_GROSSISTA_PESCE',
  FoodServiceGrossistaSurgelati = 'FOOD_SERVICE_GROSSISTA_SURGELATI',
  RetailCC = 'RETAIL_C_C',
  RmaBroker = 'RMA_BROKER',
  RmaCedec = 'RMA_CEDEC',
  RmaCentraleDAcquisto = 'RMA_CENTRALE_D_ACQUISTO',
  RmaConsorzio = 'RMA_CONSORZIO',
  RmaECommerce = 'RMA_E_COMMERCE',
  RmaGruppoInternazionale = 'RMA_GRUPPO_INTERNAZIONALE',
  RmaGruppoNazionale = 'RMA_GRUPPO_NAZIONALE',
  RmaGruppoRegionale = 'RMA_GRUPPO_REGIONALE',
  RmaImportatore = 'RMA_IMPORTATORE',
  RmaLandlord = 'RMA_LANDLORD',
  RmaWholesaler = 'RMA_WHOLESALER',
  RmsBroker = 'RMS_BROKER',
  RmsCedec = 'RMS_CEDEC',
  RmsCentraleDAcquisto = 'RMS_CENTRALE_D_ACQUISTO',
  RmsConsorzio = 'RMS_CONSORZIO',
  RmsECommerce = 'RMS_E_COMMERCE',
  RmsGruppoInternazionale = 'RMS_GRUPPO_INTERNAZIONALE',
  RmsGruppoNazionale = 'RMS_GRUPPO_NAZIONALE',
  RmsGruppoRegionale = 'RMS_GRUPPO_REGIONALE',
  RmsImportatore = 'RMS_IMPORTATORE',
  RmsLandlord = 'RMS_LANDLORD',
  RmsWholesaler = 'RMS_WHOLESALER',
  VendingDistrLocale = 'VENDING_DISTR_LOCALE',
  VendingDistributoreCentrale = 'VENDING_DISTRIBUTORE_CENTRALE'
}

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA","BUYER":true,"BUYER_PL_ITALIA":true},"canPatchRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canDeleteRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canSetStatusRoles":{"status":{"PENDING":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"},"ACCEPTED":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"},"REJECTED":{"INTERNAL":"EDITORIAL_BASE","PRODUCER":"EDIT"}}}} */
export type SampleRequest = Node & {
  __typename?: 'SampleRequest';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  targetSite: TargetSite;
  /** {"readonly":true} */
  Company: Company;
  /** {} */
  User: User;
  /** {} */
  Team?: Maybe<Team>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  status: SampleRequestStatus;
  /** {"dtPrecision":"FULL"} */
  sentDate: Scalars['DateTime'];
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>;
  /** {"required":true} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  stateProvince?: Maybe<Scalars['String']>;
  /** {"required":true} */
  country?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type SampleRequestConnection = {
  __typename?: 'SampleRequestConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SampleRequestEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SampleRequestCreateInput = {
  /** {"readonly":true} */
  Company: RefInputObject;
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>;
  /** {"required":true} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  stateProvince?: Maybe<Scalars['String']>;
  /** {"required":true} */
  country?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type SampleRequestEdge = {
  __typename?: 'SampleRequestEdge';
  /** The item at the end of the edge */
  node?: Maybe<SampleRequest>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type SampleRequestFilterInput = {
  Company?: Maybe<Array<RefInputObject>>;
  User?: Maybe<Array<RefInputObject>>;
  Team?: Maybe<Array<RefInputObject>>;
  Retailer?: Maybe<Array<RefInputObject>>;
  statuses?: Maybe<Array<SampleRequestStatus>>;
};

export type SampleRequestPatchInput = {
  /** {"required":true,"multiline":true,"max":500} */
  description?: Maybe<Scalars['String']>;
  /** {"required":true} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {"required":true} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  stateProvince?: Maybe<Scalars['String']>;
  /** {"required":true} */
  country?: Maybe<Scalars['String']>;
};

/** SampleRequest's possible statues */
export enum SampleRequestStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

/** Filter by name */
export type SearchByNameInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
};

/** Filter by name, Company_id and/or Brand_id */
export type SearchGoodClassInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  Brand?: Maybe<RefInputObject>;
  Company?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":"DIRECTORY_EXTRA"},"canPatchRoles":{"INTERNAL":"DIRECTORY_BASE","SERVICE":"EDIT"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Service = Node & {
  __typename?: 'Service';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  slug: Scalars['String'];
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<Media>;
  /** {"readonly":true} */
  type: ServiceType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /**
   * {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"}
   * @deprecated Resterà solo su SF
   */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: Country;
  /** {} */
  StateProvince?: Maybe<StateProvince>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  newsCitations?: Maybe<Array<NewsCitation>>;
  /** {} */
  userObjectSubscriptions?: Maybe<Array<UserObjectSubscription>>;
  /** {} */
  userNotes?: Maybe<Array<UserNote>>;
};

/** A connection to a list of items. */
export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ServiceCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name: Scalars['String'];
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"readonly":true} */
  type: ServiceType;
  /** {"required":true,"readonly":true,"max":130,"min":5} */
  billingName: Scalars['String'];
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country: RefInputObject;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city: Scalars['String'];
  /** {} */
  address1: Scalars['String'];
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename?: 'ServiceEdge';
  /** The item at the end of the edge */
  node?: Maybe<Service>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type ServiceFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  producerChangedAfter?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<ServiceType>>;
  Country?: Maybe<Array<RefInputObject>>;
};

export enum ServiceOrderBy {
  Name = 'name',
  Type = 'type'
}

export type ServicePatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {"required":true,"max":80,"min":2} */
  name?: Maybe<Scalars['String']>;
  /** {"required":true,"mediaType":"LOGO"} */
  logoMedia?: Maybe<RefInputObject>;
  /** {"multiline":true,"max":250} */
  description?: Maybe<Scalars['String']>;
  /** {"multiline":true,"max":250} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLong?: Maybe<Scalars['String']>;
  /** {"max":1000,"html":true,"htmlLevel":"FORMATTING"} */
  descriptionLongIta?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  pecEmail?: Maybe<Scalars['Email']>;
  /** {"max":15,"formatRegexStr":"^((IT[0-9]{11})|((?!IT)[A-Z]{2}))[0-9A-Z]*$","forceCase":"UPPER"} */
  vatNumber?: Maybe<Scalars['String']>;
  /** {"formatRegexStr":"^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$","forceCase":"LOWER"} */
  website?: Maybe<Scalars['String']>;
  /** {} */
  Country?: Maybe<RefInputObject>;
  /** {} */
  StateProvince?: Maybe<RefInputObject>;
  /** {"max":15} */
  postalCode?: Maybe<Scalars['String']>;
  /** {} */
  city?: Maybe<Scalars['String']>;
  /** {} */
  address1?: Maybe<Scalars['String']>;
  /** {} */
  address2?: Maybe<Scalars['String']>;
  /** {} */
  lat?: Maybe<Scalars['Float']>;
  /** {} */
  lng?: Maybe<Scalars['Float']>;
};

/** Service possible types */
export enum ServiceType {
  AttrezzatureFoodService = 'ATTREZZATURE_FOOD_SERVICE',
  AttrezzatureRetail = 'ATTREZZATURE_RETAIL',
  CentroMedia = 'CENTRO_MEDIA',
  ConsulenzeFormazioneHR = 'CONSULENZE_FORMAZIONE_H_R',
  Energia = 'ENERGIA',
  EntiDiCertificazione = 'ENTI_DI_CERTIFICAZIONE',
  Fiere = 'FIERE',
  MarketingPromoz = 'MARKETING_PROMOZ',
  OrganiStatali = 'ORGANI_STATALI',
  RealEstate = 'REAL_ESTATE',
  RicercheDiMercato = 'RICERCHE_DI_MERCATO',
  ScuoleEUniversit = 'SCUOLE_E_UNIVERSIT',
  ServiziFinanziari = 'SERVIZI_FINANZIARI',
  ServiziInformatici = 'SERVIZI_INFORMATICI',
  ServiziLegali = 'SERVIZI_LEGALI',
  ServiziLogistici = 'SERVIZI_LOGISTICI',
  Telecomunicazioni = 'TELECOMUNICAZIONI',
  UfficiStampaPR = 'UFFICI_STAMPA_P_R',
  EditoriaAgenzia = 'EDITORIA_AGENZIA',
  EditoriaDistributore = 'EDITORIA_DISTRIBUTORE',
  EditoriaEditore = 'EDITORIA_EDITORE',
  EditoriaEntiPubblici = 'EDITORIA_ENTI_PUBBLICI',
  EditoriaStampatore = 'EDITORIA_STAMPATORE',
  AssociazioneDiCategoria = 'ASSOCIAZIONE_DI_CATEGORIA',
  CameraDiCommercioEntePromozione = 'CAMERA_DI_COMMERCIO_ENTE_PROMOZIONE',
  ConsorziDiTutela = 'CONSORZI_DI_TUTELA',
  ConsorziPrivati = 'CONSORZI_PRIVATI',
  IstituzioneCulturale = 'ISTITUZIONE_CULTURALE',
  FormazioneEnogastronomicaProfessionale = 'FORMAZIONE_ENOGASTRONOMICA_PROFESSIONALE'
}

/** Possible SocialTypes */
export enum SocialType {
  Google = 'GOOGLE',
  Linkedin = 'LINKEDIN',
  Facebook = 'FACEBOOK'
}

/** {} */
export type StateProvince = Node & {
  __typename?: 'StateProvince';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  nameIta: Scalars['String'];
  /** {"max":10} */
  code?: Maybe<Scalars['String']>;
  /** {} */
  region?: Maybe<Scalars['String']>;
  /** {} */
  regionIta?: Maybe<Scalars['String']>;
  /** {"max":4,"min":1} */
  nielsenArea?: Maybe<Scalars['Int']>;
  /** {} */
  Country: Country;
};

/** A connection to a list of items. */
export type StateProvinceConnection = {
  __typename?: 'StateProvinceConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StateProvinceEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type StateProvinceEdge = {
  __typename?: 'StateProvinceEdge';
  /** The item at the end of the edge */
  node?: Maybe<StateProvince>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type StateProvinceFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Contained in nameIta */
  nameIta?: Maybe<Scalars['String']>;
  /** Contained in region */
  region?: Maybe<Scalars['String']>;
  /** Contained in regionIta */
  regionIta?: Maybe<Scalars['String']>;
  nielsenArea?: Maybe<Scalars['Int']>;
  Country?: Maybe<RefInputObject>;
};

export enum StateProvinceOrderByEnum {
  Name = 'name',
  NameIta = 'nameIta',
  Region = 'region',
  RegionIta = 'regionIta',
  NielsenArea = 'nielsenArea',
  Country = 'Country'
}

export type Subscription = {
  __typename?: 'Subscription';
  changed?: Maybe<Change>;
};

export enum TargetMedia {
  Website = 'WEBSITE',
  Newsletter = 'NEWSLETTER'
}

export enum TargetSite {
  Ifn = 'IFN',
  Hub = 'HUB',
  Vote = 'VOTE',
  Foodweb = 'FOODWEB',
  Foodserviceweb = 'FOODSERVICEWEB',
  Dolcesalato = 'DOLCESALATO',
  Newsletter = 'NEWSLETTER'
}

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Team = Node & {
  __typename?: 'Team';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  users: Array<User>;
};

/** A connection to a list of items. */
export type TeamConnection = {
  __typename?: 'TeamConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TeamCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {} */
  Retailer?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: 'TeamEdge';
  /** The item at the end of the edge */
  node?: Maybe<Team>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TeamPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type ToS = Node & {
  __typename?: 'ToS';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ToSConnection = {
  __typename?: 'ToSConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ToSEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ToSCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type ToSEdge = {
  __typename?: 'ToSEdge';
  /** The item at the end of the edge */
  node?: Maybe<ToS>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ToSPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  description?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtra?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionExtraIta?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChanges?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  descriptionChangesIta?: Maybe<Scalars['String']>;
};

/** Transformation levels */
export enum TransformationLevel {
  Unprepared = 'UNPREPARED',
  Prepared = 'PREPARED',
  PreparedNotReadyToEat = 'PREPARED_NOT_READY_TO_EAT',
  PreparedReadyToEat = 'PREPARED_READY_TO_EAT'
}

/** {"canCreateRoles":{"INTERNAL":"MARKETING_BASE"},"canPatchRoles":{"INTERNAL":"MARKETING_BASE"},"canDeleteRoles":{"INTERNAL":"MARKETING_BASE"}} */
export type Typeform = Node & {
  __typename?: 'Typeform';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  targetSite: TargetSite;
  /** {} */
  name: Scalars['String'];
  /** {} */
  TypeformId?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type TypeformConnection = {
  __typename?: 'TypeformConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TypeformEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TypeformCreateInput = {
  /** {} */
  targetSite: TargetSite;
  /** {} */
  name: Scalars['String'];
  /** {} */
  TypeformId?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>;
};

/** An edge in a connection. */
export type TypeformEdge = {
  __typename?: 'TypeformEdge';
  /** The item at the end of the edge */
  node?: Maybe<Typeform>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type TypeformFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  hasOpenResults?: Maybe<Scalars['Boolean']>;
  targetSite?: Maybe<TargetSite>;
};

export type TypeformPatchInput = {
  /** {} */
  targetSite?: Maybe<TargetSite>;
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {} */
  TypeformId?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsStartDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  resultsExpirationDate?: Maybe<Scalars['DateTime']>;
};



/** {"canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type User = Node & {
  __typename?: 'User';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  Contact?: Maybe<Contact>;
  /** {} */
  campaignMemberships: Array<CampaignMember>;
  /** {} */
  firstName?: Maybe<Scalars['String']>;
  /** {} */
  lastName?: Maybe<Scalars['String']>;
  /** {} */
  name: Scalars['String'];
  /** {"readonly":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'];
  /** {} */
  socialType?: Maybe<SocialType>;
  /** {} */
  role: UserRole;
  /** {} */
  status: UserStatus;
  /** {} */
  approvedToS?: Maybe<ToS>;
  /** {} */
  newsletterAccepted: Array<NewsletterAcceptedFlag>;
  /** {"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  workEmail?: Maybe<Scalars['Email']>;
  /** {} */
  langCode?: Maybe<Scalars['String']>;
  /** {} */
  internalCapabilities: Array<InternalCapability>;
  /** {"dtPrecision":"FULL"} */
  createdOn?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"FULL"} */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** {} */
  Team?: Maybe<Team>;
  /** {} */
  userAchievements: Array<UserAchievement>;
  /** {} */
  managedByUsers: Array<ManagedByUser>;
  /** {} */
  Prospect?: Maybe<Prospect>;
};


/** {"canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserCampaignMembershipsArgs = {
  campaigns?: Maybe<Array<RefInputObject>>;
  esiti?: Maybe<Array<CampaignMember_Esito_Campaign__C_Enum>>;
};


/** {"canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserUserAchievementsArgs = {
  achievements?: Maybe<Array<Scalars['String']>>;
};


/** {"canCreateRoles":{"INTERNAL":true},"canPatchRoles":{"INTERNAL":true},"canDeleteRoles":{"INTERNAL":true},"canSetStatusRoles":{"status":{"NOT_VERIFIED":{"INTERNAL":true},"WAITING_REVIEW":{"INTERNAL":true},"ACTIVE":{"INTERNAL":true},"DISABLED":{"INTERNAL":true},"DELETED":{"INTERNAL":true}}}} */
export type UserManagedByUsersArgs = {
  currentOnly?: Maybe<Scalars['Boolean']>;
};

/** {"canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true}} */
export type UserAchievement = Node & {
  __typename?: 'UserAchievement';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  achievement: Scalars['String'];
  /** {} */
  timestamp: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type UserAchievementConnection = {
  __typename?: 'UserAchievementConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAchievementEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserAchievementCreateInput = {
  /** {} */
  achievement: Scalars['String'];
};

/** An edge in a connection. */
export type UserAchievementEdge = {
  __typename?: 'UserAchievementEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserAchievement>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type UserAchievementFilterInput = {
  User?: Maybe<Array<RefInputObject>>;
  achievements?: Maybe<Array<Scalars['String']>>;
  timespanMin?: Maybe<Scalars['DateTime']>;
  timespanMax?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserCreateInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  firstName?: Maybe<Scalars['String']>;
  /** {} */
  lastName?: Maybe<Scalars['String']>;
  /** {"readonly":true,"formatRegexStr":"^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$","forceCase":"LOWER"} */
  email: Scalars['Email'];
  /** {} */
  role: UserRole;
  /** {} */
  newsletterAccepted?: Maybe<Array<NewsletterAcceptedFlag>>;
  /** {} */
  internalCapabilities?: Maybe<Array<InternalCapability>>;
  /** {} */
  Team?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type UserFilterInput = {
  /** Contained in Full Name */
  name?: Maybe<Scalars['String']>;
  /** Contained in Full Name or E-mail or Work-email */
  nameOrEmail?: Maybe<Scalars['String']>;
  /** Contained in E-mail or Work-email */
  email?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  statuses?: Maybe<Array<UserStatus>>;
};

/** {"canCreateRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"ANONYMUS":true},"canPatchRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true},"canDeleteRoles":{"INTERNAL":"MARKETING_EXTRA","PRODUCER":"MANAGE","CONSUMER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true}} */
export type UserNote = Node & {
  __typename?: 'UserNote';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  Team?: Maybe<Team>;
  /** {} */
  isPrivate: Scalars['Boolean'];
  /** {"max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"FULL"} */
  timestamp: Scalars['DateTime'];
  /** {} */
  isAbandoned: Scalars['Boolean'];
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  Good?: Maybe<Good>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  Service?: Maybe<Service>;
  /** {} */
  FoodService?: Maybe<FoodService>;
};

/** User note possible collections */
export enum UserNoteCollection {
  Company = 'Company',
  Good = 'Good',
  Retailer = 'Retailer',
  CompanyGroup = 'CompanyGroup',
  Service = 'Service',
  FoodService = 'FoodService'
}

/** A connection to a list of items. */
export type UserNoteConnection = {
  __typename?: 'UserNoteConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserNoteEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserNoteCreateInput = {
  /** {} */
  isPrivate: Scalars['Boolean'];
  /** {"max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
  workEmail?: Maybe<Scalars['Email']>;
};

/** An edge in a connection. */
export type UserNoteEdge = {
  __typename?: 'UserNoteEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNote>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type UserNoteFilterInput = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  isAbandoned?: Maybe<Scalars['Boolean']>;
  User?: Maybe<RefInputObject>;
  Team?: Maybe<RefInputObject>;
  collection?: Maybe<UserNoteCollection>;
  Object?: Maybe<RefInputObject>;
  timestampMin?: Maybe<Scalars['DateTime']>;
  timestampMax?: Maybe<Scalars['DateTime']>;
};

export type UserNotePatchInput = {
  /** {} */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** {"max":2147483647,"min":-2147483647} */
  vote?: Maybe<Scalars['Int']>;
  /** {} */
  note?: Maybe<Scalars['String']>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
};

/** {"canCreateRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true},"canPatchRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true},"canDeleteRoles":{"INTERNAL":true,"PRODUCER":true,"BUYER":true,"BUYER_PL_ITALIA":true,"FOODSERVICE":true,"SHOPWORKER":true,"SERVICE":true,"CONSUMER":true}} */
export type UserObjectSubscription = Node & {
  __typename?: 'UserObjectSubscription';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  User: User;
  /** {} */
  flags: Array<UserObjectSubscriptionFlag>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  Good?: Maybe<Good>;
  /** {} */
  Retailer?: Maybe<Retailer>;
  /** {} */
  CompanyGroup?: Maybe<CompanyGroup>;
  /** {} */
  Service?: Maybe<Service>;
  /** {} */
  FoodService?: Maybe<FoodService>;
};

/** A connection to a list of items. */
export type UserObjectSubscriptionConnection = {
  __typename?: 'UserObjectSubscriptionConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserObjectSubscriptionEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserObjectSubscriptionCreateInput = {
  /** {} */
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type UserObjectSubscriptionEdge = {
  __typename?: 'UserObjectSubscriptionEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserObjectSubscription>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type UserObjectSubscriptionFilterInput = {
  User?: Maybe<RefInputObject>;
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>;
  collection?: Maybe<UserNoteCollection>;
  Object?: Maybe<RefInputObject>;
};

/** // TODO */
export enum UserObjectSubscriptionFlag {
  Bookmark = 'BOOKMARK',
  Follow = 'FOLLOW'
}

export type UserObjectSubscriptionPatchInput = {
  /** {} */
  flags?: Maybe<Array<UserObjectSubscriptionFlag>>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  Good?: Maybe<RefInputObject>;
  /** {} */
  Retailer?: Maybe<RefInputObject>;
  /** {} */
  CompanyGroup?: Maybe<RefInputObject>;
  /** {} */
  Service?: Maybe<RefInputObject>;
  /** {} */
  FoodService?: Maybe<RefInputObject>;
};

export enum UserOrderBy {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Status = 'status',
  Role = 'role',
  CreatedOn = 'createdOn',
  LastLogin = 'lastLogin'
}

export type UserPatchInput = {
  /** {"max":18,"min":18} */
  sfId?: Maybe<Scalars['String']>;
  /** {} */
  firstName?: Maybe<Scalars['String']>;
  /** {} */
  lastName?: Maybe<Scalars['String']>;
  /** {} */
  role?: Maybe<UserRole>;
  /** {} */
  newsletterAccepted?: Maybe<Array<NewsletterAcceptedFlag>>;
  /** {} */
  internalCapabilities?: Maybe<Array<InternalCapability>>;
  /** {} */
  Team?: Maybe<RefInputObject>;
};

/** User possible roles */
export enum UserRole {
  Anonymus = 'ANONYMUS',
  Internal = 'INTERNAL',
  Producer = 'PRODUCER',
  Buyer = 'BUYER',
  BuyerPlItalia = 'BUYER_PL_ITALIA',
  Foodservice = 'FOODSERVICE',
  Shopworker = 'SHOPWORKER',
  Service = 'SERVICE',
  Consumer = 'CONSUMER'
}

/** User statuses */
export enum UserStatus {
  NotVerified = 'NOT_VERIFIED',
  WaitingReview = 'WAITING_REVIEW',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

/** User possible relations used for visibility */
export enum VisibilityRelations {
  LinkedToFair = 'LINKED_TO_FAIR',
  LinkedToAwardCandidacy = 'LINKED_TO_AWARD_CANDIDACY',
  LinkedToAwardEvent = 'LINKED_TO_AWARD_EVENT',
  LinkedToAwardVote = 'LINKED_TO_AWARD_VOTE',
  LinkedToCompany = 'LINKED_TO_COMPANY',
  LinkedToGeoIndication = 'LINKED_TO_GEO_INDICATION',
  TargetForFair = 'TARGET_FOR_FAIR',
  TargetForAwardCandidacy = 'TARGET_FOR_AWARD_CANDIDACY',
  TargetForAwardEvent = 'TARGET_FOR_AWARD_EVENT',
  TargetForAwardVote = 'TARGET_FOR_AWARD_VOTE',
  TargetForCompany = 'TARGET_FOR_COMPANY',
  TargetForGeoIndication = 'TARGET_FOR_GEO_INDICATION'
}

/** User possible roles used for visibility */
export enum VisibilityRole {
  AnonymusOnly = 'ANONYMUS_ONLY',
  UserOnly = 'USER_ONLY',
  ProducerOnly = 'PRODUCER_ONLY',
  BuyerOnly = 'BUYER_ONLY'
}

export enum VisibilityType {
  Anonymus = 'ANONYMUS',
  User = 'USER',
  Pardot = 'PARDOT',
  Typeform = 'TYPEFORM',
  SfItem = 'SF_ITEM',
  SfCampaign = 'SF_CAMPAIGN',
  SfCampaignPresente = 'SF_CAMPAIGN_PRESENTE'
}

/** {"canCreateRoles":{"INTERNAL":"ADMIN_EXTRA"},"canPatchRoles":{"INTERNAL":"ADMIN_EXTRA"},"canDeleteRoles":{"INTERNAL":"ADMIN_EXTRA"}} */
export type Widget = Node & {
  __typename?: 'Widget';
  /** {} */
  id: Scalars['ID'];
  /** {} */
  localId?: Maybe<Scalars['Int']>;
  /** {} */
  name: Scalars['String'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  targetSite: TargetSite;
  /** {} */
  locations: Array<WidgetLocation>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'];
  /** {} */
  gridSize: GridSize;
  /** {} */
  styleType: WidgetStyleType;
  /** {} */
  styleColor: WidgetStyleColor;
  /** {} */
  styleEditorialSection?: Maybe<EditorialSection>;
  /** {} */
  styleAdditionalClasses?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>;
  /** {"max":255} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>;
  /** {"max":255} */
  footerUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  query?: Maybe<Scalars['String']>;
  /** {} */
  entitiesPath?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  titleRendered?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  footerRendered?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  bodyRendered?: Maybe<Scalars['String']>;
  /** {} */
  entities?: Maybe<Array<Node>>;
  /** {} */
  entitiesType?: Maybe<Scalars['String']>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<Company>;
  /** {} */
  GeoIndication?: Maybe<GeoIndication>;
  /** {} */
  Fair?: Maybe<Fair>;
  /** {} */
  Award?: Maybe<Award>;
};

/** A connection to a list of items. */
export type WidgetConnection = {
  __typename?: 'WidgetConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WidgetEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type WidgetCreateInput = {
  /** {} */
  name: Scalars['String'];
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate: Scalars['DateTime'];
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  targetSite: TargetSite;
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority: Scalars['Int'];
  /** {} */
  gridSize: GridSize;
  /** {} */
  styleType: WidgetStyleType;
  /** {} */
  styleColor: WidgetStyleColor;
  /** {} */
  styleEditorialSection?: Maybe<RefInputObject>;
  /** {} */
  styleAdditionalClasses?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>;
  /** {"max":255} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>;
  /** {"max":255} */
  footerUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  query?: Maybe<Scalars['String']>;
  /** {} */
  entitiesPath?: Maybe<Scalars['String']>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Award?: Maybe<RefInputObject>;
};

/** An edge in a connection. */
export type WidgetEdge = {
  __typename?: 'WidgetEdge';
  /** The item at the end of the edge */
  node?: Maybe<Widget>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** @TODO DESCRIBE ME */
export type WidgetFilterInput = {
  /** Contained in name */
  name?: Maybe<Scalars['String']>;
  /** Started and not expired at */
  targetDate?: Maybe<Scalars['DateTime']>;
  targetSite?: Maybe<TargetSite>;
  location?: Maybe<WidgetLocation>;
  Company?: Maybe<RefInputObject>;
  GeoIndication?: Maybe<RefInputObject>;
  Fair?: Maybe<RefInputObject>;
  Award?: Maybe<RefInputObject>;
};

/** // TODO */
export enum WidgetLocation {
  Homepage = 'HOMEPAGE',
  Dashboard = 'DASHBOARD',
  EditorialSection = 'EDITORIAL_SECTION',
  FairDirectory = 'FAIR_DIRECTORY',
  SupplierDirectory = 'SUPPLIER_DIRECTORY',
  ProductDirectory = 'PRODUCT_DIRECTORY'
}

export enum WidgetOrderBy {
  Name = 'name',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  OrderPriority = 'orderPriority'
}

export type WidgetPatchInput = {
  /** {} */
  name?: Maybe<Scalars['String']>;
  /** {"dtPrecision":"DAY_BEGIN"} */
  startDate?: Maybe<Scalars['DateTime']>;
  /** {"dtPrecision":"DAY_END"} */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** {} */
  targetSite?: Maybe<TargetSite>;
  /** {} */
  locations?: Maybe<Array<WidgetLocation>>;
  /** {"max":2147483647,"min":-2147483647} */
  orderPriority?: Maybe<Scalars['Int']>;
  /** {} */
  gridSize?: Maybe<GridSize>;
  /** {} */
  styleType?: Maybe<WidgetStyleType>;
  /** {} */
  styleColor?: Maybe<WidgetStyleColor>;
  /** {} */
  styleEditorialSection?: Maybe<RefInputObject>;
  /** {} */
  styleAdditionalClasses?: Maybe<Scalars['String']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  title?: Maybe<Scalars['String']>;
  /** {"max":255} */
  titleUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  footer?: Maybe<Scalars['String']>;
  /** {"max":255} */
  footerUrl?: Maybe<Scalars['URL']>;
  /** {"html":true,"htmlLevel":"FULL"} */
  body?: Maybe<Scalars['String']>;
  /** {"multiline":true} */
  query?: Maybe<Scalars['String']>;
  /** {} */
  entitiesPath?: Maybe<Scalars['String']>;
  /** {} */
  visibilityByRole?: Maybe<VisibilityRole>;
  /** {} */
  visibilityByRelation?: Maybe<VisibilityRelations>;
  /** {} */
  visibilityByMinLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMaxLevel?: Maybe<CompanyLevelEnum>;
  /** {} */
  visibilityByMinCapability?: Maybe<Capability>;
  /** {} */
  Company?: Maybe<RefInputObject>;
  /** {} */
  GeoIndication?: Maybe<RefInputObject>;
  /** {} */
  Fair?: Maybe<RefInputObject>;
  /** {} */
  Award?: Maybe<RefInputObject>;
};

/** // TODO */
export enum WidgetStyleColor {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Alt = 'ALT',
  Alt2 = 'ALT2',
  ByEditorialSection = 'BY_EDITORIAL_SECTION',
  Gradient1 = 'GRADIENT1',
  Gradient2 = 'GRADIENT2'
}

/** // TODO */
export enum WidgetStyleType {
  Bordered = 'BORDERED',
  Unboxed = 'UNBOXED',
  Magazine = 'MAGAZINE',
  Big_4Small = 'BIG_4SMALL',
  Carusel = 'CARUSEL'
}

/** @TODO DESCRIBE ME */
export type WidgetViewInput = {
  locations?: Maybe<Array<WidgetLocation>>;
};
