import gql from 'graphql-tag';

export const GoodListQuery = gql`
	query GoodListQuery(
		$first: Int
		$cursor: String
		$orderBy: GoodOrderBy
		$orderDesc: Boolean
		$filter: GoodFilterInput
	) {
		entities: goods(
			first: $first
			after: $cursor
			orderBy: $orderBy
			orderDesc: $orderDesc
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					name
					nameIta
					ingredientsImageItaMedia {
						 id
					}
					ingredientsImageMedia {
						 id
					}
					Brand {
						id
						localId
						name
						Company {
							id
						}
					}
					formats {
						id
						ean
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
