/**
 * Componente per dare il via alla catena di operazioni necessarie a renderizzare una lista di entita'.
 *
 * La catena di realizzazione liste e' sempre stata trascurata rispetto alle sue sorelle per dettaglio e manipolazione
 * entita'; a causa di questo fenomeno questa porzione di codice risulta datata e poco coerente col resto.
 * Qui non vengono usati IField ma un altro tipo vecchio di tipizzazione (molto simile), il componente e' basato su una
 * classe, le tipizzazioni sono vecchie... e cosi' via.
 *
 * TODO Reimplementare il sistema di liste entita' su IFields
 * TODO Reimplementare EntityListFetcher come componente funzionale
 *
 */

import React from 'react';
import { identity } from 'ramda';
import CONFIG from '../static/config';
import {
	generateCumulativeFilterQuery,
	generateDefaultFragment,
	offsetToCursor,
	pageToEntities,
} from '../utils/graphql';
import { DefaultEntityList, DefaultEntityListProps } from './DefaultEntityList';
import { t } from '../utils/labels';
import { Spinner } from './MainContentSpinner';
import { withRouter } from 'react-router-dom';
import { generateFilterFieldList } from '../utils/filterFields';
import {
	generateObjectFieldList,
	setFieldsValues,
} from '../utils/objectFields';
import {
	calculateUserPermissions,
	parsePermissions,
	Permission,
} from '../utils/permissions';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import {
	changeFiltersInSearch,
	searchToObject,
	setPageNumberInSearch,
} from '../utils/routeHistory';
// import { toRefInput } from '../utils/dataTrasform';
import { pluralEntityLabel } from '../utils/misc';
import { ACTIONS, generateUrl } from '../utils/urls';
import { entity2String } from '../utils/toString';
import { Query } from 'react-apollo';
import { UserData } from '@food/auth';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';
import { ENTITY } from '../utils/entities';
import { AsyncChoiceFieldType, Entity, IField } from '../types/form';
import { ErrorPolicy } from 'apollo-client';
import { Action } from '../types/ui';

export interface ICustomField {
	header: string;
	field: (entity: any) => any;
}

// TODO Commentare tipizzazione props EntityListFetcher
export interface EntityListFetcherProps extends RouteComponentProps {
	// introspezione dell'entita' mostrata in lista
	typeIntro: any;

	// frammento graphql da inserire nella query per i vari campi
	fragment?: any;

	// query graphql per ottenere i dati
	query: any;
	additionalVariables?: any; // da usare nel caso la query richieda ulteriori filtri
	paginated?: boolean; // indica se la query prende entita' paginate o semplici
	extractor?: (data: any) => any; // funzione per trasformare i dati grezzi della query in una lista di dati per l'entita'
	customFields?: ICustomField[]; // eventuale lista di campi personalizzati
	errorPolicy?: ErrorPolicy; // eventuale forzatura della error policy di apollo
	filtersIntro?: any; // eventuale introspezione del tipo di input dei filtri
	editOnly?: boolean; // se true non mostra la modale di dettaglio ma solo quella di modifica
	denyAdd?: boolean; // serve per forzare il nascondere del pulsante di creazione
	actions?: ReadonlyArray<Action>; // possibile lista di azioni da visualizzare nell'alto della lista
	isModal?: boolean; // determina alcuni cambiamenti perche' si e' in una modale
	customAddLabel?: string; // quello da far vedere nel pulsante per aggiungere entita'
	customDeleteLabel?: string; // quello da far vedere nel pulsante per rimuovere l'entita'
	post?: (
		queryResult: any,
		addModalOpener: (id?: string | null) => void,
	) => JSX.Element; // componente opzionale da mostrare dopo la lista di campi. L'id del modal opener serve nel caso si apra la modale di creazione con un item da clonare
	inlineDelete?: boolean; // decide se mostrare il pulsante di delete nella riga dell'entita'
	hideTop?: boolean; // decide se nascondere la parte superiore della lista
	hideBottom?: boolean; // decide se nascodere la parte inferiore del componente lista
	hideTitle?: boolean; // decide se nascodere il titolo del componente lista
	hideEmptyMessage?: boolean; // se true non mostra alcun messaggio nel caso di liste vuote
	transformer?: (fields: ReadonlyArray<IField>) => ReadonlyArray<IField>; // utilizzato per modificare i field ricevuti in ingresso
	className?: string; // classe css opzionale da passare al componente visualizzato
	modal?: boolean; // identifica il caso in cui si sia in una modale
	sortables?: ReadonlyArray<string>;
	originalEntity?: Entity;
	addModalOpener?: () => void;
	filterFields?: string[];
	additionalActions?: ReadonlyArray<Action>;
	detailUrl?: (entity: any) => string;
	detailModalMode?: boolean;
	createModalMode?: boolean;
	detailHandler?: () => void;
	pageSize?: number;
	forcedPermissions?: Permission;
	entityLabel?: string;
	entityName: ENTITY;
	secondary?: boolean;
	headers: ReadonlyArray<string>;
	filterTransform?: (fields: ReadonlyArray<IField>) => ReadonlyArray<IField>;
	children?: (args: DefaultEntityListProps) => JSX.Element;
	detailModalOpener?: (entity: any) => void;
}

/**
 * Funzione per tradurre gli IField dei filtri in un oggetto compatibile con variabili GraphQL di filtro liste.
 *
 * Nota: la tipizzazione è carente e non sono sicuro che ricevano veramente IFields.
 * @param fields: ReadonlyArray<IField>
 * @return any
 */
const filterFieldsToFilterInput = (fields: ReadonlyArray<IField>): any => {
	return fields
		.filter((field) => field.value !== null && field.value !== undefined)
		.reduce((obj, field) => {
			/*
			Una volta i filtri utilizzavano un proprio sistema di trasformazione per gestire il passaggio da valori impostati
			dai componenti a dati compatibili con gli argomenti dei filtri GraphQL. Grazie ad una PARZIALE migrazione al sistema
			di IFields riusciamo ad appoggiarci ai `beforeSaveTransformer` assegnati in fase di generazione dei singoli campi.
			Questo non vale per i booleani, i quali utilizzano un componente di input diverso rispetto alla versione di
			manipolazione entità (i filtri usano una select, le modifiche una checkbox), e dove quindi è necessario operare
			una modifica speciale.
			
			Il `beforeSaveTransformer` in realtà può avere anche compiti di trasformazione eccezionali, come per esempio accade
			con il filtro `Object` degli `ObjectHistory`. Va quindi considerato necessario, anche se in passato era stato
			cancellato visto lo scarso utilizzo nella base di codice.
			*/
			let tmp = field.value;

			if (field.type === 'Boolean') {
				tmp = tmp.value === 'true';
			}

			obj[field.name] = (field.beforeSaveTransformer || identity)(tmp);

			return obj;
		}, {});
};

class EntityListFetcher extends React.Component<
	EntityListFetcherProps & RouteComponentProps<{}>,
	{ filterValues: any; forcedPage?: number }
> {
	private readonly parsedFilters: any[];

	constructor(props) {
		super(props);
		const { entityName, filtersIntro, filterFields, filterTransform } = props;
		const fn = filterTransform || identity;

		this.parsedFilters = filterFields
			? fn(
					generateFilterFieldList(
						filterFields,
						filtersIntro.inputFields,
						entityName,
					),
			  )
			: [];

		this.state = {
			filterValues: {},
		};
	}

	generateFilterInput(filters: ReadonlyArray<IField>) {
		// questi sono tutti e solo i campi che richiedono di ottenere informazioni dal server
		const asyncChoices = filters.filter(
			(f): f is AsyncChoiceFieldType => f.type === 'AsyncChoices' && f.value,
		);
		const filterValues = asyncChoices.reduce<
			{
				name: string;
				entityType: ENTITY;
				id: string;
			}[]
		>((list, f) => {
			const ids = f.single ? [f.value.value] : f.value.split(',');
			ids.forEach((id) => {
				list.push({
					name: f.name,
					entityType: f.entityType,
					id,
				});
			});
			return list;
		}, []);

		return filterValues.length > 0
			? generateCumulativeFilterQuery(filterValues)
			: gql`
					query Dummy {
						__type
					}
			  `;
	}

	_changePage = (newPage: number) => {
		const {
			history,
			entityName,
			location: { search },
			secondary,
		} = this.props;

		if (secondary) {
			this.setState({ forcedPage: newPage });
		} else {
			history.push(
				generateUrl(entityName, ACTIONS.LIST) +
					setPageNumberInSearch(search, newPage),
			);
		}
	};

	_valorizeParsedFilters = (searchParams) => {
		const { filterTransform } = this.props;
		const valorizedFilterFields = setFieldsValues(
			this.parsedFilters,
			searchParams,
			true,
		);

		return filterTransform
			? filterTransform(valorizedFilterFields)
			: valorizedFilterFields;
	};

	_setFilters = (filterValues) => {
		const {
			location: { search, pathname },
			history,
		} = this.props;

		// trasformo i valori del filtro in un formato adatto agli url
		const newSearch = changeFiltersInSearch(search, filterValues);

		history.push(pathname + setPageNumberInSearch(newSearch, 1));
	};

	render() {
		const {
			entityName,
			entityLabel,
			typeIntro,
			headers,
			forcedPermissions,
			fragment,
			query,
			additionalVariables,
			location: { search },
			pageSize,
			customFields,
			errorPolicy,
			additionalActions,
			detailModalMode,
			detailUrl,
			children,
			detailModalOpener,
			secondary,
			addModalOpener,
			createModalMode,
			transformer,
			post,
			hideTitle,
			hideTop,
			hideBottom,
			hideEmptyMessage,
		} = this.props;
		const { forcedPage } = this.state;
		const title = entityLabel || pluralEntityLabel(entityName);
		const searchParams: any = searchToObject(search);
		const page = forcedPage || Number(searchParams.page) || 1;
		const filters = this._valorizeParsedFilters(searchParams);

		// puo' essere che alcuni campi del filtro richiedano una query per poter eseguire la query originale e infine
		// popolare il loro elemento di input;
		const filterNeedsQuery = filters.some(
			(f) => f.type === 'AsyncChoices' && f.value,
		);

		return (
			<Query<any, any>
				query={this.generateFilterInput(filters)}
				skip={!filterNeedsQuery}
			>
				{({ error, loading, data }) => {
					if (error) {
						const errs = parseGraphqlError(error);
						sentryHandler(errs);
						modalHandler(errs);
						return null;
					}

					if (loading && filterNeedsQuery) {
						return null;
					}

					const newFilters = filters.map((field) => {
						if (field.type === 'AsyncChoices' && field.value) {
							const keys = Object.keys(data).filter((key) =>
								key.startsWith(field.name),
							);
							if (field.single) {
								const key = keys[0];
								const entity = data[key];
								const name = entity2String(entity.__typename, entity);
								field.value = {
									label: name,
									value: entity,
								};
							} else {
								field.value = keys.map((key) => {
									const entity = data[key];
									const name = entity2String(
										entity.__typename,
										entity,
									);
									return {
										label: name,
										value: entity,
									};
								});
							}
						}
						return field;
					});

					return (
						<UserData>
							{(user) => {
								const { userData, capabilities, loading } = user;

								if (loading) {
									return <Spinner show={true} />;
								}

								const permissions = calculateUserPermissions(
									capabilities,
									userData.role,
									parsePermissions(typeIntro.description),
									forcedPermissions,
								);

								const structure = generateObjectFieldList(
									user,
									headers,
									typeIntro,
									true,
								);

								const finalFragment =
									fragment || generateDefaultFragment(entityName);

								const finalQuery =
									typeof query === 'function'
										? query(finalFragment)
										: query;
								const offset =
									(page - 1) * (pageSize || CONFIG.ENTITIES_PER_PAGE) -
									1;
								// const order = getOrderFromSearchString(search);

								return (
									<Query<any, any>
										query={finalQuery}
										errorPolicy={errorPolicy}
										fetchPolicy={'cache-and-network'}
										variables={{
											first: pageSize || CONFIG.ENTITIES_PER_PAGE,
											cursor: offsetToCursor(offset),
											// orderBy: order.field,
											// orderDesc: order.direction === 'DESC',
											filter: filterFieldsToFilterInput(filters),
											...additionalVariables,
										}}
									>
										{({ error, loading, data, refetch }) => {
											if (loading) {
												return <Spinner show={true} />;
											}

											if (
												error &&
												errorPolicy !== 'all' &&
												errorPolicy !== 'ignore'
											) {
												const errContext = {
													entityName,
												};
												const errs = parseGraphqlError(
													error,
													errContext,
												);
												sentryHandler(errs);
												modalHandler(errs);
												return null;
											}

											const { paginated, extractor } = this.props;
											const dataExtractor =
												extractor ||
												((data) => (data ? data.entities : null));
											const entities = dataExtractor(data);
											const entityList =
												paginated === false
													? entities
													: pageToEntities(entities);

											const label =
												entityLabel ||
												pluralEntityLabel(entityName);

											const R =
												children ||
												((args: DefaultEntityListProps) => (
													<DefaultEntityList {...args} />
												));

											return R({
												headers,
												entityName,
												detailModalOpener,
												entities,
												customFields,
												permissions,
												records: entityList
													? entityList.map((e) => ({
															...e,
															original: e,
													  }))
													: [],
												pageInfo: {
													slice:
														pageSize || CONFIG.ENTITIES_PER_PAGE,
													page,
													total:
														paginated === false || !entities
															? 0
															: entities.total,
													// sortBy: order.field,
													// sortDirection: order.direction,
												},
												// changeOrder: this._changeOrder,
												changePage: this._changePage,
												setFilters: this._setFilters,
												breadcrumbs: [
													{ label: t`home`, path: '/' },
													{ label: t(label) },
												],
												title: t(title),
												entityStructure: structure,
												filters: newFilters,
												user,
												actions: additionalActions,
												detailModalMode,
												detailUrl,
												secondary,
												paginated,
												addModalOpener,
												createModalMode,
												refetch,
												transformer,
												post,
												hideTitle,
												hideTop,
												hideBottom,
												hideEmptyMessage,
											});
										}}
									</Query>
								);
							}}
						</UserData>
					);
				}}
			</Query>
		);
	}
}

export default withRouter<EntityListFetcherProps, any>(EntityListFetcher);
