/**
 *
 * "Dio ti aiuti, vecchio, i tuoi pensieri hanno creato una creatura dentro di te;
 * e colui che l'intensità del pensiero rende un Prometeo, di quel cuore per sempre si ciberà un avvoltoio;
 * quell'avvoltoio è la creatura stessa che egli crea."
 *                                                         Herman Melville, Moby Dick
 *
 * Questo componente si occupa di implementare le logiche per l'utilizzo di un form e di fornirne le primitive ai componenti sottostanti. Frutto di molto lavoro parziale e temporalmente sconnesso, risulta oggi comunque una buona risposta alle nostre necessita'. Si basa completamente sugli IField per il suo funzionamento ma all'interno contiene logiche basate su "path" (stringhe dinamiche per identificare i singoli campi). Permette di gestire sotto-form, anche di tipo eterogeneo, e ad oggi non esiste un caso interno che non copra. Rimangono tuttavia molte questioni aperte, come per esempio le effettive prestazioni del sistema e un potenziale refactor completamente basato su path.
 *
 * Il componente Form detiene i valori assunti dagli IField come copie degli IField originali valorizzati. Nonostante mantenere solamente i valori senza duplicare dagli IField possa sembrare una strutturazione piu' pulita e sensata, abbiamo scoperto che non e' sufficiente ai nostri scopi. Purtroppo i valori che i campi assumono possono avere effetti sulla struttura dei form, quindi entrambi questi due elementi risultano dinamici e indissolubilmente legati. Dobbiamo ancora trovare una soluzione per questo inconveniente ma, detto questo, la strutturazione attuale ha dimostrato di essere migliore per le nostre necessita'.
 *
 * Va fatta una spiegazione su cosa sono i "path" e come si collegano fra di loro gli IField. Nel nostro sistema di form un IField in realta' puo' essere una lista trascinabile di IFields, anche eterogenei fra loro, i quali a loro volta possono essere liste di IField, i quali a loro volta... hai capito. Questo fatto crea uno strano misto fra struttura e valori di un form poiche' l'IField "lista di news" per poter avere $n sottocampi deve inserire questa informazione all'interno del proprio valore. Per avere un modo univoco di identificare un IField all'interno di questa struttura dinamica usiamo i path, i quali non sono altro che stringhe che, man mano che si scende nell'albero dei componenti React, si arricchisce passando di IField in IField. Vorremmo, un giorno, basarci solamente sui path per i funzionamenti interni di Form, ma per il momento servono solo per gli errori e per i campi "touched". Purtroppo ancora non abbiamo in mente un'architettura chiara su come organizzare questo cambiamento.
 *
 * Un'altra nota importante: Form definisce un context per fornire a componenti molto annidati nell'albero gerarchico tutte le primitive necessarie al loro funzionamento. I Context Reac... TODO finire documentazione form
 */

import React, { useCallback, useEffect, useState } from 'react';
import { union, fromPairs, identity } from 'ramda';
import { FieldSetter, FormContainer, IField } from '../types/form';
import { assign } from '../utils/misc';
import { getAllPaths, validateFieldValue } from '../utils/formErrors';
import { FormContext } from '../utils/context/form';

interface FieldValues {
	[key: string]: any;
}

interface FormProps {
	// gli IField di cui sara' composto il form e su cui si basera' il suo stato interno
	fields: ReadonlyArray<IField>;
	
	// il componente figlio del Form, a cui vengono esposte le primitive create tramite il primo argomento di una funzione
	children: (args: FormContainer) => JSX.Element | null;
	
	// beforeSubmit?: (values: FieldValues) => boolean;
	
	// se si tratta di una creazione o di una modifica - ci sono alcuni cambiamenti per come mandiamo i dati al server
	create?: boolean;
	
	// funzione richiamata quando viene inviato il form - opzionale perche' ci sono altri modi di ottenere i dati e usarli rispetto all'invio del form
	onSubmit?: (
		fields: ReadonlyArray<IField>,
		values: FieldValues,
		injectErrors: (errors: { [key: string]: ReadonlyArray<Error> }) => void,
	) => void;
	
	// funzione richiamata quando il server non accetta l'invio del form.
	// NOTA BENE: questo significa che gli errori scatenati dalla validazione client-side non avviano questa callback; in realta' non sono proprio intercettabili al momento
	onFailedSubmit?: (
		fields: ReadonlyArray<IField>,
		validationErrors: { [key: string]: ReadonlyArray<Error> },
	) => void;
	
	// funzione richiamata ogni qual volta cambia il valore di un campo del form
	onChange?: (
		field: IField,
		value: any,
		mutationErrors: { [key: string]: ReadonlyArray<Error> },
		fields: ReadonlyArray<IField>,
		values: FieldValues,
		submit: () => Promise<void>,
	) => void;
}

interface FormState {
	fields: ReadonlyArray<IField>;
	errors: { [key: string]: ReadonlyArray<Error> };
	touched: ReadonlyArray<string>;
	values: FieldValues;
}

export const Form: React.FC<FormProps> = ({
	fields: propFields,
	children,
	onSubmit,
	onFailedSubmit,
	onChange,
	create = true,
}) => {
	const [state, setState] = useState<FormState>({
		fields: propFields,
		errors: {},
		touched: [],
		values: propFields.reduce((tot, field) => {
			tot[field.name] = field.value;
			return tot;
		}, {}),
	});
	
	// Utilizzo questo effetto per aggiornare il form al cambiamento dei field passati come props. Se devo aggiornare i campi, allora ri-eseguo il codice di inizializzazione stato (resettando valori ed errori) onestamente non ricordo come mai facessi questa operazione, che a sentirla cosi' potrebbe sembrare pericolosa; ricordo pero' distintamente che fu necessario inserirla, quindi la tratto ancora oggi come una cosa necessaria
	useEffect(() => {
		setState({
			fields: propFields,
			errors: {},
			touched: [],
			values: propFields.reduce((tot, field) => {
				tot[field.name] = field.value;
				return tot;
			}, {}),
		});
	}, [propFields]);

	const { fields, errors, touched, values } = state;

	// Metodo utilizzato per iniettare eventuali errori provenienti da sorgenti esterne (API Graphql per esempio) all'interno dello stato della form. In altre parole, espongo a chi utilizza le primitive di Form un modo per inserisi all'interno del suo ciclo di vita, cosi' da poter iniettare errori provenienti da altre sorgenti rispetto a quelle della validazione client-side
	const injectErrors = useCallback(
		(errors: { [key: string]: ReadonlyArray<Error> }) => {
			setState((state) => ({
				...state,
				errors,
			}));
		},
		[],
	);

	
	
	const submit = useCallback(async () => {
		
		// per le validazioni che capitano durante l'uso del form controllo solo i campi che sono stati effettivamente modificati dall'utente; in caso di submit invece e' necessario controllarli tutti. Per questo motivo trovo tutti i path presenti all'interno del form e li uso come secondo argomento di `validateFieldValue` (usato poco sotto) cosi' che non ci siano validazioni di campi che vengono saltate perche' il campo non e' stato "toccato"
		const newTouched = getAllPaths(fields);

		// eseguo tutti i validatori dei campi presenti ad eccezione di quelli nascosti
		const splittedNewErrors = await Promise.all(
			fields
				.filter((field) => !field.hidden)
				.map((field) => validateFieldValue(field, newTouched)),
		);

		const newErrors = splittedNewErrors.reduce(
			(errs, cumulative) => Object.assign(cumulative, errs),
			{},
		);

		if (Object.entries(newErrors).length > 0) {
			// ho dei nuovi errori, fallisco il submit e avvio la gestione collegata al fallimento
			setState({
				...state,
				errors: newErrors,
				touched: newTouched,
			});
			if (onFailedSubmit) {
				onFailedSubmit(fields, newErrors);
			}
		} else {
			// devo calcolare il valore finale dei campi, quindi applicare i "beforeSaveTransformer"
			const finalValues = fields
				.filter(
					(f) =>
						f.value !== undefined &&
						(create ? f.value !== null : f.changed),
				)
				.reduce((final, field) => {
					final[field.name] = (field.beforeSaveTransformer || identity)(
						field.value,
					);
					return final;
				}, {});

			onSubmit(fields, finalValues, injectErrors);
		}
	}, [onSubmit, values, fields]);
	
	
	/** i componenti di input possono modificare lo stato del form solo attraverso una chiamata a un "mutatore", ovvero una funzione specifica che gli viene passata come primitiva di lavoro. Il metodo mutatorFactory e' appunto una factory: dato l'IField genera un mutatore per il componente di input che lo rappresentaera'. Chiaramente avremmo potuto evitarci questo factory pattern, ma ci e' sembrata la soluzione piu' elegante.
	 *
	 * Ci sono diverse note da fare sui mutatori:
	 * - i mutatori modificano lo stato del form in modo immutabile; siccome lo stato e' composto da un array di IField, una modifica comporta una clonazione dell'array con un IField a sua volta clonato al posto di quello originario. Non ho idea di quali impatti abbia questa decisione a livello prestazionale, ma sicuramente qualcosa perdiamo;
	 * - i mutatori sono asincroni; da una parte la modifica puo' avviare effetti aggiuntivi (afterChange), dall'altra la validazione client-side in realta' puo' fare richieste al server;
	 * - il metodo degli IField (opzionale asincrono) afterChange restituisce una nuova lista di IField: siccome vogliamo mantenere l'immutabilita', e poiche' un cambio di valore in un campo potrebbe comportare cambi strutturali in altri campi del form, questo e' l'unico modo di poter implementare il cambio;
	 * - come secondo argomento del mutatore usiamo i path poiche' solo i componenti di input sono consci del path di un IField (vedi descrizione in cima al file);
	 */
	
	const mutatorFactory = useCallback<FieldSetter>(
		(field: IField) => async (value: any, path: string) => {
			
			// essendo un update volutamente immutabile devo clonare il campo e creare una nuova lista di IField, la quale sostituira' lo stato attuale
			const newField = assign({ value, changed: true }, field);
			let newFields: ReadonlyArray<IField> = fields.map((f) =>
				f.name === newField.name ? newField : f,
			);

			if (field.afterChange) {
				newFields = await field.afterChange(field, value, newFields);
			}

			const mutationErrors = await validateFieldValue(newField, touched);
			const basePath = newField.name;
			const cleanedOldErrors = fromPairs(
				Object.entries(errors).filter(
					([path]) => !path.startsWith(basePath),
				),
			);
			const newErrors = Object.assign(cleanedOldErrors, mutationErrors);
			const pieces = path.split('/');
			const paths = pieces.map((p, i) => pieces.slice(0, i + 1).join('/'));
			const newTouched = union(touched, paths);
			const newValues = assign({ [path]: value }, values);

			setState({
				fields: newFields,
				errors: newErrors,
				touched: newTouched,
				values: newValues,
			});

			if (onChange) {
				onChange(field, value, mutationErrors, newField, newValues, submit);
			}
		},
		[fields],
	);

	const args: FormContainer = {
		fields,
		errors,
		touched,
		usedMedias: [],
		mutatorFactory,
		submit,
		values,
	};

	return (
		<FormContext.Provider value={args}>{children(args)}</FormContext.Provider>
	);
};
