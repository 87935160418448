import {
	fastTransformer,
	generateBaseField,
	generateRichTextField,
	generateSubFormField,
	generateTextField,
	subFormsConstructors,
	toColor,
	toMediaField,
	toUrl,
} from '../form';
import { FieldsTransformer, HTML_LEVELS, IField, SubForms } from '../../types/form';
import {
	assign,
	Dict,
	fieldLabel,
	generateRandomID,
	googleFontInputClean,
	toRequired,
} from '../misc';
import { setValue } from '../transformers';
import { t } from '../labels';
import { ERROR_CODES } from '../validation';
import { InputTemplate, MediaType } from '../../server-types';
import { ENTITY } from '../entities';
import { generateAsyncQuery, graphqlQuery, mediaQuery } from '../graphql';
import { newsletterSelectQuery } from '../../graphql/query/newsletterSelectQuery';
import { genericChoiceQuery } from '../../graphql/query/genericChoiceQuery';
import { encodeEmoji } from 'utils/emoji';

const entitiesColValidator = (v) => {
	const selectedLayout = v.fields[0].value;
	const numCards = v.fields[1].value ? v.fields[1].value.length : 0;
	if (selectedLayout) {
		if (
			((selectedLayout.value === '3_col' ||
				selectedLayout.value === '3_col_unboxed') &&
				numCards % 3 !== 0) ||
			((selectedLayout.value === '4_col' ||
				selectedLayout.value === '4_col_unboxed') &&
				numCards % 4 !== 0)
		) {
			return new Error(ERROR_CODES.WRONG_BLOCK_NUMBER);
		}
	}
	return false;
};

const imageTextColValidator = (v) => {
	const selectedLayout = v.fields[0].value;
	const numCards = v.fields[1].value ? v.fields[1].value.length : 0;

	// qui controllo che il numero di blocchi sia coerente con il layout scelto
	if (selectedLayout) {
		if (
			(selectedLayout.value === '3_col' && numCards % 3 !== 0) ||
			(selectedLayout.value === '4_col' && numCards % 4 !== 0) ||
			(selectedLayout.value === '5_col' && numCards % 4 !== 0)
		) {
			return new Error(ERROR_CODES.WRONG_BLOCK_NUMBER);
		}
	}

	return false;
};

const transformBlock = (block: any): any => {
	if (!block) {
		return null;
	}
	let other: any;
	switch (block.type) {
		case SubForms.COMPANY:
			other = {
				entity: block.Company,
			};
			break;
		case SubForms.GOOD:
			other = {
				entity: block.Good,
			};
			break;
		case SubForms.CANDIDACY:
			other = {
				entity: block.Candidacy,
			};
			break;
		case SubForms.ENTITIES:
			other = block;
			other.entities = other.entities.map(transformBlock);
			break;
		case SubForms.NEWS_LIST:
			other = block;
			other.news = other.news.map(transformBlock);
			break;
		case SubForms.NEWSLETTER_CLAIM_LIST:
			other = block;
			other.claims = other.claims.map(transformBlock);
			break;
		case SubForms.LAUNCH_LIST:
			other = block;
			other.launches = block.launches.map(transformBlock);
			break;
		case SubForms.IMAGE_TEXT_COLUMNS:
			other = block;
			other.blocks = block.blocks.map(transformBlock);
			break;
		case SubForms.RICH_TEXT:
			block.richtext = encodeEmoji(block.richtext);
			other = block;
			break;
		default:
			other = block;
			break;
	}
	return {
		...other,
		type: block.type,
	};
};

const newsletterIssueBeforeSaveTransformer = (data) => {
	data.values = {
		blocks: data.blocks
			? data.blocks.map(transformBlock).filter((res) => res != null)
			: [],
	};
	delete data.blocks;

	// i campi che non appartengono ai 5 originali vengono sicuramente dagli override della newsletter,
	// quindi invece che gestire i casi uno ad uno faccio la differenza con quelli originali e li inserisco tutti
	const {
		name,
		publicationDate,
		Newsletter,
		hiddenIntro,
		values,
		...overrideValues
	} = data;
	
	// parso i valori di input dei font google
	if (overrideValues.fontBody) 	overrideValues.fontBody 	= googleFontInputClean(overrideValues.fontBody);
	if (overrideValues.fontTitles) 	overrideValues.fontTitles 	= googleFontInputClean(overrideValues.fontTitles);

	data.values.newsletterOverrides = overrideValues;
	Object.keys(overrideValues).forEach((key) => {
		delete data[key];
	});

	Object.keys(data.values.newsletterOverrides).forEach((key) => {
		if (typeof(data.values.newsletterOverrides[key]) === 'string') {
			data.values.newsletterOverrides[key] = encodeEmoji(data.values.newsletterOverrides[key]);
		}
	});
	// TODO: genera errore!
	Object.keys(data.values).forEach((key) => {
		if (typeof(data.values[key]) === 'string') {
			data.values[key] = encodeEmoji(data.values[key]);
		}
	});

	

	data.values = JSON.stringify(data.values);
	return data;
};

// creo funzioni comode per centralizzare il comportamento dei label
const label = (s: string) =>
	t(fieldLabel(ENTITY.NEWSLETTER_ISSUE, s + 'Override'));
const helpText = (s: string) =>
	t(fieldLabel(ENTITY.NEWSLETTER, s + 'Override', true));

const newsletterTemplateToFields: { [key: string]: ReadonlyArray<IField> } = {
	[InputTemplate.NewsletterGeneric]: [
		toMediaField(
			generateBaseField(
				'headerMedia',
				label('headerMedia'),
				helpText('headerMedia'),
			),
			MediaType.MailImage,
		),
		toColor(
			generateBaseField(
				'primaryColor',
				label('primaryColor'),
				helpText('primaryColor'),
			),
		),
		generateRichTextField(
			'prefooter',
			label('prefooter'),
			HTML_LEVELS.NEWSLETTER,
		),
		toUrl(generateTextField('link', label('link'), helpText('link')))
	],
	[InputTemplate.DemGeneric]: [
		generateTextField('fontTitles', label('fontTitles')),
		generateTextField('fontBody', label('fontBody')),
		toRequired(
			toColor(
				generateBaseField(
					'primaryColor',
					label('primaryColor'),
					helpText('primaryColor'),
				),
			),
		),
		toColor(
			generateBaseField(
				'backgroundColor',
				label('backgroundColor'),
				helpText('backgroundColor'),
			),
		),
		toColor(
			generateBaseField(
				'backgroundColor2',
				label('backgroundColor2'),
				helpText('backgroundColor2'),
			),
		),
		{
			...generateTextField('prefooter', label('prefooter')),
			multiline: true
		},
		{
			...generateTextField('footerHtml', label('footerHtml')),
			multiline: true
		}
	],
};

const addOverrideFields = (
	fields: ReadonlyArray<IField>,
	inputTemplate: InputTemplate,
	values?: Dict,
): ReadonlyArray<IField> => {
	const safeValue = inputTemplate;
	const originalFields = fields.slice(0, 4);
	const blockField = fields.find((f) => f.name === 'blocks');

	if (
		safeValue === InputTemplate.NewsletterGeneric ||
		safeValue === InputTemplate.DemGeneric
	) {
		const additionalFields = newsletterTemplateToFields[safeValue].map(
			(f) => {
				// operazione necessaria per motivi di purezza: se usassi `f` direttamente il caricamento dell'attributo `value` comporterebbe strani effetti collaterali in eventuali aperture successive del campo in situazioni in cui non è valorizzato
				const newF = { ...f}
				if (values && values[f.name]) {
					newF.value = values[f.name];
					newF.changed = true;
				}
				return newF;
			},
		);
		return [...originalFields, ...additionalFields, blockField];
	}

	return [...originalFields, blockField];
};

const newsletterTemplateAdditionalFieldsTransformer = fastTransformer({
	Newsletter: assign({
		asyncQuery: generateAsyncQuery(newsletterSelectQuery, true),
		afterChange: (
			field: IField,
			value: any,
			fields: ReadonlyArray<IField>,
		): ReadonlyArray<IField> => {
			if (value && value.value && value.value.inputTemplate) {
				return addOverrideFields(fields, value.value.inputTemplate);
			}
			return fields;
		},
	}),
});

const newsletterIssueCreateTransformer: FieldsTransformer = (fields) => {
	return fastTransformer({
		hiddenIntro: assign({ type: 'Text', multine: true }),
	})(
		fields.concat([
			generateSubFormField(
				'blocks',
				t`BlockSubFormList`,
				[
					SubForms.LINK_LIST,
					SubForms.NEWS_LIST,
					SubForms.TYPEFORM_PREVIEW,
					SubForms.ENTITIES,
					SubForms.NEWS_WITH_LAYOUT,
					SubForms.BANNER,
					SubForms.MAIN_IMAGE,
					SubForms.RICH_TEXT,
					SubForms.TITLE,
					SubForms.TITLE_NO_IMAGE,
					SubForms.INSTANT_ACTION,
					SubForms.DOUBLE_INSTANT_ACTION,
					SubForms.IFN_SEARCH_BAR,
					SubForms.IMAGE_TEXT,
					SubForms.SPONSOR,
					SubForms.AUTHOR,
					SubForms.SECTION_TAGS,
					SubForms.LAUNCH,
					SubForms.LAUNCH_LIST,
					SubForms.NEWSLETTER_CLAIM_LIST,
					SubForms.IMAGE_TEXT_COLUMNS,
					SubForms.SOCIAL_BUTTONS,
					SubForms.ADD_TO_CALENDAR,
				],
				false,
				true,
				{
					[SubForms.ENTITIES]: {
						validators: [entitiesColValidator], // TODO controllare se ha senso specificare i validator in questo posto, e commentarne l'uso
					},
				},
			),
		]),
	);
};

/**
 * Funzione per trasformare un blocco serializzato di SubFormFieldList in un IField SubFormField con i campi collegati e il loro valore precaricato.
 * Come spiegato in altre parti, questo comportamento non è molto razionale perché frutto di adattamenti consecutivi. Da una parte la serializzazione di un SubFormListField non si basa su singoli blocchi ma su una lista di blocchi, quindi questa funzione deve essere chiamata come "map" della vera serializzazione di SubFormListField (vedi commento alla funzione `valuesToForms` in questo file). D'altra parte, l'assegnamento dei valori ai singoli IField di un SubForm avviene tramite posizione e non nominalmente, il che rende la funzione molto lunga; questo potrebbe cambiare facilmente, con qualche accorgimento. Tutto questo si innesta in una situazione in cui la logica di aggiunta/caricamento SubForm nei SubFormListField non è centralizzata (vedi il componente per maggiori info).
 *
 * Insomma, c'è margine di miglioramento. Comunque la funzione è ricorsiva e perciò supporta form con infiniti livelli di inglobamento. Inoltre, per il momento, per quanto scomoda svolge il suo lavoro adeguatamente.
 * Se questa tecnica del form dinamico dovesse prendere piede allora sarà necessario generalizzarla maggiormente.
 * @param block: any
 */
const blockToIField = (block: any): IField => {
	const name = generateRandomID();
	const { type } = block;
	// ricostruisco il form originale senza valori
	const fields: IField[] = (subFormsConstructors[type] || []).map((field) =>
		Object.assign({}, field),
	);

	switch (type) {
		case SubForms.GOOD:
		case SubForms.COMPANY:
		case SubForms.CANDIDACY:
			fields[0].value = block.choice;
			break;
		case SubForms.RICH_TEXT:
			fields[0].value = block.richtext;
			break;
		case SubForms.NEWS:
			fields[0].value = block.news;
			fields[1].value = block.media;
			break;
		case SubForms.NEWS_WITH_LAYOUT:
			fields[0].value = block.news;
			fields[1].value = {
				value: block.layout,
				label: t('wordpressNewsLayout/' + block.layout),
			};
			fields[2].value = block.media;
			fields[3].value = block.title;
			fields[4].value = block.excerpt;
			break;
		case SubForms.LAUNCH:
			fields[0].value = block.url;
			break;
		case SubForms.MAIN_IMAGE:
			fields[0].value = {
				... block.media,
				id: block.id,
				selectedSize: block.media.selectedSize ? block.media.selectedSize : null
			};
			fields[1].value = block.alt;
			fields[2].value = block.caption;
			fields[3].value = block.link;
			break;
		case SubForms.BANNER:
			fields[0].value = block.media;
			fields[1].value = block.alt;
			fields[2].value = block.link;
			break;
		case SubForms.TITLE:
			fields[0].value = block.media;
			fields[1].value = block.text;
			fields[2].value = block.backgroundColor;
			fields[3].value = block.textColor;
			fields[4].value = block.url;
			break;
		case SubForms.IFN_SEARCH_BAR:
			fields[0].value = {
				value: block.searchType,
				label: t(block.searchType),
			};
			fields[1].value = block.link;
			fields[2].value = block.text;
			break;
		case SubForms.INSTANT_ACTION:
			fields[0].value = block.media;
			fields[1].value = block.text;
			fields[2].value = block.url;
			fields[3].value = block.backgroundColor;
			fields[4].value = block.textColor;
			fields[5].value = block.icon;
			fields[6].value = {
				value: block.size,
				label: t(block.size),
			};
			break;
		case SubForms.DOUBLE_INSTANT_ACTION:
			fields[0].value = block.text1;
			fields[1].value = block.url1;
			fields[2].value = block.text2;
			fields[3].value = block.url2;
			fields[4].value = block.backgroundColor;
			fields[5].value = block.textColor;
			fields[6].value = {
				value: block.size,
				label: t(block.size),
			};
			break;
		case SubForms.LINK:
			fields[0].value = block.link;
			fields[1].value = block.text;
			fields[2].value = block.secondLine;
			break;
		case SubForms.IMAGE_TEXT:
			fields[0].value = block.media;
			fields[1].value = block.richtext;
			fields[2].value = block.link;
			fields[3].value = {
				label: t(block.alignment),
				value: block.alignment,
			};
			fields[4].value = {
				label: t(block.proportion),
				value: block.proportion,
			};
			fields[5].value = {
				label: t(block.valignment || 'top'),
				value: block.valignment || 'top',
			};
			break;
		case SubForms.SPONSOR:
			fields[0].value = block.title;
			fields[1].value = block.name;
			fields[2].value = block.media;
			fields[3].value = block.url;
			break;
		case SubForms.AUTHOR:
			fields[0].value = block.name;
			fields[1].value = block.titles;
			fields[2].value = block.media;
			fields[3].value = block.url;
			break;
		case SubForms.TITLE_NO_IMAGE:
			fields[0].value = block.text;
			fields[1].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[2].value = block.alignCenter;
			fields[3].value = block.backgroundColor;
			fields[4].value = block.textColor;
			fields[5].value = block.url;
			fields[6].value = block.subtitle;
			fields[7].value = block.subtitleColor;
			break;
		case SubForms.ENTITIES:
			fields[0].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[1].value = block.entities.map(blockToIField);
			break;
		case SubForms.NEWS_LIST:
			fields[0].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[1].value = block.news.map(blockToIField);
			break;
		case SubForms.LAUNCH_LIST:
			fields[0].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[1].value = block.launches.map(blockToIField);
			break;
		case SubForms.NEWSLETTER_CLAIM:
			fields[0].value = block.text;
			fields[1].value = block.url;
			fields[2].value = block.backgroundColor;
			fields[3].value = block.textColor;
			break;
		case SubForms.NEWSLETTER_CLAIM_LIST:
			fields[0].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[1].value = block.claims?.map(blockToIField);
			break;
		case SubForms.LINK_LIST:
			fields[0].value = block.urls.map(blockToIField);
			break;
		case SubForms.IFN_CARD:
			fields[0].value = block.url;
			break;
		case SubForms.TYPEFORM_PREVIEW:
			fields[0].value = block.url;
			break;
		case SubForms.EDITORIAL_SECTIONS:
			fields[0].value = block.target;
			break;
		case SubForms.SECTION_TAGS:
			fields[0].value = {
				value: block.target,
				label: t(block.target),
			};
			break;
		case SubForms.NEWSLETTER_FAQ:
			fields[0].value = block.question;
			fields[1].value = block.answer;
			break;
		case SubForms.IMAGE_TEXT_COLUMNS:
			fields[0].value = {
				value: block.layout,
				label: t(block.layout),
			};
			fields[1].value = block.blocks.map(blockToIField);
			break;
		case SubForms.IMAGE_TEXT_BASE:
			fields[0].value = block.media;
			fields[1].value = block.richtext;
			fields[2].value = block.link;
			break;
		case SubForms.SOCIAL_BUTTONS:
			fields[0].value = {
				value: block.size,
				label: t(block.size),
			};
			fields[1].value = block.textColor;
			fields[2].value = block.facebook;
			fields[3].value = block.twitter;
			fields[4].value = block.instagram;
			fields[5].value = block.youtube;
			fields[6].value = block.vimeo;
			fields[7].value = block.linkedin;
			break;
		case SubForms.ADD_TO_CALENDAR:
			fields[0].value = block.title;
			fields[1].value = new Date(block.begin);
			fields[2].value = new Date(block.end);
			fields[3].value = block.allday;
			fields[4].value = block.except;
			fields[5].value = block.location;
			break;
	}

	const newField: IField = {
		...generateSubFormField(name, name, [type], true, true),
		value: {
			selected: type,
			fields: fields,
		},
		changed: true,
	};

	if (type === SubForms.ENTITIES) {
		newField.validators = newField.validators.concat([entitiesColValidator]);
	}
	if (type === SubForms.IMAGE_TEXT_COLUMNS) {
		newField.validators = newField.validators.concat([imageTextColValidator]);
	}

	return newField;
};


/**
 * Transformer per avviare la deserializzazione del valore di un SubFormListField, in modo da caricare il suo vecchio stato.
 * Purtroppo la funzione è molto specifica in quanto nata per un caso particolare: il transformer opera solo se il SubFormListField si chiama "blocks" e il valore serializzato "values". Più preoccupantemente, svolge una parte del lavoro che dovrebbe essere svolto dalla funzione più generica di deserializzazione (ovvero, fa quel "blocks.map" che dovrebbe essere integrata in "blockToIField"). Questo andrebbe davvero messo apposto..
 * @param entity: any
 * @returns FieldsTransformer
 */
const valuesToForms = (entity: any): FieldsTransformer => {
	const data = entity.values;
	const { blocks } = data;

	return fastTransformer({
		blocks: setValue(blocks.map(blockToIField)),
	});
};


const jsonToPromises = (blocks: any[], accum: any): Promise<any> => {
	const promises = [];
	blocks.forEach((block) => {
		if (block.entity) {
			// sono nel caso di un blocco GOOD o COMPANY
			promises.push(graphqlQuery(genericChoiceQuery, block.entity)());
		}

		const possibleSingle = ['media', 'icon'];

		possibleSingle.forEach((lens) => {
			if (block[lens]) {
				if (lens === 'media' && block[lens].media && block[lens].media.id) {
					promises.push(graphqlQuery(mediaQuery, block[lens].media)());
				} else if (block[lens].id) {
					promises.push(graphqlQuery(mediaQuery, block[lens])());
				}
			}
		});

		const possibleDeeps = ['entities', 'news', 'blocks'];

		possibleDeeps.forEach((lens) => {
			if (block[lens] && Array.isArray(block[lens])) {
				promises.push(jsonToPromises(block[lens], accum));
			}
		});
	});

	return Promise.all(promises);
};

const injectFetchedData = (
	resolvedData: { [key: string]: any },
	blocks: any[],
): any => {
	blocks.forEach((block) => {
		if (block.entity) {
			const data = resolvedData.shift().node;
			block.choice = {
				value: data,
				label: data.name,
			};
		}

		const possibleSingle = ['media', 'icon'];

		possibleSingle.forEach((lens) => {
			if (block[lens] && (block[lens].id || block[lens].media.id)) {
				if (lens === 'media') {
					block[lens].media = resolvedData.shift().node;
				} else {
					block[lens] = resolvedData.shift().node;
				}
			}
		});

		const possibleDeeps = ['entities', 'news', 'blocks'];

		possibleDeeps.forEach((lens) => {
			if (block[lens] && Array.isArray(block[lens])) {
				injectFetchedData(resolvedData.shift(), block[lens]);
			}
		});
	});
};

export {
	newsletterIssueBeforeSaveTransformer,
	newsletterIssueCreateTransformer,
	newsletterTemplateAdditionalFieldsTransformer,
	valuesToForms,
	addOverrideFields,
	blockToIField,
	jsonToPromises,
	injectFetchedData,
};
