import { parse } from 'query-string';
import { Channel } from '@food/sync';
import {auth, IConfig, logout, RedirectHandler} from '@food/auth';
import CONFIG from '../static/config';
import { client } from './client';
import { stacked } from './broadcastChannel';
import { history } from './history';
import * as Sentry from '@sentry/browser';
import { UserRole } from '../server-types';

const authConfig: IConfig = {
	AUTH_ENDPOINT: CONFIG.API_ENDPOINT.AUTH,
	TARGET_SITE: CONFIG.TARGET_SITE,
	LOGO: CONFIG.LOGO,
	AUTH_ROLES: [UserRole.Producer as any],
	// OAUTH_REDIRECT_URI: CONFIG.OAUTH_REDIRECT_URI
};

const authManager = auth(authConfig);
const authChannel = new Channel('@food/auth');

const onRedirect: RedirectHandler = (user, redirectUrl) => {
	
	/*
	* ===================================================================================================================
	* ATTENZIONE!
	* ===================================================================================================================
	*
	* Non abbiamo il controllo completo di come e quando questa funzione venga chiamata. Mettere, per esempio, un redirect di default alla pagina per consumer, innesca un meccanismo per cui non si riesce ad entrare tramite social.
	*
	* Sospetto si tratti di una race condition che si scatena con la tab in cui viene ottenuto il codice di accesso dal social, ma non ne sono sicuro. In questo momento è necessario non mettere nessun goto di default.
	*
	* */
	const goto = history.push;
	
	// se sono in WAITING_REVIEW ci pensa @food/auth a gestire il messaggio, io non faccio niente
	if (user && user.status === 'WAITING_REVIEW') {
		logout();
	}

	if (!user) {
		goto('/');
		return;
	}

	if (user.role === UserRole.Buyer || user.role === UserRole.BuyerPlItalia) {
		goto('/buyer-welcome');
		return;
	}
	
	if (user.role === UserRole.Foodservice) {
		goto('/foodservice-welcome');
		return;
	}
	
	if (user.role === UserRole.Shopworker) {
		goto('/shopworker-welcome');
		return;
	}
	
	
	if (user.role === UserRole.Internal || user.role === UserRole.Producer) {
		goto(redirectUrl);
		return;
	}
	
	// rimangono i consumer, che sono anche i piu' validi a livello generico
	if (user.role === UserRole.Consumer) {
		goto('/consumer-welcome');
	}

	const { location } = history;
	if (user && location.pathname === '/login') {
		goto(redirectUrl);
	}
};

const userChangedHandler = async (
	oldUser: any,
	newUser: any,
	isSwitch: boolean,
) => {
	const { location } = history;
	const searchParams = parse(location.search);
	const userIsEqual =
		(!oldUser && oldUser === newUser) ||
		(oldUser && newUser && oldUser.id === newUser.id);

	if (!userIsEqual) {
		Sentry.configureScope((scope) => {
			if (newUser) {
				scope.setUser({
					id: newUser.id,
					username: newUser.name,
					email: newUser.email,
				});
			} else {
				scope.setUser(null);
			}
		});
		
		if (newUser?.role === UserRole.Internal) {
			if (window.location.host.indexOf('hub.italianfood.net') !== -1) {
				alert('Per cortesia, valuta di utilizzare la versione di HUB raggiungibile all\'indirizzo' +
					' hub2.italianfood.net per aiutare gli sviluppatori nel loro lavoro')
			}
		}
	}

	if (!userIsEqual || isSwitch) {
		await client.cache.reset();
		client.resetStore();
	}
	
	// console.log('new user:', newUser);
	// console.log('old user:', oldUser);
	// console.log('pathname:', location.pathname)

	if (!newUser || location.pathname === '/login' || isSwitch || location.pathname === '/') {
		const redirectUrl = searchParams.redirect || '/';
		
		onRedirect(newUser, redirectUrl);
	}
};

authChannel.onmessage = stacked((events: any[]) => {
	// console.log('events:', events);
	const lastEvent = events.filter((e) => e.data.type === 'USER_CHANGED')[0];
	if (lastEvent) {
		const { oldUser, newUser, isSwitch } = lastEvent.data.args;
		// console.log('message:', lastEvent.data.args);
		userChangedHandler(oldUser, newUser, isSwitch);
	}
}, 100);

export { authManager, onRedirect, authConfig };
