import React from 'react';
import { AwardPatchMutation } from '../graphql/mutations/AwardPatchMutation';
import { AwardEditQuery } from '../graphql/query/AwardEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Award.json';
import inputTypeIntro from '../static/introspection/AwardPatchInput.json';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { graphqlQuery } from '../utils/graphql';

export const AwardEdit = ({ id }) => (
	<EntityEditFetcher
		id={id}
		fetcher={graphqlQuery(AwardEditQuery, { id })}
		mutation={AwardPatchMutation}
		entityName={ENTITY.AWARD}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={[
			'name',
			'visibility',
			'edition',
			'year',
			'langCode',
			'logoMedia',
			'publicDescription',
			'eventDescription',
			'descriptionForProducer',
			'descriptionForVoter',
			'descriptionProducerHighlights',
			'maxPerCompanyCandidacies',
			'maxGoodsPerCandidacy',
			'extraMediaType',
			'extraMediaLabel',
			'candidaciesCloseAt',
			'votesCloseAt',
			'autovoterRoles',
			'ratingOverallLabel',
			'rating1Label',
			'rating2Label',
			'rating3Label',
			'rating4Label',
			'rating5Label',
			'comment1Label',
			'comment1Max',
			'comment2Label',
			'comment2Max',
			'comment3Label',
			'comment3Max',
			'website',
			'options',
			'description1Label',
			'description1Max',
			'description2Label',
			'description2Max',
			'description3Label',
			'description3Max',
			'description4Label',
			'description4Max',
			'description5Label',
			'description5Max',
			'description6Label',
			'description6Max',
			'description7Label',
			'description7Max',
			'description8Label',
			'description8Max',
			'description9Label',
			'description9Max',
			'description10Label',
			'description10Max',
			'vademecumCompany',
			'vademecumGoods',
			'vademecumCandidacies',
			'vademecumInvited',
			'supportEmail',
			'regulationsMedia',
			'contractMedia',
		]}
		tabs={[
			t('generic data'),
			t([ENTITY.AWARD, 'descriptions', 'label'].join('/')),
			t([ENTITY.AWARD, 'candidacies fields', 'label'].join('/')),
			t([ENTITY.AWARD, 'rating options', 'label'].join('/')),
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'publicDescription':
					case 'eventDescription':
					case 'descriptionForProducer':
					case 'descriptionForVoter':
					case 'descriptionProducerHighlights':
					case 'vademecumCompany':
					case 'vademecumGoods':
					case 'vademecumCandidacies':
					case 'vademecumInvited':
						f.tab = 1;
						break;

					case 'description1Label':
					case 'description1Max':
					case 'description2Label':
					case 'description2Max':
					case 'description3Label':
					case 'description3Max':
					case 'description4Label':
					case 'description4Max':
					case 'description5Label':
					case 'description5Max':
					case 'description6Label':
					case 'description6Max':
					case 'description7Label':
					case 'description7Max':
					case 'description8Label':
					case 'description8Max':
					case 'description9Label':
					case 'description9Max':
					case 'description10Label':
					case 'description10Max':
						f.tab = 2;
						break;

					case 'ratingOverallLabel':
					case 'rating1Label':
					case 'rating2Label':
					case 'rating3Label':
					case 'rating4Label':
					case 'rating5Label':
					case 'comment1Label':
					case 'comment1Max':
					case 'comment2Label':
					case 'comment2Max':
					case 'comment3Label':
					case 'comment3Max':
						f.tab = 3;
						break;

					default:
						break;
				}
			});
			return data;
		}}
	/>
);
