import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const GoodFullFragment = gql`
	fragment GoodFullFragment on Good {
		id
		localId
		Brand {
			id
			name
			Company {
				id
				name
			}
		}
		featuredImageMedia {
			...MediaBaseFragment
		}
		featuredImageItaMedia {
			...MediaBaseFragment
		}
		ingredientsImageMedia {
			...MediaBaseFragment
		}
		ingredientsImageItaMedia {
			...MediaBaseFragment
		}
		GeoIndication {
			id
			name
		}
		GoodClass {
			id
			name
			nameIta
			shortname
			shortnameIta
		}
		goodFeatureCertifications {
			id
			name
			nameIta
		}
		range
		name
		nameIta
		description
		descriptionIta
		descriptionLong
		descriptionLongIta
		shelfLifeLength
		extraMedia {
			...MediaBaseFragment
		}
		extraItaMedia {
			...MediaBaseFragment
		}
		formats {
			id
			ean
			cuMesureValue
			flavor
			cuPerSellingPack
		}
		productionBranches {
			id
			name
		}
	}
	${MediaBaseFragment}
`;
