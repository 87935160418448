import React from 'react';
import { MagazinePatchMutation } from '../graphql/mutations/MagazinePatchMutation';
import { MagazineEditQuery } from '../graphql/query/MagazineEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Magazine.json';
import inputTypeIntro from '../static/introspection/MagazinePatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

const MagazineEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(MagazineEditQuery, { id })}
		mutation={MagazinePatchMutation}
		entityName={ENTITY.MAGAZINE}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'MagazineCollection',
			'category',
			'publicationDate',
			'name',
			'title',
			'customHighlightDays',
			'issueNumber',
			'numOfTheYear',
			'numPages',
			'prefixPages',
			'customVisibilityType',
			'customVisibilityArgs',
			'fullUrl',
			'previewUrl',
			'dropboxPath',
			'Media',
			'previewMedia',
			'featuredImageMedia',
			// 'magazineContents',
			// 'magazineContents__inline',
		]}
	/>
);

export { MagazineEdit };
