import { ENTITY } from './entities';
import { t } from './labels';
import gql from 'graphql-tag';
import moment from 'moment';

export function entity2String(entityType: ENTITY, entity: any): string {
	switch (entityType) {
		case ENTITY.COMPANY:
			return entity.billingName || entity.name;
		case ENTITY.AWARD:
			return entity.edition
				? `${entity.name} ${entity.edition} ${entity.year}`
				: `${entity.name} ${entity.year}`;
		case ENTITY.FAIR:
			return `${entity.name}${entity.edition ? ' ' + entity.edition : ''} ${
				entity.year
			}`;
		case ENTITY.FAIR_PARTICIPATION:
			return `${t(ENTITY.FAIR_VISIT)} ${entity2String(
				ENTITY.FAIR,
				entity.Fair,
			)}`;
		case ENTITY.QUESTION_SET:
			return (
				'Domanda di ' +
				entity.User.name +
				' il ' +
				moment(entity.sentDate).format('DD/MM/YYYY')
			);
		case ENTITY.ANSWER_SET:
			return 'Risposta di ' + entity2String(ENTITY.COMPANY, entity.Company);
		case ENTITY.NEWSLETTER_ISSUE:
			return `${entity.Newsletter.name} ${entity.localId}`;
		case ENTITY.MAGAZINE_COLLECTION:
			return `${t(entity.BrandSf)} - ${entity.name}`;
		case ENTITY.BRANCH:
			return `${entity.name} - ${entity.address1} ${entity.StateProvince &&
				entity.StateProvince.name}`;
		case ENTITY.HIGHLIGHTED_GOOD:
			return entity.Good && entity2String(ENTITY.GOOD, entity.Good);
		default:
			return entity.name;
	}
}

export function entity2StringFragment(entityType: ENTITY): string {
	switch (entityType) {
		case ENTITY.MEDIA:
			return ['name', 'origUrl'].join('\n');
		case ENTITY.COMPANY:
			return ['billingName', 'name'].join('\n');
		case ENTITY.AWARD:
			return ['name', 'edition', 'year'].join('\n');
		case ENTITY.FAIR:
			return ['name', 'edition', 'year'].join('\n');
		case ENTITY.FAIR_PARTICIPATION:
			return `Fair { ${entity2StringFragment(ENTITY.FAIR)} }`;
		case ENTITY.ANSWER_SET:
			return [
				`Company { ${entity2StringFragment(ENTITY.COMPANY)} }`,
				`QuestionSet { ${entity2StringFragment(ENTITY.QUESTION_SET)} }`,
			].join('\n');
		case ENTITY.NEWSLETTER_ISSUE:
			return ['Newsletter { name }', 'localId'].join('\n');
		case ENTITY.MAGAZINE_COLLECTION:
			return ['name', 'BrandSf'].join('\n');
		case ENTITY.BRANCH:
			return ['name', 'address1', 'StateProvince{ id, name }'].join('\n');
		case ENTITY.NEWS:
			return 'title';
		case ENTITY.EMAIL_DOMAIN:
			return 'emailDomain';
		case ENTITY.COMPANY_YEAR:
			return [
				'year',
				`Company { ${entity2StringFragment(ENTITY.COMPANY)} }`,
			].join('\n');
		case ENTITY.COMPANY_LEVEL:
			return [
				'level',
				`Company { ${entity2StringFragment(ENTITY.COMPANY)} }`,
			].join('\n');
		case ENTITY.OBJECT_HISTORY:
			return ['action', 'collection'].join('\n');
		case ENTITY.HIGHLIGHTED_GOOD:
			return `Good { ${entity2StringFragment(ENTITY.GOOD)} }`;
		case ENTITY.GOOD_FORMAT:
			return [
				`Good { id ${entity2StringFragment(ENTITY.GOOD)} }`,
				'cuMesureUnit',
				'cuMesureValue',
			].join('\n');
		case ENTITY.AWARD_INVITED_USER:
			return [
				`Award { ${entity2StringFragment(ENTITY.AWARD)} }`,
				`User { ${entity2StringFragment(ENTITY.USER)} }`,
			].join('\n');
		case ENTITY.AWARD_INVITED_COMPANY:
			return [
				`Award { ${entity2StringFragment(ENTITY.AWARD)} }`,
				`Company { ${entity2StringFragment(ENTITY.COMPANY)} }`,
			].join('\n');
		case ENTITY.NEWS_CITATION:
			return `News { ${entity2StringFragment(ENTITY.NEWS)} }`;
		case ENTITY.AWARD_RESULT:
			return [
				'finalPosition',
				`AwardCategory { ${entity2StringFragment(ENTITY.AWARD_CATEGORY)} }`,
			].join('\n');
		case ENTITY.DISTRIBUTION_CHANNEL:
			return ['channels', 'mode'].join('\n');
		case ENTITY.MANAGED_BY_USER:
			return `User { ${entity2StringFragment(ENTITY.USER)} }`;
		case ENTITY.CANDIDACY_RATING:
			return `Candidacy { ${entity2StringFragment(ENTITY.CANDIDACY)} }`;
		case ENTITY.FAIR_VISIT:
			return [
				`Fair { ${entity2StringFragment(ENTITY.FAIR)} }`,
				`User { ${entity2StringFragment(ENTITY.USER)} }`,
			].join('\n');
		case ENTITY.QUESTION_SET:
			return [
				'id',
				`User { ${entity2StringFragment(ENTITY.USER)} }`,
				'sentDate',
			].join('\n');
		default:
			return ['id', 'name'].join('\n');
	}
}

export const nodeNameFragment = gql`
	fragment NodeNameFragment on Node {
		id
		${Object.values(ENTITY)
			.filter((f) => f !== ENTITY.FOOD_SERVICE)
			.map((value) => {
				return `... on ${value} { ${entity2StringFragment(
					value as ENTITY,
				)} }`;
			})
			.join('\n')}
	}
`;
