import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { AwardListQuery } from '../graphql/query/AwardListQuery';
import typeIntro from '../static/introspection/Award.json';
import AwardFilters from '../static/introspection/AwardFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const AwardList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={AwardListQuery}
				entityName={ENTITY.AWARD}
				headers={['name', 'edition', 'year', 'status']}
				sortables={['name', 'edition', 'year', 'status']}
				typeIntro={typeIntro}
				filtersIntro={AwardFilters}
				filterFields={
					isInternal ? ['name', 'edition', 'year', 'statuses'] : null
				}
			/>
		)}
	</UserData>
);
