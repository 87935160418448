import gql from 'graphql-tag';

export const NewsletterFullFragment = gql`
	fragment NewsletterFullFragment on Newsletter {
		id
		localId
		name
		target
		delivery
		inputTemplate
		values
	}
`;
