import React from 'react';
import { GoodCreateMutation } from '../graphql/mutations/GoodCreateMutation';
import { GoodCreateQuery } from '../graphql/query/GoodCreateQuery';
import inputTypeIntro from '../static/introspection/GoodCreateInput.json';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Good.json';
import { GoodEditQuery } from '../graphql/query/GoodEditQuery';
import { goodTransformer } from '../utils/entityFieldsTrasformers';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { graphqlQuery } from '../utils/graphql';
import { nodes } from '../utils/misc';
import { EntityCreatePage } from '../types/entities';
import { Good } from '../server-types';

export const GoodCreate: React.FC<EntityCreatePage<Good>> = ({ id }) => (
	<UserData>
		{(user) => (
			<EntityEditFetcher
				fetcher={
					id
						? graphqlQuery(GoodEditQuery, { id })
						: graphqlQuery(GoodCreateQuery)
				}
				create={true}
				clone={!!id}
				id={id}
				mutation={GoodCreateMutation}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entityName={ENTITY.GOOD}
				fields={[
					'nameIta',
					'name',
					'Brand',
					'range',
					'featuredImageItaMedia',
					'featuredImageMedia',
					'ingredientsImageItaMedia',
					'ingredientsImageMedia',
					'GoodClass',
					'goodFeatureCertifications',
					'GeoIndication',
					'shelfLifeLength',
					'descriptionIta',
					'description',
					'descriptionLongIta',
					'descriptionLong',
					'formats',
				]}
				entitiesToFields={(props) => ({
					EditorialSections: props.editorialSectionTree,
					certifications: props.goodFeatureCertifications,
					brands: nodes(props.brands),
				})}
				transformer={(...args) => goodTransformer(user, ...args)}
			/>
		)}
	</UserData>
);
