import React from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { t } from '../../utils/labels';
import { ListFilterType } from '../Filters';
import { SyncChoiceFieldType } from '../../types/form';

export const EnumFilter: React.FC<ListFilterType<
	string,
	SyncChoiceFieldType
>> = ({ field, mutator, size = 3, entityName }) => (
	<FormGroup className={`filter col-sm-${size}`}>
		<Select
			className={'full-width'}
			value={field.value}
			isMulti={!field.single}
			isClearable={true}
			delimiter=","
			name={field.name}
			options={field.choices}
			onChange={(val) => mutator(val, field.name)}
			placeholder={t([entityName, field.name, 'filter'].join('/'))}
		/>
	</FormGroup>
);
