import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { always } from 'ramda';
import { InternalCapability } from '../server-types';
import { nodes } from 'utils/misc';

export const candidaciesVoteResultReport: ReportType = {
	filename: always('Candidature di un premio - risultati voto'),
	title: 'Candidature di un premio - risultati voto',
	requirements: [InternalCapability.MarketingExtra],
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: 'Award',
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],
	preVariablesGenerator: (values: any) => ({
		id: values.Award.value.id,
	}),
	variablesGenerator: (pre: any) => ({
		filter: {
			Award: { id: pre.award.id },
		},
	}),
	preQuery: gql`
		query PreQueryRep1($id: ID!) {
			award: node(id: $id) {
				id
				... on Award {
					ratingOverallLabel
					rating1Label
					rating2Label
					rating3Label
					rating4Label
					rating5Label
					managerUser {
						id
						email
					}
				}
			}
		}
	`,
	customRequirementsCheck: (pre, user) => {
		const manager = pre.award.managerUser;

		if (!manager) {
			return new Error('Nessun utente manager impostato per il premio');
		}

		if (manager.id !== user.id) {
			return new Error(
				'Permessi insufficienti per accedere alle informazioni. Utente responsabile: ' +
					manager.email,
			);
		}

		return null;
	},
	query: gql`
		query QueryRep1(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						AwardCategory {
							id
							name
						}
						Company {
							name
						}
						candidacyRatings {
							User {
								role
							}
							overall
							rating1
							rating2
							rating3
							rating4
							rating5
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	header: (pre: any) => {
		const { award } = pre;
		const mediaSuffix = ' ' + t`(media)`;
		const numSuffix = ' ' + t`(num)`;
		return [
			t`Candidacy Name`,
			t`Company Name`,
			t`Categoria`,
			t`Role`,
			(award.ratingOverallLabel || t`Overall`) + mediaSuffix,
			(award.ratingOverallLabel || t`Overall`) + numSuffix,
		].concat(
			Object.keys(award)
				.filter((n) => /rating\dLabel/.test(n))
				.filter((n) => award[n])
				.map((n) => [award[n] + mediaSuffix, award[n] + numSuffix])
				.flat(),
		);
	},
	extractor: (data: any) => nodes(data.connector),
	globalTransformer: (records) => {
		return records
			.map((record) => {
				const ratingsByRole = record.candidacyRatings.reduce(
					(acc: { [key: string]: any[] }, cur) => {
						const role = cur.User.role;
						if (!acc[role]) {
							acc[role] = [cur];
						} else {
							acc[role].push(cur);
						}
						return acc;
					},
					{} as { [key: string]: any[] },
				);
				return Object.keys(ratingsByRole).map((role) => ({
					...record,
					role,
					candidacyRatings: ratingsByRole[role],
				}));
			})
			.flat();
	},
	transformer: (e: any, pre: any) => {
		const { award } = pre;
		const ratingFields = [
			'overall',
			...Object.keys(award)
				.filter((n) => /rating\dLabel/.test(n))
				.filter((n) => award[n])
				.map((n) => n.replace('Label', '')),
		];

		return [
			e.name,
			e.Company.name,
			e.AwardCategory.name,
			e.role,
			...e.candidacyRatings
				.reduce(
					(acc, cur) => {
						ratingFields.forEach((n, idx) => {
							if (cur[n]) {
								acc[idx].total += cur[n];
								acc[idx].count++;
							}
						});
						return acc;
					},
					ratingFields.map((n) => ({ total: 0, count: 0 })),
				)
				.map((acc) => [acc.count ? acc.total / acc.count : '', acc.count])
				.flat(),
		];
	},
};
