import React, { useCallback, useState } from 'react';
import { AwardProducerDetailQuery } from '../graphql/query/AwardProducerDetailQuery';
import { ENTITY } from '../utils/entities';
import { Query } from 'react-apollo';
import { modalHandler, sentryHandler } from '../utils/errors';
import {
	Award,
	AwardOptionFlag,
	AwardVisibility,
	Candidacy,
	// Good,
	GoodFormat,
	User,
} from '../server-types';
import { entity2String } from '../utils/toString';
import {
	Badge,
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import { t } from '../utils/labels';
import { cns, currySC, IFNTheme, registerClass } from '@food/css-manager';
import { Tag } from '@food/ui';
import {
	EntityListFieldType,
	FieldsTransformer,
	FormDefinition,
	IField,
	SubForms,
	TreeFieldType,
} from '../types/form';
import {
	assign,
	generateDefaultEntityListField,
	goodFormatsWithEAN,
	nodes,
	pluralEntityLabel,
	toRequired,
	transformerHandler,
} from '../utils/misc';
import { UserData } from '@food/auth';
import { EntityListField } from './fields/EntityListField';
import goodTypeIntro from '../static/introspection/Good.json';
import { GoodListQuery } from '../graphql/query/GoodListQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import { GoodDetailQuery } from '../graphql/query/GoodDetailQuery';
import { GoodFormatEdit } from './GoodFormatEdit';
import { GoodFormatDetail } from './GoodFormatDetail';
import { GoodFormatCreate } from './GoodFormatCreate';
import {
	fastTransformer,
	generateBaseField,
	parseFormDefinition,
	refactorInfo,
	toAsyncRO,
	toHidden,
} from '../utils/form';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import {
	generateAsyncQuery,
	generateDefaultListQuery,
	generatePaginatedSelectQuery,
	graphqlQuery,
} from '../utils/graphql';
import goodEditTypeIntro from '../static/introspection/GoodPatchInput.json';
import { GoodEditQuery } from '../graphql/query/GoodEditQuery';
import { GoodPatchMutation } from '../graphql/mutations/GoodPatchMutation';
import { GoodCreateMutation } from '../graphql/mutations/GoodCreateMutation';
import { GoodCreateQuery } from '../graphql/query/GoodCreateQuery';
import GoodCreateInputTypeIntro from '../static/introspection/GoodCreateInput.json';
import { BrandCreateQuery } from '../graphql/query/BrandCreateQuery';
import { BrandCreateMutation } from '../graphql/mutations/BrandCreateMutation';
import brandTypeIntro from '../static/introspection/Brand.json';
import brandCreationTypeIntro from '../static/introspection/BrandCreateInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { editorialSectionsToTree, getExpanded } from '../utils/tree';
import { CandidacyListQuery } from '../graphql/query/CandidacyListQuery';
import candidacyTypeIntro from '../static/introspection/Candidacy.json';
import { producerAwardCandidacyDetailQuery } from '../graphql/query/ProducerAwardCandidacyDetailQuery';
import { Candidacy_producer_Create } from './Candidacy_producer_Create';
import { Candidacy_producer_PatchMutation } from '../graphql/mutations/Candidacy_producer_PatchMutation';
import inputTypeIntro from '../static/introspection/CandidacyPatchInput.json';
import { max, required } from '../utils/validation';
import { producerAwardCandidacyEditQuery } from '../graphql/query/ProducerAwardCandidacyEditQuery';
import moment from 'moment';
import { identity } from 'ramda';
import {
	DefaultEntityList,
	DefaultEntityListProps,
} from '../components/DefaultEntityList';
import gql from 'graphql-tag';
import { Paginator } from '../components/Paginator';
import { Spinner } from '../components/MainContentSpinner';
import { CompanyDetail } from './CompanyDetail';
import { CompanyEdit } from './CompanyEdit';
import { Form } from '../components/Form';
import { FieldList } from '../components/form/FieldsList';
import { awardInvitedCompanyPatchMutation } from '../graphql/mutations/AwardInvitedCompanyPatchMutation';
import { client } from '../utils/client';
import { blockToIField } from '../utils/entities/newsletterIssue';
import { HashLink } from 'react-router-hash-link';
import {
	EntityModalState,
	EntityModalStateEnum,
} from '../components/EntityModalState';
import { ViewContext } from '../utils/EntityViewContext';
// import { Candidacy_showcase_producer_Create } from './Candidacy_showcase_producer_create';
import { EntityCreateModal, EntityDetailModal } from '../types/entities';
import { CandidacyCloneListModal } from './CandidacyCloneListModal';
import { ICustomField } from '../components/EntityListFetcher';

const goodsPageSize = 5;
const emptyClass = registerClass(
	(t: IFNTheme) => `color: ${t.colors.danger.plain};`,
);
const fullClass = registerClass(
	(t: IFNTheme) => `color: ${t.colors.success.plain};`,
);
const middleClass = registerClass(() => `color: orange;`);

export const handleCandidaciesColor = (
	current: number,
	max: number,
): string => {
	if (current === 0) {
		return emptyClass;
	} else if (current === max) {
		return fullClass;
	} else {
		return middleClass;
	}
};

// definizione, per ora statica, della struttura form di AwardInvite con il custom value
const customInviteFormDefinition: FormDefinition = {
	fields: [
		{
			name: 'alreadyPresent',
			type: 'Boolean',
			required: true,
			defaultValue: null,
		},
		{
			name: 'internationalChannels',
			type: 'Text',
			required: false,
			multiline: true,
		},
		{
			name: 'internationalPartners',
			type: 'Text',
			required: false,
			multiline: true,
		},
		{ name: 'contacts', type: 'Text', required: true, multiline: true },
	],
};
const customInviteFormDefinitionShowcase: FormDefinition = {
	fields: [
		{
			name: 'alreadyPresent',
			type: 'Boolean',
			required: true,
			defaultValue: null,
		},
		{
			name: 'internationalChannels',
			type: 'Text',
			required: false,
			multiline: true,
		},
		{
			name: 'internationalPartners',
			type: 'Text',
			required: false,
			multiline: true,
		},
		{ name: 'contacts', type: 'Text', required: true, multiline: true },
		{
			name: 'faqs',
			type: 'SubForm',
			form: SubForms.NEWSLETTER_FAQ_LIST,
			defaultValue: null,
			required: false,
		},
	],
};

const styleClass = registerClass(
	(t: IFNTheme, sc) => `

	.${sc('action-button')} {
		margin-left: ${t.ratios.xl}rem;
	}
	
	.${sc('title')} {
		font-size: ${t.ratios.xl ** 2}em;
		margin-bottom: ${t.ratios.xl}rem;
	}
	
	.${sc('big')} {
		font-size: ${t.ratios.xl}em;
	}
	
	.${sc('enormous')} {
		font-size: ${t.ratios.xl ** 4}em;
		color: black;
		font-weight: ${t.font.weights.semibold};
	}
	
	.${sc('actions')} {
		display: flex;
		justify-content: space-between;
	}
	
	.${sc('divider')} {
		border-left: 1px solid #dee2e6;
	}
	
	.${sc('good-sub-header > td')} {
		font-weight: ${t.font.weights.bold};
		border-top: 0;
		color: #54667a;
		text-transform: uppercase;
	}
	
	.${sc('table')} {
		color: #54667a;
		
		td {
			padding: .5rem;
		}
	}
	
	.paginator > select.form-control {
		margin-top: 0;
		margin-right: 0;
	}
	
	.${sc('check-error')} {
		display: flex;
		flex-direction: row;
	}
	
	.${sc('check-error-description')} {
		color: ${t.colors.ink};
		font-size: 1em;
	}
	
	.${sc('check-error-title')}, .${sc('check-error-icon')} {
		color: #f62d51;
	}
	
	.${sc('check-error-icon')} {
		margin-right: ${t.ratios.xs / 2}em;
		line-height: ${t.ratios.xl}em;
	}
	
	.${sc('no-padding')} {
		padding: 0;
	}
`,
);

// ho necessita' di utilizzare una classe per adeguare la grandezza del testo nei nostri componenti Tag
// noi tendiamo a non usare i px ma, in quanto questa volta dobbiamo uniformarci con bootstrap, siamo costretti
const smallTagClass = registerClass(() => `font-size: 12px;`);

const noPadding = registerClass(
	() => `
.card-body {
	padding: 0;
}`,
);

const sc = currySC(styleClass);

interface queryResultType {
	entity: Award;
	currentUser: User;
}

export const AwardProducerDetail: React.FC<{ id: string }> = ({ id }) => {
	const [selectedGood, selectGood] = useState(undefined);
	const [detailModalOpen, setDetailModalOpen] = useState(false);
	const [inviteDataModalOpen, setInviteDataModalOpen] = useState(false);
	const [
		candidacyCloneListModalOpen,
		setCandidacyCloneListModalOpen,
	] = useState(false);

	const awardInviteEditPatch = useCallback(async (id: string, values: any) => {
		try {
			const json = JSON.stringify(values);
			const variables = {
				id,
				changes: {
					formValues: json,
				},
			};
			await client.mutate({
				mutation: awardInvitedCompanyPatchMutation,
				variables,
			});
		} catch (e) {
			sentryHandler([e]);
			console.error(e);
			modalHandler([e]);
		}
		setInviteDataModalOpen(false);
	}, []);

	return (
		<UserData>
			{(user) => (
				<Query<queryResultType, { id: string }>
					query={AwardProducerDetailQuery}
					variables={{ id }}
					fetchPolicy={'network-only'}
				>
					{({ error, loading, data, refetch }) => {
						if (error) {
							modalHandler(error);
							sentryHandler(error);
							return null;
						}

						if (loading) {
							return <Spinner show={true} />;
						}

						const award: Award = data.entity;

						// gestisco il form custom relativo all'award invite
						const customAwardInviteFields = parseFormDefinition(
							award.visibility === AwardVisibility.Showcase
								? customInviteFormDefinitionShowcase
								: customInviteFormDefinition,
						);
						const awardInvitedCompany =
							award?.invitedCompanies.edges?.[0]?.node;
						const inviteFormValues = awardInvitedCompany?.formValues;
						const hasCompletedInviteData = !!inviteFormValues;
						let hasCompletedFAQ = false;

						// Customize internationalPartnersField.helpText
						const internationalPartnersField = customAwardInviteFields.find(
							(field) => field.name === 'internationalPartners',
						);
						if (internationalPartnersField) {
							internationalPartnersField.helpText = t(
								'internationalPartners/helpText ' + award.visibility,
							);
						}

						if (inviteFormValues) {
							// se ho i valori impostati nell'AwardInvite li parso e li inietto nel form correlato
							const parsed = JSON.parse(inviteFormValues);
							customAwardInviteFields.forEach((field) => {
								if (parsed[field.name] !== undefined) {
									if (field.name === 'faqs') {
										const val = parsed[field.name];
										field.value = val.map(blockToIField);
										hasCompletedFAQ =
											Array.isArray(val) && val.length >= 5;
									} else {
										field.value = parsed[field.name];
									}
								}
							});
						}

						const completedInviteDescriprionKey = !hasCompletedInviteData
							? award.visibility === AwardVisibility.Showcase
								? 'not completed invite data and FAQ description html'
								: 'not completed invite data description html'
							: award.visibility === AwardVisibility.Showcase &&
							  !hasCompletedFAQ
							? 'completed invite data but missing FAQ description html'
							: 'completed invite data description html';

						const managed = data.currentUser.managedByUsers[0];
						const company = managed ? managed.Company : null;
						const numGoods = company ? company.goods.length : 0;
						const numCandidacies = award.candidacies.length;
						const title = entity2String(ENTITY.AWARD, award);
						const eanRequired =
							award.options.indexOf(AwardOptionFlag.EanRequired) !== -1;
						const awardHasExtraMediaType = award.extraMediaType;
						const extraMediaRequired =
							award.options.indexOf(
								AwardOptionFlag.ExtramediaRequired,
							) !== -1;
						const launchDateRequired =
							award.options.indexOf(
								AwardOptionFlag.LaunchdateRequired,
							) !== -1;
						const signedContractRequired =
							award.options.indexOf(
								AwardOptionFlag.SignedContractRequired,
							) !== -1;
						const currentOpenInvite =
							award.invitedCompanies &&
							award.invitedCompanies.edges[0] &&
							award.invitedCompanies.edges[0].node;

						const awardRequiresOneProduct =
							currentOpenInvite.maxGoodsPerCandidacy === 1 ||
							(!currentOpenInvite.maxGoodsPerCandidacy &&
								award.maxGoodsPerCandidacy === 1);

						const maxCandidacies =
							(currentOpenInvite &&
								currentOpenInvite.maxPerCompanyCandidacies) ||
							award.maxPerCompanyCandidacies;

						const awardIsIT = award.langCode === 'it_IT';

						const lastYear = new Date().getFullYear();
						const lastCompanyYear = company.companyYears.find(
							(c) => c.year === lastYear || c.year === lastYear - 1,
						);

						const hasGallery =
							award.options.indexOf(AwardOptionFlag.GalleryEnabled) !==
							-1;

						const awardRequiresIngredientsImage =
							award.options.indexOf(
								AwardOptionFlag.IngredientsImageRequired,
							) !== -1;

						const variableLabel = (s: string): string =>
							t(s + ' ' + award.visibility);

						const checkLabel = (check: string): string =>
							t(`checklist ${award.visibility} ${check}`);

						const dayTilClose = Math.max(
							moment(award.candidaciesCloseAt).diff(moment(), 'days'),
							0,
						);

						const reflectChangesInEN = awardIsIT
							? (data) =>
									Object.assign(data, {
										name: data.nameIta,
										featuredImageMedia: data.featuredImageItaMedia,
									})
							: identity;

						const formatsBaseField = selectedGood
							? generateDefaultEntityListField(
									'formats',
									user,
									ENTITY.GOOD_FORMAT,
									selectedGood,
							  )
							: null;

						const formatsListField: IField = selectedGood
							? {
									...formatsBaseField,
									editComponent: GoodFormatEdit,
									detailComponent: GoodFormatDetail,
									addComponent: GoodFormatCreate,
									entityLabel: ENTITY.GOOD_FORMAT,
									props: {
										...formatsBaseField.props,
										hideTop: true,
										inlineDelete: true,
										hideTitle: true,
										className: noPadding,
										editOnly: true,
										headers: [],
										customFields: [
											{
												header: 'data',
												field: (e: GoodFormat) => ({
													name: ENTITY.GOOD_FORMAT,
													label: t(ENTITY.GOOD_FORMAT),
													type: 'Text',
													value: `${e.cuPerSellingPack} x ${
														e.cuMesureValue
													} ${t(e.cuMesureUnit)}`,
												}),
											},
											{
												header: 'ean',
												field: (e: GoodFormat) => ({
													name: 'ean',
													label: 'ean',
													type: 'Text',
													value: e.ean,
												}),
											},
										],
										originalEntity: selectedGood,
										fragment: gql`
											fragment Fragment on GoodFormat {
												ean
												cuPerSellingPack
												cuMesureValue
												cuMesureUnit
											}
										`,
										query: generateDefaultListQuery(
											ENTITY.GOOD,
											'formats',
											false,
										),
										additionalVariables: {
											id: selectedGood.id,
										},
										extractor: (data) => data.node.formats,
										post: (entities, addModalOpener) => {
											return (
												<div className={'text-right'}>
													<Button
														onClick={() =>
															setDetailModalOpen(false)
														}
													>{t`Close`}</Button>
													<Button
														color={'success'}
														onClick={() => addModalOpener()}
														className={'margin-left'}
													>
														<i className={'fa fa-plus'} />{' '}
														{t`create`} {t(ENTITY.GOOD_FORMAT)}
													</Button>
												</div>
											);
										},
									},
							  }
							: null;

						// Form in modale per il dettaglio di un prodotto
						const ProducerAwardGoodDetail = ({ id, ...props }) => (
							<EntityDetailFetcher
								id={id}
								modal={true}
								{...props}
								query={GoodDetailQuery}
								entityName={ENTITY.GOOD}
								typeIntro={goodTypeIntro}
								fields={[
									'nameIta',
									!awardIsIT && 'name',
									'Brand',
									'range',
									'featuredImageItaMedia',
									!awardIsIT && 'featuredImageMedia',
									awardRequiresIngredientsImage &&
										awardIsIT &&
										'ingredientsImageItaMedia',
									awardRequiresIngredientsImage &&
										!awardIsIT &&
										'ingredientsImageMedia',
									'GoodClass',
									'goodFeatureCertifications',
									'shelfLifeLength',
									'descriptionIta',
									!awardIsIT && 'description',
									'descriptionLongIta',
									!awardIsIT && 'descriptionLong',
									'GeoIndication',
								]}
								forcedPermissions={{
									canCreate: true,
									canEdit: true,
									canDelete: false,
								}}
								transformer={transformerHandler(
									fastTransformer({
										goodFeatureCertifications: assign({
											type: 'AsyncChoices', // lo faccio diventare un elenco invece di una lista completa
										}),
										GoodClass: assign({
											myToString: (entity) => entity.nameIta,
										}),
									}),
								)}
							/>
						);

						// form in modale per modifica prodotto
						const ProducerAwardGoodEdit = ({ id, ...props }) => (
							<EntityEditFetcher
								id={id}
								{...props}
								modal={true}
								fetcher={graphqlQuery(GoodEditQuery, { id })}
								entityName={ENTITY.GOOD}
								typeIntro={goodTypeIntro}
								inputTypeIntro={goodEditTypeIntro}
								mutation={GoodPatchMutation}
								fields={[
									'nameIta',
									!awardIsIT && 'name',
									'Brand',
									'range',
									'featuredImageItaMedia',
									!awardIsIT && 'featuredImageMedia',
									awardRequiresIngredientsImage &&
										awardIsIT &&
										'ingredientsImageItaMedia',
									awardRequiresIngredientsImage &&
										!awardIsIT &&
										'ingredientsImageMedia',
									'GoodClass',
									'goodFeatureCertifications',
									'shelfLifeLength',
									'descriptionIta',
									!awardIsIT && 'description',
									'descriptionLongIta',
									!awardIsIT && 'descriptionLong',
									'GeoIndication',
								]}
								entitiesToFields={(props) => ({
									EditorialSections: props.editorialSectionTree,
									certifications: props.goodFeatureCertifications,
									brands: nodes(props.brands),
								})}
								transformer={candidacyGoodTransformer}
							/>
						);

						/**
						 * ProducerAwardGoodCreate e' il componente di creazione Good specifico per questa pagina. Si tratta
						 * di una normale interfaccia per creazione di entita' di secondo livello. Utilizza il transformer
						 * "candidacyGoodTransformer", il quale trasforma il campo "Brand" in modo da permetterne la creazione
						 * inline. In questo particolare caso si puo' ritenere sicuro il passaggio di "props" con
						 * destrutturazione.
						 */
						const ProducerAwardGoodCreate = (props) => (
							<EntityEditFetcher
								{...props}
								fetcher={graphqlQuery(GoodCreateQuery)}
								create={true}
								modal={true}
								entityName={ENTITY.GOOD}
								typeIntro={goodTypeIntro}
								inputTypeIntro={GoodCreateInputTypeIntro}
								mutation={GoodCreateMutation}
								beforeSaveTransformer={reflectChangesInEN}
								fields={[
									'nameIta',
									!awardIsIT && 'name',
									'Brand',
									'range',
									'featuredImageItaMedia',
									!awardIsIT && 'featuredImageMedia',
									awardRequiresIngredientsImage &&
										awardIsIT &&
										'ingredientsImageItaMedia',
									awardRequiresIngredientsImage &&
										!awardIsIT &&
										'ingredientsImageMedia',
									'GoodClass',
									'goodFeatureCertifications',
									'shelfLifeLength',
									'descriptionIta',
									!awardIsIT && 'description',
									'descriptionLongIta',
									!awardIsIT && 'descriptionLong',
									'GeoIndication',
								]}
								entitiesToFields={(props) => ({
									EditorialSections: props.editorialSectionTree,
									certifications: props.goodFeatureCertifications,
									brands: nodes(props.brands),
								})}
								transformer={candidacyGoodTransformer}
							/>
						);

						const ProducerAwardCandidacyDetail: React.FC<EntityDetailModal<
							Award
						>> = ({ id, onDelete }) => (
							<EntityDetailFetcher
								id={id}
								onDelete={onDelete}
								query={producerAwardCandidacyDetailQuery}
								modal={true}
								typeIntro={candidacyTypeIntro}
								clonable={false}
								entityName={ENTITY.CANDIDACY}
								entityLabel={ENTITY.CANDIDACY + '_' + award.visibility}
								fields={[
									'Brand',
									'goods',
									'name',
									'featuredImageMedia',
									awardHasExtraMediaType ? 'extraMedia' : null,
									'description1',
									'description2',
									'description3',
									'description4',
									'description5',
									'description6',
									'description7',
									'description8',
									'description9',
									'description10',
									'launchDate',
									hasGallery ? 'additionalImagesMedia' : null,
								]}
								transformer={(data) => {
									// le descrizioni possono essere nascoste a seconda del valore dell'Award a cui
									// si riferisce la Candidacy. Anche la loro lunghezza e' definita a livello
									// di campi dell'Award
									data.fields = data.fields.filter(
										(f) =>
											f.name.indexOf('description') === -1 ||
											!!award[f.name + 'Label'],
									);

									data.fields = data.fields.map((f) => {
										switch (f.name) {
											case 'description1':
											case 'description2':
											case 'description3':
											case 'description4':
											case 'description5':
											case 'description6':
											case 'description7':
											case 'description8':
											case 'description9':
											case 'description10':
												const labelsPart = award[
													f.name + 'Label'
												].split('|');
												f.label = labelsPart[0].replace('$', '');
												f.helpText = labelsPart[1] || '';
												break;

											case 'Brand':
											case 'goodFeatureCertifications':
												if (f.type === 'AsyncChoices')
													f.mapToEntity = null;
												break;

											case 'goods':
												if (f.type !== 'EntityList') {
													refactorInfo(f, 'EntityList');
													break;
												}

												const newField = toAsyncRO(f, ENTITY.GOOD);

												if (awardRequiresOneProduct) {
													newField.label = t(ENTITY.GOOD) + '*';
												}
												const lens =
													award.langCode === 'it_IT'
														? 'nameIta'
														: 'name';

												newField.myToString = (e) =>
													`${e[lens]}${
														eanRequired
															? ' (' +
															  goodFormatsWithEAN(e).length +
															  ' EAN)'
															: ''
													}`;
												return newField;

											case 'extraMedia':
												if (f.type === 'Media') {
													const labels =
														award.extraMediaLabel &&
														award.extraMediaLabel.split('|');
													if (labels) {
														f.label = labels[0];
														f.helpText = labels[1];
													}
													f.mediaType = awardHasExtraMediaType;
												}
												break;

											default:
												break;
										}
										return f;
									});
									return data;
								}}
							/>
						);

						const ProducerAwardCandidacyCreate: React.FC<EntityCreateModal<
							Candidacy,
							Award
						>> = (props) => (
							<Candidacy_producer_Create
								{...props}
								originalEntity={award}
								entityLabel={ENTITY.CANDIDACY + '_' + award.visibility}
							/>
						);

						const ProducerAwardCandidacyEdit = (props) => (
							<EntityEditFetcher
								{...props}
								originalEntity={award}
								fetcher={graphqlQuery(producerAwardCandidacyEditQuery, {
									id: props.id,
								})}
								modal={true}
								disableCache={true}
								entityName={ENTITY.CANDIDACY}
								entityLabel={ENTITY.CANDIDACY + '_' + award.visibility}
								mutation={Candidacy_producer_PatchMutation}
								typeIntro={candidacyTypeIntro}
								inputTypeIntro={inputTypeIntro}
								fields={[
									'name',
									'featuredImageMedia',
									awardHasExtraMediaType ? 'extraMedia' : null,
									'description1',
									'description2',
									'description3',
									'description4',
									'description5',
									'description6',
									'description7',
									'description8',
									'description9',
									'description10',
									'launchDate',
									hasGallery ? 'additionalImagesMedia' : null,
								]}
								transformer={(data, entity) => {
									// le descrizioni possono essere nascoste a seconda del valore dell'Award a cui
									// si riferisce la Candidacy. Anche la loro lunghezza e' definita a livello
									// di campi dell'Award
									data.fields = data.fields.filter(
										(f) =>
											f.name.indexOf('description') === -1 ||
											!!award[f.name + 'Label'],
									);

									data.fields.forEach((f) => {
										switch (f.name) {
											case 'description1':
											case 'description2':
											case 'description3':
											case 'description4':
											case 'description5':
											case 'description6':
											case 'description7':
											case 'description8':
											case 'description9':
											case 'description10':
												const labelsPart = award[
													f.name + 'Label'
												].split('|');
												f.label = labelsPart[0].replace('$', '');
												f.helpText = labelsPart[1] || '';

												const hasMax = award[f.name + 'Max'];
												if (hasMax) {
													f.description.max = hasMax; // mettendo la voce in description mimo la forma in cui il server definisce il massimo per i campi di input graphql
													f.validators = f.validators.concat([
														max(hasMax, f),
													]);
												}

												if (f.label.indexOf('*') !== -1) {
													f.required = true;
													f.validators = f.validators.concat([
														required,
													]);
												}
												break;

											case 'name':
												// se ho selezionato un solo prodotto questi campi sono disabilitati
												if (entity.goods.length === 1) {
													f.disabled = true;
													f.helpText = t(
														'name will be derived from good name ' +
															award.visibility,
													);
												}
												break;

											case 'launchDate':
												if (launchDateRequired) {
													f.validators = f.validators.concat([
														required,
													]);
													f.required = true;
													f.label += '*';
												}
												break;

											case 'extraMedia':
												if (f.type === 'Media') {
													const labels =
														award.extraMediaLabel &&
														award.extraMediaLabel.split('|');
													if (labels) {
														f.label = labels[0];
														f.helpText = labels[1];
													}
													f.mediaType = awardHasExtraMediaType;
													if (extraMediaRequired) {
														f.validators = f.validators.concat([
															required,
														]);
														f.required = true;
														f.label += '*';
													}
												}
												break;

											default:
												break;
										}
									});
									return data;
								}}
							/>
						);

						const ProducerAwardBrandCreate = (props) => (
							<EntityEditFetcher
								{...props}
								create={true}
								modal={true}
								fetcher={graphqlQuery(BrandCreateQuery)}
								mutation={BrandCreateMutation}
								fields={[
									'name',
									'logoMedia',
									'pricePositioning',
									'retailChainDistribution',
									'foodServiceChainDistribution',
									'gourmetShopChainDistribution',
									'Company',
								]}
								entityName={ENTITY.BRAND}
								typeIntro={brandTypeIntro}
								inputTypeIntro={brandCreationTypeIntro}
								transformer={transformerHandler(
									fastTransformer({
										Company: toHidden(toRefInput(company)),
									}),
								)}
							/>
						);

						/**
						 * "candidacyGoodTransformer" e' un trasformer utilizzato solo dalla creazione e dalla modifica Good
						 * presenti in questo componente. Alcuni dettagli degni di nota:
						 * - trasforma il campo "Brand" permettendone la creazione inline
						 * - trasforma il campo GoodClass in un albero, aggiustando il valore pre-caricato in caso venga
						 *   richiamato nella modifica Good
						 */
						const candidacyGoodTransformer = (
							data,
							entity,
							listsByType,
						) => {
							const trans: FieldsTransformer = fastTransformer({
								Brand: assign({
									asyncQuery: generateAsyncQuery(
										generatePaginatedSelectQuery(ENTITY.BRAND, false),
										true,
										(value) => ({
											filter: {
												name: value,
											},
										}),
										(e) => e.choices,
										{
											fetchPolicy: 'network-only',
										},
									),
									addComponent: (props) => (
										<ProducerAwardBrandCreate {...props} />
									),
								}),
								shelfLifeLength: assign({
									additionalHelpComponent: (
										<div>
											<Badge color="warning">{t`warning`}</Badge>{' '}
											{t`shelf life is in days`}
										</div>
									),
								}),
								GoodClass: (f: IField): TreeFieldType => {
									const newField: TreeFieldType = {
										...generateBaseField(f.name, f.label),
										type: 'Tree',
										beforeSaveTransformer: (e) => e[0],
										single: true,
										nodes: editorialSectionsToTree(
											listsByType.EditorialSections,
										),
									};

									if (entity) {
										newField.value =
											f.value && f.value.value
												? [toRefInput(f.value.value)]
												: [];
										newField.expanded = getExpanded(
											listsByType.EditorialSections,
											[toRefInput(f.value.value)],
										);
									}
									return newField;
								},
								descriptionLongIta: (f) => toRequired(f, false),
							});
							data.fields = trans(data.fields);
							return data;
						};

						const CustomCandidaciesList: React.FC<DefaultEntityListProps> = (
							props,
						) => {
							return (
								<Card>
									<CardBody>
										<div className={sc('check-block')}>
											<div className={sc('actions')}>
												<h2 className={sc('title')} id={'check3'}>
													3.{' '}
													{checkLabel(
														'step has candidacies title',
													)}
												</h2>
												<h2
													className={cns(
														sc('title'),
														handleCandidaciesColor(
															props.records.length,
															maxCandidacies,
														),
													)}
												>
													{props.records.length}/{maxCandidacies}
												</h2>
											</div>

											{companyIsComplete && numGoods > 0 && (
												<>
													<p
														dangerouslySetInnerHTML={{
															__html: candidaciesCheckIntro,
														}}
													/>
													<DefaultEntityList {...props} />
												</>
											)}
											{companyIsComplete && numGoods === 0 && (
												<Card>
													<CardBody className={'text-center'}>
														<h3
															className={cns(
																'text-danger',
																sc('big'),
															)}
														>
															{variableLabel(
																'no goods for candidacies message',
															)}
														</h3>
													</CardBody>
												</Card>
											)}
											{!companyIsComplete && (
												<Card>
													<CardBody className={'text-center'}>
														<h3
															className={cns(
																'text-danger',
																sc('big'),
															)}
														>
															{variableLabel(
																'company not completed message',
															)}
														</h3>
													</CardBody>
												</Card>
											)}
											{companyIsComplete &&
												signedContractRequired &&
												props.records.length > 0 && (
													<p className={'text-center'}>
														{currentOpenInvite.signedContract && (
															<h3
																className={'text-success'}
															>{t`signed contract message`}</h3>
														)}
														{!currentOpenInvite.signedContract && (
															<h3
																style={{
																	color: 'orange',
																}}
															>{t`not signed contract message`}</h3>
														)}
													</p>
												)}
										</div>
									</CardBody>
								</Card>
							);
						};

						const CustomGoodsList: React.FC<DefaultEntityListProps> = ({
							records,
							detailModalOpener,
							addModalOpener,
							paginated,
							pageInfo,
							changePage,
							post,
						}) => (
							<Card>
								<CardBody>
									<div className={sc('check-block')}>
										<h2 className={sc('title')} id={'check2'}>
											2.{' '}
											{t(`checklist step ${goodsCheckName} title`)}
										</h2>
										<p
											dangerouslySetInnerHTML={{
												__html:
													award.vademecumGoods ||
													t(
														`checklist step ${goodsCheckName} html`,
													),
											}}
										/>
										<div className={sc('actions')}>
											<h3 className={'text-themecolor'}>
												{t(pluralEntityLabel(ENTITY.GOOD))}
											</h3>
											<div>
												<Button
													color={'success'}
													onClick={() => addModalOpener()}
												>
													<i className={'fa fa-plus'} />{' '}
													{t`create new`}{' '}
													{t(ENTITY.GOOD).toLowerCase()}
												</Button>
											</div>
										</div>
										{records.length > 0 && (
											<table className={'table'}>
												<thead>
													<tr className={sc('good-sub-header')}>
														<td>{t`Name`}</td>
														<td>{t(ENTITY.BRAND)}</td>
														{awardRequiresIngredientsImage && (
															<td>{t`Ingredients image`}</td>
														)}
														<td />
														{eanRequired && (
															<>
																<td
																	className={sc('divider')}
																	colSpan={2}
																>
																	{t`associated eans`}
																</td>
															</>
														)}
													</tr>
												</thead>
												<tbody className={sc('table')}>
													{records.map((good, index) => {
														const eans = good.formats.filter(
															(f) => f.ean,
														).length;
														return (
															<tr key={index}>
																<td>
																	{awardIsIT
																		? good.nameIta
																		: good.name}
																</td>
																<td>{good.Brand.name}</td>
																{awardRequiresIngredientsImage && (
																	<td>
																		{(awardIsIT ? (
																			good.ingredientsImageItaMedia
																		) : (
																			good.ingredientsImageMedia
																		)) ? (
																			<Tag
																				className={
																					smallTagClass
																				}
																				label={t`Yes`}
																				type={'success'}
																			/>
																		) : (
																			<Tag
																				className={
																					smallTagClass
																				}
																				label={t`No`}
																				type={'danger'}
																			/>
																		)}
																	</td>
																)}
																<td
																	className={
																		!eanRequired
																			? 'text-right'
																			: ''
																	}
																>
																	<Button
																		size="sm"
																		color="success"
																		onClick={() =>
																			detailModalOpener(good)
																		}
																	>
																		<i className="fa fa-search" />{' '}
																		{t`detail`}
																	</Button>
																</td>
																{eanRequired && (
																	<>
																		<td
																			className={sc(
																				'divider',
																			)}
																		>
																			<Tag
																				type={
																					eans === 0 &&
																					eanRequired
																						? 'danger'
																						: 'success'
																				}
																				label={eans}
																				className={
																					smallTagClass
																				}
																			/>
																		</td>

																		<td>
																			<div
																				className={
																					'text-right'
																				}
																			>
																				<Button
																					size="sm"
																					color="success"
																					onClick={() => {
																						selectGood(
																							good,
																						);
																						setDetailModalOpen(
																							true,
																						);
																					}}
																				>
																					<i className="fa fa-search" />{' '}
																					{t`create/edit`}
																				</Button>
																			</div>
																		</td>
																	</>
																)}
															</tr>
														);
													})}
												</tbody>
											</table>
										)}
									</div>
									{paginated !== false &&
										pageInfo.total > goodsPageSize && (
											<div className={'text-right'}>
												<Paginator
													{...pageInfo}
													pageChanger={changePage}
												/>
											</div>
										)}
									{post && post(records, addModalOpener)}
								</CardBody>
							</Card>
						);

						const goodsCheckName =
							'has goods' + (eanRequired ? ' with ean' : '');

						const companyChecks = company
							? [
									{
										value: !company.logoMedia,
										text: 'no logoMedia on company',
									},
									{
										value:
											!company.companyCertifications ||
											company.companyCertifications.length === 0,
										text: 'no companyCertifications on company',
									},
									{
										value: !awardIsIT && !company.description,
										text: 'company description empty',
									},
									{
										value: !company.descriptionIta,
										text: 'company description ita empty',
									},
									{
										value: !awardIsIT && !company.descriptionLong,
										text: 'company description long empty',
									},
									{
										value:
											award.visibility !== AwardVisibility.Vote &&
											!lastCompanyYear,
										text: 'company last year empty',
									},
							  ]
							: [];

						const goodListFieldBase = generateDefaultEntityListField(
							'foods',
							user,
							ENTITY.GOOD,
							null,
						);

						const companyIsComplete = companyChecks.every(
							(check) => !check.value,
						);

						// I campi custom di goodsListField, creati separatamente per favorire la leggibilita' del codice.
						const goodsListFieldCustomFields: ICustomField[] = [];

						/*console.log(eanRequired, awardIsShowcase, awardRequiresIngredientsImage)
						
						// Da una parte, se il premio richiede gli EAN, faccio vedere una colonna booleana che determina se gli ean sono presenti o meno
						if (eanRequired && !awardIsShowcase) {
							goodsListFieldCustomFields.push({
								header: 'hasEan',
								field: (entity: Good) => ({
									name: 'hasEan',
									label: t`has ean?`,
									type: 'Boolean',
									value: entity.formats.some(
										(f) => f.ean,
									),
								}),
							})
						}
						
						// Dall'altra gestisco eventuali richieste di immagini ingredienti, coniugate a seconda della lingua del premio
						if (awardRequiresIngredientsImage) {
							goodsListFieldCustomFields.push({
								header: 'hasIngredientsImage',
								field: (entity: Good) => ({
									name: 'hasIngredientsImage',
									label: t`has Ingredients image?`,
									type: 'Boolean',
									value: awardIsIT ? entity.ingredientsImageItaMedia : entity.ingredientsImageMedia,
								}),
							})
						}*/

						// l'IField usato per renderizzare la lista di Good appartenenti all'azienda
						const goodsListField: EntityListFieldType = {
							...goodListFieldBase,
							detailComponent: ProducerAwardGoodDetail,
							editComponent: ProducerAwardGoodEdit,
							addComponent: ProducerAwardGoodCreate,
							entityLabel: ENTITY.GOOD,
							props: {
								...goodListFieldBase.props,
								children: CustomGoodsList,
								pageSize: goodsPageSize,
								query: GoodListQuery,
								typeIntro: goodTypeIntro,
								entityName: ENTITY.GOOD,
								paginated: true,
								headers: [awardIsIT ? 'nameIta' : 'name', 'Brand'],
								isModal: true,
								customAddLabel: t`add good action`,
								post: (entities, addModalOpener) => (
									<div className={'text-center'}>
										{entities.length === 0 && (
											<h2
												className={'text-danger'}
											>{t`no goods message`}</h2>
										)}
										<Button
											size={'lg'}
											color={'success'}
											onClick={() => addModalOpener()}
										>
											{t`add good action`}
										</Button>
									</div>
								),
								customFields: goodsListFieldCustomFields,
							},
						};

						const candidaciesListFieldBase = generateDefaultEntityListField(
							'candidacies',
							user,
							ENTITY.CANDIDACY,
							null,
						);

						const candidaciesListField: EntityListFieldType = {
							...candidaciesListFieldBase,
							detailComponent: ProducerAwardCandidacyDetail,
							editComponent: ProducerAwardCandidacyEdit,
							addComponent: ProducerAwardCandidacyCreate,
							entityLabel: ENTITY.CANDIDACY,
							props: {
								...candidaciesListFieldBase.props,
								customDeleteLabel: t`delete`,
								inlineDelete: true,
								query: CandidacyListQuery,
								typeIntro: candidacyTypeIntro,
								entityName: ENTITY.CANDIDACY,
								entityLabel: ENTITY.CANDIDACY + ' ' + award.visibility,
								paginated: true,
								headers: ['name'],
								hideTop: true,
								hideBottom: true,
								hideTitle: true,
								modal: true,
								children: CustomCandidaciesList,
								isModal: true,
								additionalVariables: {
									filter: {
										Award: {
											id: award.id,
										},
									},
								},
								denyAdd: true,
								hideEmptyMessage: true,
								post: (entities, addModalOpener) => {
									return (
										<div className={'text-center'}>
											<CandidacyCloneListModal
												open={candidacyCloneListModalOpen}
												onChose={(id) => {
													setCandidacyCloneListModalOpen(false);
													addModalOpener(id);
												}}
												currentAwardId={award.id}
												currentAwardVisibility={award.visibility}
												toggle={() =>
													setCandidacyCloneListModalOpen(false)
												}
											/>
											{entities.edges.length === 0 && (
												<>
													<h2
														className={handleCandidaciesColor(
															numCandidacies,
															maxCandidacies,
														)}
													>{t`no candidacies message`}</h2>
													<Button
														size={'lg'}
														color={'success'}
														onClick={() => addModalOpener()}
													>
														{t(
															'add candidacy action ' +
																award.visibility,
														)}
													</Button>
													{award.visibility !==
														AwardVisibility.Vote && (
														<Button
															size={'lg'}
															color={'success'}
															onClick={() =>
																setCandidacyCloneListModalOpen(
																	true,
																)
															}
															className={'margin-left'}
														>
															{t(
																`clone candidacy ` +
																	award.visibility,
															)}
														</Button>
													)}
												</>
											)}
											{entities.edges.length > 0 &&
												entities.edges.length < maxCandidacies && (
													<>
														<h2
															className={handleCandidaciesColor(
																entities.edges.length,
																maxCandidacies,
															)}
														>
															{t(
																'still space for candidacies message ' +
																	award.visibility,
															)}
														</h2>
														<Button
															size={'lg'}
															color={'success'}
															onClick={() => addModalOpener()}
														>
															{t(
																'add candidacy action ' +
																	award.visibility,
															)}
														</Button>
														{award.visibility !==
															AwardVisibility.Vote && (
															<Button
																size={'lg'}
																color={'success'}
																onClick={() =>
																	setCandidacyCloneListModalOpen(
																		true,
																	)
																}
																className={'margin-left'}
															>
																{t(
																	`clone candidacy ` +
																		award.visibility,
																)}
															</Button>
														)}
													</>
												)}
											{entities.edges.length === maxCandidacies && (
												<h2
													className={handleCandidaciesColor(
														entities.edges.length,
														maxCandidacies,
													)}
												>
													{variableLabel(
														'no more candidacies allowed',
													)}
												</h2>
											)}
										</div>
									);
								},
							},
						};

						const companyCheckIntro =
							award.vademecumCompany ||
							checkLabel('step company completed html');

						const candidaciesCheckIntro =
							award.vademecumCandidacies ||
							checkLabel('step has candidacies html');

						const hasGoods = numGoods > 0;
						const hasCandidacies = numCandidacies > 0;

						const checks = [
							companyIsComplete,
							hasGoods,
							hasCandidacies,
							award.visibility === AwardVisibility.Vote ||
								hasCompletedInviteData,
						];

						const totalCheck = checks.every(identity);
						const firstErrorStep = checks.indexOf(false);

						return (
							<div className={styleClass}>
								{/* Modale per visualizzare le liste di GoodFormat di un good selezionato */}
								<Modal
									isOpen={detailModalOpen}
									toggle={() => setDetailModalOpen(false)}
									size={'xl'}
								>
									<ModalHeader
										toggle={() => setDetailModalOpen(false)}
									>
										{t(pluralEntityLabel(ENTITY.GOOD_FORMAT))}
									</ModalHeader>
									<ModalBody>
										{formatsListField !== null && (
											<EntityListField
												field={formatsListField}
												modal={true}
											/>
										)}
									</ModalBody>
								</Modal>

								{/* Modale per la modifica del form personalizzato i cui valori andranno nell'AwardInvitedCompany */}
								<Modal
									isOpen={inviteDataModalOpen}
									toggle={() => setInviteDataModalOpen(false)}
									size={'xl'}
								>
									<Form
										fields={customAwardInviteFields}
										onSubmit={(fields, finalValues) => {
											awardInviteEditPatch(
												awardInvitedCompany.id,
												finalValues,
											);
										}}
									>
										{({ fields, mutatorFactory, submit }) => {
											return (
												<>
													<ModalHeader>
														{t`edit awardInvite custom data`}
													</ModalHeader>
													<ModalBody>
														<FieldList
															fields={fields}
															setter={mutatorFactory}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															color="secondary"
															onClick={() =>
																setInviteDataModalOpen(false)
															}
														>
															<i className="fa fa-times" />{' '}
															{t`cancel`}
														</Button>
														<Button
															color={'success'}
															className={'margin-left'}
															onClick={() => submit()}
														>
															<i className={'fa fa-save'} />{' '}
															{t`save`}
														</Button>
													</ModalFooter>
												</>
											);
										}}
									</Form>
								</Modal>

								<Row>
									<Col sm={8}>
										<Card>
											<CardBody>
												<h1 className="text-themecolor">{title}</h1>
												<p
													dangerouslySetInnerHTML={{
														__html: award.descriptionForProducer,
													}}
												/>
											</CardBody>
										</Card>
										<Card>
											<CardBody>
												<EntityModalState>
													{(mode, changeState) => (
														<>
															<ViewContext.Provider
																value={{
																	type: 'modal',
																	mode,
																	changeState,
																}}
															>
																<CompanyDetail
																	id={company.id}
																/>

																<CompanyEdit id={company.id} />
															</ViewContext.Provider>

															<div className={sc('check-block')}>
																<h2
																	className={sc('title')}
																	id={'check1'}
																>
																	1.{' '}
																	{checkLabel(
																		'step company completed title',
																	)}
																</h2>
																<p
																	dangerouslySetInnerHTML={{
																		__html: companyCheckIntro,
																	}}
																/>
																{companyIsComplete && (
																	<>
																		<h2
																			className={cns(
																				'text-success',
																				'checklist-info',
																			)}
																		>
																			<i className="fa fa-check" />{' '}
																			{t(
																				`checklist step company completed ok`,
																			)}
																		</h2>
																		<div
																			className={
																				'text-center'
																			}
																		>
																			<Button
																				color={'success'}
																				size={'lg'}
																				onClick={() =>
																					changeState(
																						EntityModalStateEnum.Detail,
																					)
																				}
																			>
																				{t`review company data`}
																			</Button>
																		</div>
																	</>
																)}
																{!companyIsComplete && (
																	<div>
																		<div
																			className={cns(
																				'text-danger',
																				sc('big'),
																			)}
																		>
																			{t`missing company requirements`}
																			:
																		</div>
																		{companyChecks
																			.filter(
																				(check) =>
																					check.value,
																			)
																			.map(({ text }) => (
																				<div key={text}>
																					<div
																						className={sc(
																							'check-error',
																						)}
																					>
																						<i
																							className={cns(
																								'fa fa-times-circle',
																								sc(
																									'check-error-icon',
																								),
																								sc(
																									'big',
																								),
																							)}
																						/>
																						<div>
																							<div
																								className={cns(
																									sc(
																										'check-error-title',
																									),
																									sc(
																										'big',
																									),
																								)}
																							>
																								{' '}
																								{t(
																									text,
																								)}
																							</div>
																							<div
																								className={sc(
																									'check-error-description',
																								)}
																							>
																								<div
																									dangerouslySetInnerHTML={{
																										__html: t(
																											text +
																												' description',
																										),
																									}}
																								/>
																							</div>
																						</div>
																					</div>
																				</div>
																			))}
																		<div
																			className={
																				'text-center'
																			}
																		>
																			<Button
																				color={'danger'}
																				size={'lg'}
																				onClick={() =>
																					changeState(
																						EntityModalStateEnum.Detail,
																					)
																				}
																			>
																				{t`complete company data`}
																			</Button>
																		</div>
																	</div>
																)}
															</div>
														</>
													)}
												</EntityModalState>
											</CardBody>
										</Card>

										<EntityListField
											field={goodsListField}
											onCreate={() => refetch()}
											onDelete={() => refetch()}
										/>

										<EntityListField
											field={candidaciesListField}
											onCreate={() => refetch()}
											onDelete={() => refetch()}
											customAddTitle={t(
												'add candidacy action ' + award.visibility,
											)}
										/>

										{award.visibility !== AwardVisibility.Vote && (
											<Card>
												<CardBody>
													<div className={sc('check-block')}>
														<h2
															className={sc('title')}
															id={'check4'}
														>
															4.{' '}
															{checkLabel(
																'step company award invited custom form',
															)}
														</h2>
														<p
															dangerouslySetInnerHTML={{
																__html: t(
																	completedInviteDescriprionKey,
																),
															}}
														/>
													</div>
													<div className={'text-center'}>
														<Button
															color={
																hasCompletedInviteData
																	? 'success'
																	: 'danger'
															}
															size={'lg'}
															onClick={() =>
																setInviteDataModalOpen(true)
															}
														>
															{t(
																hasCompletedInviteData
																	? 'review invite data'
																	: 'complete invite data',
															)}
														</Button>
													</div>
												</CardBody>
											</Card>
										)}
									</Col>
									<Col sm={4}>
										<Card>
											<CardBody>
												<div className={sc('big')}>
													{t`progress status`}:
												</div>
												<div className={cns('text-center')}>
													{totalCheck && (
														<>
															<div>
																<i
																	className={cns(
																		'fa fa-check text-success',
																		sc('enormous'),
																	)}
																/>
															</div>
															<div className={sc('big')}>
																{variableLabel(
																	'candidacy progress completed text',
																)}
															</div>
															{maxCandidacies - numCandidacies >
																0 && (
																<div className={sc('big')}>
																	{t(
																		`still space for candidacies message ` +
																			award.visibility,
																	)}
																</div>
															)}
														</>
													)}
													{!totalCheck && (
														<>
															<div>
																<i
																	className={cns(
																		'fa fa-times danger text-themecolor',
																		sc('enormous'),
																	)}
																/>
															</div>
															<div className={sc('big')}>
																{variableLabel(
																	'candidacy progress not completed text',
																)}
															</div>
															<div>
																{t`see point`}{' '}
																<HashLink
																	smooth={true}
																	to={
																		'#check' +
																		(firstErrorStep + 1)
																	}
																	onClick={(e) => {
																		e.preventDefault();
																	}}
																>
																	{t`check`}{' '}
																	{firstErrorStep + 1}
																</HashLink>
															</div>
														</>
													)}
												</div>
											</CardBody>
										</Card>
										<Card>
											<CardBody>
												<div className={sc('big')}>
													{t`days left to candidate`}:
												</div>
												<div
													className={cns(
														sc('enormous'),
														'text-center',
													)}
												>
													{dayTilClose}
												</div>
											</CardBody>
										</Card>
										<Card>
											<CardBody>
												<CardTitle className={sc('big')}>
													{t`sidebar award title`}:
												</CardTitle>
												{award.contractMedia && (
													<div className={'award-shortcut'}>
														<span>
															{t`Read and sign the contract`}:
														</span>
														<div className={'text-center'}>
															<a
																className={
																	'btn btn-large btn-success'
																}
																href={
																	award.contractMedia.origUrl
																}
																download
															>
																{t`Download Contract in PDF`}{' '}
																<i
																	className={
																		'mdi mdi-download'
																	}
																/>
															</a>
														</div>
													</div>
												)}

												{award.regulationsMedia && (
													<div className={'award-shortcut'}>
														<span>
															{t`Check the award rules`}:
														</span>
														<div className={'text-center'}>
															<a
																className={
																	'btn btn-large btn-success'
																}
																href={
																	award.regulationsMedia
																		.origUrl
																}
																download
															>
																{t`Download Regluation in PDF`}{' '}
																<i
																	className={
																		'mdi mdi-download'
																	}
																/>
															</a>
														</div>
													</div>
												)}
												<div className={'award-shortcut'}>
													<span>{t`support email label`}:</span>
													<div className={cns('text-center')}>
														<strong>
															<a
																href={
																	'mailto:' +
																	(award.supportEmail ||
																		'support@gruppofood.com')
																}
															>
																{award.supportEmail ||
																	'support@gruppofood.com'}
															</a>
														</strong>
													</div>
												</div>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</div>
						);
					}}
				</Query>
			)}
		</UserData>
	);
};
