import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { FieldComponentRO, TextFieldType } from '../../types/form';

export const TextFieldRO: React.FC<FieldComponentRO<TextFieldType>> = ({
	field,
}) => {
	const val = field.value || '';

	return (
		<Row className={field.hidden ? 'hidden' : ''}>
			<Col sm={4}>
				<Label>{field.label}:</Label>
			</Col>
			<Col sm={8}>{field.formatText ? <pre>{val}</pre> : val}</Col>
		</Row>
	);
};
