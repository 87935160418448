import React from 'react';
import * as Sentry from '@sentry/browser';
import {
	AsyncChoiceFieldType,
	BaseField,
	BooleanFieldType,
	ColorFieldType,
	DateFieldType,
	DTPrecision,
	EntityListFieldType,
	FieldMuter,
	FieldsTransformer,
	FormDefinition,
	FormParsingOptions,
	HiddenFieldType,
	HTML_LEVELS,
	IField,
	IntegerFieldType,
	LaunchFieldType,
	MediaFieldType,
	RichTextFieldType,
	SubFormFieldType,
	SubFormListFieldType,
	SubForms,
	TextFieldType,
	TreeFieldType,
	WordpressPostFieldType,
} from '../types/form';
import { ENTITY } from './entities';
import { cond, identity, isNil, pipe, T } from 'ramda';
import {
	assign,
	IChoice,
	toChoices as toSelectChoices,
	toRequired,
} from './misc';
import {
	AsyncQuery,
	generateAsyncQuery,
	generatePaginatedSelectQuery,
} from './graphql';
import { ACTIONS, generateUrl } from './urls';
import { choicesBeforeSaveTransformer, toRefInput, toRefMediaInput } from './dataTrasform';
import { Permission } from './permissions';
import { DateField } from '../components/fields/DateField';
import { CodeField } from '../components/fields/CodeField';
import { RichTextField } from '../containers/fields/RichTextField';
import { TreeField } from '../containers/fields/TreeField';
import { AsyncChoicesField } from '../components/fields/AsyncChoices';
import { ChoicesField } from '../components/fields/ChoicesField';
import { TextField } from '../components/fields/TextField';
import { IntField } from '../components/fields/IntField';
import { MediaFieldFood as MediaField } from '../components/fields/MediaField';
import { BooleanField } from '../components/fields/BooleanField';
import { MediaListField } from '../components/fields/MediaListField';
import { SubFormListField } from '../components/fields/SubFormListField';
import { SubFormField } from '../components/fields/SubFormField';
import { WordpressPostField } from '../components/fields/WordpressPostField';
import {
	checkApiResponse,
	ERROR_CODES,
	required,
	typeformUrlValidator,
	urlValidator,
} from './validation';
import { t } from './labels';
import { ColorField } from '../components/fields/ColorField';
import { MediaType } from '../server-types';
import { RichTextFieldRO } from '../containers/fields/RichTextFieldRO';
import { MediaFieldRO } from '../components/fields/MediaFieldRO';
import { BooleanFieldRO } from '../components/fields/BooleanFieldRO';
import { TextFieldRO } from '../components/fields/TextFieldRO';
import { ChoicesFieldRO } from '../components/fields/ChoicesFieldRO';
import { EntityListField } from '../containers/fields/EntityListField';
import { DateFieldRO } from '../containers/fields/DateFieldRO';
import { MediaListFieldRO } from '../components/fields/MediaListFieldRO';
import { IFieldROProps } from '../containers/EntityDetailView';
import { FormGroup } from 'reactstrap';

const associations = {
	Int: IntField,
	Float: IntField,
	RichText: RichTextField,
	Date: DateField,
	Boolean: BooleanField,
	Text: TextField,
	HTML: CodeField,
	Query: TextField,
	SubForm: SubFormField,
	SubFormList: SubFormListField,
	Choices: ChoicesField,
	Tree: TreeField,
	AsyncChoices: AsyncChoicesField,
	EntityList: AsyncChoicesField,
	Media: MediaField,
	MediaList: MediaListField,
	WordpressPost: WordpressPostField,
	Launch: WordpressPostField,
	Color: ColorField,
	Hidden: () => null,
};

/**
 * Funzione per velocizzare la scrittura di transformer. Dato un dizionario di nomi di campi form e transformatori
 * di campo lo traduce in un transformer con controllo del nome campo basato sulle chiavi del dizionario
 *
 * Utilizza il costrutto cond, decisamente contro-intuitivo. More info: https://ramdajs.com/docs/#cond
 *
 * @param assocs: { nome campo: FieldTrasformer }
 * @return FieldsTransformer
 */
const fastTransformer = (assocs: {
	[key: string]: (f: IField) => IField;
}): FieldsTransformer => {
	return (fields) => {
		/*const fieldNames = fields.map((f) => f.name);
		const wrongAssocs = Object.keys(assocs).filter(
			(name) => !fieldNames.includes(name),
		);

		if (wrongAssocs.length > 0) {
			Sentry.captureException(
				new Error('Wrong fastTransformer names: ' + wrongAssocs.join(', ')),
			);
		}*/

		return fields.map(
			// @ts-ignore
			cond([
				...Object.entries(assocs).map(([fieldName, fn]) => [
					(e) => e.name === fieldName, // se il nome del campo e' uguale alla chiave del dizionario...
					fn, // ... allora restituisco la funzione associata
				]),
				[T, identity], // come scrivere [() => true, a => a]
			]),
		);
	};
};

/**
 * Compatta i transformer in un unico transformer; esegue da sinistra a destra
 * Si tratta, in pratica, di un "pipe" di ramda tipizzato
 *
 * @params ...ts: FieldTransformer[]
 * @return FieldsTransformer
 */
const transform = (
	...ts: ReadonlyArray<FieldsTransformer | false | null | undefined>
) =>
	// @ts-ignore
	pipe(...ts.filter((t) => !isNil(t) && t !== false)) as FieldsTransformer;

/**
 * Genera un IField base senza alcuna configurazione
 *
 * @param name: string
 * @param label: string
 * @param helpText: string = ''
 * @return IField
 */
const generateBaseField = (
	name: string,
	label: string,
	helpText: string = '',
): BaseField<any> => ({
	type: 'Base',
	name,
	label,
	description: {},
	validators: [],
	helpText,
});

/**
 * Dato un IField base lo rende un campo AsyncChoices
 *
 * @param entity: ENTITY
 * @param asyncQuery: AsyncQuery
 * @param single: boolean
 * @param base: IField
 * @return IField
 */
const toAsyncChoices = (
	entity: ENTITY,
	asyncQuery: AsyncQuery,
	single: boolean,
	base: BaseField<any>,
): AsyncChoiceFieldType => ({
	...base,
	type: 'AsyncChoices',
	single,
	asyncQuery,
	entityType: entity,
	mapToEntity: (id) => generateUrl(entity, ACTIONS.DETAIL, id),
	beforeSaveTransformer: (o) => {
		if (Array.isArray(o)) {
			return o.map((c) => toRefInput(c.value));
		} else {
			if (o) {
				return toRefInput(o.value);
			} else {
				return null;
			}
		}
	},
});

/**
 * Genera un IField per i campi di wordpress post
 *
 * @param name: string
 * @param label: string
 * @return IField
 */
const generateWordpressPostField = (
	name: string,
	label: string,
): WordpressPostFieldType => ({
	...generateBaseField(name, label),
	type: 'WordpressPost',
	validators: [required, urlValidator, checkApiResponse],
});

/**
 * Genera un IField per i campi di lanci wordpress
 *
 * @param name: string
 * @param label: string
 * @return IField
 */
const generateLaunchField = (name: string, label: string): LaunchFieldType => ({
	...generateBaseField(name, label),
	type: 'Launch',
	validators: [required, urlValidator /*checkApiResponse*/],
});

const subFormsToChoices = (
	types: ReadonlyArray<SubForms>,
): ReadonlyArray<IChoice> =>
	types.map((type) => ({
		value: type,
		label: t('subFormType/' + type),
	}));

/**
 * Utility per creare la base di un campo SubForm
 * Nota: presets serve come mezzo per sovrascrivere elementi degli IField quando un utente "sceglie" una tipologia di form (anche se, come abbiamo visto, in realtà non si scelgono quasi mai i campi; spesso viene specificata una sola tipologia possibile). Per esempio, se un determinato campo campo di un SubForm deve avere dei validatori speciali, è possibile applicarveli usando i presets.
 *
 * Un'altra cosa discutibile e' che, a differenza di altri casi, utilizzo questo unico costruttore sia per il caso di SubFormField che SubFormListField. Risulta coerente con le choices, ma col senno di poi si e' trattata di una scelta infelice.
 * TODO differenziare costruttore SubForm e SubFormList
 *
 * @param name: string
 * @param label: string
 * @param types: SubForms[]
 * @param single?: boolean
 * @param modalMode
 * @param presets: {[key: string]: Partial<IField>}
 * @return IField
 */
const generateSubFormField = (
	name: string,
	label: string,
	types: SubForms[],
	single: boolean = true,
	modalMode: boolean = false,
	presets?: { [key: string]: Partial<IField> },
): SubFormFieldType | SubFormListFieldType => ({
	...generateBaseField(name, label),

	type: single ? 'SubForm' : 'SubFormList',
	choices: subFormsToChoices(types),
	types,
	modalMode,
	beforeSaveTransformer: (value: any) => {
		const subForms = single ? [value] : value;
		const step1 = subForms
			.filter((f) => f.value)
			.map(({ value: { selected, fields } }) => {
				return fields.reduce(
					(obj, { name, beforeSaveTransformer, value }) => {
						obj[name] = (beforeSaveTransformer || identity)(value);
						return obj;
					},
					{ type: selected },
				);
			});

		return single ? step1[0] : step1;
	},
	presets,
});

/**
 * Funzione che restituisce un mutator per rendere un IField base un ChoicesField
 * @param choices
 * @param single
 */
const toChoices = (choices: ReadonlyArray<IChoice>, single: boolean) =>
	assign({
		type: 'Choices',
		isEnum: true,
		choices,
		single,
		beforeSaveTransformer: choicesBeforeSaveTransformer,
	});

// TODO commentare
const toMediaField = (
	f: BaseField<any>,
	mediaType: MediaType,
	permissions: Permission = {
		canCreate: true,
		canDelete: true,
		canEdit: true,
	},
): MediaFieldType => ({
	...f,
	type: 'Media',
	beforeSaveTransformer: toRefMediaInput,
	mediaType,
	permissions,
});

/**
 * Genera un IField per immagini e PDF
 *
 * @param name: string
 * @param label: string
 * @param mediaType: MediaType
 * @param permissions: Permission
 * @return IField
 */
// TODO deprecare generateMediaField in favore di toMediaField
const generateMediaField = (
	name: string,
	label: string,
	mediaType: MediaType,
	permissions: Permission,
	chooseSize?: boolean | ReadonlyArray<string>
): MediaFieldType => ({
	...generateBaseField(name, label),
	type: 'Media',
	mediaType,
	beforeSaveTransformer: toRefMediaInput,
	permissions,
	chooseSize
});

/**
 * Genera un IField per una rich text
 *
 * @param name: string
 * @param label: string
 * @param level?: HTML_LEVELS
 * @return IField
 */
const generateRichTextField = (
	name: string,
	label: string,
	level: HTML_LEVELS = HTML_LEVELS.FORMATTING,
): RichTextFieldType => ({
	...generateBaseField(name, label),
	type: 'RichText',
	description: {
		htmlLevel: level,
	},
});

/**
 * Genera un IField per un campo testo base
 *
 * @param name: string
 * @param label: string
 * @param helpText
 * @return IField
 */
const generateTextField = (
	name: string,
	label: string,
	helpText?: string,
): TextFieldType => ({
	...generateBaseField(name, label, helpText),
	type: 'Text',
});

/**
 * Genera un IField per i campi booleani, richiesti o meno
 *
 * @param name: string
 * @param label: string
 * @param required: boolean
 * @return IField
 */
const generateBooleanField = (
	name: string,
	label: string,
	required: boolean,
): BooleanFieldType => ({
	...generateBaseField(name, label),
	type: 'Boolean',
	required,
	value: required ? false : undefined,
});

const generateDateField = (
	name: string,
	label: string,
	precision: DTPrecision = DTPrecision.DayBegin,
): DateFieldType => ({
	...generateBaseField(name, label),
	type: 'Date',
	precision,
});

/**
 *
 * @param name
 * @param value
 */
const generateHiddenField = (name: string, value: any): HiddenFieldType => ({
	...generateBaseField(name, ''),
	type: 'Hidden',
	value,
});

const toColor = (f: BaseField<any>): ColorFieldType => ({
	...f,
	type: 'Color',
});

/**
 * Dato un IField base vi aggiunge il validatore per l'url, rendendolo un campo url de facto
 *
 * @param f: IField
 * @return IField
 */
const toUrl = (f: BaseField<any> | TextFieldType): TextFieldType => ({
	...f,
	type: 'Text',
	validators: f.validators.concat([urlValidator]),
});

/**
 * Dato un campo AsyncChoice restituisce un campo ad albero che ne condivide le proprieta' comuni
 *
 * @param f: AsyncChoiceFieldType
 * @param single: boolean
 * @param nodes: ReadonlyArray<any>
 */
const toTree = (
	f: AsyncChoiceFieldType,
	single: boolean,
	nodes: ReadonlyArray<any>,
): TreeFieldType => {
	const { asyncQuery, beforeSaveTransformer, ...safeFields } = f;
	return {
		...safeFields,
		type: 'Tree',
		single,
		nodes,
	};
};

const HTMLLevelToQuillConfig = (field: IField, colors?: string[]): any => {
	const level = HTML_LEVELS[field.description.htmlLevel];
	const config: any = { modules: {} };

	if (level === HTML_LEVELS.BASE || level === HTML_LEVELS.BASE_MULTILINE) {
		config.modules.toolbar = [[], ['bold', 'italic', 'strike']];
		config.formats = ['bold', 'italic', 'strike'];
	} else if (level === HTML_LEVELS.BASE_LINK) {
		config.modules.toolbar = [[], ['bold', 'italic', 'strike'], ['link']];
		config.formats = ['bold', 'italic', 'strike', 'link'];
	} else if (level === HTML_LEVELS.FORMATTING) {
		config.modules.toolbar = [
			[],
			['bold', 'italic', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
		];
		config.formats = ['bold', 'italic', 'strike', 'list'];
	} else if (level === HTML_LEVELS.NEWSLETTER) {
		config.modules.toolbar = {
			container: [
				['textColorAdvanced', { header: [1, 2, 3, 4, false] }],
				['bold', 'italic', 'strike', 'blockquote'],
				[{ align: ['', 'right', 'center'] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link'],
				['clean'],
			],
			handlers: {
				'textColorAdvanced': function () { return false; }
			}
		}

		config.formats = [
			'bold',
			'italic',
			'strike',
			'list',
			'blockquote',
			'link',
			'header',
			'align',
			'color'
		];
	}

	return config;
};

/**
 * Funzione per tradurre un JSON creato dal form builder in una lista di IField, adatta ad essere renderizzata da un form
 */
const parseFormDefinition = (
	definition: FormDefinition,
	options?: FormParsingOptions,
): ReadonlyArray<IField> => {
	const labelFn = options?.customLabelFn || ((n: string) => t(n + '/label'));
	const helpTextFn =
		options?.customHelpTextFn || ((n: string) => t(n + '/helpText'));

	return definition.fields.reduce((result, field) => {
		const label = labelFn(field.name) + (field.required ? '*' : '');

		const baseField: Partial<IField> = {
			value: field.defaultValue,
			helpText: helpTextFn(field.name),
			required: field.required,
			validators: field.required ? [required] : [],
		};

		if (field.type === 'Text') {
			result.push({
				...generateTextField(field.name, label),
				...baseField,
				multiline: !!field.multiline,
			});
		} else if (field.type === 'RichText') {
			result.push({
				...generateRichTextField(field.name, label, HTML_LEVELS.FORMATTING),
				...baseField,
			});
		} else if (field.type === 'Boolean') {
			result.push({
				...generateBooleanField(field.name, label, field.required),
				...baseField,
			});
		} else if (field.type === 'SubForm') {
			result.push(
				...subFormsConstructors[field.form].map((field) =>
					Object.assign({}, field),
				),
			);
		}
		return result;
	}, []);
};

/**
 * Dizionario con i campi associati a ogni sotto-form.
 *
 * @type {[key: string]: IField[]}
 */
const subFormsConstructors: { [key: string]: ReadonlyArray<IField> } = {
	[SubForms.GOOD]: [
		toRequired(
			toAsyncChoices(
				ENTITY.GOOD,
				generateAsyncQuery(generatePaginatedSelectQuery(ENTITY.GOOD), true),
				true,
				generateBaseField('Good', t(ENTITY.GOOD)),
			),
		),
	],
	[SubForms.COMPANY]: [
		toRequired(
			toAsyncChoices(
				ENTITY.COMPANY,
				generateAsyncQuery(
					generatePaginatedSelectQuery(ENTITY.COMPANY),
					true,
				),
				true,
				generateBaseField('Company', t(ENTITY.COMPANY)),
			),
		),
	],
	[SubForms.CANDIDACY]: [
		toRequired(
			toAsyncChoices(
				ENTITY.CANDIDACY,
				generateAsyncQuery(
					generatePaginatedSelectQuery(ENTITY.CANDIDACY),
					true,
				),
				true,
				generateBaseField('Candidacy', t(ENTITY.CANDIDACY)),
			),
		),
	],
	[SubForms.NEWS_WITH_LAYOUT]: [
		toRequired(generateWordpressPostField('news', t('wordpress news url'))),
		pipe(
			toChoices(
				['expanded', 'no_intro_text', 'no_image_no_text'].map((e) => ({
					value: e,
					label: t('wordpressNewsLayout/' + e),
				})),
				true,
			),
			toRequired,
		)(generateBaseField('layout', t`wordpress news view layout`)),
		toMediaField(
			generateBaseField(
				'media',
				t`subFormNewsWithLayout/media/label`,
				t`subFormNewsWithLayout/media/helpText`,
			),
			MediaType.MailImage,
			{
				canCreate: true,
				canEdit: true,
				canDelete: true,
			},
		),
		generateTextField('title', t`subFormNewsWithLayout/title`),
		generateRichTextField(
			'excerpt',
			t`subFormNewsWithLayout/excerpt`,
			HTML_LEVELS.NEWSLETTER,
		),
	],
	[SubForms.NEWS]: [
		toRequired(generateWordpressPostField('news', t('wordpress news url'))),
		toMediaField(
			generateBaseField(
				'media',
				t`subFormNews/media/label`,
				t`subFormNews/media/helpText`,
			),
			MediaType.MailImage,
			{
				canCreate: true,
				canEdit: true,
				canDelete: true,
			},
		),
	],
	[SubForms.BANNER]: [
		toRequired(
			generateMediaField(
				'media',
				t`subFormBanner/media`,
				MediaType.BannerLeaderboard,
				{
					canCreate: true,
					canEdit: true,
					canDelete: true,
				},
			),
		),
		generateTextField('alt', t`subFormBanner/altText`),
		toUrl(generateTextField('link', t`subFormBanner/linkUrl`)),
	],

	[SubForms.MAIN_IMAGE]: [
		toRequired(
			generateMediaField(
				'media',
				t`subFormMainImage/media`,
				MediaType.MailImage,
				{
					canCreate: true,
					canEdit: true,
					canDelete: true,
				},
				['smallThumbUrl', 'mediumThumbUrl', 'largeThumbUrl', 'origUrl']
			),
		),
		toRequired(generateTextField('alt', t`subFormMainImage/altText`)),
		generateRichTextField(
			'caption',
			t`subFormMainImage/caption`,
			HTML_LEVELS.BASE_LINK,
		),
		toUrl(generateTextField('link', t`subFormMainImage/linkUrl`)),
	],
	[SubForms.RICH_TEXT]: [
		toRequired(
			generateRichTextField(
				'richtext',
				t`subFormRichText/text`,
				HTML_LEVELS.NEWSLETTER,
			),
		),
	],
	[SubForms.TITLE]: [
		toRequired(
			generateMediaField(
				'media',
				t`subFormTitle/media`,
				MediaType.MailImage,
				{
					canCreate: true,
					canEdit: true,
					canDelete: true,
				},
			),
		),
		generateTextField('text', t`subFormTitle/text`),
		toColor(
			generateBaseField('backgroundColor', t`subFormTitle/backgroundColor`),
		),
		toColor(generateBaseField('textColor', t`subFormTitle/textColor`)),
		toUrl(generateTextField('url', t`subFormTitle/url`)),
	],
	[SubForms.TITLE_NO_IMAGE]: [
		toRequired(generateTextField('text', t`subFormTitleNoImage/text`)),
		pipe(
			toChoices(
				toSelectChoices(['main_title', 'new_section', 'internal']),
				true,
			),
			toRequired,
		)(generateBaseField('layout', t`subFormTitleNoImage/layout`)),
		generateBooleanField(
			'alignCenter',
			t`subFormTitleNoImage/alignCenter`,
			true,
		),
		pipe(toColor)(
			generateBaseField(
				'backgroundColor',
				t`subFormTitleNoImage/backgroundColor`,
			),
		),
		pipe(toColor)(
			generateBaseField('textColor', t`subFormTitleNoImage/textColor`),
		),
		toUrl(generateTextField('url', t`subFormTitleNoImage/url`)),
		generateTextField('subtitle', t`subFormTitleNoImage/subtitle`),
		toColor(
			generateBaseField(
				'subtitleColor',
				t`subFormTitleNoImage/subtitleColor`,
			),
		),
	],
	[SubForms.IMAGE_TEXT]: [
		toRequired(
			generateMediaField(
				'media',
				t`subFormImageText/media`,
				MediaType.MailImage,
				{
					canCreate: true,
					canEdit: true,
					canDelete: true,
				},
			),
		),
		toRequired(
			generateRichTextField(
				'richtext',
				t`subFormImageText/text`,
				HTML_LEVELS.FULL,
			),
		),
		toUrl(generateBaseField('link', t`subFormImageText/linkUrl`)),
		pipe(
			toChoices(
				[
					{ value: 'left', label: t`left` },
					{ value: 'right', label: t`right` },
				],
				true,
			),
			toRequired,
		)(generateBaseField('alignment', t`subFormImageText/alignment`)),
		pipe(
			toChoices(
				[
					{ value: 'half', label: t`half proportion` },
					{ value: 'small_image', label: t`small_image` },
				],
				true,
			),
			toRequired,
		)(generateBaseField('proportion', t`subFormImageText/proportion`)),
		pipe(
			toChoices(
				[
					{ value: 'top', label: t`top` },
					{ value: 'middle', label: t`middle` },
					{ value: 'bottom', label: t`bottom` },
				],
				true,
			),
			toRequired,
		)(generateBaseField('valignment', t`subFormImageText/valignment`)),
	],
	[SubForms.LINK]: [
		pipe(
			toRequired,
			toUrl,
		)(generateBaseField('link', t`subFormLink/linkUrl`)),
		toRequired(generateTextField('text', t`subFormLink/text`)),
		generateTextField('secondLine', t`subFormLink/secondLine`),
	],
	[SubForms.INSTANT_ACTION]: [
		generateMediaField(
			'media',
			t`subFormInstantAction/media`,
			MediaType.MailImage,
			{
				canCreate: true,
				canEdit: true,
				canDelete: true,
			},
		),
		toRequired(generateTextField('text', t`subFormInstantAction/text`)),
		toRequired(toUrl(generateTextField('url', t`subFormInstantAction/url`))),
		toColor(
			generateBaseField(
				'backgroundColor',
				t`subFormInstantAction/backgroundColor`,
			),
		),
		toColor(
			generateBaseField('textColor', t`subFormInstantAction/textColor`),
		),
		generateMediaField(
			'icon',
			t`subFormInstantAction/icon`,
			MediaType.MailImage,
			{
				canCreate: true,
				canEdit: true,
				canDelete: true,
			},
		),
		toChoices(
			toSelectChoices(['small', 'medium', 'big', 'huge']),
			true,
		)(generateBaseField('size', t`subFormInstantAction/size`)),
	],
	[SubForms.DOUBLE_INSTANT_ACTION]: [
		toRequired(
			generateTextField('text1', t`subFormDoubleInstantAction/text1`),
		),
		toRequired(
			toUrl(generateTextField('url1', t`subFormDoubleInstantAction/url1`)),
		),
		toRequired(
			generateTextField('text2', t`subFormDoubleInstantAction/text2`),
		),
		toRequired(
			toUrl(generateTextField('url2', t`subFormDoubleInstantAction/url2`)),
		),
		toColor(
			generateBaseField(
				'backgroundColor',
				t`subFormDoubleInstantAction/backgroundColor`,
			),
		),
		toColor(
			generateBaseField(
				'textColor',
				t`subFormDoubleInstantAction/textColor`,
			),
		),

		toChoices(
			toSelectChoices(['small', 'medium', 'big', 'huge']),
			true,
		)(generateBaseField('size', t`subFormDoubleInstantAction/size`)),
	],
	[SubForms.IFN_SEARCH_BAR]: [
		toChoices(
			toSelectChoices([ENTITY.GOOD, ENTITY.COMPANY]),
			true,
		)(generateBaseField('searchType', t`seeMoreType`)),
		toUrl(
			generateTextField('link', t`seeMoreLink`, t`see More Link helpText`),
		),
		generateTextField('text', t`seeMoreText`, t`see More Text helpText`),
	],
	[SubForms.ENTITIES]: [
		pipe(
			toRequired,
			toChoices(
				toSelectChoices([
					'3_col',
					'4_col',
					'3_col_unboxed',
					'4_col_unboxed',
				]),
				true,
			),
		)(generateBaseField('layout', t`subFormEntities/layout`)),
		{
			...generateSubFormField(
				'entities',
				t`subFormEntities/entities`,
				[
					SubForms.GOOD,
					SubForms.COMPANY,
					SubForms.CANDIDACY,
					SubForms.IFN_CARD,
				],
				false,
				true,
			),
			addButtonLabel: t`add card`,
		},
	],
	[SubForms.TYPEFORM_PREVIEW]: [
		pipe(
			toRequired,
			assign({ validators: [required, urlValidator, typeformUrlValidator] }),
		)(generateBaseField('url', t`subFormTypeformPreview/url`)),
	],
	[SubForms.IFN_CARD]: [
		pipe(toUrl, toRequired)(generateTextField('url', t`subFormIfnCard/url`)),
	],
	[SubForms.NEWS_LIST]: [
		pipe(
			toRequired,
			toChoices(
				toSelectChoices([
					'small_image',
					'50_50_left_image',
					'50_50_alternate',
					'compact',
					'compact_no_image',
					'compact_plain_image',
					'compact_plain_no_image',
				]),
				true,
			),
		)(generateBaseField('layout', t`subFormNewsList/layout`)),
		{
			...generateSubFormField(
				'news',
				t`subFormNewsList/news`,
				[SubForms.NEWS],
				false,
				true,
			),
			addButtonLabel: t`add news`,
		},
	],
	[SubForms.LAUNCH_LIST]: [
		pipe(
			toRequired,
			toChoices(toSelectChoices(['boxed', 'unboxed']), true),
		)(generateBaseField('layout', t`subFormLaunchesList/layout`)),
		{
			...generateSubFormField(
				'launches',
				t`subFormLaunchesList/launches`,
				[SubForms.LAUNCH],
				false,
				true,
			),
			addButtonLabel: t`add launch`,
		},
	],
	[SubForms.EDITORIAL_SECTIONS]: [
		pipe(
			toChoices(
				toSelectChoices([ENTITY.GOOD, ENTITY.COMPANY, ENTITY.NEWS]),
				true,
			),
			toRequired,
		)(generateBaseField('target', t`subFormEditorialSections`)),
	],
	[SubForms.LINK_LIST]: [
		{
			...generateSubFormField(
				'urls',
				t`subFormLinkList/urls`,
				[SubForms.LINK],
				false,
				true,
			),
			addButtonLabel: t`add link`,
		} as SubFormListFieldType,
	],
	[SubForms.SPONSOR]: [
		{
			...generateTextField('title', t`subFormSponsor/title`),
			value: 'In partnership con:',
		},
		toRequired(generateTextField('name', t`subFormSponsor/name`)),
		toRequired(
			generateMediaField(
				'media',
				t`subFormSponsor/media`,
				MediaType.MailImage,
				{ canCreate: true, canEdit: true, canDelete: true },
			),
		),
		toUrl(generateTextField('url', t`subFormSponsor/url`)),
	],
	[SubForms.AUTHOR]: [
		toRequired(generateTextField('name', t`subFormSponsor/name`)),
		generateTextField('titles', t`subFormSponsor/titles`),
		toRequired(
			generateMediaField(
				'media',
				t`subFormSponsor/media`,
				MediaType.MailImage,
				{ canCreate: true, canEdit: true, canDelete: true },
			),
		),
		toUrl(generateTextField('url', t`subFormSponsor/url`)),
	],
	[SubForms.SECTION_TAGS]: [
		toChoices(
			toSelectChoices([ENTITY.COMPANY, ENTITY.GOOD]),
			true,
		)(generateBaseField('target', t`subFormSectionTags/target`)),
	],
	[SubForms.LAUNCH]: [
		toRequired(generateLaunchField('url', t('wordpress launch url'))),
	],
	[SubForms.NEWSLETTER_FAQ]: [
		toRequired({
			...generateTextField(
				'question',
				t`subFormNewsletterFAQ/question` + '*',
			),
			multiline: true,
		}),
		{
			...toRequired({
				...generateTextField(
					'answer',
					t`subFormNewsletterFAQ/answer` + '*',
				),
				multiline: true,
			}),
		},
	],
	[SubForms.NEWSLETTER_FAQ_LIST]: [
		{
			...(generateSubFormField(
				'faqs',
				t`subFormNewsletterFAQList/faqs`,
				[SubForms.NEWSLETTER_FAQ],
				false,
				true,
			) as SubFormListFieldType),
			addButtonLabel: t`add faq`,
			max: 5,
			validators: [
				(val) => {
					if (!val || (Array.isArray(val) && val.length === 0)) {
						return new Error(ERROR_CODES.REQUIRED);
					}
					return false;
				},
			],
		},
	],
	[SubForms.NEWSLETTER_CLAIM]: [
		toRequired(generateTextField('text', t`subFormNewsletterClaim/text`)),
		toUrl(generateTextField('url', t`subFormNewsletterClaim/url`)),

		toColor(
			generateBaseField(
				'backgroundColor',
				t`subFormNewsletterClaim/backgroundColor`,
			),
		),
		toColor(
			generateBaseField('textColor', t`subFormNewsletterClaim/textColor`),
		),
	],
	[SubForms.NEWSLETTER_CLAIM_LIST]: [
		pipe(
			toRequired,
			toChoices(
				toSelectChoices(['ordered_list', 'unordered_list', 'tags']),
				true,
			),
		)(generateBaseField('layout', t`subFormNewsletterClaimList/layout`)),
		{
			...generateSubFormField(
				'claims',
				t`subFormNewsletterClaimList/news`,
				[SubForms.NEWSLETTER_CLAIM],
				false,
				true,
			),
			addButtonLabel: t`add news`,
		},
	],
	[SubForms.IMAGE_TEXT_BASE]: [
		toRequired(
			generateMediaField(
				'media',
				t`subFormImageText/media`,
				MediaType.MailImage,
				{
					canCreate: true,
					canEdit: true,
					canDelete: true,
				},
			),
		),
		generateRichTextField(
			'richtext',
			t`subFormImageText/text`,
			HTML_LEVELS.NEWSLETTER,
		),
		toUrl(generateBaseField('link', t`subFormImageText/linkUrl`)),
	],
	[SubForms.IMAGE_TEXT_COLUMNS]: [
		pipe(
			toRequired,
			toChoices(toSelectChoices(['2_col', '3_col', '4_col']), true),
		)(generateBaseField('layout', t`subFormImageTextColumns/layout`)),
		{
			...generateSubFormField(
				'blocks',
				t`subFormImageTextColumns/block`,
				[SubForms.IMAGE_TEXT_BASE],
				false,
				true,
			),
			addButtonLabel: t`add block`,
		},
	],
	[SubForms.SOCIAL_BUTTONS]: [
		toChoices(
			toSelectChoices(['small', 'medium', 'big']),
			true,
		)(generateBaseField('size', t`subFormSocialButtons/size`)),
		toColor(
			generateBaseField('textColor', t`subFormSocialButtons/textColor`),
		),
		toUrl(generateTextField('facebook', t`subFormSocialButtons/facebook`)),
		toUrl(generateTextField('twitter', t`subFormSocialButtons/twitter`)),
		toUrl(generateTextField('instagram', t`subFormSocialButtons/instagram`)),
		toUrl(generateTextField('youtube', t`subFormSocialButtons/youtube`)),
		toUrl(generateTextField('vimeo', t`subFormSocialButtons/vimeo`)),
		toUrl(generateTextField('linkedin', t`subFormSocialButtons/linkedin`)),
	],
	[SubForms.ADD_TO_CALENDAR]: [
		/*
	title: string;
	begin: Date;
	end: Date;
	allday?: boolean;
	local?: boolean;
	except?: string;
	location?: string;
	*/
		toRequired(generateTextField('title', t`subFormsAddToCalendar/title`)),
		toRequired(
			generateDateField(
				'begin',
				t`subFormsAddToCalendar/begin`,
				DTPrecision.Full,
			),
		),
		toRequired(
			generateDateField(
				'end',
				t`subFormsAddToCalendar/end`,
				DTPrecision.Full,
			),
		),
		generateBooleanField('allday', t`subFormsAddToCalendar/allday`, false),
		generateTextField('except', t`subFormsAddToCalendar/except`),
		generateTextField('location', t`subFormsAddToCalendar/location`),
	],
};

function renderField(
	f: IField,
	path: string,
	mutator: FieldMuter,
	isModal: boolean = false,
) {
	const Type = associations[f.type] || TextField;

	// nel caso di un campo Media devo anche calcolare le immagini usate dagli altri campi (compreso se
	// stesso) e passarle al campo, cosi' che possa impedirne il cancellamento
	/*	const usedMedias = fields
		.filter((f) => f.type === 'Media' && f.value)
		.map((f) => f.value);*/

	return (
		<section
			key={f.name}
			data-field-name={f.name}
			data-field-type={f.type}
			data-field-path={path}
		>
			{f.pre && f.pre}
			<Type field={f} changeHandler={mutator} modal={isModal} path={path} />
			{f.post && f.post}
		</section>
	);
}

// TODO questi any sono rimuovibili secondo Alessandro?
const associationsRO: {
	[key: string]:
	| React.ComponentClass<IFieldROProps<any>>
	| React.FC<IFieldROProps<any>>;
} = {
	RichText: RichTextFieldRO,
	Media: MediaFieldRO,
	Boolean: BooleanFieldRO,
	Text: TextFieldRO,
	Choices: ChoicesFieldRO,
	AsyncChoices: ChoicesFieldRO,
	EntityList: EntityListField,
	Date: DateFieldRO,
	MediaList: MediaListFieldRO,
	Int: TextFieldRO,
	Url: TextFieldRO,
	Float: TextFieldRO,
};

const renderFieldRO = (f: IField, modal: boolean = false): JSX.Element => {
	const Type = associationsRO[f.type];
	if (Type === undefined) {
		console.log('Impossible to render field:', f.name, f.type, f);
		return null;
	} else
		return (
			<FormGroup key={f.name}>
				<Type field={f} modal={modal} />
			</FormGroup>
		);
};

const toAsyncRO = (
	f: EntityListFieldType,
	entityType: ENTITY,
): AsyncChoiceFieldType => ({
	...f,
	type: 'AsyncChoices',
	asyncQuery: () => null,
	entityType,
	single: false,
});

const refactorInfo = (f: IField, expectedType: string) => {
	Sentry.captureException(
		new Error(
			`Form refactor error: field ${f.name} should be of type ${expectedType}, is ${f.type}`,
		),
	);
	console.error(
		`Form refactor error: field ${f.name} should be of type ${expectedType}, is ${f.type}`,
	);
};

/**
 * Trasforma un campo base in un campo nascosto
 * Di default imposto anche changed a true
 */
const toHidden = (value?: any) => (f: IField): HiddenFieldType => ({
	type: 'Hidden',
	name: f.name,
	label: '',
	description: {},
	validators: [],
	value: value || f.value,
	changed: true,
});

const toInteger = (f: BaseField<number>): IntegerFieldType =>
	assign({
		type: 'Number',
	});

export {
	transform,
	fastTransformer,
	generateSubFormField,
	generateBaseField,
	generateRichTextField,
	generateTextField,
	generateMediaField,
	toAsyncChoices,
	subFormsConstructors,
	toChoices,
	generateBooleanField,
	generateDateField,
	generateWordpressPostField,
	renderField,
	toColor,
	toUrl,
	subFormsToChoices,
	renderFieldRO,
	toMediaField,
	HTMLLevelToQuillConfig,
	generateLaunchField,
	toTree,
	refactorInfo,
	toAsyncRO,
	toHidden,
	toInteger,
	generateHiddenField,
	parseFormDefinition,
};
