import React from 'react';
import { NewsletterCreateMutation } from '../graphql/mutations/NewsletterCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Newsletter.json';
import inputTypeIntro from '../static/introspection/NewsletterCreateInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { transformerHandler } from '../utils/misc';
import {
	newsletterBeforeSaveTransformer,
	newsletterTransformer,
} from '../utils/entities/newsletter';
import { NewsletterDetailQuery } from '../graphql/query/NewsletterDetailQuery';
import { EntityEditPage } from '../types/entities';
import { Newsletter } from '../server-types';

export const NewsletterCreate: React.FC<EntityEditPage<Newsletter>> = ({
	id,
}) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(NewsletterDetailQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={NewsletterCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.NEWSLETTER}
		fields={['name', 'target', 'delivery', 'inputTemplate']}
		transformer={transformerHandler(newsletterTransformer)}
		beforeSaveTransformer={newsletterBeforeSaveTransformer}
	/>
);
