/**
 * Questo componente viene utilizzato all'interno del sistema dei form. I configuratori di manipolazione entita' hanno
 * il compito di chiamare EntityEditFetcher con i parametri adeguati alla richiesta.
 *
 * Nella catena di componenti che segue questo e' appunto il primo. Il suo compito e' ottenere dati da fonti esterne
 * (quasi sempre una sola chiamata GraphQL) tramite la funzione `fetcher` quando richiesti (in creazione a volte non e'
 * necessario), convertire le introspezioni GraphQL fornite in IFields, eventualmente valorizzarli e, finalmente,
 * passarli al prossimo anello della catena (FunctionalEntityEdit).
 *
 * In altre parole, qui vengono creati gli IField che comanderanno tutti i successivi step del sistema.
 *
 * Per più informazioni rivolgiti alla pagina di documentazione `architettura_form.md`
 */

import React, { ReactInstance, useEffect, useState } from 'react';
import {
	generateObjectFieldList,
	setFieldsValues,
} from '../utils/objectFields';
import { t } from '../utils/labels';
import { Spinner } from './MainContentSpinner';
import {
	ERRORS,
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { NotFoundComponent } from './404';
import { entity2String } from '../utils/toString';
import { UserData, UserDataInterface } from '@food/auth';
import { identity } from 'ramda';
import { DocumentNode } from 'graphql';
import { Dict } from '../utils/misc';
import { ENTITY } from '../utils/entities';
import { IField } from '../types/form';
import {
	EntityEditViewProps,
	EntityEditViewSwitch,
} from './EntityEditViewSwitch';
import { FunctionalEntityEdit } from '../containers/FunctionalEntityEdit';

export type ITransformerFunction = (
	data: { id?: string; fields: ReadonlyArray<IField> },
	entity?: any,
	list?: Dict,
) => { id?: string; fields: ReadonlyArray<IField> };

// TODO commentare tipizzazione props EntityEditFetcher
interface IEntityEditFetcherProps {
	fields: ReadonlyArray<string>;
	entityName: ENTITY;
	typeIntro: any; // introspezione dell'entita' che si vuole modificare/creare
	inputTypeIntro: any; // introspezione della mutazione di edit/create
	mutation: DocumentNode;
	entitiesToFields?: (data: any) => Dict;
	clone?: boolean;
	create?: boolean;
	id?: string;
	fetcher?: (() => Promise<any>) | null;
	transformer?: ITransformerFunction;
	myToString?: (entity: any) => string;
	entityLabel?: string;
	modal?: boolean;
	tabs?: ReadonlyArray<string>;
	cols?: string[][];
	pre?: (entity: any) => JSX.Element;
	post?: (entity: any) => JSX.Element;
	beforeSaveTransformer?: (data: any) => any;
	customDetailUrl?: (id: string) => string;
	onSubmit?: (mutationResult: any) => void;
	children?: (
		args: EntityEditViewProps,
	) => JSX.Element | ReactInstance | React.ReactNode | string | null;
}

/**
 * Funzione per trasformare i dati passati ad EntityEditFetcher in IFields valorizzati - usata in casi di modifica o
 * clonazione
 * @param user
 * @param entity
 * @param fieldNames
 * @param typeIntrospection
 * @param mutationInputIntrospection
 * @param listsByType
 * @param entityLabel
 */
const toEditData = (
	user: UserDataInterface,
	entity,
	fieldNames,
	typeIntrospection,
	mutationInputIntrospection,
	listsByType,
	entityLabel,
) => {
	const fields = generateObjectFieldList(
		user,
		fieldNames,
		typeIntrospection,
		false,
		mutationInputIntrospection,
		listsByType,
		entityLabel,
	);

	// da molto tempo vorrei rimuovere questo "id" dall'oggetto in modo da unificare questo metodo con `toCreateData`;
	// probabilmente ora questo dato arriva anche attraverso altri modi e potrei farlo, ma non e' mai stata una
	// priorita' e non ho test per garantire la buona riuscita del trapianto. Sad violin playing
	return {
		id: entity.id,
		fields: setFieldsValues(fields, entity),
	};
};

/**
 * Funzione per trasformare i dati passati ad EntityEditFetcher in IFields non valorizzati - usata in casi di creazione
 * @param user
 * @param fieldNames
 * @param typeIntrospection
 * @param mutationInputIntrospection
 * @param listsByType
 * @param entityLabel
 */
const toCreateData = (
	user: UserDataInterface,
	fieldNames,
	typeIntrospection,
	mutationInputIntrospection,
	listsByType,
	entityLabel,
) => {
	const fields = generateObjectFieldList(
		user,
		fieldNames,
		typeIntrospection,
		false,
		mutationInputIntrospection,
		listsByType,
		entityLabel,
	);
	return { fields };
};

const EntityEditFetcher: React.FC<IEntityEditFetcherProps> = ({
	fields: fieldNames,
	entityName,
	create,
	clone,
	id,
	fetcher,
	entitiesToFields = identity,
	transformer,
	typeIntro,
	inputTypeIntro,
	myToString,
	entityLabel,
	customDetailUrl,
	modal,
	mutation,
	beforeSaveTransformer,
	children = EntityEditViewSwitch,
	tabs,
	onSubmit,
}) => {
	const [{ data, loading, error }, setState] = useState<{
		loading: boolean;
		data: any;
		error: null | Error;
	}>({
		loading: Boolean(fetcher),
		data: null,
		error: null,
	});

	useEffect(() => {
		if (fetcher) {
			fetcher().then(
				(data) => setState({ loading: false, data, error: null }),
				(error) =>
					setState({
						loading: false,
						data: null,
						error,
					}),
			);
		}
	}, [fetcher]);

	if (error !== null) {
		const errContext = { entityName };
		const errs = parseGraphqlError(error, errContext);
		if (errs.length === 1 && errs[0].message === ERRORS.NOT_FOUND) {
			// siamo in caso di not found
			return <NotFoundComponent />;
		}
		sentryHandler(errs);
		modalHandler(errs);
		return <span>{error.toString()}</span>;
	}

	const entity = data ? data.entity : null;
	const parsedQueryResult = data ? entitiesToFields(data) : {};
	
	return (
		<UserData>
			{(user) => {
				if (user.loading) {
					return <Spinner show={true} />;
				}

				const parsedData =
					!entity || (create && !clone)
						? toCreateData(
								user,
								fieldNames,
								typeIntro,
								inputTypeIntro,
								parsedQueryResult,
								entityLabel,
						  )
						: toEditData(
								user,
								entity,
								fieldNames,
								typeIntro,
								inputTypeIntro,
								parsedQueryResult,
								entityLabel,
						  );

				
				const dataTransformer =
					!loading && transformer ? transformer : identity;
				
				const transformedData =
					create && !clone
						? dataTransformer(parsedData, null, parsedQueryResult)
						: dataTransformer(parsedData, entity, parsedQueryResult);

				let title: string = create
					? t`create` + ' ' + t(entityName)
					: t`update` + ' ' + t(entityName);
				
				if (entity) {
					title = myToString
						? myToString(entity)
						: entity2String(entityName, entity);
				}

				return (
					<FunctionalEntityEdit
						modal={modal}
						fields={loading ? [] : transformedData.fields}
						id={id}
						customDetailUrl={customDetailUrl}
						entityName={entityName}
						create={!!create}
						mutation={mutation}
						beforeSaveTransformer={beforeSaveTransformer}
						onSubmit={onSubmit}
					>
						{(
							onSubmit,
							mutatorFactory,
							fields,
							validationModalOpen,
							toggleValidationModal,
							isChanged,
						) => (
							<>
								{children({
									loading,
									fields,
									id,
									clone,
									tabs,
									myToString,
									entity,
									entityName,
									onSubmit,
									create,
									validationModalOpen,
									toggleValidationModal,
									entityLabel,
									mutatorFactory,
									title,
									isChanged,
								})}
							</>
						)}
					</FunctionalEntityEdit>
				);
			}}
		</UserData>
	);
};

export { EntityEditFetcher };
