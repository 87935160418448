import React from 'react';
import { BrandCreateQuery } from '../graphql/query/BrandCreateQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import { toRefInput } from '../utils/dataTrasform';
import { BrandCreateMutation } from '../graphql/mutations/BrandCreateMutation';
import typeIntro from '../static/introspection/Brand.json';
import inputTypeIntro from '../static/introspection/BrandCreateInput.json';
import { editorialSectionsToTree } from '../utils/tree';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, toHidden, toTree } from '../utils/form';
import {Brand, Company} from '../server-types';
import {EntityCreateModal} from "../types/entities";


export const BrandCreate: React.FC<EntityCreateModal<Brand, Company>> = ({
	originalEntity,
	onSubmit,
}) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				create={true}
				modal={true}
				entityName={ENTITY.BRAND}
				fetcher={graphqlQuery(BrandCreateQuery)}
				mutation={BrandCreateMutation}
				onSubmit={onSubmit}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entitiesToFields={(props) => ({
					EditorialSections: props.editorialSectionTree,
				})}
				fields={[
					'Company',
					'name',
					'logoMedia',
					'pricePositioning',
					'goodClasses',
					'retailChainDistribution',
					'foodServiceChainDistribution',
					'gourmetShopChainDistribution',
					isInternal ? 'order' : null,
					'website',
					'socialInstagramPage',
					'socialFacebookPage',
				]}
				transformer={(data, entity, listsByType) => {
					data.fields = fastTransformer({
						Company: (f) => {
							if (originalEntity) {
								return toHidden(toRefInput(originalEntity))(f);
							}
							return f;
						},
						goodClasses: (f) => {
							if (f.type === 'AsyncChoices') {
								if (isInternal) {
									return toTree(
										f,
										false,
										editorialSectionsToTree(
											listsByType.EditorialSections,
										),
									);
								} else {
									return toHidden(f.value)(f);
								}
							}
							return f;
						},
					})(data.fields);
					return data;
				}}
			/>
		)}
	</UserData>
);
