import { TargetSite, UserRole } from '../server-types';
const apiEndpointUrl =
	process.env.REACT_APP_API_ENDPOINT || 'https://api2.italianfood.net';

const CONFIG = {
	TARGET_SITE: TargetSite.Hub,
	ENTITIES_PER_PAGE: 50,
	BASE_URL: apiEndpointUrl,
	GRAPHQL_API: apiEndpointUrl + '/graphql',
	API_ENDPOINT: {
		AUTH: apiEndpointUrl + '/api/authenticate',
		SWITCH: apiEndpointUrl + '/api/switch',
		VERIFY: apiEndpointUrl + '/api/verify',
		TRACKING: apiEndpointUrl + '/api/bucket/tracking',
	},
	SENTRY_SERVER: 'https://baa48acce1c24aaf9ba91f3db7d72d9e@sentry.io/215198',
	GA_ID: 'UA-21094558-8',
	HOST: apiEndpointUrl,
	DEFAULT_REDIRECT_URL: '/',
	SENTRY_NOTIFICATION_TIMEOUT: 1000, // ms
	AUTH_ROLES: [UserRole.Producer],
	LOGIN_START_TRADITIONAL: true,
	LOGO: '/logo_gruppo.svg',
	SUPPORT_EMAIL: 'support@gruppofood.com',
};

export default CONFIG;
