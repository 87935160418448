import React from 'react';
import { MagazineCreateMutation } from '../graphql/mutations/MagazineCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Magazine.json';
import inputTypeIntro from '../static/introspection/MagazineCreateInput.json';
import { MagazineEditQuery } from '../graphql/query/MagazineEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

const MagazineCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(MagazineEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={MagazineCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.MAGAZINE}
		fields={[
			'MagazineCollection',
			'category',
			'publicationDate',
			'name',
			'title',
			'customHighlightDays',
			'issueNumber',
			'numOfTheYear',
			'numPages',
			'prefixPages',
			'customVisibilityType',
			'customVisibilityArgs',
			'fullUrl',
			'previewUrl',
			'dropboxPath',
			'Media',
			'previewMedia',
			'featuredImageMedia',
		]}
	/>
);

export { MagazineCreate };
