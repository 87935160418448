import React from 'react';
import { NewsletterDetailQuery } from '../graphql/query/NewsletterDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Newsletter.json';
import { ENTITY } from '../utils/entities';
import { assign, transformerHandler } from 'utils/misc';
import { fastTransformer } from 'utils/form';

export const NewsletterDetail: React.FC<{ id: string }> = ({ id }) => (
	<EntityDetailFetcher
		query={NewsletterDetailQuery}
		entityName={ENTITY.NEWSLETTER}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'name',
			'target',
			'delivery',
			'inputTemplate',
			'values',
		]}
		transformer={transformerHandler(
			fastTransformer({
				values: (f) =>
					assign({
						value: JSON.stringify(JSON.parse(f.value), null, 3),
						formatText: true,
					})(f),
			}),
		)}
	/>
);
